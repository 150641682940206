import { Component, OnInit, Input } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { TraderService } from '../../../services/trader.service';

@Component({
  selector: 'app-sort-popover',
  templateUrl: './sort-popover.component.html',
  styleUrls: ['./sort-popover.component.scss'],
})
export class SortPopoverComponent implements OnInit {
  data: any;
  items = []
  @Input() farms: any;
  SortingValues: any;
  lat: string;
  lng: string;
  u_idId: string;
  constructor(params: NavParams, private popctrl: PopoverController, private traderservice: TraderService) {
    console.log(params.get('dataFrom'))
    this.data = params.get('dataFrom')

  }

  ngOnInit() {
    this.lat = localStorage.getItem("lat");
    this.lng = localStorage.getItem("lng");
    this.u_idId = localStorage.getItem('u_id');
    if (this.data == 'vans') {
      this.items.push('Km', 'Capacity')
      this.getVans()
    } else if (this.data == 'home') {
      this.getNearestForms()
      this.items.push('Km', 'Price', 'Avg Weight', 'Capacity')
    }
  }
  sort(item) {
    if(this.data == 'vans'){
    if (item == 'Capacity') {
      this.SortingValues.sort( (a, b)=>b.capacity - a.capacity )
      this.popctrl.dismiss({
        newdata: this.SortingValues
      })
    }else if(item == 'Km'){
    console.log('this functionality is not completed because changes are not done')
    }
  }else if(this.data == 'home'){
    if(item == 'Capacity'){
      this.SortingValues.sort( (a, b)=> b.no_of_birds -  a.no_of_birds )
      this.popctrl.dismiss({
        newdata: this.SortingValues
      })
    }else if(item == 'Km'){
      this.SortingValues.sort((a, b)=>  a.distance - b.distance)
      this.popctrl.dismiss({
        newdata: this.SortingValues
      })
    }else if(item == 'Price'){
      this.SortingValues.sort( (a, b) => a.price - b.price)
      this.popctrl.dismiss({
        newdata: this.SortingValues
      })
    }else if(item == 'Avg Weight'){
      this.SortingValues.sort( (a, b)=> a.avg_weight - b.avg_weight)
      this.popctrl.dismiss({
        newdata: this.SortingValues
      })
    }
  }

  }



  //geting all the vans
  getVans() {
    this.traderservice.getVans({ u_id: localStorage.getItem('u_id') }).subscribe((data: any) => {
      this.SortingValues = data.data

    })
  }
  getNearestForms(){
    // let obj = {
    //   u_id: this.u_idId,
    //   lat: this.lat,
    //   lng: this.lng
    // }
    // this.traderservice.getFarmsList(obj).subscribe(data=>{
    //   console.log(data,'popover')
    //   this.SortingValues = data.data
    // })

  }


}


// ngOnInit() {
//   this.getVans()
// }
// // sorting functionality
// sort(item) {
//   if (item == 'Km') {
//     console.log('km functionality not completed')
//   } else if (item == 'Price') {
//     console.log('price functionality not completed')
//   } else if (item == 'Avg Weight') {
//     console.log('avg weight functionality not completed')
//   } else if (item == 'Capacity') {
//     console.log('capacity')
//     this.SortingVans.sort(function (a, b) {
//       return a.capacity - b.capacity
//     })
//     this.popctrl.dismiss({
//       newdata:this.SortingVans
//     })
//   }
// }
// //geting all the vans
// getVans() {
//   this.traderservice.getVans({ u_id: localStorage.getItem('u_id') }).subscribe((data: any) => {
//     console.log(data, 27777);
//     this.SortingVans = data.data

//   })
// }