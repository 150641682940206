import { Component, OnInit, ViewChild } from '@angular/core';
import * as HighCharts from 'highcharts';
import { CommonService } from 'src/services/common.service';
import { InventoryInfoService } from '../inventory-info/inventory-info.service';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { SupervisorService } from 'src/services/supervisor.service';
import { Subject } from 'rxjs';
import { ToastController, ModalController, IonSlides } from '@ionic/angular';
import { AlertController } from '@ionic/angular';
import { MarketService } from '../market-price-indicators/market.service';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { InventoryCreateComponent } from '../home/inventory-create/inventory-create.component'
import { FarmerService } from "../services/farmer-service.service";
import { NetworkService } from '../services/network.service';
import { LiftingsService } from 'src/services/liftings.service';
import { LoadingserviceService } from '../loadingservice.service';
import { TranslateConfigService } from '../services/translate-config.service';
import { TranslateService } from '@ngx-translate/core';
import { File } from '@ionic-native/file/ngx';
import { FirebaseX } from "@ionic-native/firebase-x/ngx";
import { Events } from '../services/events.service';
import { RolechangeService } from '../services/rolechange.service';
import { DeviceIdentityService } from '../services/device-identity.service';
import { LeaseService } from 'src/services/lease.service';
import { LeasePage } from '../lease/lease/lease.page';

// declare var RazorpayCheckout: any;
@Component({
  selector: 'app-poultry-home',
  templateUrl: './poultry-home.page.html',
  styleUrls: ['./poultry-home.page.scss'],
})
export class PoultryHomePage implements OnInit {
  @ViewChild(IonSlides) slides: IonSlides;
  countryCallingCode: string = localStorage.getItem('countryCallingCode');
  currencySymbol: string = localStorage.getItem('currencySymbol');
  country = localStorage.getItem('country').toLowerCase();
  roleRole = localStorage.getItem('role_id');
  imgUrl = '../../assets/icon/favicon.png';
  @ViewChild('lineCanvas') lineCanvas;
  options;
  roleName: string = localStorage.getItem('roleName');
  selectedGraphDay = 'week';
  graphDaySeries: any;
  graphValueSeries1: any;
  graphValueSeries2: any;
  graphDays = [{ day: 'week', value: 'week' }, { day: 'month', value: 'month' }, { day: 'year', value: 'year' }]
  day_status;
  back: any;
  slideOpts = {
    slidesPerView: 'auto',
    spaceBetween: 0,
  }
  slidesData = [
    {
      name: "broiler"
    },
    {
      name: "chick"
    },
    {
      name: "eggs"
    },
    {
      name: "hatchEggs"
    },
  ];
  commonArray;
  sliderOptions = {
    initialSlide: 0,
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true
    }
  }

  market_summaray_prices: any;
  curDate = new Date();
  inventoryData: any;
  myChart: HighCharts.Chart;
  chartData: HighCharts.Chart;
  myfarmData: any;
  notifyCount = 0;
  farmBG: string;
  role_id = localStorage.getItem("role_id");
  pricesettings: any;
  userRole: string = localStorage.getItem("role_id");
  role: string = localStorage.getItem('role_id');;
  supervisor_form_list: Object;
  shareurl;
  enablerefresh: boolean = false;

  w_bg: '';
  r_animation: boolean;
  rainAnimate: boolean = false;
  snowAnimate: boolean = false;
  showSun: boolean = false;
  showMoon: boolean = false;
  forcast = [];
  lineChart: any;
  countdata = [];
  units: string;
  market_price: any;
  chickprice: any;
  hatchprice: any;
  timedate = [];
  graphD: boolean;
  gp: string;
  broilerprice: any;
  eggsprice: any;
  features: { content: string; }[];
  dummy_content: any;
  rootPage: any = PoultryHomePage;
  public counter = 0;
  public alertShown: boolean = false;
  alertCount: any;
  showFarmImage: boolean = false;
  inventoryShow: boolean = false;
  orginalprices: any;
  farms: any;
  maxx: any;
  graphShow: boolean = false;
  orderNotifyStatus: boolean = false;
  orderList: any;
  notifyOrderCount: any;
  pendingOrderCount: number = 0;
  default: string;
  language: string;
  layerInventoriesList: any;
  BYE: number;
  layerTime: number;
  unset: any;
  priceStart = true;

  solidUnits: string = localStorage.getItem('solidUnits');
  pertonnInlbs:any;
  constructor(
    private socialSharing: SocialSharing,
    public alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private commonService: CommonService,
    private inventory: InventoryInfoService,
    public router: Router,
    private supervisorService: SupervisorService,
    private firebaseX: FirebaseX,
    private marketService: MarketService,
    private toastCtrl: ToastController,
    private farmerService: FarmerService,
    private net: NetworkService,
    private liftings: LiftingsService,
    private loading: LoadingserviceService,
    private translateConfigService: TranslateConfigService,
    public translate: TranslateService,
    private file: File,
    private alert: AlertController,
    public events: Events,
    public activeRote: ActivatedRoute,
    public roleChange: RolechangeService,
    public deviceIdentity: DeviceIdentityService,
    private leaseService: LeaseService
  ) {
    // console.log(this.deviceIdentity.isMobileDevice(), 140);
    if (localStorage.getItem('lngCode') && localStorage.getItem('lang') &&
      localStorage.getItem('lngCode') !== "undefined" &&
      localStorage.getItem('lang') !== "undefined" &&
      localStorage.getItem('lngCode') !== "null" &&
      localStorage.getItem('lang') !== "null") {
      this.default = localStorage.getItem('lngCode');
      this.commonService.setLang(this.default)
      this.translateConfigService.setLanguage(this.default);
      this.language = localStorage.getItem('lang');
    } else {
      translate.setDefaultLang('en');
      this.commonService.setLang('en');
      this.language = "English";
      this.default = 'en'
      localStorage.setItem("lang", "English");
      localStorage.setItem('lngCode', 'en');
    }

    if (localStorage.getItem('role_id') == '9') {
      this.slideOpts['initialSlide'] = 2;
    }

    this.role = localStorage.getItem("role_id");
    this.pushTokenSave();

    if (this.solidUnits == "pound") {
      this.pertonnInlbs = "1000lbs";
    } else {
      this.pertonnInlbs = "Tonn";
    }
  }

  ngOnInit() { };

  ionViewWillEnter() {
    this.postingsGet();
    this.liftings.getUnassignedAutoOrders({ skip: 0, u_id: "" }).subscribe((data: any) => {
      this.liftings.orderSubject.next(data);
      if (data.success) {
        this.liftings.pendingOrdersObserve$.subscribe((orderCount) => {
          if (orderCount.data.length) {
            this.pendingOrderCount = orderCount.data.length;
          } else {
            this.pendingOrderCount = 0;
          }
        });
      }
    });

    // let tempRole = localStorage.getItem('role_id');
    // if ([2, 8, 10, "2", "8", "10"].includes(tempRole)) {
    //   this.commonRoleFunc()
    // }


    // this.events.subscribe('user:created', (data: any) => {  // initial line. now changed tto user:farmer
    this.events.subscribe('user:farmer', (data: any) => {
      let role = data.user['role_Id'];
      this.roleName = (role == 2) ? "Trader" :
        (role == 8) ? 'Trader Supervisor' :
          (role == 1) ? "Farmer" :
            (role == 15) ? "Whole Seller" :
              (role == 18) ? "Retail supplier" :
                (role == 17) ? "Seller" :
                  (role == 3) ? "Supervisor" : "Chicken Shop";
      this.userRole = role;
      if (this.userRole == "1") {
        //layer farmer
        this.refresh(true);
        this.unsub();
      } else if (this.userRole == "3") {
        //layer supervisor
        this.unsub();
      } else {
        // this.commonRoleFunc();
        this.unsub();
      }
      this.events.destroy('user:farmer')   // destroy farmer role change event
    });

    this.layerTime = new Date().getHours()
    this.net.getCurrentNetworkStatus();
    this.role = localStorage.getItem('role_id');
    this.userRole = this.role;

    // if (this.userRole == 'commonrole') {
    //   this.commonService.getFeatures({
    //     "category": "feautres",
    //     role: localStorage.getItem('role_id')
    //   }).subscribe((data) => {
    //     if (data.success) {
    //       this.dummy_content = data.data[0];
    //       if (this.dummy_content) {
    //         var fetu = this.dummy_content.features;
    //         if (fetu) {
    //           this.features = fetu;
    //           this.loading.loadingDismiss()
    //         }
    //         else {
    //           this.features = [];
    //           this.loading.loadingDismiss()
    //         }
    //       }
    //     }
    //   });
    // }

    if (localStorage.getItem('country') == 'India') {
      this.loadNext();
      // this.commonService.marketPricesSummary().subscribe((data: any) => {
      //   if (data.length) {
      //     this.broilerprice = data[0];
      //     this.chickprice = data[1];
      //     this.eggsprice = data[2];
      //     this.hatchprice = data[3];
      //   }
      // });
    }

    this.userRole = this.role;
    // this.commonService.navItem$.subscribe((data: any) => {
    //   if (data.length > 0) {
    //     setTimeout(() => {
    //       this.myfarmData = data
    //     }, 200)
    //   }
    // });

    this.commonService.farmerNotifications$.subscribe((data: any) => {
      this.notifyCount = data;
    });

    if (this.userRole == '1' || this.userRole == '3') {
      this.farmerService.manageOrder().subscribe((data: any) => {
        if (data.success) {
          this.orderList = data.data;
          this.notifyOrderCount = this.orderList.length;
          this.orderNotifyStatus = (this.orderList.length > 0) ? true : false
        }
      });


      // this.farms = this.commonService.local_myfarms;

      // if (this.farms && this.farms.length) {
      //   this.showFarmImage = (this.farms.length > 0) ? false : true;
      //   this.myfarmData = this.farms;
      // } else {

      //   this.commonService.myform_getData({}).subscribe((res: any) => {
      //     if (localStorage.getItem('role_id') == '1' || localStorage.getItem('role_id') == '3' || localStorage.getItem('role_id') == '13') {
      //       this.myfarmData = res;
      //       this.commonService.local_myfarms = res;
      //       this.showFarmImage = (this.myfarmData.length > 0) ? false : true;
      //     } else {
      //       this.showFarmImage = false;
      //     }
      //   });
      // }

      let inventoryExist = this.inventory.getInventoryData();
      if (inventoryExist && inventoryExist.length > 0) {
        this.inventoryData = inventoryExist;
        if (this.inventoryData && this.inventoryData.length > 0) {
          this.inventoryShow = (this.inventoryData?.length <= 0) ? true : false;
          this.iterateInventoryData(this.inventoryData);
        } else {
          this.inventoryData = [];
          this.inventoryShow = (this.inventoryData?.length <= 0) ? true : false;
        }

      } else {
        this.inventory.inventoryInfo({}).subscribe((data: any) => {
          this.inventory.setInventoryData(data.data);
          this.inventory.setAValue(data.data);
          this.inventoryData = data.data;
          this.alertCount = data.alert_count;
          this.inventoryShow = (this.inventoryData?.length <= 0) ? true : false;
          if (this.inventoryData && this.inventoryData.length > 0) {
            this.iterateInventoryData(this.inventoryData);
          } else {
            this.inventoryData = [];
          }
        });
      }

      this.inventory.getBehaviourSubjectData().subscribe((data: any) => {
        this.inventoryData = data;
        this.inventoryShow = (this.inventoryData?.length <= 0) ? true : false;
        if (this.inventoryData && this.inventoryData.length > 0) {

          this.iterateInventoryData(this.inventoryData);
        } else {
          this.inventoryData = [];
        }
      });

      if (localStorage.getItem("role_id") == '1' || (localStorage.getItem("role_id") == '13')) {
        let params = {
          'u_id': localStorage.getItem("u_id")
        }
        this.commonService.farmerNotifications(params).subscribe((data: any) => {
          if (data.success) {
            this.notifyCount = (data.notification_count);
          }
        });
      }
    }
    if (this.userRole == '3') {
      this.supervisorService.getSupervisorList().subscribe((data: any) => {
        this.supervisor_form_list = data.data;
      });
    };
  }

  postings = [];
  postingsGet() {
    this.postings = [];
    this.commonService.ViewLeasePostings({ skip: 0, category: "all", from: "home" }).subscribe(data => {
      if (data) {
        data.forEach(element => {
          if (element.description.length > 25) {
            element['showMore'] = true;
            this.postings.push(element)
          } else {
            element['showMore'] = false
            this.postings.push(element)
          }
        });
        // this.postings = data;
      }
      else {
        this.postings = [];
      }
    })
  }

  capitalise(word) {
    if (!word) return word;
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }

  getDate(data) {
    let todayDate = new Date();
    let sentOnDate = new Date(data);
    sentOnDate.setDate(sentOnDate.getDate());
    let differenceInTime = todayDate.getTime() - sentOnDate.getTime();
    // To calculate the no. of days between two dates
    let differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
    return differenceInDays;
  }

  async createLease() {
    this.modalCtrl.create({
      component: LeasePage
    }).then(modal => {
      modal.present();
      modal.onDidDismiss().then(data => {
        console.log(data, 228)
        if (data.data.farm && !data.data.newData.latitude && !data.data.newData.longitude) {
          console.log("error in location");
          this.loading.showError("error in location selection")
        }
        else {
          if (data.data.farm) {
            this.loading.loadingPresent();
            this.leaseService.postLease(data.data.newData).subscribe(result => {
              console.log(result, 235)
              if (result.success) {
                this.loading.loadingDismiss();
                this.postingsGet();
              }
            })
          }
        }
      });
    });
  }

  showMore(data) {
    data['showMore'] = !data['showMore']
  }

  leaseDetails(data) {
    this.router.navigate(['/lease-home/lease-details']);
    this.leaseService.viewLeaseData(data)
  }

  loadNext() {
    this.priceStart = true;
    this.commonArray = [];
    this.slides.getActiveIndex().then((val) => {
      console.log(val);
      let rates;
      if (val == 0) {
        rates = "broiler"
      }
      else if (val == 1) {
        rates = "chick"
      }
      else if (val == 2) {
        rates = "eggs"
      }
      else if (val == 3) {
        rates = "hatchEggs"
      }
      this.commonService.marketIndicators({
        priceFor: rates
      }).subscribe((res) => {
        if (res.success) {
          this.commonArray = res.data;
          this.priceStart = false;
        }
      })
    })
  }

  loadPrev() {
    this.priceStart = true;
    this.commonArray = [];
    this.slides.getActiveIndex().then((val) => {
      console.log(val);
      let rates;
      if (val == 0) {
        rates = "broiler"
      }
      else if (val == 1) {
        rates = "chick"
      }
      else if (val == 2) {
        rates = "eggs"
      }
      else if (val == 3) {
        rates = "hatchEggs"
      }
      this.commonService.marketIndicators({
        priceFor: rates
      }).subscribe((res) => {
        if (res.success) {
          this.commonArray = res.data;
          this.priceStart = false;
        }
      })
    })
  }

  getName(data) {
    if (data == "broiler") {
      return "Broiler Rates"
    }
    else if (data == "chick") {
      return "Chick Rates"
    }
    else if (data == "eggs") {
      return "Egg Rates"
    }
    else {
      return "Hatch Egg Rates"
    }
  }

  ionViewDidEnter() { }

  // frwdFarmPage(data) {
  //   setTimeout(() => {
  //     this.loading.loadingDismiss();  
  //   }, 1000);
  //   if (this.userRole != '9') {
  //     this.loading.loadingPresent();
  //     if (data) {
  //       this.commonService.myfarmSetData(data);
  //       this.router.navigateByUrl('/home/tabs/myfarm/info');
  //     } else {
  //       this.router.navigateByUrl('/home/tabs/myfarm/myfarmedit');
  //     }
  //   }
  // }

  marketpricesPage(tab) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "active": tab,
      }
    };
    this.router.navigate(["/home/tabs/market-indicators"], navigationExtras);
    this.marketService._marketQueryParams = navigationExtras;
  }

  frwdPage(data) {
    this.inventory.clearIndexFunction();
    let temp = {
      id: data._id, days: data.days, info: data
    }
    this.inventory.setData(temp)
    let navigationExtras: NavigationExtras = { state: { urlFrom: this.router.url } };
    this.router.navigate(['inventory-detail'], navigationExtras);
  }

  async showFormula(detail) {
    let details = [
      {
        feedType: "soya",
        feedPercent: 60
      },
      {
        feedType: "maize",
        feedPercent: 40
      }
    ]
    let feedData = [];
    details.forEach(element => {
      feedData.push(`${element.feedType}:${element.feedPercent}%`)
    });
    const alert = await this.alert.create({
      header: this.translate.instant('Lfarmer.Formuladet'),
      message: `${feedData}`,
      buttons: [this.translate.instant('buttons.ok')]
    });
    await alert.present();
  }




  //farms background animations 
  background(j) {
    if (j.hasOwnProperty('weth_type') && j['weth_type'].hasOwnProperty('main')) {
      if (j['weth_type']['main'] != undefined) {
        let tempVar = j.weth_type.main;
        this.day_status = j.weth_type.icon.match(/[^\d]+|\d+/g);
        if (tempVar == "Rain" && this.day_status[1] == 'd') {
          this.farmBG = "v_rain"
        } else if (tempVar == "Rain" && this.day_status[1] == 'n') {
          this.farmBG = "v_rain_night";
        } else if (tempVar == "Clear" && this.day_status[1] == 'd') {
          this.farmBG = "v_clear";
        } else if (tempVar == "Clear" && this.day_status[1] == 'n') {
          this.farmBG = "v_clear_night";
        } else if (tempVar == "Snow" && this.day_status[1] == 'd') {
          this.farmBG = "v_fog";
        } else if (tempVar == "Snow" && this.day_status[1] == 'n') {
          this.farmBG = "v_fog_night";
        } else if (tempVar == "Clouds" && this.day_status[1] == 'd') {
          this.farmBG = "v_clouds";
        } else if (tempVar == "Clouds" && this.day_status[1] == 'n') {
          this.farmBG = "v_clouds_night";
        } else if (tempVar == "Thinderstrom" && this.day_status[1] == 'd') {
          this.farmBG = 'v_thunderstrom';
        } else if (tempVar == "v_thunderstrom_night" && this.day_status[1] == 'n') {
        } else if (tempVar == "Drizzle" && this.day_status[1] == 'd') {
        } else if (tempVar == "Drizzle" && this.day_status[1] == 'n') {
        }
      }
      return this.farmBG;
    } else {
      return this.farmBG
    }
  }

  //refresh options for home page
  refresh(event: boolean) {
    if (event) {
      this.enablerefresh = true;
      setTimeout(() => {
        this.enablerefresh = false;
      }, 1000);
      // if (localStorage.getItem('country') == "India") {
      //   this.commonService.marketPricesSummary().subscribe((data: any) => {
      //     // this.market_summaray_prices = data.data[0];
      //     if (data.length) {
      //       this.broilerprice = data[0];
      //       this.chickprice = data[1];
      //       this.eggsprice = data[2];
      //       this.hatchprice = data[3];
      //     }
      //   });
      // }
      if (localStorage.getItem('role_id') == '1' || localStorage.getItem('role_id') == '3') {
        // this.commonService.myform_getData({}).subscribe((res: any) => {
        //   if ((res.success && (this.role_id == '1' || this.role_id == '3'))) {
        //     this.myfarmData = res.data;
        //   }
        // });
        this.postingsGet();
        this.inventory.inventoryInfo({}).subscribe((data: any) => {
          if (data.success) {
            this.inventoryData = data.data;
            this.inventory.setInventoryData(data.data);
            this.inventory.setAValue(data.data);
            this.alertCount = data.alert_count;
          } else {
            // console.log("get inventory data failed", 607);
          }
        });
      } else {
        // this.commonService.getFeatures({
        //   "category": "feautres",
        //   role: localStorage.getItem('role_id')
        // }).subscribe((data: any) => {
        //   if (data.success) {
        //     this.dummy_content = data.data[0];
        //     if (this.dummy_content) {
        //       var fetu = this.dummy_content.features;
        //       if (fetu) {
        //         this.features = fetu;
        //       }
        //       else {
        //         this.features = [];
        //       }
        //     }
        //   }
        // });
      }
    }
  }
  //refresh options are done.
  // createfarm() {
  //   if (this.myfarmData.length < 5) {
  //     this.modalCtrl.create({
  //       component: MyfarmCreateEditComponent
  //     }).then(modal => {
  //       modal.present();
  //       modal.onDidDismiss().then((data: any) => {
  //         if (data.data.farm) {
  //           this.loading.loadingPresent();
  //           this.commonService.myfarm_farmInsert(data.data.farmData).subscribe((data) => {
  //             if (data.success) {
  //               this.loading.loadingDismiss();
  //               this.myfarmData = data.data;
  //               this.showFarmImage = this.myfarmData.length > 0 ? false : true;
  //               this.inventory.local_inventory_list = data.inventory_list;
  //               this.inventoryData = data.inventory_list;
  //               if (this.inventoryData) {
  //                 this.iterateInventoryData(this.inventoryData);
  //               }
  //               this.commonService.setAValue(data.data);
  //               this.inventory.setAValue(data.inventory_list);
  //               this.inventory.setInventoryData(data.inventory_list);
  //               this.loading.showError(this.translate.instant('flashMessages.insertedSuccessfully'));
  //             } else {
  //               this.loading.loadingDismiss();
  //             }
  //           })

  //           // this.myfarmData = data.data.newData.data;
  //           // this.showFarmImage = this.myfarmData.length >0 ? false : true;
  //           // this.inventory.local_inventory_list = data.data.newData.inventory_list;
  //           // this.inventoryData = data.data.newData.inventory_list;
  //           // if (this.inventoryData) {
  //           //   this.iterateInventoryData(this.inventoryData);
  //           // }
  //           // this.commonService.setAValue(data.data.newData.data);
  //           // this.inventory.setAValue(data.data.newData.inventory_list);
  //           // this.inventory.setInventoryData(data.data.newData.inventory_list);
  //         } else {
  //           console.log("something went Wrong", 787);
  //         }
  //       });
  //     });
  //   } else {
  //     this.loading.showError(this.translate.instant('alertMessages.farmgrater'))
  //   }
  // };

  createInventory() {
    if (this.inventoryData.length < 5) {
      this.modalCtrl.create({
        component: InventoryCreateComponent
      }).then(modal => {
        modal.present();
        modal.onDidDismiss().then((data: any) => {
          if (data.data.inven) {
            this.loading.loadingPresent();
            this.inventory.addInventory(data.data.farmData).subscribe((data: any) => {
              if (data.success) {
                this.loading.loadingDismiss();
                this.inventoryData = data.data;
                if (this.inventoryData.length > 0) {
                  this.inventoryShow = false;
                }
                setTimeout(() => {
                  if (this.inventoryData.length > 0) {
                    this.inventoryShow = false;
                  }
                }, 100)
                // this.myfarmData = data.farms;
                // this.showFarmImage = this.myfarmData.length > 0 ? false : true;
                // this.commonService.setAValue(data.farms);
                this.inventory.setInventoryData(data.data);
                this.inventory.setAValue(data.data);
                this.inventoryData = data.data;
                // this.commonService.local_myfarms = data.farms;
              } else {
                this.loading.loadingDismiss();
              }
            });
          }
        });
      });
    } else {
      this.loading.showError(this.translate.instant('flashMessages.morethenFiveInventory'));
    }
  };

  disclaimer() {
    this.router.navigate(['/disclaimer']);
  }

  async showError(msg) {
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: 2000,
      position: 'bottom',
      buttons: [
        {
          text: this.translate.instant('buttons.done'),
          role: 'cancel',
          handler: () => {
            // console.log('Cancel clicked');
          }
        }
      ],
      animated: true,
      cssClass: "my-custom-class toast-text-center",
    });
    toast.present();
  }


  iterateInventoryData(data) {
    data.forEach(element => {
      if (element.last_updated_date) {
        let today = new Date();
        let inventoryDate = new Date(element.last_updated_date);
        let month1 = today.getMonth() + 1;
        let month2 = inventoryDate.getMonth() + 1;
        let date1 = today.getDate() + "/" + month1 + "/" + today.getFullYear();
        let date2 = inventoryDate.getDate() + "/" + month2 + "/" + inventoryDate.getFullYear();
        if (date1 == date2) {
          element.blink = false;
        } else {
          element.blink = true;
        }
      } else {
        let today = new Date();
        let inventoryDate = new Date(element.arrival_date);
        let month1 = today.getMonth() + 1;
        let month2 = inventoryDate.getMonth() + 1;
        let date1 = today.getDate() + "/" + month1 + "/" + today.getFullYear();
        let date2 = inventoryDate.getDate() + "/" + month2 + "/" + inventoryDate.getFullYear();
        if (date1 == date2) {
          element.blink = false;
          element.last_updated_date = element.arrival_date;
        } else {
          element.blink = true;
          element.last_updated_date = element.arrival_date;
        }
      };
    });
  }


  sharing(clickChoice) {
    if (clickChoice == "whatsapp") {
      this.socialSharing.shareViaWhatsApp("write message", undefined, "https://play.google.com/store/apps/details?id=com.poultryapp.www").then((data) => {
      }).catch((error) => {
        // console.error(error);
      });
    } else {
      this.commonBrowserOpen('https://poultryapp.com/')
    }

  }

  commonBrowserOpen(url) {
    this.socialSharing.share("Poultry App :-)", "It's great!", null, url).then(s => {
      // console.log(s, 19)
    }).catch(e => {
      // console.log('Error' + e)
    })
  }
  receivedOrders() {
    // this.farmerService.updateOrderNotification({}).subscribe((data) => {
    //   this.notifyOrderCount = 0;
    // });
    // this.orderNotifyStatus = (this.notifyOrderCount > 0) ? true : false;
    this.supervisorService.routeChange({ segment: "ordersRoute" })
    this.router.navigateByUrl('home/tabs/liftings');
  }

  async langTranslation() {
    const alert = await this.alertCtrl.create({
      inputs: [
        {
          type: 'radio',
          label: this.translate.instant("languages.english"),
          value: 'English',
          checked: this.language == 'English'
        },
        {
          label: this.translate.instant("languages.telugu"),
          type: 'radio',
          value: 'తెలుగు',
          checked: this.language == 'తెలుగు'
        },
        {
          type: 'radio',
          label: this.translate.instant("languages.hindi"),
          value: 'हिन्दी',
          checked: this.language == 'हिन्दी'
        },
        {
          label: this.translate.instant("languages.tamil"),
          type: 'radio',
          value: 'தமிழ்',
          checked: this.language == 'தமிழ்'
        },
        {
          label: this.translate.instant("languages.kannada"),
          type: 'radio',
          value: 'ಕನ್ನಡ',
          checked: this.language == 'ಕನ್ನಡ'
        },
        {
          label: this.translate.instant("languages.malayalam"),
          type: 'radio',
          value: 'ಮಲಯಾಳಂ',
          checked: this.language == 'ಮಲಯಾಳಂ'
        }
      ],
      message: this.translate.instant('languages.selectLanguage'),
      cssClass: 'customAllert',
      buttons: [
        {
          text: this.translate.instant('buttons.cancel'),
          role: 'cancel',
          handler: (blah) => {
            // console.log('you cant choose any languages');
          }
        },
        {
          text: this.translate.instant('buttons.ok'),
          handler: (result) => {
            this.language = result;
            this.default = (result == "తెలుగు") ? "te" : (result == "हिन्दी") ? "hi" : (result == "தமிழ்") ? "ta" : (result == "ಕನ್ನಡ") ? 'ka' : (result == "ಮಲಯಾಳಂ") ? 'ma' : "en";
            if (result == 'தமிழ்') {
              this.translateConfigService.setLanguage("ta");
              this.commonService.setLang("ta")
              let langData = {
                lang: 'தமிழ்',
                lngCode: "ta"
              }
              this.loading.loadingPresent();
              this.commonService.changeLanguage(langData).subscribe((data: any) => {
                if (data.success) {
                  localStorage.setItem("lang", langData.lang);
                  localStorage.setItem("lngCode", langData.lngCode);
                  this.loading.loadingDismiss();
                }
              });
            } else if (result == 'తెలుగు') {
              this.translateConfigService.setLanguage("te");
              this.commonService.setLang("te")
              let langData = {
                lang: 'తెలుగు',
                lngCode: "te"
              }
              this.loading.loadingPresent();
              this.commonService.changeLanguage(langData).subscribe((data: any) => {
                if (data.success) {
                  localStorage.setItem("lang", langData.lang);
                  localStorage.setItem("lngCode", langData.lngCode);
                  this.loading.loadingDismiss();
                }
              })
            } else if (result == 'हिन्दी') {
              this.translateConfigService.setLanguage("hi");
              this.commonService.setLang("hi")
              let langData = {
                lang: 'हिन्दी',
                lngCode: "hi"
              }
              this.loading.loadingPresent();
              this.commonService.changeLanguage(langData).subscribe((data: any) => {
                if (data.success) {
                  localStorage.setItem("lang", langData.lang);
                  localStorage.setItem("lngCode", langData.lngCode);
                  this.loading.loadingDismiss();
                }
              })
            } else if (result == 'ಕನ್ನಡ') {
              this.translateConfigService.setLanguage("ka");
              this.commonService.setLang("ka")
              let langData = {
                lang: 'ಕನ್ನಡ',
                lngCode: "ka"
              }
              this.loading.loadingPresent();
              this.commonService.changeLanguage(langData).subscribe((data: any) => {
                if (data.success) {
                  localStorage.setItem("lang", langData.lang);
                  localStorage.setItem("lngCode", langData.lngCode);
                  this.loading.loadingDismiss();
                }
              })
            } else if (result == 'ಮಲಯಾಳಂ') {
              this.translateConfigService.setLanguage("ma");
              this.commonService.setLang("ma")
              let langData = {
                lang: 'ಮಲಯಾಳಂ',
                lngCode: "ma"
              }
              this.loading.loadingPresent();
              this.commonService.changeLanguage(langData).subscribe((data: any) => {
                if (data.success) {
                  localStorage.setItem("lang", langData.lang);
                  localStorage.setItem("lngCode", langData.lngCode);
                  this.loading.loadingDismiss();
                }
              })
            } else {
              this.translateConfigService.setLanguage("en");
              this.commonService.setLang("en")
              let langData = {
                lang: 'English',
                lngCode: "en"
              }
              this.loading.loadingPresent();
              this.commonService.changeLanguage(langData).subscribe((data: any) => {
                if (data.success) {
                  localStorage.setItem("lang", langData.lang);
                  localStorage.setItem("lngCode", langData.lngCode);
                  this.loading.loadingDismiss();
                }
              })
            }
          }
        }
      ],
    });
    await alert.present();
  }

  async resloveFile() {
    // return this.file.copyFile(``)
    return this.file.copyFile(`${this.file.applicationDirectory}www/assets/icon`, 'favicon.png', this.file.cacheDirectory, `${new Date()}.jpg`)
  }

  removeTempFile(name) {
    this.file.removeFile(this.file.cacheDirectory, name)
  }

  pushTokenSave() {
    // save the token server-side and use it to push notifications to this device
    this.firebaseX.getToken().then(token => {
      this.commonService.savePushToken(Object.assign({ pushToken: token }, { u_id: localStorage.getItem('u_id') })).subscribe(
        (result) => {
          // console.log(result);
        }
      )
    }).catch(error => {
      // console.error('Error getting token', error);
    });

    this.firebaseX.onMessageReceived().subscribe(data => {
      this.router.navigateByUrl("/notification");
    });

    this.firebaseX.onTokenRefresh().subscribe((token: string) => {
      // console.log(`Got a new token ${token}`);
    });
  }

  openExpencess() {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        //  inventory:true,
        route: '/home/tabs/poultry-home'
      }
    };
    this.router.navigate(["/statisticks"], navigationExtras)
  }

  async openLifted(Day) {
    if (Day > 30) {
      this.router.navigateByUrl("/lifting-supervisor");
    } else {
      const toast = await this.toastCtrl.create({
        message: this.translate.instant('flashMessages.inventoryNotAllowForLifting'),
        duration: 3000,
        position: 'bottom',
        buttons: [
          {
            text: 'Done',
            role: 'cancel',
            handler: () => {
              // console.log('Cancel clicked');
            }
          }
        ],
        animated: true,
        cssClass: "my-custom-class toast-text-center",
      });
      toast.present();
    }
  }

  gotoSales(inventoryData) {
    if (this.userRole == '9') {
      // let navigationExtras: NavigationExtras = {
      //   queryParams: {
      //     farmId: inventoryData._id,
      //     totaleggs: inventoryData.collectedExtraEggs,
      //     totaltrays: inventoryData.collectedTrays,
      //   },
      // }
      // this.router.navigate(['/layerssalesdetails'], navigationExtras);
      this.router.navigate(['/layers-sales']);
    }
  }

  gotoExpenses(data) {
    if (this.userRole == '9') {
      this.router.navigate(['/statisticks'])
    }
  }
  async textBlink(itemData) {
    itemData.forEach(element => {
      if (element.lastInventoryUpdated) {
        let today = new Date();
        let inventoryDate = new Date(element.lastInventoryUpdated);
        let month1 = today.getMonth() + 1;
        let month2 = inventoryDate.getMonth() + 1;
        let date1 = today.getDate() + "/" + month1 + "/" + today.getFullYear();
        let date2 = inventoryDate.getDate() + "/" + month2 + "/" + inventoryDate.getFullYear();
        if (date1 == date2) {
          element.blink = false;
        } else {
          element.blink = true;
        }
      } else {
        let today = new Date();
        let inventoryDate = new Date(element.arrivalDate);
        let month1 = today.getMonth() + 1;
        let month2 = inventoryDate.getMonth() + 1;
        let date1 = today.getDate() + "/" + month1 + "/" + today.getFullYear();
        let date2 = inventoryDate.getDate() + "/" + month2 + "/" + inventoryDate.getFullYear();
        if (date1 == date2) {
          element.blink = false;
        } else {
          element.blink = true;
        }
      };
    });
  }


  // commonRoleFunc() {
  //   this.commonService.getFeatures({
  //     "category": "feautres",
  //     role: localStorage.getItem('role_id')
  //   }).subscribe((data) => {
  //     if (data.success) {
  //       this.dummy_content = data.data[0];
  //       if (this.dummy_content) {
  //         var fetu = this.dummy_content.features;
  //         if (fetu) {
  //           this.features = fetu;
  //           this.loading.loadingDismiss()
  //         }
  //         else {
  //           this.features = [];
  //           this.loading.loadingDismiss()
  //         }
  //       }
  //     }
  //   });
  // }

  // payWithRazor(Orderdata) {
  //   console.log(Orderdata);
  //   var options = {
  //     description: 'Poultry account subcription',
  //     image: 'https://img.techpowerup.org/200531/logo2.png',
  //     currency: 'INR',
  //     key: 'rzp_test_XaywDYrFTIzUA2',
  //     order_id: 12,
  //     amount: 20,
  //     name: 'Poultry',
  //     prefill: {
  //       email: '',
  //       contact: '',
  //       name: ''
  //     },
  //     theme: {
  //       color: '#24c2f2'
  //     }
  //   }
  //   console.log(options, 985);

  //   // RazorpayCheckout.on('payment.success', (success) => {
  //   //   console.log(success)
  //   //   // this.consumer.placeOrders(success).subscribe((data: any) => {
  //   //   //   if (data.success) {
  //   //   //     this.loader.toastMessage(this.translate.instant('alertText.orderPlacedSuccess'));
  //   //   //     this.route.navigateByUrl('/order-success');
  //   //   //   } else {
  //   //   //     console.log("Order was not Placed", 156);
  //   //   //   }
  //   //   // }, (err) => {
  //   //   //   this.loader.loadingDismiss();
  //   //   //   console.error("Error : " + JSON.stringify(err));
  //   //   // }, () => {
  //   //   //   this.loader.loadingDismiss();
  //   //   //   console.log('order placed completed', 163);
  //   //   // });
  //   // });
  //   // RazorpayCheckout.on('payment.cancel', (error) => {
  //   //   alert(error.description + ' (Error ' + error.code + ')')
  //   // });

  //   var successCallback = function (payment_id) {
  //     alert('payment_id: ' + payment_id);
  //   };

  //   var cancelCallback = function (error) {
  //     console.log(error, 1013)
  //     // alert(error.description + ' (Error ' + error.code + ')');
  //   };

  //   RazorpayCheckout.open(options, successCallback, cancelCallback);
  // }
  unsub() {
    // this.events.destroy('user:created');
  }

  roleChangeEvent(event) {
    console.log(event, 1035);
  }

  convertTranslationUnits(unit) {
    // console.log(unit)
    let covertUnit = this.translate.instant("common." +  this.solidUnits)
    // console.log(covertUnit)
   let showUnit = (unit == 'kg') ?  covertUnit : (unit == 'tonn') ? this.pertonnInlbs : (unit == '50kg') ?  `*50 ${covertUnit}` : (unit == '60kg') ? `*60 ${covertUnit}` : (unit == '80kg') ? `*80 ${covertUnit}` :  `*100 ${covertUnit}`;
   return showUnit;
  }
}