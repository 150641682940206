import { Component, OnInit } from '@angular/core';
import { Route, Router, ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormControl, FormBuilder, FormsModule } from '@angular/forms';
import { TraderService } from '../../services/trader.service';
import { ModalController, NavParams, ToastController } from '@ionic/angular';
import { LoadingserviceService } from '../loadingservice.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-trader-price-settings',
  templateUrl: './trader-price-settings.component.html',
  styleUrls: ['./trader-price-settings.component.scss'],
})
export class TraderPriceSettingsComponent implements OnInit {
  priceStatus = true;
  activeTab: Boolean = true;
  settingsForm: FormGroup;
  ManualSettingForm: FormGroup;
  cityname;
  dataId: any;
  ngModelState = false;
  checkedState = false
  FormValues: any;
  isToggled: boolean;
  cities: any;
  citytrue: boolean = false;

  prices: any;
  xyz: any;
  city: any;
  constructor(private route: Router,
    private fb: FormBuilder,
    private toastController: ToastController,
    private trader: TraderService,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private modal: ModalController,
    private navParam: NavParams,
    private LS: LoadingserviceService) {
  }
  validation_messages = {
    'marketPrice': [{ type: 'required', message: 'required.' }],
    'automaticCounter': [{ type: 'required', message: 'required.' }],
    'margin': [{ type: 'required', message: 'required.' }],
  }

  ngOnInit() {
    this.LS.loadingPresent();
    this.nearCities();
    this.settingsForm = this.fb.group({
      marketPrice: ['', Validators.required],
      automaticCounter: [false, Validators.required],
      city: ['', Validators.required],
      margin: ['', Validators.required],
    })

    this.ManualSettingForm = this.fb.group({
      marketPrice: ['', Validators.required],
      margin: ['', Validators.required],

    })
    //get trader price
    this.trader.getPrice().subscribe(data => {
      this.LS.loadingDismiss();
      if (data.data.length > 0) {
        console.log(data.data[0], 512);
        this.prices = data.data[0];
        console.log(this.prices);
        if (data.data[0].priceSetting === "auto") {
          this.activeTab = true;
          console.log(data.data[0].marketPrice, 4554)
          this.settingsForm.patchValue({
            marketPrice: data.data[0].marketPrice,
            margin: data.data[0].margin,
            // city: data.data[0].city
          })
          this.priceStatus = data.data[0].status;
          this.settingsForm.controls['marketPrice'].setValue(data.data[0].marketPrice);
        } else {
          this.activeTab = false;
          this.settingsForm.patchValue({
            marketPrice: data.data[0].marketPrice,
            margin: data.data[0].margin,
          })
          this.priceStatus = data.data[0].status;
        }
      } else {
        // this.trader.automaticprice().subscribe((resp) => {
        //   if (resp.success) {
        //     this.settingsForm.patchValue({
        //       marketPrice: resp.data[0].price,
        //     })
        //   }
        // });
      }
    })


  }


  //submit price value
  submit(trader: boolean) {
    let res = {};
    if (trader) {
      console.log(this.settingsForm.value, 890);
      if (this.activeTab) {
        Object.assign(this.settingsForm.value, { priceChoice: "auto",cityId:this.settingsForm.value.city.city_id,cityName:this.settingsForm.value.city.city_name });      } else {
        Object.assign(this.settingsForm.value, { priceChoice: "manual", });
        delete this.settingsForm.value.city;
      }

      console.log(this.settingsForm.value, 1254);

      this.trader.priceSettings(this.settingsForm.value).subscribe((data) => {
        console.log(data, 67890);
        if (data.success == true) {
          if (data.data[0].priceSetting == "auto") {
            this.settingsForm.patchValue({
              marketPrice: data.data[0].marketPrice,
              margin: data.data[0].margin,
              status: data.data[0].status,
              // city: data.data[0].city
            })
          } else {
            this.ManualSettingForm.patchValue({
              marketPrice: data.data[0].marketPrice,
              margin: data.data[0].margin,
              status: data.data[0].status,
            })
          }
        }
      })
      this.modal.dismiss();
      this.showError(this.translate.instant('flashMessages.submited'))
    } else {
      this.modal.dismiss({
        status: trader
      })
    }
    this.route.navigateByUrl('/trader/tabs/orders');
  }
  //toggle status
  MyToggle() {
    this.trader.priceSettingUpdate({ status: true }).subscribe(
      (data) => {
        console.log(data);
      }
    )
    console.log(!this.priceStatus)
  }
  //toast contrller
  async showError(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 3000,
      position: 'bottom',
      animated: true,
      cssClass: "my-custom-class",
    });
    toast.present();
  }



  tabChange(type) {
    if (type === 'auto') {
      this.activeTab = true;
      this.settingsForm.get('marketPrice').disable();
      this.formControlValuesAdd();
    } else {
      this.activeTab = false;
      this.settingsForm.get('marketPrice').enable();
      this.formControlValueRemove();
    }
  }

  formControlValuesAdd() {
    const city = this.settingsForm.get('city');
    if (this.activeTab) {
      city.setValidators([Validators.required]);
    }
    city.updateValueAndValidity();
  }

  formControlValueRemove() {
    console.log(this.activeTab, 2345);
    const city = this.settingsForm.get('city');
    if (!this.activeTab) {
      city.clearValidators();
    } else {
      city.setValidators([Validators.required]);
    }
    city.updateValueAndValidity();
  }

  // cityPrice(data) {
  //   let selectedCity = this.cities.find((item) => {
  //     return (item.city_name == data) ? item : ''
  //   })
  //   console.log(selectedCity, 5456);
  //   this.settingsForm.patchValue({
  //     marketPrice: selectedCity.price,
  //   })
  // }
  nearCities() {
    this.trader.nearesetPriceAvailableCity({}).subscribe(
      (result) => {
        console.log(result);
        if (result.success) {
          this.cities = result.data;
          this.cityname = this.cities[0].city_name;
          // this.citytrue = true;
        }
      }
    )
  }
}
