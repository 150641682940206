import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { config } from '../helper/config';
import { map } from 'rxjs/operators';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InventoryInfoService {
  in: any;
  inventoryData;
  inventoryDataStore: any;
  local_inventory_list = [];
  infoOfInventory: any;
  dailyInventoryData: any;
  updateIndex;
  protected inventoryd = new BehaviorSubject<any>([]);

  // navItem$ = this.inventoryd.asObservable().pipe(distinctUntilChanged());

  constructor(private httpClient: HttpClient) { }
  public data: any


  setData(data) {
    this.data = data
  }

  getData() {
    let tempararydata = this.data;
    return tempararydata;
  }

  setta(val) {
    this.in = val;
  }
  getta() {
    let a = this.in;
    if (a == undefined) {
      a = {}
    } else {
      a;
    }
    return a;
  }

  setInventoryInfo(info) {
    this.infoOfInventory = info;
  }
  getInventoryInfo() {
    return this.infoOfInventory;
  }
  dailyInventoryView(data) {
    this.dailyInventoryData = data;
  }

  getDailyInventoryView() {
    this.dailyInventoryData;
  }

  setUpdateIndex(data) {
    this.updateIndex = data;
  }

  getUpdateIndex() {
    return this.updateIndex;
  }

  clearIndexFunction() {
    this.updateIndex = [];
  }

  inventoryInfo(data): Observable<any> {
    // return this.httpClient.get(config.api + 'inventory/getinventories');
    return this.httpClient.get(config.api + `inventory/getinventories?u_id=${data.u_id}`);
  }
  layerInventory(): Observable<any> {
    return this.httpClient.get(config.api + 'layers/layersinventoryList');
  }

  setInventoryData(data) {
    this.inventoryDataStore = data;
  }

  getInventoryData() {
    return this.inventoryDataStore;
  }
  // inventoryInfo(): Observable<any> {
  //   return this.httpClient.get(config.api + 'inventory/getinventories').pipe(map((response: any) => {
  //     console.log(response, 52);
  //       if (response.success) {
  //         this.local_inventory_list = response.data;
  //         return this.local_inventory_list;
  //       } else {
  //         return response;
  //       }
  //   }));
  // }

  clearInventoryInfo(): Observable<any> {
    this.local_inventory_list = null;
    return null;
  }

  setAValue(data) {
    this.inventoryd.next(data);
  }

  getBehaviourSubjectData() {
    return this.inventoryd.asObservable().pipe(distinctUntilChanged());
  }
  addInventory(inventoryData): Observable<any> {
    return this.httpClient.post<Observable<any>>(`${config.api}inventory/createinventory`, inventoryData);
  }


}
