import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController, ModalController, NavParams, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { SupervisorService } from 'src/services/supervisor.service';
import { InventoryDetailService } from '../inventory-detail/inventory-detail.service';
import { LayerfarmerSupervisorService } from '../services/layerfarmer-supervisor.service';
import { LayersService } from '../services/layers.service';
import { FeedrelatedService } from "../services/feedrelated.service";
import { ThousandSeperatorPipe } from '../pipes/thousand-seperator.pipe';

@Component({
  selector: 'app-inventory-feed-edit',
  templateUrl: './inventory-feed-edit.component.html',
  styleUrls: ['./inventory-feed-edit.component.scss'],
})
export class InventoryFeedEditComponent implements OnInit {
  select: any = [];
  addfeedstock: FormGroup;
  feedIntake: FormGroup;
  feedTransferForm: FormGroup;
  editableNo: boolean = false;
  selectedunits: string = 'kg';
  solidUnits: string = localStorage.getItem('solidUnits');
  todayDate = new Date().toISOString();
  noAccess: boolean = false;
  edit: boolean = false;
  selectFeedId: any;
  unitsnum: any;
  totalStock: any;
  feedTypeWise12: any = [];
  selectConsumeFeedId: any;
  showConsumeQuantity: boolean = false;
  editDocInfo: any;
  showAddDate: any;
  inventoryName: any;
  editSelectFeedId: any;
  feedInakeBtn: boolean = true;
  feedTransferBtn: boolean = true;
  selectFeedTransferId: any;
  selectInvId: any;
  presentInvId: any;
  selectInvName: any;
  consumedArray = [];
  transferArray = [];
  invList = [];
  validation_messages = {
    type: [{ type: "required", message: "choose Feed" }],
    quantity: [{ type: "required", message: "Quantity is required" }],
    price: [{ type: "required", message: "price is required" }],
    selectInv: [{ type: "required", message: "price is required" }],
  };
  pertonnInlbs: string;
  currencySymbol = localStorage.getItem('currencySymbol');
  feedtypeName: any;
  exitCosumedFeed = [];
  feedTransferInv: boolean = false;
  feedPurpose: any;
  docExit: boolean = false;
  selectTransferFeedId: any;
  exitDocId: any;
  addStockExit: any;
  editExitFeed: any;
  editTransferFeed: any;
  constructor(
    private modal: ModalController,
    protected navParams: NavParams,
    private formBuilder: FormBuilder,
    public translate: TranslateService,
    private alertController: AlertController,
    private LayersService: LayersService,
    private supervisor: SupervisorService,
    private layerSupervisor: LayerfarmerSupervisorService,
    private alert: AlertController,
    public supervisorService: SupervisorService,
    private toastController: ToastController,
    protected inventoryDetail: InventoryDetailService,
    private FeedrelatedService: FeedrelatedService,
  ) {
    this.selectedunits = this.navParams.get('selectedUnits') || 'kg';
    if (this.solidUnits == "pound") {
      this.pertonnInlbs = "1000lbs";
    } else {
      this.pertonnInlbs = "Tonn";
    }

    this.feedPurpose = this.navParams.get('feedPurpose');
    this.docExit = this.navParams.get('exitDoc');
    this.presentInvId = this.navParams.get('invId');
    this.showAddDate = this.navParams.get('date');
    this.getInvFeedStockDetails();
    if (this.feedPurpose == 'add') {
      this.addfeedstock = this.formBuilder.group({
        date: ['', [Validators.required]],
        type: ['', [Validators.required]],
        quantity: ['', [Validators.required]],
        price: ['', [Validators.required]],
        invName: ['', [Validators.required]],
      });
      console.log(this.docExit, 100);
      if (this.docExit) {
        this.editableNo = true;
        let exitDocDetails = this.navParams.get('doc');
        this.addStockExit = exitDocDetails;
        let pricePerKg = exitDocDetails.price;
        let exitAddUnits = exitDocDetails.units;
        let pricePerUnits = this.calcPriceBasedOnUnits(pricePerKg, exitAddUnits);
        // ((this.selectedunits == 'kg') ? (exitDocDetails.price * 1).toFixed(2) : (this.selectedunits == 'tonn') ? (exitDocDetails.price * 1000).toFixed(2) : (this.selectedunits == '50kg') ? (exitDocDetails.price * 50).toFixed(2) : (this.selectedunits == '60kg') ? (exitDocDetails.price * 60).toFixed(2) : (this.selectedunits == '80kg') ? (exitDocDetails.price * 80).toFixed(2) : (exitDocDetails.price * 100).toFixed(2)).toString();

        this.addfeedstock.controls['date'].setValue(this.navParams.get('date'));
        this.addfeedstock.controls['invName'].setValue(this.navParams.get('invName'));
        this.addfeedstock.controls['type'].setValue(exitDocDetails.feedType);
        this.addfeedstock.controls['price'].setValue(pricePerUnits);
        this.addfeedstock.controls['quantity'].setValue(exitDocDetails.quantity);
        this.exitDocId = exitDocDetails._id;
        this.feedtypeName = exitDocDetails.feedType;
        this.selectFeedId = exitDocDetails.selectFeed_Id;
      } else {
        this.getFeedNames();
        this.editableNo = false;
        this.addfeedstock.controls['date'].setValue(this.navParams.get('date'));
        this.addfeedstock.controls['invName'].setValue(this.navParams.get('invName'));
      }
    } else if (this.feedPurpose == 'consume') {
      this.feedIntake = this.formBuilder.group({
        date: ['', [Validators.required]],
        type: ['', [Validators.required]],
        quantity: ['', [Validators.required]],
        invName: ['', [Validators.required]],
      });

      if (this.docExit) {
        this.showConsumeQuantity = true;
        this.editableNo = true;
        let exitDocDetails = this.navParams.get('doc');
        this.feedIntake.controls['date'].setValue(this.navParams.get('date'));
        this.feedIntake.controls['invName'].setValue(this.navParams.get('invName'));
        this.feedIntake.controls['type'].setValue(exitDocDetails.feedType);
        this.feedIntake.controls['quantity'].setValue(exitDocDetails.quantity);
        this.exitDocId = exitDocDetails._id;
        this.feedtypeName = exitDocDetails.feedType;
        this.selectConsumeFeedId = exitDocDetails.selectFeed_Id;
        this.editExitFeed = exitDocDetails.quantity;
      } else {
        this.editableNo = false;
        this.feedIntake.controls['date'].setValue(this.navParams.get('date'));
        this.feedIntake.controls['invName'].setValue(this.navParams.get('invName'));
      }

    } else {
      this.feedTransferForm = this.formBuilder.group({
        date: ['', [Validators.required]],
        type: ['', [Validators.required]],
        quantity: ['', [Validators.required]],
        selectInv: ['', [Validators.required]],
        invName: ['', [Validators.required]],
      });
      this.getActiveInv();
      if (this.docExit) {
        this.editableNo = true;
        let exitDocDetails = this.navParams.get('doc');
        this.feedTransferForm.controls['date'].setValue(this.navParams.get('date'));
        this.feedTransferForm.controls['invName'].setValue(this.navParams.get('invName'));
        this.feedTransferForm.controls['type'].setValue(exitDocDetails.feedType);
        this.feedTransferForm.controls['quantity'].setValue(exitDocDetails.quantity);
        this.feedTransferForm.controls['selectInv'].setValue(exitDocDetails.selectInvName);
        this.exitDocId = exitDocDetails._id;
        this.feedtypeName = exitDocDetails.feedType;
        this.selectTransferFeedId = exitDocDetails.selectFeed_Id;
        this.selectInvId = exitDocDetails.selectInvId;
        this.selectInvName = exitDocDetails.selectInvName;
        this.editTransferFeed = exitDocDetails.quantity;
      } else {
        this.editableNo = false;
        this.feedTransferForm.controls['date'].setValue(this.navParams.get('date'));
        this.feedTransferForm.controls['invName'].setValue(this.navParams.get('invName'));
      }
    }
  }

  ionViewWillEnter() { }

  ngOnInit() { }

  calcPriceBasedOnUnits(pricePerKg, units) {
    let priceValue = ((units == 'kg') ? (pricePerKg * 1).toFixed(2) : (units == 'tonn') ? (pricePerKg * 1000).toFixed(2) : (units == '50kg') ? (pricePerKg * 50).toFixed(2) : (units == '60kg') ? (pricePerKg * 60).toFixed(2) : (units == '80kg') ? (pricePerKg * 80).toFixed(2) : (pricePerKg * 100).toFixed(2)).toString();
    return priceValue;
  }

  getFeedNames() {
    let data = {};
    if (localStorage.getItem('role_id') == '3') {
      let temp = this.supervisor.locallySaved();
      Object.assign(data, { u_id: temp['u_id'] })
    } else if (localStorage.getItem('role_id') == '11') {
      let temp = this.layerSupervisor.returnAddedFarmer();
      Object.assign(data, { u_id: temp['u_id'] })
    }
    this.LayersService.getStandardFeedNames(data).subscribe((list: any) => {
      if (list.success) {
        this.select = list.data;
      } else {
        this.select = [];
      }
    });
  }

  selectId(e) {
    this.selectFeedId = e.target.value._id;
  }

  onSelect() {
    this.modal.dismiss({ status: false, });
  }

  unitsInput: any = [];

  async units() {
    this.unitsInput = [
      {
        label: this.translate.instant("common." + this.solidUnits),
        value: 'kg',
        type: 'radio',
        checked: this.selectedunits == 'kg',
      },
      {
        label: this.translate.instant("common." + 'tonn'),
        value: 'tonn',
        type: 'radio',
        checked: this.selectedunits == "tonn"
      },
      {
        label: "50" + this.translate.instant("common." + this.solidUnits),
        value: '50kg',
        type: 'radio',
        checked: this.selectedunits == '50kg',
      },
      {
        label: "60" + this.translate.instant("common." + this.solidUnits),
        value: '60kg',
        type: 'radio',
        checked: this.selectedunits == '60kg',
      },
      {
        label: "80" + this.translate.instant("common." + this.solidUnits),
        value: '80kg',
        type: 'radio',
        checked: this.selectedunits == '80kg',
      },
      {
        label: "100" + this.translate.instant("common." + this.solidUnits),
        value: '100kg',
        type: 'radio',
        checked: this.selectedunits == '100kg',
      },
    ];
    this.unitsInput.forEach(element => {
      if (element.label == "Tonn") {
        if (this.solidUnits == "pound") {
          element.label = "1000lbs";
          this.pertonnInlbs = "1000lbs";
        } else {
          element.label = "Tonn";
          this.pertonnInlbs = "Tonn";
        }
      }
    });
    const alert = await this.alert.create({
      inputs: this.unitsInput,
      header: this.translate.instant('formLabels.Units'),
      cssClass: 'wholeSellerAlert',
      buttons: [
        {
          text: this.translate.instant('buttons.cancel'),
          role: 'cancel',
          handler: (blah) => {
            console.log('you cont choose any account');
          }
        },
        {
          text: this.translate.instant('buttons.ok'),
          handler: (result) => {
            this.selectedunits = (result == 'kg') ? 'kg' : (result == 'tonn') ? 'tonn' : (result == '50kg') ? '50kg' : (result == '60kg') ? '60kg' : (result == '80kg') ? '80kg' : '100kg';
            this.unitsnum = this.selectedunits == 'kg' ? 1 : this.selectedunits == 'tonn' ? 1000 : this.selectedunits == '50kg' ? 50 : this.selectedunits == '60kg' ? 60 : this.selectedunits == '80kg' ? 80 : 100;
            if (this.selectedunits == 'tonn') {
              if (this.solidUnits == "pound") {
                this.pertonnInlbs = "1000lbs";
              } else {
                this.pertonnInlbs = "Tonn";
              }

            }
          }
        }
      ]
    });
    await alert.present();
  }

  numberOnlyValidation(e, formcontrol) {
    if (Number(e.target.value) < 0 && e.target.value != '') {
      this.feedIntake.controls[formcontrol].setValue('0');
      this.showError(this.translate.instant('flashMessages.negativeValuesNotAllowed'));
    } else {
      // console.log(e.target.value, 469);
    }
  }

  getActiveInv() {
    let data = { invId: this.presentInvId };
    if (localStorage.getItem('role_id') == '3') {
      let temp = this.supervisor.locallySaved();
      Object.assign(data, { u_id: temp['u_id'] });
    } else if (localStorage.getItem('role_id') == '11') {
      let temp = this.layerSupervisor.returnAddedFarmer();
      Object.assign(data, { u_id: temp['u_id'] });
    } else {
      Object.assign(data, { u_id: localStorage.getItem('u_id') });
    }
    this.inventoryDetail.getActiveInv(data).subscribe((inventory: any) => {
      if (inventory.success) {
        this.invList = inventory.data;
      } else {
        this.invList = [];
        this.showError('we dont find inventory with your choice of feed system.');
      }
    });
  }

  feedIntakeFuntion() {
    if (this.docExit) {
      let feedConsumed = {
        feedAddDate: this.feedIntake.value.date,
        feedName: this.feedIntake.value.type || this.feedtypeName,
        selectFeedId: this.selectConsumeFeedId,
        quantity: this.feedIntake.value.quantity,
        units: this.selectedunits,
        invName: this.feedIntake.value.invName,
        invId: this.navParams.get('invId'),
        editDoc: true,
        docId: this.exitDocId,
      }
      if (localStorage.getItem('role_id') == "3") {
        let selectedFarmer = this.supervisorService.locallySaved();
        Object.assign(feedConsumed, { farmerId: selectedFarmer['u_id'] })
      } else if (localStorage.getItem('role_id') == '11') {
        let selectedFarmer = this.layerSupervisor.returnAddedFarmer();
        Object.assign(feedConsumed, { farmerId: selectedFarmer['u_id'] })
      } else {
        Object.assign(feedConsumed, { farmerId: localStorage.getItem('u_id') });
      }
      this.feedTransferInv = false;
      this.inventoryDetail.consumedFeedForInvDayWise(feedConsumed).subscribe((data: any) => {
        if (data.success) {
          this.modal.dismiss({
            status: true,
          });
        }
      });
      // let enterFeed = feedConsumed.quantity;
      // this.feedTypeWise12.forEach((element: any) => {
      //   if (feedConsumed.selectFeedId == element.selectFeed_Id) {
      //     let enterFeedConvertion = this.selectedunits == 'kg' ? 1 * enterFeed : this.selectedunits == 'tonn' ? 1000 * enterFeed : this.selectedunits == '50kg' ? 50 * enterFeed : this.selectedunits == '60kg' ? 60 * enterFeed : this.selectedunits == '80kg' ? 80 * enterFeed : 100 * enterFeed;
      //     console.log(enterFeedConvertion, element.remainingFeedTypeWiseStock + this.editExitFeed);
      //     if (enterFeedConvertion > (element.remainingFeedTypeWiseStock + this.editExitFeed)) {
      //       this.feedInakeBtn = true;
      //       this.showError(this.translate.instant('In sufficient stock'));
      //     } else {
      //       this.feedInakeBtn = false;
      //       this.inventoryDetail.consumedFeedForInvDayWise(feedConsumed).subscribe((data: any) => {
      //         if (data.success) {
      //           this.modal.dismiss({
      //             status: true,
      //           });
      //         }
      //       });
      //     }
      //   }
      // });
    } else {
      let feedConsumed = {
        feedAddDate: this.feedIntake.value.date,
        feedName: this.feedIntake.value.type,
        selectFeedId: this.selectConsumeFeedId,
        quantity: this.feedIntake.value.quantity,
        units: this.selectedunits,
        invName: this.feedIntake.value.invName,
        invId: this.navParams.get('invId'),
        editDoc: false,
      }
      if (localStorage.getItem('role_id') == "3") {
        let selectedFarmer = this.supervisorService.locallySaved();
        Object.assign(feedConsumed, { farmerId: selectedFarmer['u_id'] });
      } else if (localStorage.getItem('role_id') == '11') {
        let selectedFarmer = this.layerSupervisor.returnAddedFarmer();
        Object.assign(feedConsumed, { farmerId: selectedFarmer['u_id'] });
      } else {
        Object.assign(feedConsumed, { farmerId: localStorage.getItem('u_id') });
      }
      this.feedTransferInv = false;
      this.inventoryDetail.consumedFeedForInvDayWise(feedConsumed).subscribe((data: any) => {
        if (data.success) {
          this.modal.dismiss({
            status: true,
            feedAddToday: feedConsumed,
          });
        }
      });

      // let enterFeed = feedConsumed.quantity;
      // this.feedTypeWise12.forEach((element: any) => {
      //   if (feedConsumed.selectFeedId == element.selectFeed_Id) {
      //     let enterFeedConvertion = this.selectedunits == 'kg' ? 1 * enterFeed : this.selectedunits == 'tonn' ? 1000 * enterFeed : this.selectedunits == '50kg' ? 50 * enterFeed : this.selectedunits == '60kg' ? 60 * enterFeed : this.selectedunits == '80kg' ? 80 * enterFeed : 100 * enterFeed;
      //     if (enterFeedConvertion > element.remainingFeedTypeWiseStock) {
      //       this.feedInakeBtn = true;
      //       this.showError(this.translate.instant('In sufficient stock'));
      //     } else {
      //       this.feedInakeBtn = false;
      //       this.inventoryDetail.consumedFeedForInvDayWise(feedConsumed).subscribe((data: any) => {
      //         if (data.success) {
      //           this.modal.dismiss({
      //             status: true,
      //             feedAddToday: feedConsumed,
      //           });
      //         }
      //       });
      //     }
      //   }
      // });
    }
  }

  feedTransferFuntion() {
    console.log('document clicked on feed transfer')
    if (this.docExit) {
      console.log("else conditions", 9011);
      let transferFeed = {
        feedAddDate: this.feedTransferForm.value.date,
        feedName: this.feedTransferForm.value.type || this.feedtypeName,
        selectFeedId: this.selectFeedId,
        quantity: this.feedTransferForm.value.quantity,
        units: this.selectedunits,
        invName: this.feedTransferForm.value.invName,
        invId: this.navParams.get('invId'),
        selectInvName: this.selectInvName,
        selectInvId: this.selectInvId,
        editDoc: true,
        docId: this.exitDocId,
      }
      if (localStorage.getItem('role_id') == "3") {
        let selectedFarmer = this.supervisorService.locallySaved();
        Object.assign(transferFeed, { farmerId: selectedFarmer['u_id'] })
      } else if (localStorage.getItem('role_id') == '11') {
        let selectedFarmer = this.layerSupervisor.returnAddedFarmer();
        Object.assign(transferFeed, { farmerId: selectedFarmer['u_id'] })
      } else {
        Object.assign(transferFeed, { farmerId: localStorage.getItem('u_id') });
      }
      this.feedTransferInv = false;
      this.inventoryDetail.transferFeedForInvDayWise(transferFeed).subscribe((data: any) => {
        if (data.success) {
          this.modal.dismiss({
            status: true,
          });
        }
      });
      // let enterFeed = transferFeed.quantity;
      // console.log("transfer feed");
      // this.feedTypeWise12.forEach((element: any) => {
      //   console.log("element", 436);
      //   if (transferFeed.selectFeedId == element.selectFeed_Id) {
      //     console.log("nested if condition");
      //     let enterFeedConvertion = this.selectedunits == 'kg' ? 1 * enterFeed : this.selectedunits == 'tonn' ? 1000 * enterFeed : this.selectedunits == '50kg' ? 50 * enterFeed : this.selectedunits == '60kg' ? 60 * enterFeed : this.selectedunits == '80kg' ? 80 * enterFeed : 100 * enterFeed;
      //     if (enterFeedConvertion > element.remainingFeedTypeWiseStock + this.editTransferFeed) {
      //       this.feedTransferInv = true;
      //       this.showError(this.translate.instant('In sufficient stock'));
      //     } else {
      //       this.feedTransferInv = false;
      //       this.inventoryDetail.transferFeedForInvDayWise(transferFeed).subscribe((data: any) => {
      //         if (data.success) {
      //           this.modal.dismiss({
      //             status: true,
      //           });
      //         }
      //       });
      //     }
      //   } else {
      //     console.log("nested else condition");
      //     console.log("edited data of the transferfeed");

      //     // this.inventoryDetail.transferFeedForInvDayWise(transferFeed).subscribe((data: any) => {
      //     //   if (data.success) {
      //     //     this.modal.dismiss({
      //     //       status: true,
      //     //     });
      //     //   }
      //     // });
      //   }
      // });
    } else {
      console.log("else conditions", 9010);
      let transferFeed = {
        feedAddDate: this.feedTransferForm.value.date,
        feedName: this.feedTransferForm.value.type,
        selectFeedId: this.selectTransferFeedId,
        quantity: this.feedTransferForm.value.quantity,
        units: this.selectedunits,
        invName: this.inventoryName,
        invId: this.navParams.get('invId'),
        selectInvId: this.selectInvId,
        selectInvName: this.selectInvName,
        editDoc: false,
      }
      if (localStorage.getItem('role_id') == "3") {
        let selectedFarmer = this.supervisorService.locallySaved();
        Object.assign(transferFeed, { farmerId: selectedFarmer['u_id'] });
      } else if (localStorage.getItem('role_id') == '11') {
        let selectedFarmer = this.layerSupervisor.returnAddedFarmer();
        Object.assign(transferFeed, { farmerId: selectedFarmer['u_id'] });
      } else {
        Object.assign(transferFeed, { farmerId: localStorage.getItem('u_id') });
      }
      this.feedTransferInv = false;
      this.inventoryDetail.transferFeedForInvDayWise(transferFeed).subscribe((data: any) => {
        if (data.success) {
          this.modal.dismiss({
            status: true,
            feedAddToday: transferFeed,
          });
        }
      });
      // let enterFeed = transferFeed.quantity;
      // this.feedTypeWise12.forEach((element: any) => {
      //   if (transferFeed.selectFeedId == element.selectFeed_Id) {
      //     let enterFeedConvertion = this.selectedunits == 'kg' ? 1 * enterFeed : this.selectedunits == 'tonn' ? 1000 * enterFeed : this.selectedunits == '50kg' ? 50 * enterFeed : this.selectedunits == '60kg' ? 60 * enterFeed : this.selectedunits == '80kg' ? 80 * enterFeed : 100 * enterFeed;
      //     if (enterFeedConvertion > element.remainingFeedTypeWiseStock) {
      //       this.feedTransferInv = true;
      //       this.showError(this.translate.instant('In sufficient stock'));
      //     } else {
      //       this.feedTransferInv = false;
      //       this.inventoryDetail.transferFeedForInvDayWise(transferFeed).subscribe((data: any) => {
      //         if (data.success) {
      //           this.modal.dismiss({
      //             status: true,
      //             feedAddToday: transferFeed,
      //           });
      //         }
      //       });
      //     }
      //   }
      // });
    }
    //ending
  }

  addstockInv() {
    if (this.docExit) {
      if (localStorage.getItem('role_id') == "3") {
        let selectedFarmer = this.supervisorService.locallySaved();
        Object.assign(this.addStockExit, { farmerId: selectedFarmer['u_id'] });
      } else if (localStorage.getItem('role_id') == '11') {
        let selectedFarmer = this.layerSupervisor.returnAddedFarmer();
        Object.assign(this.addStockExit, { farmerId: selectedFarmer['u_id'] });
      } else {
        Object.assign(this.addStockExit, { farmerId: localStorage.getItem('u_id') });
      }
      let enterValue = this.addfeedstock.value.quantity;
      // let newQuantity = (enterValue == 'kg') ? enterValue * 1 : (this.selectedunits == 'tonn') ? enterValue * 1000 : (this.selectedunits == '50kg') ? enterValue * 50 : (this.selectedunits == '60kg') ? enterValue * 60 : (this.selectedunits == '80kg') ? enterValue * 80 : enterValue * 100;
      let enterValuePrice = this.addfeedstock.value.price;
      // let newPrice =(this.selectedunits == 'kg') ? enterValuePrice / 1 : (this.selectedunits == 'tonn') ? enterValuePrice / 1000 : (this.selectedunits == '50kg') ? enterValuePrice / 50 : (this.selectedunits == '60kg') ? enterValuePrice / 60 : (this.selectedunits == '70kg') ? enterValuePrice / 70 : (this.selectedunits == '80kg') ? enterValuePrice / 80 : enterValuePrice / 100;
      Object.assign(this.addStockExit, {
        quantity: enterValue,
        price: enterValuePrice,
        units: this.selectedunits || this.addStockExit.units,
      });

      let feedstock = {
        feedAddDate: this.addfeedstock.value.date,
        feedName: this.addfeedstock.value.type || this.feedtypeName,
        selectFeedId: this.selectFeedId || this.editSelectFeedId,
        quantity: this.addfeedstock.value.quantity,
        price: this.addfeedstock.value.price,
        units: this.selectedunits,
        invName: this.addfeedstock.value.invName,
        invId: this.navParams.get('invId'),
        editDoc: true,
        docId: this.exitDocId,
      }
      if (localStorage.getItem('role_id') == "3") {
        let selectedFarmer = this.supervisorService.locallySaved();
        Object.assign(feedstock, { farmerId: selectedFarmer['u_id'] })
      } else if (localStorage.getItem('role_id') == '11') {
        let selectedFarmer = this.layerSupervisor.returnAddedFarmer();
        Object.assign(feedstock, { farmerId: selectedFarmer['u_id'] })
      } else {
        Object.assign(feedstock, { farmerId: localStorage.getItem('u_id') });
      }
      this.inventoryDetail.addfeedstockForInvDayWise(feedstock).subscribe((data: any) => {
        if (data.success) {
          this.modal.dismiss({
            status: true,
          });
        }
      });
      // this.inventoryDetail.checkEditStock(this.addStockExit).subscribe(async (check: any) => {
      //   if (check.success) {
      //     if (check.data[0].used) {
      //       const alert = await this.alertController.create({
      //         header: this.translate.instant('alertMessages.usedFeedEditAlert'),
      //         cssClass: 'singleButtonAlert',
      //         buttons: [
      //           {
      //             text: this.translate.instant('buttons.okay'),
      //             handler: (data) => {
      //               console.log(data);
      //             }
      //           }
      //         ]
      //       });
      //       await alert.present();
      //     } else {
      //       let feedstock = {
      //         feedAddDate: this.addfeedstock.value.date,
      //         feedName: this.addfeedstock.value.type || this.feedtypeName,
      //         selectFeedId: this.selectFeedId || this.editSelectFeedId,
      //         quantity: this.addfeedstock.value.quantity,
      //         price: this.addfeedstock.value.price,
      //         units: this.selectedunits,
      //         invName: this.addfeedstock.value.invName,
      //         invId: this.navParams.get('invId'),
      //         editDoc: true,
      //         docId: this.exitDocId,
      //       }
      //       if (localStorage.getItem('role_id') == "3") {
      //         let selectedFarmer = this.supervisorService.locallySaved();
      //         Object.assign(feedstock, { farmerId: selectedFarmer['u_id'] })
      //       } else if (localStorage.getItem('role_id') == '11') {
      //         let selectedFarmer = this.layerSupervisor.returnAddedFarmer();
      //         Object.assign(feedstock, { farmerId: selectedFarmer['u_id'] })
      //       } else {
      //         Object.assign(feedstock, { farmerId: localStorage.getItem('u_id') });
      //       }
      //       this.inventoryDetail.addfeedstockForInvDayWise(feedstock).subscribe((data: any) => {
      //         if (data.success) {
      //           this.modal.dismiss({
      //             status: true,
      //           });
      //         }
      //       });
      //     }
      //   } else {
      //     console.log("failed check feed", 181);
      //   }

      // });
    } else {
      let feedstock = {
        feedAddDate: this.addfeedstock.value.date,
        feedName: this.addfeedstock.value.type.feedName || this.feedtypeName,
        selectFeedId: this.selectFeedId || this.editSelectFeedId,
        quantity: this.addfeedstock.value.quantity,
        price: this.addfeedstock.value.price,
        units: this.selectedunits,
        invName: this.addfeedstock.value.invName,
        invId: this.navParams.get('invId'),
        editDoc: false,
      }
      if (localStorage.getItem('role_id') == "3") {
        let selectedFarmer = this.supervisorService.locallySaved();
        Object.assign(feedstock, { farmerId: selectedFarmer['u_id'] });
      } else if (localStorage.getItem('role_id') == '11') {
        let selectedFarmer = this.layerSupervisor.returnAddedFarmer();
        Object.assign(feedstock, { farmerId: selectedFarmer['u_id'] });
      } else {
        Object.assign(feedstock, { farmerId: localStorage.getItem('u_id') });
      }
      this.inventoryDetail.addfeedstockForInvDayWise(feedstock).subscribe((data: any) => {
        if (data.success) {
          this.modal.dismiss({
            status: true,
            feedAddToday: feedstock
          });
        }
      });
    }
  }

  // checkStockInv(event) {
  //   if (this.feedPurpose == 'consume') {
  //     let enterFeed = Number(event.target.value);
  //     this.feedTypeWise12.forEach((element: any) => {
  //       if (this.selectConsumeFeedId == element.selectFeed_Id) {
  //         let enterFeedConvertion = this.selectedunits == 'kg' ? 1 * enterFeed : this.selectedunits == 'tonn' ? 1000 * enterFeed : this.selectedunits == '50kg' ? 50 * enterFeed : this.selectedunits == '60kg' ? 60 * enterFeed : this.selectedunits == '80kg' ? 80 * enterFeed : 100 * enterFeed;
  //         if (this.docExit) {
  //           if (enterFeedConvertion > element.remainingFeedTypeWiseStock + this.editExitFeed) {
  //             this.feedInakeBtn = true;
  //             this.showError(this.translate.instant('In sufficient stock'));
  //           } else {
  //             this.feedInakeBtn = false;
  //           }
  //         } else {
  //           if (enterFeedConvertion > element.remainingFeedTypeWiseStock) {
  //             this.feedInakeBtn = true;
  //             this.showError(this.translate.instant('In sufficient stock'));
  //           } else {
  //             this.feedInakeBtn = false;
  //           }
  //         }
  //       }
  //     });
  //   } else {
  //     let enterFeed = Number(event.target.value);
  //     this.feedTypeWise12.forEach((element: any) => {
  //       if (this.selectTransferFeedId == element.selectFeed_Id) {
  //         let enterFeedConvertion = this.selectedunits == 'kg' ? 1 * enterFeed : this.selectedunits == 'tonn' ? 1000 * enterFeed : this.selectedunits == '50kg' ? 50 * enterFeed : this.selectedunits == '60kg' ? 60 * enterFeed : this.selectedunits == '80kg' ? 80 * enterFeed : 100 * enterFeed;
  //         if (this.docExit) {
  //           if (enterFeedConvertion > element.remainingFeedTypeWiseStock + this.editTransferFeed) {
  //             this.feedTransferBtn = true;
  //             this.showError(this.translate.instant('In sufficient stock'));
  //           } else {
  //             this.feedTransferBtn = false;
  //           }
  //         } else {
  //           if (enterFeedConvertion > element.remainingFeedTypeWiseStock) {
  //             this.feedTransferBtn = true;
  //             this.showError(this.translate.instant('In sufficient stock'));
  //           } else {
  //             this.feedTransferBtn = false;
  //           }
  //         }
  //       }
  //     });
  //   }
  // }

  selectFeedType(e) {
    if (e.target.value) {
      // let enterConsumedFeed = this.feedIntake.value.quantity || 0;
      this.showConsumeQuantity = true;
      this.feedInakeBtn = true;
      this.select.forEach(element => {
        if (element.feedName == e.target.value) {
          this.selectConsumeFeedId = element._id;
        }
      });
    }
  }

  selectFeedTransfer(e) {
    this.select.forEach(element => {
      console.log(element.feedName == e.target.value)
      if (element.feedName == e.target.value) {
        this.selectTransferFeedId = element._id;
        console.log(this.selectTransferFeedId)
      }
    });
  }

  selectinvId(e) {
    this.selectInvId = e.target.value;
    this.invList.filter((inv: any) => {
      if (inv._id == this.selectInvId) {
        this.selectInvName = inv.inventory_name;
      }

    });
  }

  async showError(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 3000,
      position: 'bottom',
      buttons: [
        {
          text: this.translate.instant('buttons.done'),
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        }
      ],
      animated: true,
      cssClass: "my-custom-classInventory",
    });
    toast.present();
  }

  getInvFeedStockDetails() {
    let data = {
      inventory_id: this.presentInvId,
      date: this.showAddDate,
    }
    if (localStorage.getItem('role_id') == "3") {
      let selectedFarmer = this.supervisorService.locallySaved();
      Object.assign(data, { u_id: selectedFarmer['u_id'] });
    } else if (localStorage.getItem('role_id') == '11') {
      let selectedFarmer = this.layerSupervisor.returnAddedFarmer();
      Object.assign(data, { u_id: selectedFarmer['u_id'] });
    } else {
      Object.assign(data, { u_id: localStorage.getItem('u_id') });
    }
    this.inventoryDetail.getStockForInv(data).subscribe((stock: any) => {
      if (stock.success) {
        this.feedTypeWise12 = stock.data;
        if (this.feedPurpose == 'consume' || this.feedPurpose == 'transfer') {
          if (this.feedTypeWise12.length > 0) {
            this.feedTypeWise12.forEach(element => {
              this.select.push(
                {
                  feedName: element.feedTypeName,
                  _id: element.selectFeed_Id,
                }
              )
            });
          } else {
            this.select = [];
          }
        }
      }
    });
  }

  notAllowNegitive(triggedValue) {
    if (Number(triggedValue) < 0 && triggedValue != '') {
      this.showError(this.translate.instant('flashMessages.negativeValuesNotAllowed'));
    }
  }


  desiredPriceLimitValTruck(event, i) {
    // let replacedPipe = new ThousandSeperatorPipe();
    // let divider = replacedPipe.transform(event.target.value);
    // if (divider.includes('.')) {
    //   var regex = /[.,\s]/g;
    //   var result = divider.replace(regex, '');

    // } else {

    // }
  }

  convertTranslationUnits(unit) {
    console.log(unit)
    let covertUnit = this.translate.instant("common." + this.solidUnits)
    // console.log(covertUnit)
    let showUnit = (unit == 'kg') ? covertUnit : (unit == 'tonn') ? this.pertonnInlbs : (unit == '50kg') ? `*50 ${covertUnit}` : (unit == '60kg') ? `*60 ${covertUnit}` : (unit == '80kg') ? `*80 ${covertUnit}` : `*100 ${covertUnit}`;
    return showUnit;
  }

}