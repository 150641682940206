import { Injectable, } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { Router, } from '@angular/router';
import { Platform } from '@ionic/angular';
import { config } from '../app/helper/config';
import { BehaviorSubject, Observable } from 'rxjs';
// import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthServicesService {
  token: string;
  lang: string;
  lngCode: string;
  authState = new BehaviorSubject(false);
  language: boolean;
  constructor(private http: HttpClient, private router: Router, private platform: Platform,) {
    this.platform.ready().then(() => {
      this.ifLoggedIn();
    });
    this.token = localStorage.getItem("token");
  }

  registerUser(data) {
    return this.http.post(config.api + "/signup", { data });
  }
  login() {
    this.ifLoggedIn();
  }

  ifLoggedIn() {
    let res = localStorage.getItem('token');
    this.language = localStorage.getItem('lang') && localStorage.getItem('lngCode') &&
      localStorage.getItem('lngCode') !== "undefined" && localStorage.getItem('lang') !== "undefined" &&
      localStorage.getItem('lngCode') !== "null" && localStorage.getItem('lang') !== "null" ? true : false;
    if (res && res != 'undefined') {
      this.authState.next(true);
    } else if (this.router.routerState.snapshot.url == '/register') {
    } else {
      if (this.language) {
        this.router.navigate(['/user-authentication']);
        this.authState.next(false);
      } else {
        this.router.navigate(['language']);
        this.authState.next(false);
      }
    }
  }

  logout() {
    this.lang = localStorage.getItem("lang");
    this.lngCode = localStorage.getItem("lngCode");
    localStorage.clear();
    localStorage.setItem("lang", this.lang);
    localStorage.setItem('lngCode', this.lngCode);
    window.location.reload();
    this.ifLoggedIn();
  }

  isAuthenticate(): boolean {
    this.ifLoggedIn();
    return this.authState.value;
  }
  verifyUser(data): Observable<any> {
    // return this.http.post(config.api + "/verifyUser",  data );
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json")
    return this.http.post(config.api + 'auth/verifyUser', data, { headers })
  }
  verifyFacebookUser(id): Observable<any> {
    return this.http.post(config.api + 'auth/verifyFacebookUser', id, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', }) })
  }

  settingCheck(data): Observable<any> {
    return this.http.post(config.api + "auth/settingCheck", data)
  }

}
