import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { config } from '../helper/config';

@Injectable({
  providedIn: 'root'
})
export class MarketService {
  _marketQueryParams: any;

  constructor(public http: HttpClient, ) { }
  marketprice(data): Observable<any> {
    return this.http.post(config.api + 'marketIndicators/get_Prices', data)
  }
  market_over_all_price(data): Observable<any> {
    return this.http.post(config.api + 'marketPrice/marketprices_app', data)
  }
  market_over_all_price_graph(data): Observable<any> {
    return this.http.post(config.api + 'marketPrice/market_prices_to_app_graph', data)
  }

  graphview(data): Observable<any> {
    return this.http.post(config.api + "marketPric/marketPrices_graph", data);
  }
  val: any = {};

  setData(data) {
    this.val = data;
  }
  getData() {
    return this.val;
  }

  public set marketQueryParams(params: any) {
    this._marketQueryParams = params
  }

  public get marketQueryParams(): any {
    return this._marketQueryParams;
  }

}