import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { LoadingserviceService } from 'src/app/loadingservice.service';
import { LayerRetailSupplierService } from 'src/app/services/layer-retail-supplier.service';

@Component({
  selector: 'app-retail-supplier-selectcontact',
  templateUrl: './retail-supplier-selectcontact.page.html',
  styleUrls: ['./retail-supplier-selectcontact.page.scss'],
})
export class RetailSupplierSelectcontactPage implements OnInit {

  constructor(private modal:ModalController ,private router:Router,
    private loading:LoadingserviceService,
    private retailSupplierService:LayerRetailSupplierService
    ) { }
  customerList = [];
  search: any = '';
  searchTimeout: any;
  sort: any;
  ngOnInit() {
  }
  ionViewWillEnter(){
    this.sort = { createdDate: -1 }
    this.search = '';
    this.getcustomer();
  }
  getcustomer() {
    let data = {
      skip: 0,
      search: this.search,
      sort: this.sort
    }
    this.loading.loadingPresent();
    this.retailSupplierService.getBuyers(data).subscribe((data: any) => {
      if (data.success) {
        this.loading.loadingDismiss();
        this.customerList = data.data;
        console.log(this.customerList,39)
      } else {
        this.customerList = []
        this.loading.loadingDismiss();
      }
    });
  }
   // resfersh page
   doRefresh(event) {
    this.sort = { createdDate: -1 }
    this.search = '';
    setTimeout(() => {
      let data = {
        skip: 0,
        search: this.search,
        sort: this.sort
      }
      this.loading.loadingPresent();
      this.retailSupplierService.getBuyers(data).subscribe((data: any) => {
        if (data.success) {
          this.loading.loadingDismiss();
          this.customerList = data.data;
          console.log(this.customerList,60)
          event.target.complete();
        } else {
          this.customerList = []
          this.loading.loadingDismiss();
          event.target.complete();
        }
      });
    }, 1000);
  }
  // infinite scroll
  loadData(event) {
    if (!(this.customerList.length % 10)) {
      setTimeout(() => {
        let data = {
          skip: this.customerList.length,
          search: this.search,
          sort: this.sort

        }
        this.retailSupplierService.getBuyers(data).subscribe((data: any) => {
          if (data.success) {
            this.customerList = this.customerList.concat(data.data);
            event.target.complete();
          } else {
            this.customerList = []
            console.log(this.customerList,87)
            event.target.complete();
          }
        });
      }, 1000);
    } else {
      event.target.complete();
    }
  }
  filteredCustomers(event) {
    // if(this.customerList.length >= 10){
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      if (event.target.value != this.search && event.target.value.length >= 3) {
        this.search = event.target.value;
        let val = {
          skip: 0,
          search: this.search,
          sort: this.sort

        }
        this.retailSupplierService.getBuyers(val).subscribe((dat: any) => {
          if (dat.success) {
            this.customerList = dat.data;
            console.log(this.customerList,110)
          }
        });
      } else if (event.target.value.length < 3) {
        let val = {
          skip: 0,
          search: '',
          sort: this.sort
        }
        this.retailSupplierService.getBuyers(val).subscribe((dat: any) => {
          if (dat.success) {
            this.customerList = dat.data;
            console.log(this.customerList,123)
          }
        });
      }
    }, 500);
  // }
  }
  selectCustomer(val,data) {
    if (!val) {
      this.modal.dismiss({
        customers:false
      })
    }else{
      this.modal.dismiss({
        customers:true,
        customerData:data
      })
    }
  }
  addContact(){
    this.modal.dismiss({
      customers:false
    })
    let val = {type: "create", from:'createSale'};
    this.retailSupplierService.createOredit(val);
    this.router.navigateByUrl('/retailer/tabs/add-edit-customer');
  }
}
