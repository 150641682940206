import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { config } from '../helper/config';
@Injectable({
  providedIn: 'root'
})
export class LayerfarmerSupervisorService {
  savedata: any;
  farmerdetail: any;

  constructor(public http: HttpClient) { }

  getfarmer(): Observable<any> {
    return this.http.get(config.api + "layerSupervisor/userRequestList");
  }
  layerSupervisorHomePage(): Observable<any> {
    return this.http.get(config.api + "layerSupervisor/usersList");
  }
  addfarme(data): Observable<any> {
    return this.http.post(config.api + 'layerSupervisor/addfarmer', data);
  }
  saveRouter(data) {
    this.savedata = data;
  }

  getInventoryData(id): Observable<any> {
    return this.http.post(config.api + 'layerSupervisor/inventory', id);
  }
  getMyfarms(data): Observable<any> {
    return this.http.post(config.api + 'layerSupervisor/myfarms', data);
  }
  myfarmSetData(data) {
    this.farmerdetail = data;
  }
  farmerdata() {
    return this.farmerdetail;
  }

  returnAddedFarmer() {
    return this.savedata
  }

}
