import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class SharingService {

  sharingData = { name: " " };

  // Observable string source
  private dataStringSource = new BehaviorSubject<any>([]);

  // Observable string stream
  dataString$ = this.dataStringSource.asObservable();

  constructor(private http: HttpClient) { }

  public saveData(value) {
    console.log("save data function called " + value + this.sharingData.name);
    this.sharingData.name = value;
    this.dataStringSource.next(this.sharingData.name);

  }
}