import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { config } from '../app/helper/config';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: "root"
})
export class LeaseService {
  public baseUrl = config.api;
  private postings = new BehaviorSubject<any>([]);
  postings$ = this.postings.asObservable();
  local_postingsData;
  viewSinglelease: any;
  LocalPostData: any;
  postedDAta: any;

  constructor(public http: HttpClient) { }

  postLease = (body: any): Observable<any> => {
    return this.http.post(`${this.baseUrl}lease/insertlease`, body)
  }
  updateLease(data): Observable<any> {
    console.log(data, 19)
    return this.http.post(config.api + "lease/updatebyId", data);
  }

  setData(data) {
    this.local_postingsData = data;
  }
  setAValue(data) {
    this.postings.next(data);
  }
  viewLeaseData(data) {
    this.viewSinglelease = data
  }
  viewData() {
    return this.viewSinglelease;
  }
  deleteLease(id): Observable<any> {
    return this.http.post(config.api + "lease/deleteLease", id);
  }

  setLocalPostingData(data){
    this.LocalPostData= data;
  }

  getLocalPostingData(){
    console.log(this.LocalPostData)
    return this.LocalPostData;
  }

  localPostedData(data){
    this.postedDAta = data;
  }

  recoverData(){
    return this.postedDAta;
  }
}