import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { config } from '../helper/config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LayerShopServiceService {
  bookinngOrder = {
    seller: false,
    orderSeller: '',
    eggsData:''
  }
  createSale: any;
  orderData: any;
  supplierdata: any;
  constructor(private http: HttpClient) { }
  expenses(data): Observable<any>{
    console.log(data,1)
    return this.http.post(config.api + "layerSeller/lyrExpenses", data);
  };
  lyrCreateSale(data): Observable<any> {
    return this.http.post(config.api + "layerSeller/lyrCreateSale", data);
  }
  getHomePageData(data): Observable<any> {
    return this.http.post(config.api + "layerSeller/lyrgetHmPgeDta",data);
  }
  // insert stock
  addStock(data):Observable<any> {
    return this.http.post(config.api + "layerSeller/addStock",data);
  }
  autoOrderForRetail(data):Observable<any> {
    return this.http.post(config.api + "layerSeller/orderforRetaler",data);
  }
  autoOrderForWhole(data):Observable<any> {
    return this.http.post(config.api + "layerSeller/orderForWholeseller",data);
  }
  getSuppliersList(data): Observable<any> {
    return this.http.post(config.api + "layerSeller/supplierList",data);
  }
  getBookingOrders(data): Observable<any> {
    return this.http.post(config.api + "layerSeller/bookingOrders",data);
  }
  detailView(data){
    console.log(data, 25063)
    this.supplierdata = data
  }
  supplierdetail() {
    return this.supplierdata
  }

  booKingOrder(order) {
    this.bookinngOrder = {
      seller: order.seller,
      orderSeller: order.sellerOrder,
      eggsData:order.eggsData
    }
  };
  getSellerShopSettings():Observable<any>{
    return this.http.get(config.api + 'auth/getUserSettings');
  };
  updatePrices(object):Observable<any>{
    return this.http.post(config.api + 'auth/updtAndGet',object);
  };
  crossCheck(data){
    this.createSale=data;
  }
  setCardData(data){
    this.orderData = data;
  }
  getOrderDetailes(){
    return this.orderData
  }
}
