import { Pipe, PipeTransform } from '@angular/core';
import { timer, Subscription } from "rxjs";

@Pipe({
  name: 'timerPipe'
})
export class TimerPipePipe implements PipeTransform {
  // counter;
  // tick = 2000;
  // countDown: Subscription;
  transform(value: unknown[], ...args: unknown[]): unknown {
    value.map((o: any, i) => {
      // console.log(o.orderType);
      if (o.orderType == 'auto') {
        // console.log(o.status, o.timeOut);
        if (!o.status) {
          let counter;
          let countDown: Subscription;
          let tick = 2000;
          let takeCreateDate;
          if (o.createdDate) {
            takeCreateDate = new Date(o.createdDate);
          } else {
            takeCreateDate = new Date(o.createdAt);
          }
          let validExpireDate = new Date(takeCreateDate.getTime() + (6 * 60 * 60 * 1000));
          let now = new Date();
          let dif = validExpireDate.getTime() - now.getTime();
          let Seconds_from_bw = dif / 1000;
          counter = Seconds_from_bw;
          if (o.timeOut) {
            o.showTime = "expired";
          } else {
            countDown = timer(0, tick).subscribe(() => {
              --counter;
              var h = Math.floor(counter / 3600);
              var m = Math.floor(counter % 3600 / 60);
              var s = Math.floor(counter % 3600 % 60);
              var hDisplay = h > 0 ? h + (h == 1 ? " hour," : " hours, ") : "";
              var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
              var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
              o.showTime = hDisplay + mDisplay + sDisplay;
            });
          }
        }
      }
    });
    return value;
  }

}
