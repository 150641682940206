import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { LiftingsService } from 'src/services/liftings.service';
import { PopoverController, ToastController, Platform } from '@ionic/angular';
import { Router } from '@angular/router';


@Component({
  selector: 'app-liftings-popup',
  templateUrl: './liftings-popup.component.html',
  styleUrls: ['./liftings-popup.component.scss'],
})
export class LiftingsPopupComponent implements OnInit {

  _addForm = new FormGroup({
    toggleStatus: new FormControl(),
    price: new FormControl()
  });
  id: any;
  priceData: any;
  toggleStatus: boolean = false;
  price: any;


  constructor(private router: Router, private _formBuilder: FormBuilder, protected liftings: LiftingsService, private tost: ToastController, private pop: PopoverController) { }

  ngOnInit() {
    this.liftings.liftingPrices({ id: this.id._id }).subscribe((data) => {
      this.priceData = data.data[0];
      
      this.toggleStatus = this.priceData.toggleStatus;
      this.price = this.priceData.price;
      
      this._addForm.patchValue(this.priceData);
    })
  }

  onSubmit() {
    
    let data = {
      toggleStatus: this._addForm.value.toggleStatus,
      price: this._addForm.value.price,
      _id: this.id._id,
    }
    this.liftings.settingLiftingsPrice(data).subscribe((data) => {
      
      if (data.success) {
        this.pop.dismiss();
        this.router.navigateByUrl('/lifting-supervisor')
      }
    })
  }



}
