import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss'],
})

export class StarRatingComponent implements OnInit {
  @Input('value') value: number;
  @Output('onRatingChange') onRatingChange: EventEmitter<any> = new EventEmitter();
  @Input('fontSize') fontSize: any;
  @Input('rating_value') rating: any;
  @Input('star_rating') star_rating: any;
  @Input('disable_value') disable_value: any;

  ratings = [{
    value: 1,
    name: ''
  }, {
    value: 2,
    name: ''
  }, {
    value: 3,
    name: ''
  }, {
    value: 4,
    name: ''
  }, {
    value: 5,
    name: ''
  }];

  ratingGiven: number = 0;

  constructor() { }

  ngOnInit() {
    this.ratingGiven = this.star_rating;
    this.setRatings();
  }

  onStarClick(ind) {
    if (this.disable_value) {
    this.ratingGiven = ind;
      this.onRatingChange.emit(ind);
      console.log(this.star_rating);
      this.setRatings();


    }
  }

  setRatings() {

    let value = this.ratingGiven || 0;
    let ratingsFull = Math.floor(value);
    let modular = value % 1;
    let halfAdded = false;

    for (let i = 0; i < this.ratings.length; i++) {
      if (this.ratings[i].value <= ratingsFull) {
        this.ratings[i].name = 'star';
      } else if (this.ratings[i].value > ratingsFull && modular > 0 && !halfAdded) {
        this.ratings[i].name = 'star-half';
        halfAdded = true;
      } else {
        this.ratings[i].name = 'star-outline';
      }
    }
  }
}
