import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { config } from '../helper/config';

@Injectable({
  providedIn: 'root'
})
export class AutomaticSystemService {

  modalData = new Subject<any>()
  mac: string;

  constructor(private http: HttpClient) { }

  sendData(data) {
    this.mac = data
  }

  getData() {
    return this.mac;
  }


  deviceConfigureRequest(data): Observable<any> {
    return this.http.post(config.api + 'automate/deviceConfigurationInApp', data);
  }

  deviceConfigurationInAppOtpCheck(data): Observable<any> {
    return this.http.post(config.api + 'automate/deviceConfigurationInAppOtpCheck', data);
  }

  // getSummaryData(): Observable<any> {
  //   return this.http.get(config.api + 'automate/getSummaryData');
  // }

  getSummaryData(data): Observable<any> {
      return this.http.post(config.api + 'automate/getSummaryData',data);
    }

  getFullDeviceData(data): Observable<any> {
    return this.http.post(config.api + 'automate/getFullDeviceData', data);
  }

  postAutomaticSettings(data):Observable<any> {
    return this.http.post(config.api + 'automate/postRelayData', data);
  }

  userSensorAlarmSettings(data): Observable<any>{
    return this.http.post(config.api + 'automate/sensorSettings',{id:data});
  }

  updateSystemSettings(data): Observable<any>{
    return this.http.post(config.api + 'automate/updateSettings', data)
  }

}
