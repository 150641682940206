import { Injectable } from '@angular/core';
import { Camera,CameraOptions } from '@ionic-native/camera/ngx';


@Injectable({
  providedIn: 'root'
})

export class  FileUploadService {
    myphoto: any;
    constructor(private camera: Camera) {
      
     }
    openCamera() {  
        const options: CameraOptions = {
          allowEdit: true,
        correctOrientation: true,
          quality: 50,
          destinationType: this.camera.DestinationType.DATA_URL,
          encodingType: this.camera.EncodingType.JPEG,
          mediaType: this.camera.MediaType.PICTURE,
          saveToPhotoAlbum: true,
        }
        return   this.camera.getPicture(options);
      }

}
