import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { config } from "../../helper/config";

@Injectable()
export class InventoryService {

  constructor(private httpClient: HttpClient) {
  }
  getInventory() {

  }
  addInventory(inventoryData): Observable<any> {
    return this.httpClient.post<Observable<any>>(`${config.api}inventory/createinventory`, inventoryData);
  }
}
