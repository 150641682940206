import { Component, OnInit, NgZone } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { TraderService } from '../../services/trader.service';
import { ModalController, NavParams, ToastController, PopoverController } from '@ionic/angular';
import { NativeGeocoder, NativeGeocoderOptions, NativeGeocoderResult } from '@ionic-native/native-geocoder/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { CustmmessagePopOverComponent } from '../custmmessage-pop-over/custmmessage-pop-over.component';
import { TranslateService } from '@ngx-translate/core';
declare var google: any;

@Component({
  selector: 'app-add-chicken-centers',
  templateUrl: './add-chicken-centers.component.html',
  styleUrls: ['./add-chicken-centers.component.scss'],
})
export class AddChickenCentersComponent implements OnInit {
  validationType: string = "phone";
  // Geocoder configuration start here
  GoogleAutocomplete = new google.maps.places.AutocompleteService();
  countryCallingCode = localStorage.getItem('countryCallingCode');

  autocomplete = { input: '' };
  autocompleteItems = [];
  latitude: any;
  longitude: any;
  geoAddress: string;
  mapvalues = false;
  geoencoderOptions: NativeGeocoderOptions = {
    useLocale: true,
    maxResults: 5
  };
  // Geocoder configuration end here
  validation_messages = {
    phone: [{ type: 'required', message: 'phnNoRequired' },
    { type: 'maxlength', message: 'phNumberMax' },
    { type: 'pattern', message: 'phNumberMin' }
    ],
    name: [{ type: 'required', message: 'nameRequired' }],
    address: [{ type: 'required', message: 'locationRequired' }],
    capacity: [{ type: 'required', message: 'capacityRequired' }],
    contact: [{ type: 'required', message: 'phnNoRequired' }],
  };

  modelId: any;
  userNotFound: boolean = false;
  showMessage: boolean = false;
  errorMessage: any;
  countryCode: string = localStorage.getItem('countryCode');
  myChickenCenter: FormGroup;

  constructor(public router: Router,
    private formBuilder: FormBuilder,
    private geolocation: Geolocation,
    public zone: NgZone,
    private nativeGeocoder: NativeGeocoder,
    private TraderService: TraderService,
    private modalCtrl: ModalController,
    private toastController: ToastController,
    private navParams: NavParams,
    private translate: TranslateService,
    public popoverController: PopoverController) {
    this.myChickenCenter = this.formBuilder.group({
      name: ['', Validators.required],
      phone: ['', Validators.required],
      address: ['', Validators.required],
      capacity: ['', Validators.required],
      // contact: ['', Validators.required],
    });
  }





  formControlChanges() {
    const address = this.myChickenCenter.get('address');
    const name = this.myChickenCenter.get('name');
    const capacity = this.myChickenCenter.get('capacity');

    console.log(this.userNotFound);
    if (this.userNotFound) {
      this.myChickenCenter.controls["address"].setValidators([Validators.required]);
      this.myChickenCenter.controls["name"].setValidators([Validators.required]);
      this.myChickenCenter.controls["capacity"].setValidators([Validators.required]);
      if (this.validationType == "email") {
        this.myChickenCenter.addControl("contact", new FormControl('', [Validators.required]))
      }
    } else {
      address.clearValidators();
      name.clearValidators();
      capacity.clearValidators();
    }
    address.updateValueAndValidity();
    name.updateValueAndValidity();
    capacity.updateValueAndValidity();
  }


  ngOnInit() {
    console.log(this.navParams.data.data)
    this.modelId = this.navParams.data.foo;
    this.formControlChanges();

  }
  submitCenter(trader: boolean) {
    if (trader) {
      if (this.myChickenCenter.valid) {
        // console.log(this.myChickenCenter.value, this.myChickenCenter.value.phone.length,1212);
        // if (this.myChickenCenter.value.phone.length != 10) {
        //   this.myChickenCenter.controls["phone"].setValue('');
        //   this.flashMessageShow('enter mobile numer 10 digits only');
        // } else {
        let submitData;
        if (this.validationType == "phone") {
          submitData = { phoneNumber: this.myChickenCenter.value.phone, contactType: "chickencenter", fieldType: 'phone' }
        } else {
          submitData = { email: this.myChickenCenter.value.phone, contactType: "chickencenter", fieldType: 'email' }
        }
        this.TraderService.chickenCenterAvilabiltyCheck(submitData).subscribe(
          (data) => {
            if (data.success) {
              if (data.code == 200) {
                this.flashMessageShow(data.msg);
                //new chicken center added successfully 
                this.modalCtrl.dismiss({
                  data: data.data,
                  status: "addedNew",
                });
              } else if (data.code == 202) {
                //waiting for permissions user already added
                this.flashMessageShow(data.msg);
                this.modalCtrl.dismiss({});
              } else if (data.code == 204) {
                //user already added to this trader
                this.flashMessageShow(data.msg);
              } else if (data.code == 208) {
                //requested number is not registered with chick center category.
                this.flashMessageShow(data.msg);
              } else if (data.code == 209) {
                this.showMessage = true;
                this.errorMessage = data.msg;
                // this.flashMessageShow(data.msg);
              } else if (data.code == 222) {
                this.flashMessageShow(data.msg);
                this.modalCtrl.dismiss({});
              }
              else {
                //user not registered with poultry App.
                this.userNotFound = true;
                this.formControlChanges()
                this.flashMessageShow(data.msg);
              }
            } else {
              this.flashMessageShow(data.msg);
            }
          }
        )
        // }
      } else {

      }
      //   Object.assign(this.myChickenCenter.value, { u_id: localStorage.getItem('u_id'), register: false, location: { type: "Point", coordinates: [parseFloat(this.longitude), parseFloat(this.latitude)] } });
      // this.TraderService.addTempChickenCenter(this.myChickenCenter.value).subscribe((data: any) => {
      //   console.log(data)
      //   console.log(this.navParams.data.data)
      //   if (data.success) {
      //     if(this.navParams.data.data == "fromChickenSelect"){
      //       console.log("jfdfjsjd")
      //       this.modalCtrl.dismiss({
      //         data: data.data,
      //         status: "addedNew",
      //       });
      //     }
      //   }
      // });
    } else {
      this.modalCtrl.dismiss({
        status: "closed",
      });
    }
  }


  tempUserAdd(trader: boolean) {
    Object.assign(this.myChickenCenter.value, { u_id: localStorage.getItem('u_id'), fieldType: 'phone', register: false, location: { type: "Point", coordinates: [parseFloat(this.longitude), parseFloat(this.latitude)] } });
    if (this.validationType != 'phone') {
      this.myChickenCenter.value['email'] = this.myChickenCenter.value.phone;
      this.myChickenCenter.value['fieldType'] = 'email';
    }
    this.TraderService.addTempChickenCenter(this.myChickenCenter.value).subscribe((data: any) => {
      console.log(this.navParams.data.data)
      if (data.success) {
        if (this.navParams.data.data == "fromChickenSelect") {
          this.modalCtrl.dismiss({
            data: data.data,
            status: "addedNew",
          });
        }
        else {
          this.modalCtrl.dismiss({
            data: data.data,
            status: trader,
          });
        }
      }
    });
  }






  //show flash message boxes to user
  async flashMessageShow(message_text) {
    const toast = await this.toastController.create({
      message: message_text,
      duration: 3000,
      position: 'bottom',
      buttons: [
        {
          text: this.translate.instant('buttons.done'),
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        }
      ],
      animated: true,
      cssClass: "v-my-custom-class font10",
    });
    toast.present();
  }



  //--_**_-- Geo location start here--_**_--
  updateSearchResults() {
    if (this.autocomplete.input === '') {
      this.autocompleteItems = [];
      return;
    }
    this.GoogleAutocomplete.getPlacePredictions(
      {
        input: this.autocomplete.input, types: ['(cities)'],
        // componentRestrictions: { country: 'IN' }
        componentRestrictions: { country: localStorage.getItem('countryCode') }
      },
      (predictions, status) => {
        this.autocompleteItems = [];
        this.zone.run(() => {
          if (predictions.length > 0) {
            predictions.forEach((prediction) => {
              this.autocompleteItems.push(prediction);
            });
          } else {
            console.log("there is no address based on your input")
          }
        });
      }
    );
  }
  selectSearchResult(item) {
    this.autocomplete.input = item.description;
    this.geoAddress = this.autocomplete.input;

    this.geoCode(this.autocomplete.input);
    // this.autocomplete.input= ''
    if (this.autocomplete.input) {
      this.mapvalues = true;
    } else {
      this.mapvalues = false;
      this.updateSearchResults()
    }
  }
  geoCode(address: any) {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ 'address': address }, (results, status) => {
      this.latitude = results[0].geometry.location.lat();
      this.longitude = results[0].geometry.location.lng();
    });
  }

  getGeoLocation() {
    this.geolocation.getCurrentPosition({ timeout: 30000, enableHighAccuracy: true, maximumAge: 3600 }).then((resp) => {
      this.latitude = resp.coords.latitude;
      this.longitude = resp.coords.longitude;
      this.getGeoencoder(this.latitude, this.longitude);
    }).catch((error) => {
    });
  }

  getGeoencoder(latitude, longitude) {
    this.nativeGeocoder.reverseGeocode(latitude, longitude, this.geoencoderOptions)
      .then((result: NativeGeocoderResult[]) => {
        if (result.length > 0) {
          this.geoAddress = this.generateAddress(result[0]);
          this.myChickenCenter.controls.address.setValue(this.geoAddress);
        }
      })
      .catch((error: any) => {
        alert('Error getting location' + JSON.stringify(error));
      });
  }
  generateAddress(addressObj) {
    const obj = [];
    let address = '';

    for (let key in addressObj) {
      obj.push(addressObj[key]);
    }
    obj.reverse();
    for (const val in obj) {
      if (obj[val].length) {
        address += obj[val] + ', ';
      }
    }
    return address.slice(0, -2);
  }
  // --_**_--Geo location end here--_**_--

  async messagePopOver() {
    const popover = await this.popoverController.create({
      component: CustmmessagePopOverComponent,
      componentProps: {
        id: "cc"
      },
      translucent: true
    });
    return await popover.present();
  }



  // formControlValueRemove() {

  //   const name = this.myChickenCenter.get('name');
  //   const address = this.myChickenCenter.get('address');
  //   const capacity = this.myChickenCenter.get('capacity');
  //   if (!this.userNotFound) {
  //     city.clearValidators();
  //   }
  //   city.updateValueAndValidity();
  // 


  wrongInput = false;
  inputCheck(event) {
    let inputValue = event.target.value;
    if (inputValue.match(/^[0-9]*$/)) {
      this.validationType = 'phone';
      this.wrongInput = false
      // } else if (inputValue.match(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)) {
    } else if (inputValue.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
      this.validationType = 'email';
      this.wrongInput = false
    } else {
      this.wrongInput = true
    }
  }
}
