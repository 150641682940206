import { Component, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';
import { FormGroup, Validators, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { ChickenService } from '../chicken.service';
import { ModalController, NavParams, LoadingController, ToastController, } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-chicken-center-order-conformation',
  templateUrl: './chicken-center-order-conformation.component.html',
  styleUrls: ['./chicken-center-order-conformation.component.scss'],
})
export class ChickenCenterOrderConformationComponent implements OnInit {
  ordersubmitform: FormGroup;
  vechileData: any;
  vanData: any;
  vanNumber: any;
  traderdata: any;
  timeBound: any;
  orderDate: any;
  units = localStorage.getItem("solidUnits");

  weightValidation = false;
  isDisabled: boolean = true;
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private ChickenService: ChickenService,
    public modalController: ModalController,
    protected navParams: NavParams,
    private toastController: ToastController,
    private translate: TranslateService,
  ) {
    // this.traderdata = this.navParams.get('trader_data');
  }

  validation_messages = {
    noofbirds: [{ type: 'required', message: 'no. of birds is required' }],
    weight_from: [],
    weight_to: [],
    orderDate: [{ type: 'required', message: 'Order date required' }],
    timeBound: [{}],
    estimatedweight: []
  };
  ngOnInit() {
    // this.formValidation()
    this.traderdata = this.navParams.get('trader_data');
    // console.log(this.vechileData, 26)
    console.log(this.traderdata, 514)

    this.ordersubmitform = this.formBuilder.group({
      noofbirds: ['', [Validators.required]],
      // weight_from: [''],
      // weight_to: [''],
      orderDate: ['', [Validators.required]],
      timeBound: [],
      estimatedweight: [""]
    });
  }

  async showError(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 3000,
      position: 'bottom',
      buttons: [
        {
          text: this.translate.instant('buttons.done'),
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        }
      ],
      animated: true,
      cssClass: 'my-custom-class',
    });
    toast.present();
  }
  submit(order: boolean) {
    this.isDisabled = false;
    if (order) {
      // weight validation (from weight should be less than to weight)
      if (this.ordersubmitform.value.weight_from > this.ordersubmitform.value.weight_to) {
        this.ordersubmitform.patchValue({ 'avgweight_from': '' });
        this.ordersubmitform.patchValue({ 'avgweight_to': '' });
        this.weightValidation = true;
      } else {
        const data = Object.assign(
          this.ordersubmitform.value,
          {
            chickenCenter_UID: localStorage.getItem('u_id'),
            trader_u_id: this.traderdata.u_id,
            orderBy: 'autobooked',
            delivery_status: false,
            trader_number: this.traderdata.phone,
            trader_name: this.traderdata.name,
            market_price: this.traderdata.broadcastedPrice,
            km_charge: this.traderdata.km_price_charge,
            purchsed_price: this.traderdata.dynamic_price,
            status: 'Wait for Order conformation from Trader',
            order_expired: this.timeBound
          });
        if (this.traderdata.avvailable_birds >= this.ordersubmitform.value.noofbirds) {
          this.modalController.dismiss({
            orderStatus: order,
            newData: data,
          })
        } else {
          console.error('orderRequest Can\'t fullfill due to availabilty then your request');
        }
      }
    } else {
      this.modalController.dismiss({
        orderStatus: order,
        newData: []
      });
    }
  }
  //if select past dates it must show error msg
  // dayverify(orderDate) {
  //   console.log(orderDate);
  //   if (new Date(new Date(orderDate).setHours(0, 0, 0, 0)) < new Date(new Date().setHours(0, 0, 0, 0))) {
  //     this.showError("You cant choose past dates.")
  //     this.ordersubmitform.patchValue({ 'orderDate': '' })
  //   } else {
  //     console.log('success');
  //     let month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  //     this.timeBound = new Date(orderDate).setDate(new Date(orderDate).getDate() + 1);
  //     this.timeBound = new Date(this.timeBound).getDate() + '/' + month[new Date(this.timeBound).getMonth()] + '/' + new Date(this.timeBound).getFullYear() + ' 11:30 AM';
  //   }
  // }
  valueVerify(event) {
    if (event.target.value > 4) {
      this.ordersubmitform.controls['weight_from'].setValue(4)
    }
  }

  month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  dayverify(orderDate) {
    if (new Date(new Date(orderDate).setHours(0, 0, 0, 0)) < new Date(new Date().setHours(0, 0, 0, 0))) {
      this.showError(this.translate.instant('alertMessages.pastdate'));
      this.ordersubmitform.patchValue({ 'orderDate': '' });
    }
    else if (new Date(orderDate).getDate() > new Date().getDate()) {
      this.timeBound = new Date(orderDate).setDate(new Date(orderDate).getDate() + 1);
      this.timeBound = new Date(this.timeBound).getDate() + '/' + this.month[new Date(this.timeBound).getMonth()] + '/' + new Date(this.timeBound).getFullYear() + ' 11:30 AM';
    }
    else {
      if (new Date(orderDate).getHours() <= 11) {
        if (new Date(orderDate).getHours() == 11 && new Date(orderDate).getMinutes() <= 30) {
          this.timeBound = new Date(orderDate).setDate(new Date(orderDate).getDate());
          this.timeBound = new Date(this.timeBound).getDate() + '/' + this.month[new Date(this.timeBound).getMonth()] + '/' + new Date(this.timeBound).getFullYear() + ' 11:30 AM';
        }
        else if (new Date(orderDate).getHours() == 11 && new Date(orderDate).getMinutes() > 30) {
          this.timeBound = new Date(orderDate).setDate(new Date(orderDate).getDate() + 1);
          this.timeBound = new Date(this.timeBound).getDate() + '/' + this.month[new Date(this.timeBound).getMonth()] + '/' + new Date(this.timeBound).getFullYear() + ' 11:30 AM';
        }
        else {
          this.timeBound = new Date(orderDate).setDate(new Date(orderDate).getDate());
          this.timeBound = new Date(this.timeBound).getDate() + '/' + this.month[new Date(this.timeBound).getMonth()] + '/' + new Date(this.timeBound).getFullYear() + ' 11:30 AM';
        }
      }
      else {
        this.timeBound = new Date(orderDate).setDate(new Date(orderDate).getDate() + 1);
        this.timeBound = new Date(this.timeBound).getDate() + '/' + this.month[new Date(this.timeBound).getMonth()] + '/' + new Date(this.timeBound).getFullYear() + ' 11:30 AM';
      }
    }
  }

  valueConfirmation(event) {
    if ((event.target.value >= this.ordersubmitform.value.weight_from) && (event.target.value <= 4)) {
    } else {
      this.showError("value cant exceed minimum value");
      this.ordersubmitform.controls['weight_to'].reset()
    }
  }

  orderSubmit(param: Boolean) {
    this.isDisabled = false;
    if (param) {
      const data = Object.assign(
        this.ordersubmitform.value,
        {
          chickenCenter_UID: localStorage.getItem('u_id'),
          trader_u_id: this.traderdata.u_id,
          orderBy: 'autobooked',
          delivery_status: false,
          trader_number: this.traderdata.phone,
          trader_name: this.traderdata.name,
          market_price: this.traderdata.broadcastedPrice,
          km_charge: this.traderdata.km_price_charge,
          purchsed_price: this.traderdata.dynamic_price,
          status: 'Wait for Order conformation from Trader',
        });
      this.modalController.dismiss({
        orderStatus: param,
        newData: data,
      })
    } else {
      this.modalController.dismiss({
        orderStatus: param,
        newData: []
      });
    }
  }
}