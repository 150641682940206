import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { config } from '../helper/config';

@Injectable({
  providedIn: 'root'
})
export class FarmerService {
set;
  constructor(public http: HttpClient) { }

  setVehicleData(data){
    this.set = data
  }

  getVehicleData(){
    return this.set;
  }

  manageOrder(): Observable<any> {
    return this.http.get(config.api + 'inventory/farmerOrdersNotification');
  }

  updateOrderNotification(data): Observable<any> {
    return this.http.post(config.api + 'inventory/ordersNotificationUpdate', data);
  }

  supervisorContacts(data): Observable<any> {
    return this.http.post(config.api + 'contacts/farmerSupervisorList', data)
  }

  farmerTraderContacts(data):Observable<any>{
    return this.http.post(config.api + 'contacts/farmerTraderContactList', data)
  }

  supervisorContactsList(data):Observable<any>{
    return this.http.post(config.api+"contacts/supervisorContactsList",data)
  }
}
