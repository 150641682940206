import { TraderService } from 'src/services/trader.service';
import { FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { SelectService } from 'src/app/select.service';
import { ModalController, NavParams } from '@ionic/angular';
import { objectEach } from 'highcharts';

@Component({
  selector: 'app-van-contact-addd',
  templateUrl: './van-contact-addd.page.html',
  styleUrls: ['./van-contact-addd.page.scss'],
})
export class VanContactAdddPage implements OnInit {
  addVanform;
  singleVanData = {};
  selected = "boxmodel";
  vanInfo;
  disable = false;
  constructor(
    protected fb: FormBuilder,
    public unviersal: SelectService,
    protected trader: TraderService,
    private navParams: NavParams,
    private modal: ModalController,
  ) {
  }
  ngOnInit() {
    this.addVanform = this.fb.group({
      VanNumber: ['', [Validators.required]],
      Capacity: [0],
      vanModel: ['', [Validators.required]],
      BoxCapacity: [0],
    });
    this.addVanform.controls['vanModel'].setValue(this.selected);
    if (this.navParams.data.vansList.length > 0 && this.navParams.data.vansList[0].keys > 0) {
      this.singleVanData = this.navParams.data.vansList[0];
    }
    if(this.navParams.data.vanInfo){
      this.vanInfo = this.navParams.data.vanInfo;
      this.addVanform.controls['VanNumber'].setValue(this.vanInfo.vanNumber);
      this.addVanform.controls['Capacity'].setValue(this.vanInfo.capacity);
      this.addVanform.controls['vanModel'].setValue(this.vanInfo.vanModel);
      this.addVanform.controls['BoxCapacity'].setValue(this.vanInfo.boxCapacity);
      this.disable = true;
    }


  }
  ionViewDidEnter() { }


  validation_messages = {
    VanNumber: [{ type: 'required', message: 'vehicleNumber' }, { type: 'maxlength', message: 'vehicleNumberMax' }],
    Capacity: [{ type: 'required', message: 'capacityRequired' }],
    BoxCapacity: [{ type: 'required', message: 'boxcapacityRequired' }],
    vanModel: [{ type: 'required', message: 'boxcapacityRequired' }]
  };

  final;
  addVanContact() {
    if(!this.disable){
    let paramData;
    if (localStorage.getItem('role_id') == '1') {
      if (this.singleVanData.hasOwnProperty('createdFor')) {
        paramData = Object.assign(
          this.addVanform.value,
          { 
            createdFor: this.singleVanData['createdFor'],
            BoxCapacity: this.addVanform.value.BoxCapacity || 0,
            Capacity: this.addVanform.value.Capacity || 0,
          }
        );
      } else {
        paramData = Object.assign(
          this.addVanform.value,
          {
            createdFor: this.navParams.data.traderData['traderId'],
            phone: this.navParams.data.traderData['phone'],
            email: this.navParams.data.traderData["email"],
            BoxCapacity: this.addVanform.value.BoxCapacity || 0,
            Capacity: this.addVanform.value.Capacity || 0,
          }
        );
      }
    } else {
      if (localStorage.getItem('role_id') == '8' || localStorage.getItem('role_id') == '2') {
        paramData = this.addVanform.value;
      } else {
        paramData = Object.assign(
          this.addVanform.value,
          {
            createdFor: this.navParams.data.traderData['traderId'],
            phone: this.navParams.data.traderData['phone'],
            email: this.navParams.data.traderData["email"]
          }
        );
      }
    }
    console.log(paramData, 87);
    this.trader.traderContactAdd(paramData).subscribe((data) => {
      console.log(data, 90);
      this.addVan(data.data);
      // this.trader.traderVechilesList({ skip: 0, sort: "orderCount" }).subscribe((data1) => {
      //   console.log(data1)
      //   // this.trader.addedVans.next(data1.data);
      // })

      // this.router.navigateByUrl('/trader/tabs/lifting'); 
    });
  }
  else{
    Object.assign(this.addVanform.value,{_id:this.vanInfo._id})
    this.trader.editvan(this.addVanform.value).subscribe((res)=>{
      if(res.success){
        this.modal.dismiss({
          status: "addedNew",
        });
      }  
    })
  }
  }

  change() {
    this.selected = this.addVanform.controls['vanModel'].value;
    if (this.selected == "general") {
      this.addVanform.controls['BoxCapacity'].setValue("")
    }
  }

  addVan(data) {
    if (data) {
      if (this.navParams.data.data == "fromVanSelect") {
        this.modal.dismiss({
          status: "addedNew",
          data: data
        });
      } else if (this.navParams.data.data == "fromLifting") {
        this.modal.dismiss({
          reason: "newVanLifting",
          data: data
        });
      } else if (this.navParams.data.data == 'IncomingLifting') {
        this.modal.dismiss({
          reason: "addLiftingFromFarmerTab",
          data: data
        });
      }
    } else {
      this.modal.dismiss({
        status: "addedNew",
      });
    }
  }
}
