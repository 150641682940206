import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from '../app/helper/config';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ChickenService {
  temp_routingData;
  orderData: any;
  traderList: any;
  localSavedRequestData: any;
  constructor(private http: HttpClient) { }

  private ccSubject = new BehaviorSubject<any>([]);
  public ccAsObs$ = this.ccSubject.asObservable();

  public ccNotifications = new BehaviorSubject<any>([]);
  public ccNotificationRef$ = this.ccNotifications.asObservable();


  public ordersList = new BehaviorSubject<any>([]);
  public ordersList$ = this.ordersList.asObservable();


  chickencenterorder(data): Observable<any> {
    return this.http.post(config.api + 'chickencenter/insertchickencenter', data).pipe(
      map((response: any) => {
        this.ccSubject.next(response.data);
        return response;
      }));
  }
  gerorders() {
    return this.http.get(config.api + 'chickencenter/getchickencenter');
  }
  getCCPastorders(skip) {
    return this.http.post(config.api + 'chickencenter/getPastOrders', skip);
  }
  getTraderPastorders(skip) {
    return this.http.post(config.api + 'trader/traderPastOrders', skip);
  }
  getorder() {
    return this.http.get(config.api + 'chickencenter/getorders');
  }
  getTradersList(data) {
    return this.http.post(config.api + 'trader/getTradersList', data);
  }

  setLocalSavedTraderList(traderList) {
    this.traderList = traderList;
  }
  getLocalSavedTraderList() {
    return this.traderList;
  }

  navParam(data) {
    this.temp_routingData = data;
  }
  getNavparams() {
    return this.temp_routingData;
  }


  setOrderData(data) {
    this.orderData = data;
  }

  getMapData() {
    return this.orderData;
  }
  getVechilesList(data): Observable<any> {
    return this.http.post(config.api + 'trader/traderVansList', data);
  }
  getPlacedOrdes(data) {
    return this.http.post(config.api + 'chickencenter/chickenCenterOrders', data);
  }
  searchForTrader(data): Observable<any> {
    return this.http.post(config.api + 'chickencenter/findTrader', data)
  }
  getCcNotifications(): Observable<any> {
    return this.http.get(config.api + 'chickencenter/getCcNotifications').pipe(
      map((resp: any) => {
        this.ccNotifications.next(resp);
        return resp;
      }))
  }
  readCCNotifications(data): Observable<any> {
    return this.http.post(config.api + 'chickencenter/readNotification', data)
  }

  localSaveRequestRecived(data) {
    this.localSavedRequestData = data;
    this.ccNotifications.next(data);
  }
  savedLocalRequestData() {
    return this.localSavedRequestData;
  }

  CCroute;
  CCTransactions(data) {
    this.CCroute = data;
  }

  getCCTransactions() {
    return this.CCroute;
  }

}
