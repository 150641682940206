import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ordersort'
})
export class OrdersortPipe implements PipeTransform {

  transform(value: any[], ...args: any): any {
    if (value == null) {
      return null;
    }
    if (args == 'today') {
      // console.log(value, 136);
      let a = value.filter(c => {
        let d = new Date();
        let getDate = new Date(c.OrderCreateDate);
        if ((d.getDate() + "/" + d.getMonth() + 1 + "/" + d.getFullYear()) === (getDate.getDate() + "/" + getDate.getMonth() + 1 + "/" + getDate.getFullYear())) {
          return c;
        }
      });
      return a;
    }
    else if (args == 'past') {
      let pastDates = value.filter(c => {
        let d = new Date();
        let getDate = new Date(c.OrderCreateDate);
        if ((getDate.getDate() + "/" + getDate.getMonth() + 1 + "/" + getDate.getFullYear()) < (d.getDate() + "/" + d.getMonth() + 1 + "/" + d.getFullYear())) {
          return c;
        }
      });
      return pastDates;
    }
    else if (args == 'feature') {
      let featureDate = value.filter(c => {
        let d = new Date();
        let getDate = new Date(c.OrderCreateDate);
        if ((getDate.getDate() + "/" + getDate.getMonth() + 1 + "/" + getDate.getFullYear()) > (d.getDate() + "/" + d.getMonth() + 1 + "/" + d.getFullYear())) {
          return c;
        }
      });
      return featureDate;
    }
  }

}
