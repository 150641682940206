import { Injectable } from '@angular/core';
import { LoadingController, ToastController } from '@ionic/angular';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { TranslateService } from '@ngx-translate/core';
@Injectable({
  providedIn: 'root'
})
export class LoadingserviceService {
  private loading: HTMLIonLoadingElement;
  isLoading = false;
  constructor(
    public loadingController: LoadingController,
    public dialPad: CallNumber,
    public toastController: ToastController,
    private translate: TranslateService
  ) { }


  // async loadingPresent() {

  //   if (!this.loading) {
  //     const loading = await this.loadingController.create({
  // spinner: 'lines-small',
  // message: this.translate.instant('common.pleaseWait'),
  // translucent: true,
  // backdropDismiss: true,
  //     });
  //     return await loading.present().then(() => console.log('present'));
  //   }
  // }
  // async loadingDismiss() {
  //   // console.log("loader closed, 31");
  //   // setTimeout(() => {
  //   //   return this.loadingController.dismiss();
  //   // }, 100);
  //   // return await this.loadingController.dismiss().then(() => console.log('dismissed'));
  // }


  async loadingPresent() {
    this.isLoading = true;
    return await this.loadingController.create({
      spinner: 'lines-small',
      message: this.translate.instant('common.pleaseWait'),
      translucent: true,
      backdropDismiss: true,
    }).then(a => {
      a.present().then(() => {
        if (!this.isLoading) {
          a.dismiss().then(() => {
            // console.log('abort presenting')
          }
          );
        }
      });
    });
  }

  async loadingDismiss() {
    this.isLoading = false;
    return await this.loadingController.dismiss().then(() => {
      // console.log('dismissed');
    });
  }

  async smartRouting() {
    if (!this.loading) {
      const loading = await this.loadingController.create({
        spinner: 'lines-small',
        // message: "fetching optimal path sugguestions",
        message: "fetching optimal path sugguestions",
        translucent: true,
        backdropDismiss: true,
      });
      return await loading.present()
    }
  }

  async dynamicLoader(data) {

    if (!this.loading) {
      const loading = await this.loadingController.create({
        spinner: 'lines-small',

        message: data,
        translucent: true,
        backdropDismiss: true,
      });
      return await loading.present()
    }
  }


  // async loadingDismiss(success: boolean) {
  //   // setTimeout(() => {
  //   //   return this.loading.dismiss(null, "cancelled");
  //   // }, 600);
  //   if (this.loading) {
  //     await this.loading.dismiss(success);
  //     this.loading = null;
  //   }
  // }


  dial(data) {
    this.dialPad.callNumber(data, true).then(res => {
      // console.log("dialer launched");
    }), error => {
      // console.log("error occured");
    }
  }


  async showError(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 3000,
      position: 'bottom',
      buttons: [
        {
          text: this.translate.instant('buttons.done'),
          role: 'cancel',
          handler: () => {
            // console.log('Cancel clicked');
          }
        }
      ],
      animated: true,
      cssClass: "my-custom-class",
    });
    toast.present();
  }
}
