import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PageRefresherService {
  refresh: BehaviorSubject<Boolean>;
  refresh$ = () => this.refresh.asObservable();

  constructor() {
    this.initializesub()
  }
  refreshPage(event) {
    console.log(event)
    this.refresh.next(event);
  }
  initializesub() {
    this.refresh = new BehaviorSubject(false);
  }
}
