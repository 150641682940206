import { LayerfarmerSupervisorService } from 'src/app/services/layerfarmer-supervisor.service';
import { SupervisorService } from './../../../services/supervisor.service';
import { Component, OnInit } from '@angular/core';
import { LayersService } from "../../services/layers.service";
import { Router, NavigationExtras } from '@angular/router';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { LoadingserviceService } from 'src/app/loadingservice.service';
import { AddFeedStockPage } from "../../layer-farmer/add-feed-stock/add-feed-stock.page";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-show-feed-over-view',
  templateUrl: './show-feed-over-view.page.html',
  styleUrls: ['./show-feed-over-view.page.scss'],
})
export class ShowFeedOverViewPage implements OnInit {
  currencySymbol: string = localStorage.getItem('currencySymbol');
  solidUnits: string = localStorage.getItem('solidUnits')
  feedstocks = [];
  summaryData: any;
  allData: any[];
  feed: any;
  selectedDropdown: string;
  fromUpdatePage: boolean = false;
  inventoryUpdateDetails: any;
  formulaDetails: any;

  constructor(
    private modal: ModalController,
    private router: Router,
    private alert: AlertController,
    private LayersService: LayersService,
    private loader: LoadingserviceService,
    public translate: TranslateService,
    public supervisor: SupervisorService,
    public layerSupervisor: LayerfarmerSupervisorService,
    private ModalController:ModalController
  ) { }

  ngOnInit() {
  }

  ionViewWillEnter() {


    this.selectedDropdown = "date"
    this.getFeedstock({ sort: this.selectedDropdown });
  }

  getFeedstock(sort) {
    this.loader.loadingPresent();
    if (localStorage.getItem('role_id') == '3') {
      let data = this.supervisor.locallySaved();
      Object.assign(sort, { u_id: data['u_id'] })
      this.solidUnits = data['solidUnits'];
    } else if (localStorage.getItem('role_id') == '11') {
      let data = this.layerSupervisor.returnAddedFarmer();
      Object.assign(sort, { u_id: data['u_id'] })
      this.solidUnits = data['solidUnits']
    }

    this.LayersService.getfeedstock(sort).subscribe((data: any) => {
      this.loader.loadingDismiss();
      if (data.success) {
        this.feedstocks = data.data;
        if (this.feedstocks.length > 0) {
          this.summaryData = data.data['0']['allStocksData'];
          const groups = data.data[0].allStocksData.reduce((groups, orders) => {
            const date = orders.created_Date.split('T')[0];
            if (!groups[date]) {
              groups[date] = [];
            }
            groups[date].push(orders);
            return groups;
          }, {});
          this.allData = Object.keys(groups).map((date) => {
            return {
              date,
              allStocksData: groups[date]
            };
          });
        }
      }
    });
  }

  onSelect(val) {
    if (!val) {
      this.modal.dismiss({
        status: false
      })
    } else {
      this.modal.dismiss({
        status: true,
      })
    }
  }

  // moredetail(feedstocks) {
  //   console.log(feedstocks, 250);
  //   this.layerService.feedstockmore(feedstocks);
  //   this.router.navigateByUrl('layerfarmer/feedstock-detail-view');
  // }

  moredetailForOverView() {
    this.LayersService.feedstockmoreDetailsView(this.feedstocks);
    this.modal.dismiss({
      status: true,
      moreDetails: true,
    });
  }

  doRefresh(event) {
    let sort = { sort: this.selectedDropdown }
    if (localStorage.getItem('role_id') == '3') {
      let data = this.supervisor.locallySaved();
      Object.assign(sort, { u_id: data['u_id'] })
    } else if (localStorage.getItem('role_id') == '11') {
      let data = this.layerSupervisor.returnAddedFarmer();
      Object.assign(sort, { u_id: data['u_id'] })
    }
    this.getFeedstock(sort)
    setTimeout(() => {
      event.target.complete();
    }, 2000);
  }

  async filter() {
    const alert = await this.alert.create({
      inputs: [
        {
          label: this.translate.instant('formLabels.date'), 
          value: 'date',
          type: 'radio',
          checked: this.selectedDropdown == "date"
        },
        {
          label: this.translate.instant('formLabels.price'),
          value: 'price',
          type: 'radio',
          checked: this.selectedDropdown == "price"
        },
        {
          label: this.translate.instant('Lfarmer.Quantity'),
          value: 'quantity',
          type: 'radio',
          checked: this.selectedDropdown == "quantity"
        }
      ],
      message: this.translate.instant('Lfarmer.ChooseSelectedOption'),
      cssClass: 'customAllert',
      buttons: [
        {
          text: this.translate.instant('buttons.cancel'),
          role: 'cancel',
          handler: (blah) => {
            console.log('choose cancel');
          }
        },
        {
          text: this.translate.instant('buttons.ok'),
          handler: (result) => {
            console.log(result, 105)
            this.selectedDropdown = result;
            let sort = { sort: this.selectedDropdown }
            if (localStorage.getItem('role_id') == '3') {
              let data = this.supervisor.locallySaved();
              Object.assign(sort, { u_id: data['u_id'] })
            } else if (localStorage.getItem('role_id') == '11') {
              let data = this.layerSupervisor.returnAddedFarmer();
              Object.assign(sort, { u_id: data['u_id'] })
            }
            this.getFeedstock(sort)

          }
        }
      ],
    });
    await alert.present();
  }

  addfeedstock() {
    // let modal = await this.modal.create({
    //   component: AddFeedStockPage
    // })
    // modal.onDidDismiss().then((data: any) => {

    // });
    // return await modal.present();
    this.modal.dismiss({
      status: true,
      openAddStock: true,
    });
  }
  
  async editFeedStock(data) {
    let modal = await this.ModalController.create({
      component: AddFeedStockPage,
      componentProps: {
        openFromPage: "editFormula",
        feedData: data
      }
    });
    modal.present();
    modal.onDidDismiss().then((data: any) => {
      console.log(data, 67);
      if (data.data.status) {
        this.getFeedstock({ sort: this.selectedDropdown });
      } else {
        console.log("it's just close");
      }
    });
  }
  deleteFeedstock(feedDoc) {
    this.LayersService.checkfeedStockDoc(feedDoc).subscribe(async (check: any) => {
      console.log(check, 132);
      if (check.success) {
        if (check.data[0].used) {
          const alert = await this.alert.create({
            header: this.translate.instant('alertMessages.usedFarmulaAlert'),
            cssClass: 'singleButtonAlert',
            buttons: [
              {
                text: this.translate.instant('buttons.okay'),
                handler: (data) => {
                  console.log(data);
                }
              }]
          });
          await alert.present();
        } else {
          const alert = await this.alert.create({
            header: this.translate.instant('alertMessages.unUsedFarmulaAlert'),
            cssClass: 'wholeSellerAlert',
            buttons: [
              {
                text: 'Cancel',
                role: 'cancel',
                handler: (canceldata) => {
                  console.log('Cancel clicked');
                }
              },
              {
                text: 'Sure',
                handler: (submit) => {
                  console.log(feedDoc);
                  this.LayersService.deleteFeed(feedDoc).subscribe((deleteDoc: any) => {
                    console.log(deleteDoc, 173);
                    if (deleteDoc.success) {
                      this.getFeedstock({ sort: this.selectedDropdown });
                    } else {
                      console.log("delete failed", 175);
                    }
                  })
                }
              }]
          });
          await alert.present();
        }
      } else {
        console.log("failed check feed", 181);
      }
    });
  }
}
