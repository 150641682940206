import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'distance'
})
export class DistancePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let val = value;
    if (typeof value == 'string') {
      val = parseFloat(value);
    }
    if (val < 1) {
      return parseInt((value * 1000).toFixed(3)) + ' m';
    } else {
      return parseFloat(value.toFixed(2)) + ' Km.';
    }
  }
}
