import { LayerfarmerSupervisorService } from 'src/app/services/layerfarmer-supervisor.service';
import { Component, OnInit } from '@angular/core';
import { LayersService } from "../../services/layers.service";
import { Router, NavigationExtras, } from '@angular/router';
import { AlertController, ModalController, ToastController, NavParams } from '@ionic/angular';
import { FeedrelatedService } from "../../services/feedrelated.service";
import { SupervisorService } from 'src/services/supervisor.service';
import { LoadingserviceService } from "../../loadingservice.service";

@Component({
  selector: 'app-select-formula',
  templateUrl: './select-formula.page.html',
  styleUrls: ['./select-formula.page.scss'],
})
export class SelectFormulaPage implements OnInit {
  formulasList = [];
  solidUnits: string = localStorage.getItem('solidUnits');
  currencySymbol: string = localStorage.getItem('currencySymbol');
  exitFormula: any;
  selectFormula: any;
  constructor(
    private modal: ModalController,
    private LayersService: LayersService,
    private route: Router,
    private feedrelatedService: FeedrelatedService,
    private supervisor: SupervisorService,
    private navParams: NavParams,
    private LoadingserviceService: LoadingserviceService,
    private layerSupervisor: LayerfarmerSupervisorService
  ) {
    this.exitFormula = this.navParams.get("formulaId");
  }

  ngOnInit() {
    let index = { index: 0 };
    let addedFarmer = this.supervisor.locallySaved();
    let layerFarmer = this.layerSupervisor.returnAddedFarmer();
    if (localStorage.getItem('role_id') == '3') {
      Object.assign(index, { u_id: addedFarmer['u_id'] })
    } else if (localStorage.getItem('role_id') == '11') {
      Object.assign(index, { u_id: layerFarmer['u_id'] })
      this.solidUnits = layerFarmer['solidUnits'];
    }
    this.getFormulas(index);


  }
  onSelect(val) {
    if (!val) {
      this.modal.dismiss({
        status: false
      });
    } else {
      this.modal.dismiss({
        status: true,
      });
    }
  }

  chooseFormula(i) {
    if (i >= 0) {
      this.modal.dismiss({
        formula: this.formulasList[i],
        status: true,
      })
    } else {
      let formula = {
        formulaName: "Standard",
      }
      this.modal.dismiss({
        formula: formula,
        status: true,
      })
    }
  }

  getFormulas(index) {
    this.LoadingserviceService.loadingPresent();
    if (localStorage.getItem('role_id') == "3") {
      console.log("supervisor");
      let selectedFarmer = this.supervisor.locallySaved();
      Object.assign(index, { u_id: selectedFarmer['u_id'] });
    } else if (localStorage.getItem('role_id') == '11') {
      let selectedFarmer = this.layerSupervisor.returnAddedFarmer();
      Object.assign(index, { u_id: selectedFarmer['u_id'] });
    }
    this.LayersService.getFormulasList(index).subscribe((list: any) => {
      if (list.success) {
        this.LoadingserviceService.loadingDismiss();
        if (localStorage.getItem('role_id') == '3') {
          let data = this.supervisor.locallySaved();
          this.solidUnits = data['solidUnits'];
        }
        this.formulasList = list.data;
        this.formulasList.map((a) => {
          if (a._id == this.exitFormula) {
            a.select = true;
            this.selectFormula = a.formulaName;
          } else {
            a.select = false;
          }
        });
        this.formulasList.forEach(data => {
          let pricePerKg = [];
          data.formulaPercentage.forEach(element => {
            if (element.typePricePerkg) {
              pricePerKg.push(element.typePricePerkg * (element.feedPercent / 100));
            } else {
              pricePerKg = [];
            }
          });
          data.formulaPricePerKg = pricePerKg.reduce((a, b) => a + b, 0)
        });

      } else {
        this.LoadingserviceService.loadingDismiss();
        console.log(list.msg);
      }
    });
  }
  loadData(event) {
    let index = { index: this.formulasList.length };
    if (localStorage.getItem('role_id') == '3') {
      let selectedFarmer = this.supervisor.locallySaved();
      Object.assign(index, { u_id: selectedFarmer['u_id'] });
    } else if (localStorage.getItem('role_id') == '11') {
      let selectedFarmer = this.layerSupervisor.returnAddedFarmer();
      Object.assign(index, { u_id: selectedFarmer['u_id'] });
    }
    this.LayersService.getFormulasList(index).subscribe((list: any) => {
      if (list.success) {
        list.data.forEach((ele) => {
          this.formulasList.push(ele);
        });

        this.formulasList.map((a) => {
          if (a._id == this.exitFormula) {
            a.select = true;
            this.selectFormula = a.formulaName;
          } else {
            a.select = false;
          }
        });
        this.formulasList.forEach(data => {
          let pricePerKg = [];
          data.formulaPercentage.forEach(element => {
            if (element.typePricePerkg) {
              pricePerKg.push(element.typePricePerkg * (element.feedPercent / 100));
            } else {
              pricePerKg = [];
            }
          });
          data.formulaPricePerKg = pricePerKg.reduce((a, b) => a + b, 0)
        });
      } else {
        console.log(list.msg);
      }
    });
    setTimeout(() => {
      console.log('Done');
      event.target.complete();

      // App logic to determine if all data is loaded
      // and disable the infinite scroll
      // if (data.length == 1000) {
      //   event.target.disabled = true;
      // }
    }, 500);
  }

  addFormula() {
    // let formula = {
    //   show: false
    // }
    // this.LayersService.carryFrmula(formula);
    // this.route.navigate(['./layerfarmer/altrntvFrmla']);
    // this.modal.dismiss({
    //   status: false,
    //   addFormula: true,
    // })
    this.modal.dismiss({
      status: false,
      openAddFormula: true,
    });
  }

  calculatePerc(data) {
    let sum = 0;
    for (let meal of data.formulaPercentage) {
      sum += meal['feedPercent'];
    }
    return sum;
  }
}
