import { CommonService } from 'src/services/common.service';
import { LayerWholeSellerService } from './../services/layer-whole-seller.service';
import { Component, OnInit, ViewContainerRef, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { LiftingsService } from '../../services/liftings.service';
import { TraderService } from './../../services/trader.service';
import { Router } from '@angular/router';
import { NavParams, ModalController } from '@ionic/angular';
import { LoadingserviceService } from '../loadingservice.service';
import { LayersService } from '../services/layers.service';
import { TranslateService } from '@ngx-translate/core';
import { LayerRetailSupplierService } from '../services/layer-retail-supplier.service';
import { LayerShopServiceService } from '../services/layer-shop-service.service';

@Component({
  selector: 'app-layers-sales-price-settings',
  templateUrl: './layers-sales-price-settings.component.html',
  styleUrls: ['./layers-sales-price-settings.component.scss'],
})
export class LayersSalesPriceSettingsComponent implements OnInit {
  currencySymbol: string = localStorage.getItem('currencySymbol');
  country = localStorage.getItem('country').toLowerCase();
  settingsForm: FormGroup;
  activeTab: Boolean = true;
  cities: any = [];
  _inventoryId: any;
  priceStatus: any;
  cityStatus: boolean = false;
  cityname: any;
  invenLocation: any;
  totalPrice: any;
  result: any;
  validation_messages = {
    'marketPrice': [{ type: 'required', message: this.translate.instant('Lfarmer.required') },],
    'automaticCounter': [{ type: 'required', message: 'required.' }],
    'margin': [{ type: 'required', message: this.translate.instant('Lfarmer.required') }, { type: 'pattern', message: this.translate.instant('Lfarmer.numalowed') }],
  }
  constructor(
    private fb: FormBuilder,
    protected liftingService: LiftingsService,
    private traderService: TraderService,
    private router: Router,
    private navParams: NavParams,
    protected modalCtrl: ModalController,
    private ls: LoadingserviceService,
    private cdr: ChangeDetectorRef,
    private layerService: LayersService,
    private translate: TranslateService,
    private lyrWhlSllrSrvce: LayerWholeSellerService,
    private retail: LayerRetailSupplierService,
    private sellerShop: LayerShopServiceService,
    private CommonService: CommonService

  ) {
    if (localStorage.getItem('country') == "India") {
      this.settingsForm = this.fb.group({
        marketPrice: ['', Validators.required],
        city: ['', Validators.required],
        margin: [0, [Validators.required,]],
      });
    } else {
      this.settingsForm = this.fb.group({
        marketPrice: ['', Validators.required],
        margin: [0, [Validators.required,]],
      });
    }
  }

  async tabChange(type) {
    if (type === 'auto') {
      this.activeTab = true;
      // this.settingsForm.get('marketPrice').disable();
      this.formControlValuesAdd();
      this.cdr.detectChanges();
    } else {
      this.activeTab = false;
      this.settingsForm.get('marketPrice').enable();
      this.formControlValueRemove();
      this.cdr.detectChanges();
    }
    // if (localStorage.getItem('role_id') == "15" || localStorage.getItem('role_id') == "18" || localStorage.getItem('role_id') == "17") {
    //   // //this.distrubuteData(this.result)
    // }
  }

  ngOnInit() {

    if (localStorage.getItem('role_id') == "9") {
      this._inventoryId = this.navParams.get("inventoryId");
      this.invenLocation = this.navParams.get("invenLocation");
      this.ls.loadingPresent();
      console.log(this.cities, 96);
      if (this.cities.length > 0) {
        console.log("cities availabel");
      } else {
        console.log("not available");
        this.layerService.layerNearestPriceCity({ _id: this._inventoryId, location: this.invenLocation || { type: "Point", coordinates: [parseFloat(localStorage.getItem("lng")), parseFloat(localStorage.getItem("lat"))] } }).subscribe((response: any) => {
          if (response.success) {
            console.log(response, 5429);
            this.ls.loadingDismiss();
            this.cities = response.data;
            if (!this.cityname) {
              this.cityname = this.cities[0].city_name;
              this.cityStatus = true;
              this.cdr.detectChanges();
            } else {
              this.cityStatus = true;
            }
          } else {
            console.log("get cities failed", 64);
            this.ls.loadingDismiss();
          }
        }
        );
      }

      // only works for inventories existed users
      if (localStorage.getItem('role_id') == '9' || localStorage.getItem('role_id') == '11') {
        this.layerService.layerInventoryPrice({ "_id": this._inventoryId }).subscribe((data: any) => {
          if (data.success) {
            this.currencySymbol = data.data[0]['currencySymbol'];
            if (data.data[0].priceSettingType == 'manual') {
              this.tabChange("manual");
              this.settingsForm.controls['margin'].setValue((data.data[0].margin));
              this.settingsForm.controls['marketPrice'].setValue((data.data[0].price));
              this.priceStatus = data.data[0].priceSetting;
              this.totalPrice = data.data[0]['price'] + data.data[0]['margin'];
              this.cdr.detectChanges();
            } else {
              this.settingsForm.controls['margin'].setValue((data.data[0].margin));
              this.settingsForm.controls['marketPrice'].setValue((data.data[0].price));
              this.cityname = data.data[0]['cityName'];
              this.priceStatus = data.data[0]['priceSetting'];
              this.totalPrice = data.data[0]['price'] + data.data[0]['margin'];
              this.cdr.detectChanges();
            }
          } else {
            console.log("get inventory price Failed", 85);
          }
        });
      }
    }
  }
  ionViewWillEnter() {
    this.cities = this.CommonService.getCityPrices();
    if (!this.cities) {
      console.log("not a valid response");
      this.layerService.layerNearestPriceCity({ _id: this._inventoryId, location: this.invenLocation || { type: "Point", coordinates: [parseFloat(localStorage.getItem("lng")), parseFloat(localStorage.getItem("lat"))] } }).subscribe((response: any) => {
        this.cities = response.data;
        console.log(response, 900)
        if (this.cities.length > 0) {
          if (!this.cityname) {
            this.cityname = this.cities[0].city_name;
            this.cdr.detectChanges();
          }
          this.cityStatus = true;
        } else {
          console.log("ionic deverl;perk");
          this.cityname = '';
          this.cdr.detectChanges();
          this.cityStatus = true;
        }
      })
    } else {
      if (this.cities.length > 0) {
        this.cityStatus = true;
      }
    }
    if (localStorage.getItem('role_id') == "15") {
      console.log(this.cities, 174);
      this.lyrWhlSllrSrvce.getWholeSellerSettings().subscribe(data => {
        this.totalPrice = data.data[0]['price'] + data.data[0]['margin'];
        if (data.success) {
          if (data.data[0]['priceSettings'] == "auto") {
            this.activeTab = true;
            this.cityname = data.data[0]['cityname'];
            this.settingsForm.controls['margin'].setValue(data.data[0]['margin']);
            this.settingsForm.controls['marketPrice'].setValue(data.data[0]['price']);
            this.priceStatus = data.data[0] = ['broadCasting']
            this.cdr.detectChanges();
          } else {
            this.activeTab = false;
            //this.distrubuteData(data.data[0]);
            this.activeTab = false;
            this.priceStatus = data.data[0]['broadCasting'];
            this.settingsForm.controls['margin'].setValue(data.data[0]['margin']);
            this.settingsForm.controls['marketPrice'].setValue(data.data[0]['price']);
          }
        }
      })
    } else if (localStorage.getItem('role_id') == "18") {
      console.log(this.cities, 174);
      this.retail.getRetailSupplierSettings().subscribe(data => {
        if (data.success) {
          this.totalPrice = data.data[0]['price'] + data.data[0]['margin'];
          this.cityname = data.data[0]['cityname'];
          if (data.data[0].priceSettings == 'auto') {
            this.activeTab = true;
            this.settingsForm.controls['margin'].setValue((data.data[0]['margin']));
            this.settingsForm.controls['marketPrice'].setValue((data.data[0].price));
            this.priceStatus = data.data[0]['broadCasting'];
            this.cdr.detectChanges();
          } else {
            this.activeTab = false;
            this.settingsForm.controls['margin'].setValue((data.data[0].margin));
            this.settingsForm.controls['marketPrice'].setValue((data.data[0].price));
            this.priceStatus = data.data[0]['broadCasting'];
            this.cdr.detectChanges();
          }
          console.log(data.data, 457);
          this.result = data.data[0];
          //this.distrubuteData(this.result)
        }
      })
    } else if (localStorage.getItem('role_id') == "17") {
      console.log(this.cities, 174);
      this.sellerShop.getSellerShopSettings().subscribe(data => {
        this.totalPrice = data.data[0]['price'] + data.data[0]['margin'];
        if (data.success) {
          if (data.data[0]['priceSettings'] == "auto") {
            this.activeTab = true;
            this.settingsForm.controls['margin'].setValue(data.data[0]['margin']);
            this.settingsForm.controls['marketPrice'].setValue(data.data[0]['price']);
            this.cityname = data.data[0]['cityname'];
            this.priceStatus = data.data[0] = ['broadCasting']
            this.cdr.detectChanges();
          } else {
            this.activeTab = false;
            //this.distrubuteData(data.data[0]);
            this.formControlValueRemove()
            this.priceStatus = data.data[0]['broadCasting'];
            this.settingsForm.controls['margin'].setValue(data.data[0]['margin']);
            this.settingsForm.controls['marketPrice'].setValue(data.data[0]['price']);
          }
        }
      })
    }
  }
  formControlValueRemove() {
    console.log("remove form controlles ");
    const city = this.settingsForm.get('city');
    if (!this.activeTab) {
      city.clearValidators();
    } else {
      if (localStorage.getItem('country') == "India") {
        city.setValidators([Validators.required]);
      }
    }
    city.updateValueAndValidity();
  }

  formControlValuesAdd() {
    console.log(this.country)
    if (this.activeTab) {
      if (localStorage.getItem('country') == "India") {
        const city = this.settingsForm.get('city');
        city.setValidators([Validators.required]);
        city.updateValueAndValidity();
      }
    }

  }

  priceSubmit(submitStatus) {
    if (submitStatus) {
      if (!this.activeTab) {
        delete this.settingsForm.value.city;
        Object.assign(this.settingsForm.value, { priceSettingType: "manual", _id: this._inventoryId })
      } else {
        if (this.cities && this.cities.length > 0) {
          this.cities.filter((ele) => {
            if (ele.city_name == this.cityname) {
              Object.assign(this.settingsForm.value, { priceSettingType: "auto", cityId: ele.city_id, cityName: ele.city_name, _id: this._inventoryId })
            }
          });
        } else {
          console.log("no city", 122);
        }
      }
      if (localStorage.getItem('role_id') == '9' || localStorage.getItem('11')) {
        this.liftingService.settingLiftingsPrice(this.settingsForm.value).subscribe((response: any) => {
          if (response.success) {
            this.modalCtrl.dismiss({ status: submitStatus, data: this.settingsForm.value });
          } else {
            console.log("setting price failed", 141);
          }
        });
      } else {
        console.log(this.settingsForm.value, 545);
        this.layerService.priceSettingUpdate(this.settingsForm.value).subscribe((data) => {
          console.log(data.data);
          if (data.success) {
            if (localStorage.getItem('role_id') == '17') {
              this.modalCtrl.dismiss({ status: submitStatus });
              this.router.navigateByUrl('/layer-seller/tabs/seller-home')
            } else if (localStorage.getItem('role_id') == "15") {
              console.log("hello wholesealler")
              this.modalCtrl.dismiss({ status: submitStatus });
              this.router.navigateByUrl('/wholeseller/tabs/seller')
              // this.router.navigateByUrl('/home/tabs/poultry-home');
            }
            else if (localStorage.getItem('role_id') == "18") {
              this.modalCtrl.dismiss({ status: submitStatus });
              this.router.navigateByUrl('/retailer/tabs/home')
              // this.router.navigateByUrl('/home/tabs/poultry-home');
            }
          }

        })
      }
    } else {
      this.modalCtrl.dismiss({ status: submitStatus });
    }
  }

  inventoryAdvertise(event) {
    if (localStorage.getItem('role_id') == "9" || localStorage.getItem('role_id') == "11") {
      this.liftingService.inventoryAdvertise(Object.assign({ _id: this._inventoryId, inventoryAdvertise: (!event.target.checked) })).subscribe((respond: any) => {
        console.log(respond);
      });
    } else {


      this.lyrWhlSllrSrvce.layerAdvertising({ advertise: (!event.target.checked) }).subscribe(data => {
        if (data.success) {
          this.result = data.data[0];
          //this.distrubuteData(this.result);
        }
      })
    }
    // else if (localStorage.getItem('role_id') == "18") {
    //   let object = { advertise: true };
    //   (this.activeTab == true) ? Object.assign(object, { priceSettings: "auto" }) : Object.assign(object, { priceSettings: "manuell" });

    //   this.retail.updatePrices(Object.assign(object, { active: (!event.target.checked) })).subscribe(data => {
    //     if (data.success) {
    //       this.result = data.data[0];
    //       //this.distrubuteData(this.result);
    //     }
    //   })
    // } else if (localStorage.getItem('role_id') == "17") {
    //   let object = { advertise: true };
    //   (this.activeTab == true) ? Object.assign(object, { priceSettings: "auto" }) : Object.assign(object, { priceSettings: "manuell" });

    //   this.sellerShop.updatePrices(Object.assign(object, { active: (!event.target.checked) })).subscribe(data => {
    //     if (data.success) {
    //       this.result = data.data[0];
    //       //this.distrubuteData(this.result);
    //     }
    //   })
    // }
  }
  cityPrice(priceObj) {

    if (priceObj && this.cities && this.cities.length > 0) {

      this.cities.filter(ele => {

        if (ele.city_name == priceObj) {
          console.log(ele.price, 909)
          this.settingsForm.controls['marketPrice'].setValue(ele.price);
        }
      });
    } else {
      console.log("no select city");
    }
  }
  priceCalc(event, type) {
    console.log("price calculation");
    if (type == 'margin') {
      console.log(event.target.value, 5445);
      let temp = event.target.value;
      if (temp.startsWith('+') || temp.startsWith("-")) {
        if (temp.startsWith('+')) {
          console.log('adding');
          this.totalPrice = parseFloat(event.target.value) + this.settingsForm.get('marketPrice').value;
        } else {
          console.log('minius');
          this.totalPrice = this.settingsForm.get('marketPrice').value + parseFloat(event.target.value);
          console.log(this.totalPrice, 5);
        }
      } else {
        console.log("last stage ");
        this.totalPrice = parseFloat(event.target.value) + this.settingsForm.get('marketPrice').value;
      }
      console.log(this.totalPrice, 54);
    }
    else {
      console.log("price", event.target.value)
      let price = parseFloat(event.target.value);
      this.totalPrice = price + this.settingsForm.get('margin').value;
    }
  };
  // onlyPricesAndStatus(event, data) {
  //   if (localStorage.getItem('role_id') == "15") {
  //     let object = { advertise: false };
  //     console.log("wholeseller");
  //     (data == "margin") ? Object.assign(object, { margin: event, update: "margin" }) : Object.assign(object, { sellingPrice: event, update: "price" });
  //     (this.activeTab == true) ? Object.assign(object, { priceSettings: "auto" }) : Object.assign(object, { priceSettings: "manuell" });
  //     this.lyrWhlSllrSrvce.layerAdvertising(object).subscribe(data => {
  //       if (data.success) {
  //         console.log(data, 542);
  //         this.result = data.data[0];
  //         //this.distrubuteData(data.data[0]);
  //       }
  //     })
  //   } else if (localStorage.getItem('role_id') == "18") {
  //     console.log("retailer supplier");
  //     let object = { advertise: false };
  //     (data == "margin") ? Object.assign(object, { margin: event, update: "margin" }) : Object.assign(object, { sellingPrice: event, update: "price" });
  //     (this.activeTab == true) ? Object.assign(object, { priceSettings: "auto" }) : Object.assign(object, { priceSettings: "manuell" });
  //     this.retail.updatePrices(object).subscribe(data => {
  //       if (data.success) {

  //         this.result = data.data[0];
  //         //this.distrubuteData(data.data[0]);
  //       }
  //     })
  //   } else if (localStorage.getItem('role_id') == "17") {
  //     let object = { advertise: false };
  //     (data == "margin") ? Object.assign(object, { margin: event, update: "margin" }) : Object.assign(object, { sellingPrice: event, update: "price" });
  //     (this.activeTab == true) ? Object.assign(object, { priceSettings: "auto" }) : Object.assign(object, { priceSettings: "manuell" });
  //     this.sellerShop.updatePrices(object).subscribe(data => {
  //       if (data.success) {
  //         this.result = data.data[0];
  //         //this.distrubuteData(data.data[0]);
  //       }
  //     })
  //   }
  // };
  // distrubuteData(result) {
  //   console.log(result, 5424);
  //   this.cityname = result?.cityname;
  //   console.log(result?.autoPriceCity, this.cityname);
  //   // this.tabChange(result.priceSettings)
  //   if (result.priceSettings == "auto") {
  //     this.activeTab = true;
  //     this.settingsForm.controls['margin'].setValue(result?.margin || 0);
  //     this.priceStatus = result?.autoPriceActive;
  //     this.settingsForm.controls['marketPrice'].setValue(result?.price || 0);
  //   } else {
  //     this.activeTab = false;
  //     this.priceStatus = result?.manualPriceActive
  //     this.settingsForm.controls['margin'].setValue(result?.margin || 0);
  //     this.settingsForm.controls['marketPrice'].setValue(result?.price || 0);
  //   }
  // }
}
