import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { config } from '../app/helper/config';
import { HttpClient, } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class CommonService {
  formData;
  local_myfarms;
  // private farms = new BehaviorSubject<any>([]);
  // private inventory = new BehaviorSubject<any>([]);
  private totalLeasePostings = new BehaviorSubject<any>([]);
  allPostings = this.totalLeasePostings.asObservable();
  local_postings;
  private postings = new BehaviorSubject<any>([]);
  postings$ = this.postings.asObservable();
  // Observable navItem stream
  // navItem$ = this.farms.asObservable();
  newData;
  url = config.api + 'farms';
  private profileUpdateSubject: Subject<any> = new Subject();
  profileUpdate$ = this.profileUpdateSubject.asObservable();
  data: any;
  returnValue: () => void;
  inventoryFarmsMap: any;
  VansList: any;
  centersList: any;
  driverForm: any;
  driverOrders: any;
  chickencentersOrders: any;


  private lang$ = new BehaviorSubject<string>(null);
  public notificationCount = new BehaviorSubject<any>([]);
  public farmerNotifications$ = this.notificationCount.asObservable();
  localStat: any;
  whlArr: any;
  listOfCities: any;
  ordersObj: any;

  constructor(public http: HttpClient) { }

  testUrl(): any {
    return this.http.get(config.api + 'cons/testReq');
  }

  savePushToken(data): any {
    return this.http.post(config.api + 'auth/savePushToken', data);
  }

  myfarmSetData(data) {
    this.data = data;
  }
  myfarmGetData() {
    let returnValue = this.data;
    return returnValue;
  }
  setLang(language) {
    this.lang$.next(language);
  }
  getLang(): Observable<any> {
    return this.lang$.asObservable();
  }

  myfarm_farmInsert(data): Observable<any> {
    return this.http.post(config.api + 'farms/insertFarm', data)
  }

  setAValue(data) {
    // this.farms.next(data);
  }

  myfarm_farmDelete(data): Observable<any> {
    return this.http.post(config.api + 'farms/farmdelete', data);
  }

  myfarm_getDataById(data): Observable<any> {
    return this.http.post(config.api + 'farms/getFarmDataById', data);
  }

  weather(data): Observable<any> {
    return this.http.post(config.api + "crons/updateWeather", data);
  }

  myfarmcarddata(data): Observable<any> {
    return this.http.post(config.api + "farms/myfarmCardData", data);
  }
  pushNotification(data): Observable<any> {
    return this.http.post(config.api + "farms/pushNotification", data);
  }


  locallySet(item) {
    this.localStat = item;
    console.log(this.localStat, 890)

  }
  locallyGetStat() {
    console.log(this.localStat);
    return this.localStat;
  }


  deleteForm(data): Observable<any> {
    return this.http.post(config.api + "farms/farmdelete", data).pipe(map((response: any) => {
      let data = response;
      if (data.success) {
        this.local_myfarms = data.data;
        return data.data;
      } else {
        return data;
      }
    }));
  }
  updateFarmById(data): Observable<any> {
    return this.http.post(config.api + "farms/updateFarmById", data).pipe(map((response: any) => {
      let data = response;
      if (data.success) {
        this.local_myfarms = data.data;
        return data.data;
      } else {
        return data;
      }
    }));
  }
  updateMyFormByid(data): Observable<any> {
    return this.http.post(config.api + "farms/updateFarmById", data);
  }
  leasePosting(data): Observable<any> {
    return this.http.post(config.api + 'lease/insertlease', data)
  }
  ViewLeasePostings(skip): Observable<any> {
    return this.http.post(config.api + 'lease/viewlease', skip).pipe(map((response: any) => {
      let data = response;
      if (data.success) {
        this.local_postings = data.data;
        return data.data;
      } else {
        return data;
      }
    }));
  }


  postingsType(data): Observable<any> {
    return this.http.post(config.api + 'lease/postings', data)
  }


  setDatalocalPOstings(data) {
    this.local_postings = data;
  }
  setDataPostings(data) {
    this.postings.next(data);
  }

  ViewLeasePostingspecific(data): Observable<any> {
    return this.http.post(config.api + 'lease/ViewLeasePostingspecific', data)
  }
  ViewLeasePostingById(data): Observable<any> {
    return this.http.post(config.api + 'lease/sortByDate', data)
  }
  updateLeasePostById(data): Observable<any> {
    return this.http.post(config.api + 'lease/updatebyId', data);
  }
  //this is old sorting functionality in lease postings
  // sortByDate(): Observable<any> {
  //   return this.http.get(config.api + 'lease/sortByDate')
  // }
  // sortByLocation(data): Observable<any> {
  //   return this.http.post(config.api + 'lease/sortByLocation', data);
  // }
  getWeatherForecast(data): Observable<any> {
    return this.http.post(config.api + 'crons/getWeatherForcast', data);
  }

  getFeatures(data): Observable<any> {
    return this.http.post(config.api + 'auth/getFeautures', data);
  }

  getImageById(fileId): Promise<any> {
    return this.http.post(config.api + 'lease/getfile', { fileId }).toPromise();
  }

  getpdfFileById(fileId): Observable<any> {
    return this.http.post(config.api + 'getPdfFile', { fileId, responseType: "blob" });
  }

  // convertToBase64(url: string) {
  //   this.http.get(url, { responseType: "blob" }).subscribe(blob => {
  //     const reader = new FileReader();
  //     const binaryString = reader.readAsDataURL(blob);
  //     reader.onload = (event: any) => {
  //       //Here you can do whatever you want with the base64 String
  //       console.log("File in Base64: ", event.target.result);
  //     };

  //     reader.onerror = (event: any) => {
  //       console.log("File could not be read: " + event.target.error.code);
  //     };
  //     });
  // }

  phoneno_verify(data) {
    return this.http.post(config.api + 'farms/phoneno_verify', data)
  }
  tempNotification;
  setNoficationData(data) {
    this.tempNotification = data;
  }
  reciveNotfication() {
    return this.tempNotification;
  }

  farmerNotifications(data): Observable<any> {
    return this.http.post(config.api + 'notify/getfarmerNotifications', data).pipe(map((response: any) => {
      if (response.success) {
        this.notificationCount.next(response.notification_count);
        return response
      } else {
        return response;
      }
    }));
  }

  getNotifications(index): Observable<any> {
    return this.http.post(config.api + 'notify/getNotificationsData', index);
  }

  updateNotification(data): Observable<any> {
    return this.http.post(config.api + 'notify/updateNotification', data);
  }
  updateOrderConfirmation(data): Observable<any> {
    return this.http.post(config.api + 'notify/updateAutoOrderConfirmation', data);
  }
  markAsRead(data): Observable<any> {
    return this.http.post(config.api + 'notify/markAsRead', data);
  }

  markAllAsRead(data): Observable<any> {
    return this.http.post(config.api + 'notify/markAllAsRead', data);
  }

  resendNotificationToFarmer(data): Observable<any> {
    return this.http.post(config.api + 'notify/resendNotificationToFarmer', data);
  }

  clearFarmerNotifications(data): Observable<any> {
    return this.http.post(config.api + 'notify/clearFarmerNotifications', data);
  }

  addFarmerToSupervisor(data): Observable<any> {
    return this.http.post(config.api + 'notify/addfarmerToSupervisor', data);
  }


  deleteFarmer(data): Observable<any> {
    return this.http.post(config.api + "notify/deleteFarmer", data)
  }

  signature(data): Observable<any> {
    return this.http.post(config.api + 'farms/signature', data);
  }

  profileUpdated(data?: any) {
    this.profileUpdateSubject.next(true)
  }

  deletePost(data): Observable<any> {
    return this.http.post(config.api + "lease/deleteLease", data).pipe(map((response: any) => {
      let data = response;
      if (data.success) {
        this.local_postings = data.data;
        return data.data;
      } else {
        return data;
      }
    }));
  }
  getCount() {
    return this.http.get(config.api + 'dealer/dealer_views');
  }
  getFarmerNotification() {
    return this.http.get(config.api + 'crons/sendNotifications');
  }

  getNotificationslazy(data): Observable<any> {
    return this.http.post(config.api + 'notify/getNotificationsDatarefresh', data);
  }
  marketIndicators(data): Observable<any> {
    return this.http.post(config.api + 'marketPrice/singleMarketPrice', data);
  }

  marketPrices: EventEmitter<any> = new EventEmitter();
  local_marketPrices;

  marketPricesSummary(): Observable<any> {
    return this.http.get(config.api + 'marketPrice/distance_based_price_list').pipe(
      map((response: any) => {
        let marketRates = response;
        if (marketRates.success) {
          this.marketPrices.emit(marketRates.data);
          this.local_marketPrices = marketRates.data;
          return this.local_marketPrices;
        } else {
          return marketRates;
        }
      }));
  }

  myform_getData(data): Observable<any> {
    return this.http.get(config.api + `farms/farmsList?u_id=${data.u_id}`).pipe(
      map((response: any) => {
        let myfarms = response;
        if (myfarms.success) {
          this.local_myfarms = myfarms.data;
          return myfarms.data;
        } else {
          return myfarms;
        }
      }));
  }

  myform_clearData(): Observable<any> {
    this.local_myfarms = null;
    return null;
  }
  getPostings(): Observable<any> {
    return this.http.get(config.api + 'lease/viewlease').pipe(map((response: any) => {
      let data = response;
      if (data.success) {
        this.local_postings = data.data;
        return data.data;
      } else {
        return data;
      }
    }));
  };

  driverConformation(): Observable<any> {
    return this.http.get(config.api + "/driver/loader")
  }
  getNewVanOrders(): Observable<any> {
    return this.http.get(config.api + 'trader/vanOredrsNotification')
  }
  removeNotificationsForVan(id): Observable<any> {
    console.log('van remove orders', id)
    return this.http.post(config.api + 'trader/removeVanNotification', id)
  }


  setInventoryfarmList(data) {
    this.inventoryFarmsMap = data;
  }
  getMapsData() {
    return this.inventoryFarmsMap
  }

  setVansList(data) {
    this.VansList = data;
  }
  getVanList() {
    return this.VansList;
  }

  setCenters(data) {
    this.centersList = data;
  }
  getCenters() {
    return this.centersList;
  }

  setDriverForm(data) {
    this.driverForm = data;
  }
  getDriverForm() {
    return this.driverForm;
  }
  setDriverOrders(data) {
    this.driverOrders = data;
  }
  getDriverOrders() {
    return this.driverOrders;
  }
  setchickenOrders(data) {
    this.chickencentersOrders = data;
  }
  getchickenOrders() {
    return this.chickencentersOrders;
  }

  driverConformationUpdate(data): Observable<any> {
    return this.http.post(config.api + "driver/driverUpdateStatus", data)
  }
  pending;
  pendingOrders(data) {
    this.pending = data
  }
  updateStatus(data): Observable<any> {
    return this.http.post(config.api + 'trader/viewStatus', data);
  }
  ckenCntrVehAssi(data): Observable<any> {
    return this.http.post(config.api + 'trader/notifCollection', data);
  }
  changeLanguage(lang): Observable<any> {
    return this.http.post(config.api + 'notify/languageChange', lang);
  }
  getRequestedUsers(i): Observable<any> {
    return this.http.post(config.api + 'notify/userAddRequests', i)
  }
  carryArr(data) {
    this.whlArr = data;
  }

  setNearCities(list) {
    this.listOfCities = list;
  }

  getCityPrices() {
    return this.listOfCities
  }
  // getMetricks(){
  //   localStorage.getItem('country');
  // }

  notificationDataNew(data): Observable<any> {
    return this.http.post(config.api + 'notify/notificationsNew', data)
  }

  updateFromNotification(data): Observable<any> {
    return this.http.post(config.api + "notify/updateFromNotification", data)
  }

  markAllRead(): Observable<any> {
    return this.http.get(config.api + "notify/markAllReadBroiler")
  }



}

