import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { config } from '../helper/config';
import { map } from 'rxjs/operators';
import { LoginService } from '../login/login.service';
@Injectable({
  providedIn: 'root'
})
export class OtpService {

  // private loginsubject = new Subject<any>();
  // public loginSubject$ = this.loginsubject.asObservable();

  constructor(private http: HttpClient,
    private loginService: LoginService) { }

  otpvalidate(data): Observable<any> {
    return this.http.post(config.api + 'auth/validateOtp', data);
  }
  otpResend(data): Observable<any> {
    return this.http.post(config.api + 'auth/resendOtp', data);
  }
  changeUserStatus(data): Observable<any> {
    console.log(data, 1212)
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json")
    return this.http.post(config.api + 'auth/updateUserStatus', data, { headers }).pipe(
      map((res: ILoginObj) => {
        if (res.success) {
          console.log("hai", 125435);
          console.log(res, 12536);
          // this.setlocalstorage(res);
          this.loginService.setlocalstorage(res)
        }
        return res;
      })
    );;
  }
  setlocalstorage(data: ILoginObj) {
    if (!data && data.data.length == 0) {
      return false
    }
    this.loginService.setlocalstorage(data)
  }
}


export interface ILoginObj {
  success: boolean, data: any[], CodeNode: number,
  token: any
}
