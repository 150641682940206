
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { RicehuskReview2Service } from './ricehusk-review2.service';
import { Router, ActivatedRoute } from '@angular/router';
import { config } from '../helper/config';
import { Subject } from 'rxjs';
import { PageRefresherService } from '../page-refresher/page-refresher.service';
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { takeUntil } from 'rxjs/operators';
import { FileUploadService } from 'src/services/fileupload.service';
import { LoadingController, ToastController, ModalController } from '@ionic/angular';
import { ImagePicker, ImagePickerOptions } from '@ionic-native/image-picker/ngx';
import { DealersService } from '../../services/dealers.service';
import { Platform } from '@ionic/angular';
import { LoadingserviceService } from '../loadingservice.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-ricehusk-review2',
  templateUrl: './ricehusk-review2.page.html',
  styleUrls: ['./ricehusk-review2.page.scss'],
})
export class RicehuskReview2Page implements OnInit {
  isDisabled: boolean = true
  editable: boolean = false;
  review: FormGroup;
  unSubscribe$: Subject<any> = new Subject();
  enablerefresh: boolean = false;
  isEditing;
  reviewForm = new FormGroup({
    // headline: new FormControl('', Validators.compose([Validators.required])),
    review_description: new FormControl('', Validators.compose([Validators.required])),
  });
  ratevalue: any;
  idsd: string;
  dealer_id: any;
  readaddress: string;
  type: string;
  uploadFileArr: any[];
  role_id: string;
  baseUrl = config.api;
  vamsi: any;
  editstars: any;
  edit = false;
  rating: any;
  ratingstar: any;
  bindingvalues: any;
  reviewhead = '';
  reviewdisc = '';
  editrating = '';
  buttons: true;
  readaddress1: string;
  editData: any;
  editpic = true;
  imageResponse = [];
  options: { width: number; quality: number; outputType: number; };
  constructor(
    private toastController: ToastController, private Platform: Platform,
    private reviewService: RicehuskReview2Service,
    private _Activatedroute: ActivatedRoute,
    public router: Router,
    private uploadService: FileUploadService,
    private imagePicker: ImagePicker,
    private fileTransfer: FileTransfer,
    private dealer: DealersService,
    public loading: LoadingserviceService,
    public modalCtrl: ModalController,
    private translate: TranslateService,
    private refreshservice: PageRefresherService) {
    this.role_id = localStorage.getItem('role_id');
    this._Activatedroute.queryParams.subscribe(params => {
      console.log(params.currency, "Fetality");
      this.editable = true;
      this.editData = JSON.parse(params.currency || '{}');
      console.log(this.editData);
      this.isEditing = params;
      this.editrating = this.editData.rating;
      // this.reviewhead = this.editData.review_headline;
      this.reviewdisc = this.editData.review_description;
      if (this.editData._id != undefined) {
        this.editable = true;
      } else {
        this.editable = false;
      }
    })
    if (this.editrating) {
      this.editpic = false;
    }
  }
  ngOnInit() {
    this.readaddress = this.router.url.split(';')[0];
    if (this.readaddress.includes('/RiceHuskreview2')) {
      this.type = "ricehusk";
      this.role_id = "4";
    } else if (this.readaddress.includes("/Coalreview2")) {
      this.type = "coalhusk";
      this.role_id = "5";
    } else if (this.readaddress.includes("/scrapBagsreview2")) {
      this.type = "scrapbags";
      this.role_id = "7";
    } else if (this.readaddress.includes("/chicksupplierreview2")) {
      this.type = "chicksupplier";
      this.role_id = "14";
    } else if (this.readaddress.includes("/feedsupplierreview2")) {
      this.type = "feedsupplier";
      this.role_id = "19";
    } else {
      this.type = "litter";
      this.role_id = "6";
    }
    this._Activatedroute.params.subscribe(params => {
      console.log(params, "Raftar");
      this.dealer.commentEditData(params).subscribe((data: any) => {
        console.log(data);
      });
    });
    console.log(this.editable);
  }
  onRatingChange(data) {
    console.log(data);
    this.ratevalue = data;
  }

  async reviewSubmit() {
    this.loading.loadingPresent();
    console.log(this.editable, 9012)
    if (!this.editable) {
      console.log("this editing button");
      let dealer_data = this.dealer.commentEditGetData();
      console.log(dealer_data, "Commited");

      let val = { ...this.reviewForm.value };
      console.log(val, "The way the things are bad ahooo");
      if (this.uploadFileArr) {
        let fileIDs = await this.uploadImages(this.uploadFileArr);
        val.files = fileIDs;
      }
      if (this.ratevalue > 0) {

        val.rating = this.ratevalue;
        val.u_id = localStorage.getItem('u_id');
        val.dealer_u_id = dealer_data.d_u_id;
        val.dealerSttngId = dealer_data.stngsId;
        console.log(dealer_data, 149);
        val.role_id = this.role_id;
         
        if (this.reviewForm.valid &&  this.isDisabled) {
          this.isDisabled =false;
          this.reviewService.reviewPosting(val).subscribe((data) => {
            if (data.success) {
              this.showError(this.translate.instant('flashMessages.reviewSuccessfullySubmited'));
              this.reviewForm.reset();
              this.router.navigateByUrl(this.type);
              this.modalCtrl.dismiss();
              this.isDisabled =true;
            }else{
              this.isDisabled =false;
            }
          });
        }
      } else {
        this.loading.showError(this.translate.instant('flashMessages.provideRating'));
      }
      this.loading.loadingDismiss();
    } else {
      //comment editing condition
      if (this.reviewForm.valid && this.isDisabled) {
       

          this.isDisabled = false;
          let val = { ...this.reviewForm.value, id: this.editData._id, rating: this.ratevalue || this.editrating }
          this.reviewService.updatereview(val).subscribe((data) => {
            console.log(data)
          });
          this.router.navigateByUrl(this.type);
     
      }else{
        this.isDisabled =true;
      }
      this.loading.loadingDismiss();
    }
  }

  chooseFile() {
    let imagePickerOptions: ImagePickerOptions;

    imagePickerOptions = {
      maximumImagesCount: 5, width: 800, height: 800, quality: 100
    }
    this.imagePicker.getPictures(imagePickerOptions).then(async (filepaths) => {
      let uploadFileArr = [];

      for (var i = 0; i < filepaths.length; i++) {
        let options: FileUploadOptions = {};
        let currentFileName = filepaths[i].substring(filepaths[i].lastIndexOf('/') + 1, filepaths[i].lastIndexOf('?'))
        options.fileKey = 'file';
        options.fileName = filepaths[i].substr(filepaths[i].lastIndexOf('/') + 1);
        options.mimeType = `image/${currentFileName.substr(currentFileName.lastIndexOf('.') + 1)}`;
        options.headers = {};
        uploadFileArr = [...uploadFileArr, { filepath: filepaths[i], options }];
        this.uploadFileArr = uploadFileArr;
        this.imageResponse = this.uploadFileArr;
        console.log('file upload');
        console.log(this.uploadFileArr);
      }
    }, (err) => { });

  }

  uploadImages = (uploadFileArr: any[]): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      let result = await Promise.all(uploadFileArr.map(fileObject => this.transferFiles(fileObject)));
      result = result.filter((result => result != false))

      resolve(result)
    }).catch(console.log)
  }

  transferFiles = (fileObject) => {
    return new Promise((resolve, reject) => {
      let transfer = this.fileTransfer.create();
      transfer.upload(fileObject.filepath, `${this.baseUrl}imageUpload/insertlease2`, fileObject.options).then((result: any) => {
        if (result && result.responseCode === 200) {
          let file = JSON.parse(result.response);
          resolve(file.file)
        } else {
          resolve(false)
        }
      }).catch(error => {
        resolve(false)
      });
    })
  }


  ionViewWillLeave() {
    this.reviewForm.reset();
  }
  async showError(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 3000,
      position: 'bottom',
      buttons: [
        {
          text: this.translate.instant('buttons.done'),
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        }
      ],
      animated: true,
      cssClass: 'my-custom-class',
    });
    toast.present();
  }

}