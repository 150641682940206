
import { LiftingPipe } from 'src/app/pipes/lifting.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import {
    FormsModule,
    ReactiveFormsModule
} from '@angular/forms';
import { StarRatingComponent } from './../star-rating/star-rating.component';
// import { CentersPageModule } from ".././trader/centers/centers.module"
//import { HighchartComponent } from './../components/highchart/highchart';
import { AutoCompletePalcesDirective } from './../directives/auto-complete-palces.directive';
// import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { BackFlipDirective } from './../back-flip.directive';
import { DistancePipe } from './../pipes/distance.pipe';
import { SplitPipe } from './../pipes/split.pipe';

import { DurationPipe } from './../pipes/duration.pipe';
import { ProgressBarComponent } from '../progress-bar/progress-bar.component';

import { AddChickenCentersComponent } from "../add-chicken-centers/add-chicken-centers.component"
import { OrdinalSuffixPipe } from '../pipes/ordinal-suffix.pipe';
import { PageRefresherComponent } from '../page-refresher/page-refresher.component';
import { AddVehiclePage } from '../add-vehicle/add-vehicle.page';
import { GetfilePipe } from '../getfile.pipe';
import { ArraylimitPipe } from '../arraylimit.pipe';
import { OverallRatingPipe } from '../overall-rating.pipe';
import { LeasePipe } from './../pipes/lease.pipe';
import { aReplacePipe } from './../pipes/divide';
import { ThousandSeperatorPipe } from './../pipes/thousand-seperator.pipe';
import { IndianCurrencyPipe } from './../pipes/indian-currency.pipe';
import { SearchPipe } from '../pipes/search.pipe';
import { SortpipePipe } from '../sortpipe.pipe';

import { LivetrackingComponent } from "../livetracking/livetracking.component";

import { LayerGraphDirective } from '../../app/layer-graph.directive';
import { RicehuskReview2Page } from "../ricehusk-review2/ricehusk-review2.page";
import { TranslateModule } from '@ngx-translate/core';
import { MyfarmCreateEditComponent } from '../myfarm-create-edit/myfarm-create-edit.component';
import { UpdateInventoryComponent } from '../update-inventory/update-inventory.component';
import { RetailSupplierSelectcontactPage } from '../retail-supplier/retail-supplier-selectcontact/retail-supplier-selectcontact.page';
import { SelectFormulaPage } from '../layers-inventory/select-formula/select-formula.page';
import { ShowFeedOverViewPage } from "../layers-inventory/show-feed-over-view/show-feed-over-view.page";
import { AddFeedStockPage } from "../layer-farmer/add-feed-stock/add-feed-stock.page";
import { AlternativeFormulaPage } from "../layer-farmer/alternative-formula/alternative-formula.page";
import { CustomersSortComponent } from '../customers-sort/customers-sort.component';
import { FeedUnitsPipe } from "../pipes/feed-units.pipe";
import { MarqueePricesComponent } from '../marquee-prices/marquee-prices.component';
import { AlarmModalPage } from '../AutomaticSystem/alarm-modal/alarm-modal.page';
import { from } from 'rxjs';
import { DataTransmissionModalPage } from '../AutomaticSystem/data-transmission-modal/data-transmission-modal.page';
import { LayerFeedDetailsComponent } from '../layers-inventory/layer-feed-details/layer-feed-details.component';
import { PriceModalComponent } from "../roledashboard/price-modal/price-modal.component";
import { TimerPipePipe } from "../pipes/timer-pipe.pipe"
import { InventoryFeedEditComponent } from '../inventory-feed-edit/inventory-feed-edit.component';
import { OrderLiftingvanComponent } from '../trader/order-liftingvan/order-liftingvan.component';
import { VanContactAdddPage } from '../trader/van-contact-addd/van-contact-addd.page';

import { ContactNotAddPage } from '../emptycard/contact-not-add/contact-not-add.page';
import { RolesheaderComponent } from '../rolesheader/rolesheader.component';





// import { ChickenCenterHomePageModule } from '../chicken-center-home/chicken-center-home.module';
// import { TraderHomePageModule } from '../trader/trader-home/trader-home.module'



@NgModule({
    declarations: [
        AddVehiclePage,
        SelectFormulaPage,
        ShowFeedOverViewPage,
        AddFeedStockPage,
        ContactNotAddPage,
        AlternativeFormulaPage,
        InventoryFeedEditComponent,
        AlarmModalPage,
        CustomersSortComponent,
        RetailSupplierSelectcontactPage,
        StarRatingComponent,
        AutoCompletePalcesDirective,
        BackFlipDirective,
        DistancePipe,
        SplitPipe,
        DurationPipe,
        ProgressBarComponent,
        OrdinalSuffixPipe,
        PageRefresherComponent,
        GetfilePipe, LiftingPipe,
        ArraylimitPipe,
        OverallRatingPipe,
        LeasePipe,
        SearchPipe,
        SortpipePipe,
        FeedUnitsPipe,
        LayerFeedDetailsComponent,
        AddChickenCentersComponent,
        LivetrackingComponent,
        LayerGraphDirective,
        MyfarmCreateEditComponent,
        // CentersPageModule,
        RicehuskReview2Page,
        UpdateInventoryComponent,
        aReplacePipe,
        ThousandSeperatorPipe,
        IndianCurrencyPipe,
        MarqueePricesComponent,
        DataTransmissionModalPage,
        PriceModalComponent,
        OrderLiftingvanComponent,
        TimerPipePipe,
        VanContactAdddPage,
        RolesheaderComponent
    ],
    imports: [
        IonicModule,
        CommonModule,
        ReactiveFormsModule,
        FormsModule,

        TranslateModule,
        
        // ChickenCenterHomePageModule,
        // TraderHomePageModule,
    ],
    exports: [
        StarRatingComponent,
        AutoCompletePalcesDirective,
        BackFlipDirective,
        DistancePipe,
        SplitPipe,
        ContactNotAddPage,
        DurationPipe,
        ProgressBarComponent,
        OrdinalSuffixPipe,
        PageRefresherComponent,
        AddVehiclePage,
        RetailSupplierSelectcontactPage,
        LayerFeedDetailsComponent,
        SelectFormulaPage,
        ShowFeedOverViewPage,
        AddFeedStockPage,
        AlternativeFormulaPage,
        InventoryFeedEditComponent,
        AlarmModalPage,
        DataTransmissionModalPage,
        CustomersSortComponent,
        ReactiveFormsModule,
        GetfilePipe, LiftingPipe,
        OverallRatingPipe,
        ArraylimitPipe,
        LeasePipe,
        SearchPipe,
        SortpipePipe,
        FeedUnitsPipe,
        AddChickenCentersComponent,
        LayerGraphDirective,
        // CentersPageModule,
        RicehuskReview2Page,
        TranslateModule,
        aReplacePipe,
        ThousandSeperatorPipe,
        IndianCurrencyPipe,
        MarqueePricesComponent,
        PriceModalComponent,
        OrderLiftingvanComponent,
        TimerPipePipe,
        VanContactAdddPage,
        RolesheaderComponent
    ],
    entryComponents: [
        AddVehiclePage,
        AddChickenCentersComponent,
        RetailSupplierSelectcontactPage,
        SelectFormulaPage,
        ShowFeedOverViewPage,
        AddFeedStockPage,
        AlternativeFormulaPage,
        LayerFeedDetailsComponent,
        AlarmModalPage,
        DataTransmissionModalPage,
        CustomersSortComponent,
        LivetrackingComponent,
        // SelectBoxViewComponent,
        MyfarmCreateEditComponent,
        UpdateInventoryComponent,
        PriceModalComponent,
        OrderLiftingvanComponent,
        VanContactAdddPage,

    ],
    providers: [SortpipePipe]
})

export class CommonExportsModule {

}