import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'thousandSeperator'
})
export class ThousandSeperatorPipe implements PipeTransform {
    constructor(
        private translate: TranslateService
    ) { }
    transform(value: number): any {
        if (!isNaN(value)) {
            var currencySymbol = '₹';
            if (value == null) {
                return '';
            }
            var InrRSOut = value;
            InrRSOut = Math.round(InrRSOut);
            var RV = "";
            if (InrRSOut > 0 && InrRSOut < 1000) {
                RV = InrRSOut.toString();
            }
            else if (InrRSOut >= 1000 && InrRSOut < 10000) {
                var f1 = InrRSOut.toString().substring(0, 1);
                var f2 = InrRSOut.toString().substring(1, 4);
                RV = f1 + "," + f2;
            }
            else if (InrRSOut >= 10000 && InrRSOut < 100000) {
                var f1 = InrRSOut.toString().substring(0, 2);
                var f2 = InrRSOut.toString().substring(2, 5);
                RV = f1 + "," + f2;
            }
            else if (InrRSOut >= 100000 && InrRSOut < 1000000) {
                var f1 = InrRSOut.toString().substring(0, 1);
                var f2 = InrRSOut.toString().substring(1, 3);
                if (f2 == "00") {
                    RV = f1 + this.translate.instant('expences.lacs');
                }
                else {
                    RV = f1 + "." + f2 + this.translate.instant('expences.lacs');
                }
            }
            else if (InrRSOut >= 1000000 && InrRSOut < 10000000) {
                var f1 = InrRSOut.toString().substring(0, 2);
                var f2 = InrRSOut.toString().substring(2, 4);
                if (f2 == "00") {
                    RV = f1 + this.translate.instant('expences.lacs');
                }
                else {
                    RV = f1 + "." + f2 + this.translate.instant('expences.lacs');
                }
            }
            else if (InrRSOut >= 10000000 && InrRSOut < 100000000) {
                var f1 = InrRSOut.toString().substring(0, 1);
                var f2 = InrRSOut.toString().substring(1, 3);
                if (f2 == "00") {
                    RV = f1 + this.translate.instant('expences.cr');
                }
                else {
                    RV = f1 + "." + f2 + this.translate.instant('expences.cr');
                }
            }
            else if (InrRSOut >= 100000000 && InrRSOut < 1000000000) {
                var f1 = InrRSOut.toString().substring(0, 2);
                var f2 = InrRSOut.toString().substring(2, 4);
                if (f2 == "00") {
                    RV = f1 + this.translate.instant('expences.cr');
                }
                else {
                    RV = f1 + "." + f2 + this.translate.instant('expences.cr');
                }
            }
            else if (InrRSOut >= 1000000000 && InrRSOut < 10000000000) {
                var f1 = InrRSOut.toString().substring(0, 3);
                var f2 = InrRSOut.toString().substring(3, 5);
                if (f2 == "00") {
                    RV = f1 + this.translate.instant('expences.cr');
                }
                else {
                    RV = f1 + "." + f2 + this.translate.instant('expences.cr');
                }
            }
            else if (InrRSOut >= 10000000000) {
                var f1 = InrRSOut.toString().substring(0, 4);
                var f2 = InrRSOut.toString().substring(6, 8);
                if (f2 == "00") {
                    RV = f1 + this.translate.instant('expences.cr');
                }
                else {
                    RV = f1 + "." + f2 + this.translate.instant('expences.cr');
                }
            }
            else {
                //   negative
                if (InrRSOut > -1000 && InrRSOut < 0) {
                    RV = InrRSOut.toString();
                } else if (InrRSOut > -10000 && InrRSOut <= -1000) {
                    var f1 = InrRSOut.toString().substring(0, 2);
                    var f2 = InrRSOut.toString().substring(2, 5);
                    RV = f1 + "," + f2;
                } else if (InrRSOut > -100000 && InrRSOut <= -10000) {
                    var f1 = InrRSOut.toString().substring(0, 3);
                    var f2 = InrRSOut.toString().substring(3, 6);
                    RV = f1 + "," + f2;
                } else if (InrRSOut > -1000000 && InrRSOut <= -100000) {
                    var f1 = InrRSOut.toString().substring(0, 2);
                    var f2 = InrRSOut.toString().substring(2, 4);
                    if (f2 == "00") {
                        RV = f1 + this.translate.instant('expences.lacs');
                    }
                    else {
                        RV = f1 + "." + f2 + this.translate.instant('expences.lacs');
                    }
                } else if (InrRSOut > -10000000 && InrRSOut <= -1000000) {
                    var f1 = InrRSOut.toString().substring(0, 3);
                    var f2 = InrRSOut.toString().substring(3, 5);
                    if (f2 == "00") {
                        RV = f1 + this.translate.instant('expences.lacs');
                    }
                    else {
                        RV = f1 + "." + f2 + this.translate.instant('expences.lacs');
                    }
                } else if (InrRSOut > -100000000 && InrRSOut <= -10000000) {
                    var f1 = InrRSOut.toString().substring(0, 2);
                    var f2 = InrRSOut.toString().substring(2, 4);
                    if (f2 == "00") {
                        RV = f1 + this.translate.instant('expences.cr');
                    }
                    else {
                        RV = f1 + "." + f2 + this.translate.instant('expences.cr');
                    }
                } else if (InrRSOut > -1000000000 && InrRSOut <= -100000000) {
                    var f1 = InrRSOut.toString().substring(0, 3);
                    var f2 = InrRSOut.toString().substring(3, 5);
                    if (f2 == "00") {
                        RV = f1 + this.translate.instant('expences.cr');
                    }
                    else {
                        RV = f1 + "." + f2 + this.translate.instant('expences.cr');
                    }
                } else if (InrRSOut > -10000000000 && InrRSOut <= -1000000000) {
                    var f1 = InrRSOut.toString().substring(0, 4);
                    var f2 = InrRSOut.toString().substring(4, 6);
                    if (f2 == "00") {
                        RV = f1 + this.translate.instant('expences.cr');
                    }
                    else {
                        RV = f1 + "." + f2 + this.translate.instant('expences.cr');
                    }
                } else if (InrRSOut <= -10000000000) {
                    var f1 = InrRSOut.toString().substring(0, 5);
                    var f2 = InrRSOut.toString().substring(7, 9);
                    if (f2 == "00") {
                        RV = f1 + this.translate.instant('expences.cr');
                    }
                    else {
                        RV = f1 + "." + f2 + this.translate.instant('expences.cr');
                    }
                } else {
                    RV = InrRSOut.toString();
                }
            }
            return currencySymbol + RV;
        }


    }

}


  // if(value != null){
      //   if (value <= 999) {
      //     return value;
      //   }else if (value >= 1000 && value <= 999999) {
      //     console.log((value / 1000) % 1)
      //     console.log(value / 1000,555)
      //     if((value / 1000) % 1 != 0){
      //       return (value / 1000).toFixed(2) + 'K'
      //     }else{
      //      return (value / 1000) + 'K';
      //     }
      //   }else if (value >= 1000000 && value <= 999999999) {
      //     if((value / 1000) % 1 != 0){
      //       return (value / 1000).toFixed(2) + 'M'
      //     }else{
      //      return (value / 1000) + 'M';
      //     }
      //   }else if (value >= 1000000000 && value <= 999999999999) {
      //     if((value / 1000) % 1 != 0){
      //       return (value / 1000).toFixed(2) + 'B'
      //     }else{
      //      return (value / 1000) + 'B';
      //     }
      //   }else{
      //     return value;
      //   }
      // }else{
      //   return null;
      // }
