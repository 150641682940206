import { Pipe, PipeTransform } from '@angular/core';
// import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'indianCurrency'
})
export class IndianCurrencyPipe implements PipeTransform {
  currencySymbol = localStorage.getItem("currencySymbol")
  constructor(
    // private translate: TranslateService
    ) {

  }
  transform(value: number): any {
    if (!isNaN(value)) {
      if (value == null) {
        return '--';
      }
      var typeofValue = Number.isInteger(value);
      if (typeofValue) { // integer value pipe
        value = Math.round(value);
        var result = value.toString().split('.');
        var lastThree = result[0].substring(result[0].length - 3);
        var otherNumbers = result[0].substring(0, result[0].length - 3);
        if (otherNumbers != '' && otherNumbers != '-')
          lastThree = ',' + lastThree;
        var output = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
        if (result.length > 1) {
          output += "." + result[1];
        }
        return output;
      } else {//float value pipe
        let getFloat = value - Math.floor(value);
        let floatedValues = getFloat.toFixed(2).slice(1, 4)
        let integerValue = Math.trunc(value);
        value = Math.round(integerValue);
        var result = value.toString().split('.');
        var lastThree = result[0].substring(result[0].length - 3);
        var otherNumbers = result[0].substring(0, result[0].length - 3);
        if (otherNumbers != '' && otherNumbers != '-')
          lastThree = ',' + lastThree;
        var output = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
        if (result.length > 1) {
          output += "." + result[1];
        }
        return output + floatedValues;
      }
    }
  }
}












        // // var output = Number(value).toLocaleString('en-IN');    //  <-- This method is not working fine in all browsers!           
        // var result = value.toString().split('.');
        // var lastThree = result[0].substring(result[0].length - 3);
        // var otherNumbers = result[0].substring(0, result[0].length - 3);
        // if (otherNumbers != '')
        //   lastThree = ',' + lastThree;
        // var output = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;

        // if (result.length > 1) {
        //   output += "." + result[1];
        // }
        // return output;







      // if (typeofValue) {      // only integer possitive values 
      //   var InrRSOut = value;
      //   InrRSOut = Math.round(InrRSOut);
      //   var RV = "";
      //   if (InrRSOut > 0 && InrRSOut < 1000) { 
      //     RV = InrRSOut.toString();
      //   }
      //   else if (InrRSOut >= 1000 && InrRSOut < 10000) { 
      //     var f1 = InrRSOut.toString().substring(0, 1);
      //     var f2 = InrRSOut.toString().substring(1, 4);
      //     RV = f1 + "," + f2;
      //   }
      //   else if (InrRSOut >= 10000 && InrRSOut < 100000) { 
      //     var f1 = InrRSOut.toString().substring(0, 2);
      //     var f2 = InrRSOut.toString().substring(2, 5);
      //     RV = f1 + "," + f2;
      //   }
      //   else if (InrRSOut >= 100000 && InrRSOut < 1000000) { 
      //     var f1 = InrRSOut.toString().substring(0, 1);
      //     var f2 = InrRSOut.toString().substring(1, 3);
      //     var f3 = InrRSOut.toString().substring(3, 6);
      //     RV = f1 + "," + f2 + "," + f3;
      //   }
      //   else if (InrRSOut >= 1000000 && InrRSOut < 10000000) { 
      //     var f1 = InrRSOut.toString().substring(0, 2);
      //     var f2 = InrRSOut.toString().substring(2, 4);
      //     var f3 = InrRSOut.toString().substring(4, 7);
      //     RV = f1 + "," + f2 + "," + f3;
      //   }
      //   else if (InrRSOut >= 10000000 && InrRSOut < 100000000) { 
      //     var f1 = InrRSOut.toString().substring(0, 1);
      //     var f2 = InrRSOut.toString().substring(1, 3);
      //     var f3 = InrRSOut.toString().substring(3, 5);
      //     var f4 = InrRSOut.toString().substring(5, 8);
      //     RV = f1 + "," + f2 + "," + f3 + "," + f4;
      //   }
      //   else if (InrRSOut >= 100000000 && InrRSOut < 1000000000) { 
      //     var f1 = InrRSOut.toString().substring(0, 2);
      //     var f2 = InrRSOut.toString().substring(2, 4);
      //     var f3 = InrRSOut.toString().substring(4, 6);
      //     var f4 = InrRSOut.toString().substring(6, 9);
      //     RV = f1 + "," + f2 + "," + f3 + "," + f4;
      //   }
      //   else if (InrRSOut >= 1000000000 && InrRSOut < 10000000000) { 
      //     var f1 = InrRSOut.toString().substring(0, 3);
      //     var f2 = InrRSOut.toString().substring(3, 5);
      //     var f3 = InrRSOut.toString().substring(5, 7);
      //     var f4 = InrRSOut.toString().substring(7, 10);
      //     RV = f1 + "," + f2 + "," + f3 + "," + f4;
      //   }
      //   else if (InrRSOut >= 10000000000) { 
      //     var f1 = InrRSOut.toString().substring(0, 4);
      //     var f2 = InrRSOut.toString().substring(6, 8);
      //     if (f2 == "00") {
      //       RV = f1 + this.translate.instant('expences.cr');
      //     }
      //     else {
      //       RV = f1 + "." + f2 + this.translate.instant('expences.cr');
      //     }
      //   }
      //   else {   // only integer negative values 
      //     if (InrRSOut > -1000 && InrRSOut < 0) { 
      //       RV = InrRSOut.toString();
      //     } else if (InrRSOut > -10000 && InrRSOut <= -1000) { 
      //       var f1 = InrRSOut.toString().substring(0, 2);
      //       var f2 = InrRSOut.toString().substring(2, 5);
      //       RV = f1 + "," + f2;
      //     } else if (InrRSOut > -100000 && InrRSOut <= -10000) { 
      //       var f1 = InrRSOut.toString().substring(0, 3);
      //       var f2 = InrRSOut.toString().substring(3, 6);
      //       RV = f1 + "," + f2;
      //     } else if (InrRSOut > -1000000 && InrRSOut <= -100000) { 
      //       var f1 = InrRSOut.toString().substring(0, 2);
      //       var f2 = InrRSOut.toString().substring(2, 4);
      //       var f3 = InrRSOut.toString().substring(4, 7);
      //       RV = f1 + "," + f2 + "," + f3;
      //     } else if (InrRSOut > -10000000 && InrRSOut <= -1000000) { 
      //       var f1 = InrRSOut.toString().substring(0, 3);
      //       var f2 = InrRSOut.toString().substring(3, 5);
      //       var f3 = InrRSOut.toString().substring(5, 8);
      //       RV = f1 + "," + f2 + "," + f3;
      //     } else if (InrRSOut > -100000000 && InrRSOut <= -10000000) { 
      //                   var f1 = InrRSOut.toString().substring(0, 2);
      //       var f2 = InrRSOut.toString().substring(2, 4);
      //       var f3 = InrRSOut.toString().substring(4, 6);
      //       var f4 = InrRSOut.toString().substring(6, 9);
      //       RV = f1 + "," + f2 + "," + f3 + "," + f4;
      //     } else if (InrRSOut > -1000000000 && InrRSOut <= -100000000) {
      //       var f1 = InrRSOut.toString().substring(0, 3);
      //       var f2 = InrRSOut.toString().substring(3, 5);
      //       var f3 = InrRSOut.toString().substring(5, 7);
      //       var f4 = InrRSOut.toString().substring(7, 10);
      //       RV = f1 + "," + f2 + "," + f3 + "," + f4;
      //     } else if (InrRSOut > -10000000000 && InrRSOut <= -1000000000) {
      //                   var f1 = InrRSOut.toString().substring(0, 4);
      //       var f2 = InrRSOut.toString().substring(4, 6);
      //       var f3 = InrRSOut.toString().substring(6, 8);
      //       var f4 = InrRSOut.toString().substring(8, 11);
      //       RV = f1 + "," + f2 + "," + f3 + "," + f4;
      //     } else if (InrRSOut <= -10000000000) { 
      //                   var f1 = InrRSOut.toString().substring(0, 5);
      //       var f2 = InrRSOut.toString().substring(7, 9);
      //       if (f2 == "00") {
      //         RV = f1 + this.translate.instant('expences.cr');
      //       }
      //       else {
      //         RV = f1 + "." + f2 + this.translate.instant('expences.cr');
      //       }
      //     } else {
      //       RV = InrRSOut.toString();
      //     }
      //   }
      //   return RV;
      // } else {
      //   let val = Number(value).toFixed(2);
      //   // var output = Number(value).toLocaleString('en-IN');    //  <-- This method is not working fine in all browsers!           
      //   var result = val.toString().split('.');

      //   var lastThree = result[0].substring(result[0].length - 3);
      //   var otherNumbers = result[0].substring(0, result[0].length - 3);
      //   if (otherNumbers != '')
      //     lastThree = ',' + lastThree;
      //   var output = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;

      //   if (result.length > 1) {
      //     output += "." + result[1];
      //   }
      //   return output;
      // }



