import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let val = value;

    
    
    
    
    
    
    

    return parseInt((val / 24).toFixed());
  }
}
