import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from '../app/helper/config';
import { BehaviorSubject, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: "root"
})

export class SupervisorService {
  saveRoute = '';
  BroilerFarmer;
  public orderSales = new BehaviorSubject<any>([]);
  public pendingOrderSales$ = this.orderSales.asObservable();
  // public traderOrdersCount = new BehaviorSubject<any>([]);
  // public traderOrdersCount$ = this.traderOrdersCount.asObservable();
  order: any;
  orderVariable: any;
  ordersObj = {};
  purchaseOrdersObj = {};
  CCName: string;
  traderDetails = {}
  constructor(
    protected http: HttpClient,
    private translate: TranslateService
  ) {
  }

  orderProcessData(data) {
    this.orderVariable = data
  }

  getOrderProcessData() {
    return this.orderVariable
  }

  chickenshopName(data) {
    this.CCName = data.name;
  }

  getCCName() {
    return this.CCName;
  }

  setTraderData(data) {
    this.traderDetails = data;
  }

  getTraderData() {
    return this.traderDetails;
  }

  getSupervisorList(): Observable<any> {
    return this.http.get(config.api + "supervisor/getAddedFarmers");
  }

  SupervisorAddFarmsList(uid): Observable<any> {
    return this.http.post(config.api + "supervisor/requestedFarms", uid)
  }

  supervisorAddedInventoy(uid): Observable<any> {
    return this.http.post(config.api + "supervisor/farmerInventoryList", uid)
  }

  getUserData(): Observable<any> {
    return this.http.get(config.api + "supervisor/getUsersData")
  }

  getOrderDetails(data): Observable<any> {
    return this.http.post(config.api + "traderSupervisor/getOrderDetails", data)
  }

  saleSummaryInfo(data): Observable<any> {
    return this.http.post(config.api + "traderSupervisor/saleSummaryInfo", data)
  }

  saveRouter(data) {
    this.saveRoute = data;
  }

  locallySaved() {
    return this.saveRoute;
  }

  svFarmer(data) {
    this.BroilerFarmer = data;
  }

  getFarmerDetails() {
    return this.BroilerFarmer;
  }

  getExpensesList(): Observable<any> {
    return this.http.get(config.api + "supervisor/expensesList");
  }

  routeChange(data) {
    this.order = data.segment;
  }

  changedRoute() {
    return this.order;
  }

  inputs = [];

  setInputData(data, selectedTab) {

    if (selectedTab == 'incomingOrders' && data.orderStatus != "waiting") {
      this.inputs = [
        {
          name: 'Box Assigned',
          type: 'radio',
          label: this.translate.instant('chickenshop.boxassigned'),
          value: 'boxassigned',
          checked: data.orderStatus == "boxassigned",
          disabled: data.orderStatus == "delivery" || data.orderStatus == "paid" || data.orderStatus == "roughestimation" || data.orderStatus == "vanassigned",
        },
        {
          name: 'Delivery',
          type: 'radio',
          label: this.translate.instant('chickenshop.delivery'),
          value: 'delivery',
          checked: data.orderStatus == "delivery",
          disabled: data.orderStatus == "paid" || data.orderStatus == "roughestimation" || data.orderStatus == "vanassigned" || data.orderStatus == "farmassigned"
        },
        {
          name: 'Paid',
          type: 'radio',
          label: this.translate.instant('chickenshop.paid'),
          value: 'paid',
          checked: data.orderStatus == "paid",
          disabled: data.orderStatus == "roughestimation" || data.orderStatus == "vanassigned" || data.orderStatus == "farmassigned" || data.vanStatus == "boxassigned"
        }]
    } else if (selectedTab == 'incomingOrders' && data.orderStatus == "waiting") {
      this.inputs = [
        {
          name: 'Accept',
          type: 'radio',
          label: this.translate.instant('buttons.accept'),
          value: 'accept',
          checked: data.orderStatus == "accept"
        },
        {
          name: 'Reject',
          type: 'radio',
          label: this.translate.instant('buttons.decline'),
          value: 'reject',
          checked: data.orderStatus == "reject"
        },
      ]
    } else if (selectedTab == 'outgoingOrders') {
      this.inputs = [
        //   {
        //   name: 'Box Assigned',
        //   type: 'radio',
        //   label: this.translate.instant('chickenshop.boxassigned'),
        //   value: 'boxassigned',
        //   checked: data.orderStatus == "boxassigned",
        //   disabled: data.orderStatus == "delivery" || data.orderStatus == "paid" || data.orderStatus == "roughestimation" || data.orderStatus == "vanassigned",
        // },
        {
          name: 'Delivery',
          type: 'radio',
          label: this.translate.instant('chickenshop.delivery'),
          value: 'delivery',
          checked: data.orderStatus == "delivery",
          // disabled: data.orderStatus == "paid" || data.orderStatus == "vanassigned" || data.orderStatus == "farmassigned"
        },
        {
          name: 'Paid',
          type: 'radio',
          label: this.translate.instant('chickenshop.paid'),
          value: 'paid',
          checked: data.orderStatus == "paid",
          // disabled: data.orderStatus == "roughestimation" || data.orderStatus == "vanassigned" || data.orderStatus == "farmassigned" || data.vanStatus == "boxassigned"
        }]
    } else if (selectedTab == 'unassignedOrders') {
      this.inputs = [
        {
          name: 'Accept',
          type: 'radio',
          label: this.translate.instant('buttons.accept'),
          value: 'accept',
          checked: data.orderStatus == "accept"
        },
        {
          name: 'Reject',
          type: 'radio',
          label: this.translate.instant('buttons.decline'),
          value: 'reject',
          checked: data.orderStatus == "reject"
        },
      ]
    } else if (selectedTab == 'allOrders') {
      if ((data['orderCategory'] == 'incomingorder' || data['orderCategory'] == 'outgoingorder') && (data['orderStatus'] != 'waiting' && data['orderStatus'] != 'delivery' && data['orderStatus'] != 'paid')) {
        this.inputs = [
          {
            name: 'Box Assigned',
            type: 'radio',
            label: this.translate.instant('chickenshop.boxassigned'),
            value: 'boxassigned',
            checked: data.orderStatus == "boxassigned",
            disabled: data.orderStatus == "delivery" || data.orderStatus == "paid" || data.orderStatus == "roughestimation" || data.orderStatus == "vanassigned",
          },
          {
            name: 'Delivery',
            type: 'radio',
            label: this.translate.instant('chickenshop.delivery'),
            value: 'delivery',
            checked: data.orderStatus == "delivery",
            disabled: data.orderStatus != "boxassigned"
          },
          {
            name: 'Paid',
            type: 'radio',
            label: this.translate.instant('chickenshop.paid'),
            value: 'paid',
            checked: data.orderStatus == "paid",
            disabled: data.orderStatus != "boxassigned"
          }]
      } else if ((data['orderCategory'] == 'incomingorder') && (data['orderStatus'] == 'delivery' || data['orderStatus'] == 'paid')) {
        this.inputs = [
          {
            name: 'Delivery',
            type: 'radio',
            label: this.translate.instant('chickenshop.delivery'),
            value: 'delivery',
            checked: data.orderStatus == "delivery",
          },
          {
            name: 'Paid',
            type: 'radio',
            label: this.translate.instant('chickenshop.paid'),
            value: 'paid',
            checked: data.orderStatus == "paid",
          },
          {
            name: 'revoke',
            type: 'radio',
            label: "Revoke",
            value: 'revoke',
          }
        ]
      } else if (data['orderCategory'] == 'outgoingorder' && (data['orderStatus'] == 'delivery' || data['orderStatus'] == 'paid')) {
        this.inputs = [
          {
            name: 'Delivery',
            type: 'radio',
            label: this.translate.instant('chickenshop.delivery'),
            value: 'delivery',
            checked: data.orderStatus == "delivery",
          },
          {
            name: 'Paid',
            type: 'radio',
            label: this.translate.instant('chickenshop.paid'),
            value: 'paid',
            checked: data.orderStatus == "paid",
          },
        ]
      }

      else {
        this.inputs = [
          {
            name: 'Accept',
            type: 'radio',
            label: this.translate.instant('buttons.accept'),
            value: 'accept',
            checked: data.orderStatus == "accept"
          },
          {
            name: 'Reject',
            type: 'radio',
            label: this.translate.instant('buttons.decline'),
            value: 'reject',
            checked: data.orderStatus == "reject"
          },
        ]
      }
    }
  }

  getInputValues() {
    return this.inputs;
  }
  orderProcessSet(data) {
    this.ordersObj = { ...this.ordersObj, ...data };
  }

  orderProcessClear() {
    this.ordersObj = {}
  }

  getOrderProcess() {
    return this.ordersObj;
  }

  traderSummary(data): Observable<any> {
    return this.http.post(config.api + "supervisor/traderSummary", data)
  }

  purcahseOrderProcess(data) {
    if (!Object.keys(data).length) {
      this.purchaseOrdersObj = {};
    } else {
      this.purchaseOrdersObj = { ...this.purchaseOrdersObj, ...data };
    }
  }


  getPurchaseOrderObj() {
    return this.purchaseOrdersObj;
  }

  orderType;
  setOrderType(data) {
    this.orderType = data;

  }
  getOrderType() {
    return this.orderType;
  }

  purchaseCount;
  purchaseCountSet(data) {
    this.purchaseCount = data;
  }

  getPurchaseCount() {
    return this.purchaseCount;
  }

  // api related to broiler price and expenses
  outgoingPrice(data): Observable<any> {
    return this.http.post(config.api + "supervisor/outgoingPrice", data)
  }

  deleteIncomingOrder(data): Observable<any> {
    return this.http.post(config.api + 'supervisor/deleteIncomingOrder', data)
  }

  deleteOutgoingOrder(data): Observable<any> {
    return this.http.post(config.api + 'supervisor/deleteOutgoingOrder', data)
  }

  revokeOrder(data): Observable<any> {
    return this.http.post(config.api + 'supervisor/revokeOrder', data)
  }

  incomingPrice(data): Observable<any> {
    return this.http.post(config.api + "supervisor/incomingPrice", data)
  }
  getPriceData(data): Observable<any> {
    return this.http.post(config.api + "supervisor/getPriceData", data)
  }

  incomingPriceAlert(data): Observable<any> {
    return this.http.post(config.api + "supervisor/incomingPriceAlert", data)
  }

  updateIncomingFarmer(data): Observable<any> {
    return this.http.post(config.api + "supervisor/updateIncomingFarmer", data)
  }
  updateVanIncoming(data): Observable<any> {
    return this.http.post(config.api + "supervisor/updateVanIncoming", data)
  }
}

