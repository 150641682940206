import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-select-van',
  templateUrl: './select-van.component.html',
  styleUrls: ['./select-van.component.scss'],
})
export class SelectVanComponent implements OnInit {
  vans: any;
  sendVanData: any;

  constructor(private modelCtrl: ModalController,public router:Router,
    public translate:TranslateService,
    private nav: NavParams) { }

  ngOnInit() {
    if (this.nav.get('data')) {
      this.vans =this.nav.get('data');
      console.log(this.vans, 29);
    }
  }
  selectedVan(value){
    this.sendVanData= value
  }
  async update(val) {
    if (val) {
      this.modelCtrl.dismiss({
        status: true,
        selectedData: this.sendVanData,
      });
    } else {
      this.modelCtrl.dismiss({ 
        status: false
      });
    }
  }
  addNewVan(){
    this.modelCtrl.dismiss({ status: false});
    this.router.navigate(['/wholeseller/whole-seller-addvehicle']);    
  }
}
