import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonService } from '../../services/common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-send-mes',
  templateUrl: './send-mes.component.html',
  styleUrls: ['./send-mes.component.scss'],
})
export class SendMesComponent implements OnInit {
  
  constructor(public popctrl: PopoverController,        private commonService: CommonService,    public router: Router,


    ) { }
  ngOnInit() {
  }
  sendmessage: FormGroup = new FormGroup({
    Phone: new FormControl('', [Validators.required]),
    Message: new FormControl('', [Validators.required]),
  });
  senddata(){
    console.log("data is send sucessfully in the send popup")
    this.popctrl.dismiss();
  }
  // pussetUp() {
  //   this.oneSignal.startInit("54a87d99-e657-412c-9c5c-27e0a97da81b", '772692365251');

  //   this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.Notification);

  //   this.oneSignal.handleNotificationOpened().subscribe(
  //     data => {

  //       this.router.navigateByUrl("/notifications");

  //     }
  //   );
  //   this.oneSignal.handleNotificationReceived().subscribe(
  //     data => {
  //       let msg = this.sendmessage.controls.Message.value;
  //       let title = 'Price';
  //       let additionalData = data.payload.additionalData;

  //     }
  //   )
  //   this.oneSignal.endInit();
  //   this.oneSignal.getIds().then(
  //     (data) => {
  //       this.commonService.savePushToken(Object.assign(data, { u_id: localStorage.getItem('u_id') })).subscribe(
  //         (result) => {

  //         }
  //       )
  //     }
  //   )
  // }
}
