import { LayerfarmerSupervisorService } from 'src/app/services/layerfarmer-supervisor.service';
import { SupervisorService } from './../../../services/supervisor.service';
import { AlertController, NavParams, ModalController, ToastController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LayersService } from "../../services/layers.service";
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-alternative-formula',
  templateUrl: './alternative-formula.page.html',
  styleUrls: ['./alternative-formula.page.scss'],
})
export class AlternativeFormulaPage implements OnInit {
  public myForm: FormGroup;
  public editForm: FormGroup;
  feedForm: any;
  display: any = [];
  feed: string;
  zeroError = false;
  show: boolean = false;
  sumErr = false;
  default;
  select: any;
  summationFeed: any = 0;
  viewFrmla: any;
  editSum: any;
  editableFormularatio: any;
  editaboleFormulaName: any;
  formulaUsed: boolean = false;

  validation_messages = {
    formulaName: [{ type: "required", message:this.translate.instant('Lfarmer.Formula name req')}],
    feedType: [{ type: "required", message: this.translate.instant('Lfarmer.choosefeed') }],
    percent: [{ type: "required", message: this.translate.instant('Lfarmer.%req') }, { type: "min", message: this.translate.instant('Lfarmer.minivalue')}],
  };
  constructor(
    private formBuilder: FormBuilder,
    private alert: AlertController,
    private LayersService: LayersService,
    public translate: TranslateService,
    private route: Router,
    public navParams: NavParams,
    private modalController: ModalController,
    private supervisor: SupervisorService,
    private layerSupervisor: LayerfarmerSupervisorService
  ) {
  }

  ngOnInit() {
    this.myForm = this.formBuilder.group({
      formulaName: ['', [Validators.required]],
      feed1: this.formBuilder.array([]),
    });
  }

  ionViewWillEnter() {
    let getFeedNames = this.LayersService.getstoreFeedNames();
    console.log(getFeedNames, 60);
    if (getFeedNames.length == 0) {
      this.getFeedNames();
    } else {
      this.select = getFeedNames;
    }
    this.default = localStorage.getItem('lngCode');
    if (this.navParams.get('openFromPage') == "inventoryPage") {
      this.show = false;
      this.initialAddControll("");
    } else {
      if (this.navParams.get('createShow')) {
        this.show = false;
        this.initialAddControll("");
      } else {
        this.viewFrmla = {
          frmlaRatios: this.navParams.get('frmlaRatios'),
          show: true,
          frmlId: this.navParams.get('frmlId'),
          frmlaName: this.navParams.get('frmlaName'),
          // frmlaRatios: this.navParams.get('frmlaRatios')
        }
        let formulaId = this.navParams.get('frmlId');
        this.editableFormularatio = this.navParams.get('frmlaRatios');
        this.editaboleFormulaName = this.navParams.get('frmlaName');
        this.editForm = this.formBuilder.group({
          editFeed: this.formBuilder.array([]),
        });
        this.LayersService.formulaUsedOrNot({ formulaId: formulaId }).subscribe((data: any) => {
          if (data.success) {
            this.formulaUsed = data.data;
            this.show = true;
            this.cntFeedPerc(this.viewFrmla);
          }
        });
      }
    }

    // this.viewFrmla = this.LayersService.frmlaDetls;
    // console.log(this.viewFrmla);
    // this.default = localStorage.getItem('lngCode');
    // this.show = this.viewFrmla.show;
    // // this.select = ['Maize', 'Wheat', 'Ragi', 'Soybean', 'Rice', 'Bajra', 'Other'];
    // let getFeedNames = this.LayersService.getstoreFeedNames();
    // if (getFeedNames.length == 0) {
    //   this.getFeedNames();
    // } else {
    //   this.select = getFeedNames;
    // }
    // // this.fetchFeeds();
    // if (!this.show) {
    //   // this.feedForm = [""];
    //   for (let data of this.feedForm) {
    //     this.initialAddControll(data)
    //   }
    // } else {
    //   this.cntFeedPerc(this.viewFrmla);
    //   this.editForm = this.formBuilder.group({
    //     editFeed: this.formBuilder.array([]),
    //   });
    // }

  };


  initialCall(): FormArray {
    return this.myForm.get("feed1") as FormArray
  }

  initialControl(data): FormGroup {
    return this.formBuilder.group({
      feedType: [data, Validators.required],
      feedId: [''],
      feedPercent: [0, Validators.compose([Validators.min(0.1), Validators.required])],
    });
  }

  initialAddControll(data) {
    this.initialCall().push(this.initialControl(data))
  }

  addFields(): FormArray {
    if (!this.show) {
      return this.myForm.get("feed1") as FormArray;
    } else {
      return this.editForm.get("editFeed") as FormArray;
    }
  }

  newControls(): FormGroup {
    if (!this.show) {
      return this.formBuilder.group({
        feedType: ['', Validators.required],
        feedId: [''],
        feedPercent: [0, Validators.compose([Validators.min(0.1), Validators.required])],
      })
    } else {
      return this.formBuilder.group({
        feedType: ['', Validators.required],
        feedId: [''],
        feedPercent: [0, Validators.compose([Validators.min(0.1), Validators.required])],
      })
    }
  }

  addControl() {
    if (!this.show) {
      this.addFields().push(this.newControls());
      this.onAddCallOnDeleteCall();
    } else {
      this.addFields().push(this.newControls());
    }
  };

  removeControl(i: number) {
    console.log(this.myForm.controls.feed1.value);
    this.addFields().removeAt(i);
    this.onAddCallOnDeleteCall();
  };

  onSubmit() {
    if (!this.show) {
      if (localStorage.getItem('role_id') == "3") {
        let addedFarmer = this.supervisor.locallySaved();
        Object.assign(this.myForm.value, { u_id: addedFarmer['u_id'] });
      } else if (localStorage.getItem('role_id') == "11") {
        let addedFarmer = this.layerSupervisor.returnAddedFarmer();
        Object.assign(this.myForm.value, { u_id: addedFarmer['u_id'] })
      }
      this.LayersService.addFeedFormulas(this.myForm.value).subscribe((data: any) => {
        if (data.success) {
          this.myForm.reset();
          this.modalController.dismiss({
            status: true,
          });
          // this.route.navigate(['/layerfarmer/feedFrmla'])
        }
      });
    } else {
      let object = {
        _id: this.viewFrmla.frmlId,
        ltstIngre: this.editForm.value.editFeed
      }
      if (localStorage.getItem('role_id') == "3") {
        let addedFarmer = this.supervisor.locallySaved();
        Object.assign(object, { u_id: addedFarmer['u_id'] });
      } else if (localStorage.getItem('role_id') == "11") {
        let addedFarmer = this.layerSupervisor.returnAddedFarmer();
        Object.assign(object, { u_id: addedFarmer['u_id'] })
      }
      this.LayersService.addNwIngre(object).subscribe((data: any) => {
        if (data.success) {
          this.editForm.reset();
          this.modalController.dismiss({
            status: true,
          });
          // this.route.navigate(['/layerfarmer/feedFrmla'])
        }
      });
    }
  }

  async selectFeed(i) {
    this.display.splice(0, this.display.length);
    for (let obj of this.select) {
      let data = {
        label: obj.feedName,
        value: obj,
        type: 'radio',
        checked: this.feed == obj.feedName,
      };
      if (obj.feedType == 'Standard') {
        data.label = `${obj.feedName}(Standard)`
      }
      this.display.push(data);
    }

    // this.display.push(
    //   {
    //     label: "OTHER",
    //     value: "Other",
    //     type: 'radio',
    //     checked: this.feed == "Other",
    //   }
    // )

    const alert = await this.alert.create({
      inputs: this.display,
      message: this.translate.instant('dealerView.feedType'),
      cssClass: 'customAllert',
      buttons: [
        {
          text: this.translate.instant('buttons.cancel'),
          role: 'cancel',
          handler: (blah) => {
            console.log('you havent choosed feed');
          }
        },
        {
          text: this.translate.instant('buttons.ok'),
          handler: (result) => {
            console.log(this.show, 258);
            if (!this.show) {
              console.log(result?.feedName, 258);
              if (result?.feedName != 'OTHER') {
                const faControl = (<FormArray>this.myForm.controls['feed1']).at(i);
                faControl['controls'].feedType.setValue(result?.feedName);
                faControl['controls'].feedId.setValue(result?._id);
                this.feed = result?.feedName;
                for (let feed of this.myForm.value.feed1) {
                  console.log(feed);
                  console.log((<FormArray>this.myForm.controls['feed1']).at(i));
                }
              } else {
                this.askNewFeedName(i);
              }
            } else {
              if (result?.feedName != 'OTHER') {
                const faControl = (<FormArray>this.editForm.controls['editFeed']).at(i);
                faControl['controls'].feedType.setValue(result?.feedName);
                faControl['controls'].feedId.setValue(result?._id);
                this.feed = result?.feedName;
              } else {
                this.askNewFeedName(i);
                // this.feed = result?.feedName;
              }
            }
          }
        }
      ],
    });
    await alert.present();
  }

  async askNewFeedName(i) {
    const alert = await this.alert.create({
      inputs: [{
        name: "newFeed",
        type: 'text',
        placeholder: 'e.g : Bran'
      }],
      message: 'Name of feed',
      cssClass: 'feedAlert',
      buttons: [
        {
          text: this.translate.instant('buttons.cancel'),
          // text: 'Cancel',
          role: 'cancel',
          handler: (blah) => {
            console.log('you havent choosed feed');
          }
        },
        {
          text: this.translate.instant('buttons.ok'),
          handler: (result) => {
            result = result.newFeed[0].toUpperCase() + result.newFeed.slice(1).toLowerCase();
            this.feed = result;
            if (!this.show) {
              if (this.select.includes(result)) {
                const faControl = (<FormArray>this.myForm.controls['feed1']).at(i);
                this.select.forEach(element => {
                  if (element.feedName == result) {
                    this.feed = element.feedName;
                    const faControl = (<FormArray>this.myForm.controls['feed1']).at(i);
                    faControl['controls'].feedType.setValue(element?.feedName);
                    faControl['controls'].feedId.setValue(element?._id);
                  }
                });

                // faControl['controls'].feedType.setValue(result?.feedName);
                // faControl['controls'].feedId.setValue(result?._id);
              } else {
                let obj = {
                  feedName: result
                }
                if (localStorage.getItem('role_id') == "3") {
                  let addedFarmer = this.supervisor.locallySaved();
                  Object.assign(obj, { u_id: addedFarmer['u_id'] });
                } else if (localStorage.getItem('role_id') == "11") {
                  let addedFarmer = this.layerSupervisor.returnAddedFarmer();
                  Object.assign(obj, { u_id: addedFarmer['u_id'] })
                }
                this.LayersService.addNewFeed(obj).subscribe((data: any) => {
                  if (data.success) {
                    this.select = data.data;
                    this.select.forEach(element => {
                      if (element.feedName == result) {
                        console.log(element, 314);
                        this.feed = element.feedName;
                        const faControl = (<FormArray>this.myForm.controls['feed1']).at(i);
                        faControl['controls'].feedType.setValue(element?.feedName);
                        faControl['controls'].feedId.setValue(element?._id);
                        this.getFeedNames();
                      }
                    });
                    // this.select.push(result);
                    //         const faControl = (<FormArray>this.myForm.controls['feed1']).at(i);
                    //         faControl['controls'].feedType.setValue(result?.feedName);
                    //         faControl['controls'].feedId.setValue(result?._id);
                    //         this.getFeedNames();
                    //       } else {
                    //         console.log(data.msg);
                  }
                });
              }
            } else {
              if (this.select.includes(result)) {
                const faControl = (<FormArray>this.editForm.controls['editFeed']).at(i);
                this.select.forEach(element => {
                  if (element.feedName == result) {
                    this.feed = element.feedName;
                    const faControl = (<FormArray>this.editForm.controls['feed1']).at(i);
                    faControl['controls'].feedType.setValue(element?.feedName);
                    faControl['controls'].feedId.setValue(element?._id);
                  }
                });
                // faControl['controls'].feedType.setValue(result?.feedName);
                // faControl['controls'].feedId.setValue(result?._id);
              } else {
                let obj = {
                  feedName: result
                }
                if (localStorage.getItem('role_id') == "3") {
                  let addedFarmer = this.supervisor.locallySaved();
                  Object.assign(obj, { u_id: addedFarmer['u_id'] });
                } else if (localStorage.getItem('role_id') == "11") {
                  let addedFarmer = this.layerSupervisor.returnAddedFarmer();
                  Object.assign(obj, { u_id: addedFarmer['u_id'] })
                }
                this.LayersService.addNewFeed(obj).subscribe((data: any) => {
                  if (data.success) {
                    this.select = data.data;
                    this.select.forEach(element => {
                      if (element.feedName == result) {
                        this.feed = element.feedName;
                        const faControl = (<FormArray>this.editForm.controls['feed1']).at(i);
                        faControl['controls'].feedType.setValue(element?.feedName);
                        faControl['controls'].feedId.setValue(element?._id);
                        this.getFeedNames();
                      }
                    });
                    // this.select.push(result);
                    //         const faControl = (<FormArray>this.editForm.controls['editFeed']).at(i);
                    //         faControl['controls'].feedType.setValue(result?.feedName);
                    //         faControl['controls'].feedId.setValue(result?._id);
                    //         this.getFeedNames();
                    //       } else {
                    //         console.log(data.msg);
                  }
                });
              }
            }
          }
        }
      ],
    })
    await alert.present();
  }

  onAddCallOnDeleteCall() {
    if (!this.show) {
      this.summationFeed = 0;
      this.sumErr = false;
      let count = 0;
      for (let data of this.myForm.controls.feed1.value) {
        count = data.feedPercent == 0 ? count = count + 1 : count = count;
        this.summationFeed = this.summationFeed + data.feedPercent;
        this.sumErr = ((this.summationFeed > 100) || (this.summationFeed < 100)) ? true : false;
      }
    } else {
      this.cntFeedPerc(this.viewFrmla);
    }
  };

  deleteField(data) {
    Object.assign(data, { _id: this.viewFrmla.frmlId });
    this.LayersService.dltIngredient(data).subscribe(latest => {
      if (latest.success) {
        let makeUp = {
          show: true,
          frmlId: latest.data._id,
          frmlaName: latest.data.formulaName,
          frmlaRatios: latest.data.formulaPercentage
        }
        this.viewFrmla = makeUp;
        this.cntFeedPerc(this.viewFrmla);
        this.modalController.dismiss({
          status: true,
        });
      } else {
        console.log(latest.msg);
      }
    })
  }

  cntFeedPerc(data) {
    this.editSum = 0;
    for (let frmls of data.frmlaRatios) {
      this.editSum += frmls.feedPercent;
    }
    let count = 0;
    if (this.editForm) {
      for (let data of this.editForm.controls.editFeed.value) {
        count = count + data.feedPercent
      }
      this.editSum += count;
    }
  }

  getFeedNames() {
    let data = {};
    if (localStorage.getItem('role_id') == '3') {
      let temp = this.supervisor.locallySaved();
      Object.assign(data, { u_id: temp['u_id'] })
    } else if (localStorage.getItem('role_id') == '11') {
      let temp = this.layerSupervisor.returnAddedFarmer();
      Object.assign(data, { u_id: temp['u_id'] })
    }
    this.LayersService.getFeedNames(data).subscribe((list: any) => {
      if (list.success) {
        this.select = list.data;
        this.LayersService.storeFeedNames(this.select);
      } else {
        this.select = [];
      }
    });
  }

  onSelect() {
    this.modalController.dismiss({
      status: false,
    });
  }
}
