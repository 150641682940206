import { preserveWhitespacesDefault } from '@angular/compiler';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { AutomaticSystemService } from 'src/app/services/automatic-system.service';

@Component({
  selector: 'app-data-transmission-modal',
  templateUrl: './data-transmission-modal.page.html',
  styleUrls: ['./data-transmission-modal.page.scss'],
})
export class DataTransmissionModalPage implements OnInit {

value="0.5"
progress = 0.20; 
showLoader: boolean=true;
p_bar_value: number = 0;
color="#fff";

data:string;
msg:any;
button :boolean = false;
  default: string;

  constructor(private modalController:ModalController,
    private automaticSystemService: AutomaticSystemService,) {}

  ngOnInit() {
    this.default = localStorage.getItem('lngCode')
    for (let index = 0; index <= 100; index++) {
      this.setPercentBar(+index);
    }

    this.automaticSystemService.modalData.subscribe((data)=>{
     this.msg = data;
    })
  }


  closeModal(){
    this.modalController.dismiss()
  }

  setPercentBar(i) {
    setTimeout(() => {
      let apc = (i / 100)
      this.p_bar_value = apc;
    }, 30 * i);
  }

}
