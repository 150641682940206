import { Directive, ElementRef, OnInit, Output, EventEmitter } from '@angular/core';
// import {  } from '';
// import * as google from '@google/maps';

declare var google: any;

@Directive({
  selector: '[places-auto-complete]'
})

export class AutoCompletePalcesDirective implements OnInit {

  @Output('onAddressChange') onAddressChange: EventEmitter<any> = new EventEmitter();

  constructor(
    protected el: ElementRef
  ) { }

  ngOnInit() {
    let autocomplete = new google.maps.places.Autocomplete(this.el.nativeElement, {

    });
  }

}


// AIzaSyDhJpyl1XWdbCkixZwSA7PgTuTJOgC2q9w