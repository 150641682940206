import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { PageRefresherService } from './page-refresher.service';

@Component({
  selector: 'app-page-refresher',
  templateUrl: './page-refresher.component.html',
  styleUrls: ['./page-refresher.component.scss'],
})
export class PageRefresherComponent implements OnInit {
  currencySymbol :string=localStorage.getItem('currencySymbol');

  constructor(private refreshService: PageRefresherService) { }
  @Output('refresh') refresh = new EventEmitter<Boolean>();
  ngOnInit() { }


  doRefresh(event: Boolean, target) {
  
    this.refresh.emit(true);
    setTimeout(() => {
      target.target.complete();
    }, 1000);
  }
}