import { Component, Input, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { TraderService } from 'src/services/trader.service';

@Component({
  selector: 'app-boxview-order-info',
  templateUrl: './boxview-order-info.component.html',
  styleUrls: ['./boxview-order-info.component.scss'],
})
export class BoxviewOrderInfoComponent implements OnInit {
  @Input() CCorder;
  orderId;
  units: string;
  currency: string;
  reason: any;
  constructor(
    public popup: PopoverController,
    private trader: TraderService,
    private navparam: NavParams) {
    this.orderId = this.navparam.get('CCorder');
    this.reason = this.navparam.get('reason');
    this.units = localStorage.getItem("solidUnits");
    this.currency = localStorage.getItem("currencySymbol");
    console.log(this.reason, this.orderId )
  }

  ngOnInit() { }

  dismiss() {
    this.popup.dismiss();
  }

}
