import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'name'
})
export class NamePipe implements PipeTransform {

  transform(value: any[], args?: any): any {
    value.sort((a, b) => {
       if  (a.price < b.price) {
        return -1;
      } else { return 1; }
    });
    return value;
  }
  }


