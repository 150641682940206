import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { LoadingController, ModalController, ToastController } from "@ionic/angular";
import { OtpService } from "./otp.service";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { AuthServicesService } from "../auth-services.service";
import { LoginService } from '../login/login.service';
import { TranslateConfigService } from "../services/translate-config.service";
import { TranslateService } from "@ngx-translate/core";


@Component({
  selector: "app-otp",
  templateUrl: "./otp.page.html",
  styleUrls: ["./otp.page.scss"]
})
export class OtpPage implements OnInit {
  SelectedOption;
  isDisabled = false;
  phone = localStorage.getItem('phone');
  number_pattern_regx = "^[0-9]*$"
  otpForm = new FormGroup({
    otp: new FormControl('', Validators.compose([Validators.required, Validators.maxLength(4), Validators.minLength(4), Validators.pattern("^[0-9]*$")]))
  });
  hasBoth: boolean = false;
  redirect = '';
  extras: any;
  button_status: string;
  timeCount: number = 90;
  resentButton: boolean = true;
  intervelClearID: any;
  selectedValue: any;
  contct: string;
  eml: string;
  default: string;

  constructor(private router: Router,
    private modalCtrl: ModalController,
    private toastController: ToastController,
    private otpService: OtpService,
    private route: ActivatedRoute,
    private authService: AuthServicesService,
    private translateConfigService: TranslateConfigService,
    private translate: TranslateService,
    private loginService: LoginService) {
    this.timerCountDown(90);
    this.route.queryParams.subscribe(params => {
      if (params && params.redirect) {
        this.redirect = params.redirect;
        console.log(this.extras);
      }
      else {
        if (this.router.getCurrentNavigation().extras.state) {
          this.extras = this.router.getCurrentNavigation().extras.state.user;
          console.log(this.extras, 333)
        }
      }
      console.log(this.extras);
    });
  }
  ngOnInit() { }
  ionViewWillEnter() {
    this.default = localStorage.getItem('lngCode')
    // this.SelectedOption = this.loginService.loginThrough;
    // if (localStorage.getItem('countryCode') == "IN") {
    //   let phone= localStorage.getItem('phone');
    //   let email = localStorage.getItem('email');
    //   if(email){
    //     this.selectedValue=phone+" / "+email
    //   }else{
    //     this.selectedValue=phone
    //   }
    // } else if(localStorage.getItem('countryCode')!=="IN"){
    //   console.log("Hello World");

    //   let phone= localStorage.getItem('phone');
    //   let email = localStorage.getItem('email');
    //   if(phone){
    //     this.selectedValue=email+" / "+phone
    //   }else{
    //     this.selectedValue=email
    //   }
    // }else{
    //   this.selectedValue = this.SelectedOption.value;
    // }



    this.SelectedOption = this.loginService.loginThrough;
    console.log(this.SelectedOption);

    let phone = localStorage.getItem('phone');
    let email = localStorage.getItem('email');
    if (localStorage.getItem('countryCode') == "IN") {
      if (email && phone) {
        this.hasBoth = true
        this.contct = phone;
        this.eml = email;
      } else if (email) {
        this.hasBoth = false
        this.selectedValue = email
      } else if (phone) {
        this.hasBoth = false
        this.selectedValue = phone
      }
    } else {
      if (localStorage.getItem('countryCode') !== "IN") {
        if (email && phone) {
          this.selectedValue = email;
        } else if (email) {
          this.hasBoth = false
          this.selectedValue = email
        } else if (phone) {
          this.selectedValue = phone
        }
      } else {
        this.selectedValue = this.SelectedOption.value;
      }
    }





    // this.SelectedOption = this.loginService.loginThrough;
    // if (localStorage.getItem('countryCode') == "IN") {
    //   let cntcNo=localStorage.getItem('phone');
    //   let emil=localStorage.getItem('email');
    //   if(emil){
    //     this.selectedValue= cntcNo+"/"+emil;
    //   }else{
    //     this.selectedValue= ""+cntcNo
    //   }
    // } else {
    //   let cntcNo=localStorage.getItem('phone');
    //   let emil=localStorage.getItem('email');
    //   if(cntcNo){
    //     this.selectedValue= cntcNo+"/"+emil;
    //   }else{
    //     this.selectedValue= ""+emil
    //   }
    // }
  }
  otp = [];
  errorMsg: any;

  otpSend() {

    let values;
    if (this.loginService.loginThrough.phone) {
      values = {
        phone: localStorage.getItem('phone'),
        otp: this.otpForm.value.otp.toString(),
        phoneNumber: true,
        emailAccount: false,
      }
    } else {
      values = {
        email: localStorage.getItem('email'),
        otp: this.otpForm.value.otp.toString(),
        phoneNumber: false,
        emailAccount: true,
      }
    }
    console.log(values, 121);
    // const otpobj = {
    //   otp: this.otpForm.value.otp.toString(),
    //   phone: localStorage.getItem('phone')
    // }
    if (this.otpForm.value.otp <= 9999 && !this.isDisabled) {
      this.isDisabled = true;
      this.otpService.otpvalidate(values).subscribe((data) => {
        if (data.success) {
          this.isDisabled = false;
          if (this.redirect == '') {
            console.log("first stage");
            if (data.success) {
              this.router.navigateByUrl("/resetpassword", { replaceUrl: true });
              clearInterval(this.intervelClearID);
            }
            else {
              this.errorMsg = data.msg;
              this.showError(data.msg);
              this.otpForm.reset();
            }
          } else {
            console.log("second stage");
            if (data.success) {
              localStorage.setItem('token', data.token);
              // let params = {
              //   phone: localStorage.getItem('phone'),
              //   active: true
              // }
              this.otpService.changeUserStatus(values).subscribe(
                (data) => {
                  // console.log(data, 12536);
                  if (data.success) {
                    clearInterval(this.intervelClearID);
                    this.resentButton = false;
                    this.otpForm.reset();
                    // console.log(data, 8333);
                    // this.router.navigateByUrl("/login");
                    localStorage.setItem('token', data.token);
                    console.log(data.data[0].role_Id, typeof data.data[0].role_Id)
                    if (data.data[0].role_Id == 1 || data.data[0].role_Id == 13) {
                      this.router.navigate(['/home/tabs/poultry-home'], { replaceUrl: true });
                    } else if (data.data[0].role_Id == 3) {
                      this.router.navigate(['/supervisor'], { replaceUrl: true });
                    }
                    else if (data.data[0].role_Id == 4 || data.data[0].role_Id == 5 || data.data[0].role_Id == 6 || data.data[0].role_Id == 7 || data.data[0].role_Id == 14 || data.data[0].role_Id == 19) {
                      console.log("dealer login entered");
                      this.router.navigate(['/dealer'], { replaceUrl: true });
                    } else if (data.data[0].role_Id == 9) {
                      this.router.navigate(['/layerfarmer/tabs/home'], { replaceUrl: true })
                    }
                    else if (data.data[0].role_Id == 15) {
                      this.router.navigate(['/wholeseller'], { replaceUrl: true });
                      // this.router.navigate(['/home/tabs/poultry-home'], { replaceUrl: true });
                    } else if (data.data[0].role_Id == 18) {
                      this.router.navigateByUrl('/retailer/tabs/home', { replaceUrl: true });
                      // this.router.navigate(['/home/tabs/poultry-home'], { replaceUrl: true });
                    } else if (data.data[0].role_Id == 17) {
                      this.router.navigate(['/layer-seller/tabs/seller-home'], { replaceUrl: true })
                      // this.router.navigate(['/home/tabs/poultry-home'], { replaceUrl: true });
                    } else if (data.data[0].role_Id == 11) {
                      this.router.navigate(['/layersupervisor'], { replaceUrl: true })
                    }
                     else if (data.data[0].role_Id == 10) {
                      this.router.navigate(['/chicken-center-home/tabs/chicken-home']);
                      // this.router.navigate(['/home/tabs/poultry-home'], { replaceUrl: true });
                    } else if (data.data[0].role_Id == 2) {
                      this.router.navigate(['/trader/tabs/orders']);
                      // this.router.navigate(['/home/tabs/poultry-home'], { replaceUrl: true });
                    } else if (data.data[0].role_Id == 8) {
                      // this.router.navigate(['/trader/tabs/orders']);
                      this.router.navigateByUrl('/contact-not-add');
                      // this.router.navigate(['/home/tabs/poultry-home'], { replaceUrl: true });
                    }
                    else {
                      this.router.navigate(['/home'], { replaceUrl: true });
                    }

                  } else {
                    this.errorMsg = data.msg;
                    this.showError(data.msg);
                  }
                });
            }
          }
        } else {
          this.showError(data.msg);
          this.isDisabled = false;
        }
      }
      )
    } else {
      if (this.otpForm.value.otp > 9999) {
        this.errorMsg = 'otpMust4digitss';
        setTimeout(() => {
          this.errorMsg = '';
        }, 3000);
      }
    }
  }
  max = 3;
  async resend() {
    let values;
    if (this.loginService.loginThrough.phone) {
      values = {
        phone: localStorage.getItem('phone'),
        phoneNumber: true,
        emailAccount: false,
      }
    } else {
      values = {
        email: localStorage.getItem('email'),
        phoneNumber: false,
        emailAccount: true,
      }
    }


    if (!this.resentButton) {
      clearInterval(this.intervelClearID);
      let data = {};
      this.timerCountDown(90);
      // if (!this.resentButton) {
      if (this.max < 4 && this.max > 0) {
        const toast = await this.toastController.create({
          message: this.max - 1 + this.translate.instant('flashMessages.attemptsLeft'),
          duration: 2000,
          position: 'bottom',
          buttons: [
            {
              text: this.translate.instant('buttons.done'),
              role: 'cancel',
              handler: () => {
                console.log('Cancel clicked');
              }
            }
          ],
          animated: true,
          cssClass: "my-custom-class toast-text-center",
        });
        toast.present();
        // this.showError(a);
        // if (this.extras) {
        //   data = {
        //     phone: localStorage.getItem("phone"),
        //     otp: "forgot"
        //   }
        // } else {
        //   data = {
        //     phone: localStorage.getItem("phone"),
        //   }
        // }


        this.otpService.otpResend(values).subscribe(
          (data) => {
            console.log(data);
            this.max = this.max - 1;
          }
        );
      }
      // } else {
      //   console.log('wait for sms');
      // }
    }
  }



  async showError(msg) {
    const toast = await this.toastController.create({
      message: this.translate.instant('formValidations.' + msg),
      duration: 2000,
      position: 'bottom',
      buttons: [
        {
          text: this.translate.instant('buttons.done'),
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        }
      ],
      animated: true,
      cssClass: "my-custom-class toast-text-center",
    });
    toast.present();
  }

  timerCountDown(time) {
    let sec = time;
    this.intervelClearID = setInterval(() => {
      if (sec >= 0) {
        this.timeCount = sec;
        --sec
        // console.log(this.timeCount, 189)
        this.resentButton = (!this.timeCount) ? false : true;
        // console.log(this.resentButton, 1254)
      }
      else {
        clearInterval(this.intervelClearID)
        console.log(this.resentButton)
        this.resentButton = false;
      }
    }, 1000)
  }
  ionViewDidLeave() {
    this.otpForm.reset();
  }
}
