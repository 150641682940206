import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
// import { InventoryEditService } from '../inventory-edit/inventory-edit.service';
// import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
// import { FileUploadService } from '../../services/fileupload.service';
import {  ToastController, ModalController, NavParams,  } from '@ionic/angular';
// import * as moment from 'moment';
import { ImagePicker, ImagePickerOptions } from '@ionic-native/image-picker/ngx';
import { FileTransfer, FileUploadOptions } from '@ionic-native/file-transfer/ngx';
import { config } from '../helper/config';
import { InventoryDetailService } from '../inventory-detail/inventory-detail.service';
import { InventoryInfoService } from '../inventory-info/inventory-info.service';
import { LoadingserviceService } from "../loadingservice.service";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-update-inventory',
  templateUrl: './update-inventory.component.html',
  styleUrls: ['./update-inventory.component.scss'],
})
export class UpdateInventoryComponent implements OnInit {
  userRole = localStorage.getItem('role_id');
  default;
  minDate;
  today = new Date();
  maxDateLimit = this.today.toISOString().substr(0, 10);
  inventory_add: FormGroup;
  submitted = false;
  nav: any;
  iddata: any;
  date = "";
  day = "";
  id = "";
  stock = "";
  remark = "";
  feed = "";
  myphoto: string;
  type: any;
  mortality = '';
  tempStock: number = 0;
  tempFeed: number = 0;
  tempFeedTransfer: number = 0;
  tempAvailableStock = 0;
  avg_weight = '';
  uploadFileArr: any[];
  public baseUrl = config.api;
  detailsdata: any;
  defaultValue: number = 0;
  test: any;
  infoData: any;
  editData: any;
  avg_weight_check: boolean;
  editPage: boolean = false;
  date_check_cond: boolean;
  dateData: any;
  inventory_id;
  isDisabled: boolean = true;
  totalAvalibleBirds: any
  totalFeed: any;
  inventoryCreation: any;
  settingValue: any;
  removeFeed: any;
  f_consumed: any;
  f_trasfermed: any;
  errorShow: any;
  cardData: any;
   editDataValue: any;
   addStock:any;
   transferFeed:any;
   consumedFeed:any;
   totalStock:any;
  bagsWeight: any;
  totalAvailable: any;
  L_Stock:any;
  M_available:any;
  N_Consumed:any;
  O__transfered:any;
  mainStock: number;
  mainShow: number;

  constructor(
    // private popover: PopoverController,
    private modal: ModalController,
    private navParams: NavParams,
    public formbuilder: FormBuilder,
    // private alertController: AlertController,
    // private router: Router,
    // private route: ActivatedRoute,
    // private uploadService: FileUploadService,
    private imagePicker: ImagePicker,
    private fileTransfer: FileTransfer,
    protected inventoryDetail: InventoryDetailService,
    private toastController: ToastController,
    protected inventoryInfo: InventoryInfoService,
    // private Platform: Platform,
    private loading: LoadingserviceService,
    private translate: TranslateService,
  ) {

    this.settingValue = this.navParams.get('data').from;
  }

  validate_message = {
    date: [
      { type: "required", message: "dateRequired" }
    ],
    day: [
      { type: "required", message: "transferedBagsRequired" }
    ],
    mortality: [
      { type: "required", message: "mortality" }
    ],
    avg_weight: [
      { type: "required", message: "avgWeightRequired" }
    ],
    stock: [
      { type: "required", message: "stockRequired" },
      { type: "pattern", message: "postive number added" }
    ],
    feed: [
      { type: "required", message: "feedbagRequired" }
    ],
  }

  ngOnInit() {
    this.inventory_add = this.formbuilder.group({
      stock: [this.defaultValue, Validators.required],
      date: ['', Validators.required,],
      day: [this.defaultValue, Validators.required],
      // stock: [this.defaultValue, Validators.required],
      remark: [""],
      feed: [this.defaultValue, Validators.required],
      mortality: [this.defaultValue, Validators.required],
      avg_weight: ["", Validators.required],
    });

    if (this.navParams.get('data').from == 'update') {
      this.tempAvailableStock = 0;
      let updatedDate = this.navParams.get('data').details;
      this.minDate = new Date(updatedDate.arrival_date).toISOString();
      this.totalAvalibleBirds = updatedDate.available;
      this.totalFeed = updatedDate.available_stock;
      this.bagsWeight = updatedDate.bags_weight;
      this.cardData = this.navParams.get('data').details;
      console.log(this.cardData, 147);
      this.tempAvailableStock = updatedDate.available_stock;
    } else if (this.navParams.get('data').from == 'edit') {
      this.editDataValue = this.navParams.get('data').data.EditData;
      this.inventory_id = this.inventoryInfo.getData();
      this.editData = this.inventoryDetail.getData();
      let appendData = this.navParams.get('data').data.EditData;
      this.bagsWeight = appendData.bags_weight;
      this.cardData = this.navParams.get('data').data.cardData;
      console.log(this.cardData, 157);
      this.totalAvalibleBirds = appendData.available + appendData.d_mortality;
      this.f_consumed = appendData.d_c_feed;
      this.f_trasfermed = appendData.d_transferdFeed;
      this.removeFeed = appendData.d_stock;
      this.totalAvailable = appendData.available_stock;
      this.tempFeed = appendData.d_c_feed;
      this.tempFeedTransfer = appendData.d_transferdFeed || 0;
      this.tempStock = appendData.d_stock;
      this.totalFeed = appendData.available_stock;
      this.addStock = appendData.d_stock;
      this.totalStock = appendData.available_stock;
      this.transferFeed = appendData.d_transferdFeed || 0;
      this.consumedFeed = appendData.d_c_feed;
      this.L_Stock = appendData.d_stock;
      this.M_available = appendData.available_stock;
      this.N_Consumed = appendData.d_c_feed;
      this.O__transfered = appendData.d_transferdFeed;
      this.mainStock = this.M_available - this.L_Stock + this.N_Consumed +  this.O__transfered;
      this.mainShow = this.mainStock + this.L_Stock - ( this.N_Consumed +  this.O__transfered );
       this.tempAvailableStock = appendData.available_stock - this.addStock - this.transferFeed - this.consumedFeed;
      // this.tempAvailableStock=0;
    }
  }

  ionViewWillEnter() {
    this.default = localStorage.getItem('lngCode');
  }

  async onSumbmitInventory(submit) {
    console.log(submit, 185);
    if (submit) {
      this.date_check_cond = new Date(new Date().setHours(0, 0, 0, 0)) >= new Date(new Date(this.inventory_add.value.date).setHours(0, 0, 0, 0));
      console.log(this.date_check_cond, 188);
      this.avg_weight_check = this.inventory_add.value.avg_weight > 4;

      if (new Date(new Date().setHours(0, 0, 0, 0)) >= new Date(new Date(this.inventory_add.value.date).setHours(0, 0, 0, 0)) || this.inventory_add.value > 4) {
        this.detailsdata = this.inventoryInfo.getta();
        this.submitted = true;
        if (this.navParams.get('data').from == 'update' && this.isDisabled) {
          this.infoData = this.inventoryInfo.getData();
          if (this.inventory_add.invalid) {
            return;
          }
          // let updateDateString = new Date(this.inventory_add.value.date);
          // let dateString = updateDateString.getDate() + "/" + updateDateString.getMonth() + "/" + updateDateString.getFullYear();
          let val = { ...this.inventory_add.value }
          val._id = this.infoData.info._id;
          val.available = this.infoData.info.available;
          val.u_id = localStorage.getItem('u_id');
          if (this.editPage) {
            val.doc_id = this.dateData._id;
          }
          if (this.uploadFileArr) {
            let fileIDs = await this.uploadImages(this.uploadFileArr);
            val.files = fileIDs;
          };
          this.isDisabled = false;
          this.loading.loadingPresent();
          if (this.userRole != '9') {
            delete this.inventory_add.value.extraEggs;
            delete this.inventory_add.value.extraFeed;
            delete this.inventory_add.value.trays;
            delete this.inventory_add.value.soldBirds;
          } else {
            delete this.inventory_add.value.avg_weight;
          }
          this.inventoryDetail.insertInventory(val).subscribe((data: any) => {
            if (data.success) {
              this.loading.loadingDismiss();
              this.inventoryInfo.setAValue(data.overallInventory);
              this.inventoryInfo.setInventoryData(data.overallInventory);
              this.showError(this.translate.instant('flashMessages.changesSaved'));
              this.modal.dismiss( {data: data.data,submit: true,inventoryList: data.overallInventory });
            } else {
              this.loading.loadingDismiss();
              this.isDisabled = true;
              console.log('update inventory failed', 162);
            }
          });
        } else if (this.navParams.get('data').from == 'edit') {
          let val = this.inventory_add.value;
          if (this.uploadFileArr) {
            let fileIDs = await this.uploadImages(this.uploadFileArr);
            val.files = fileIDs;
          }
          val.u_doc = this.detailsdata.u_doc;
          const data = { ...val, inventory_id: this.detailsdata._id, u_id: localStorage.getItem("u_id"), u_doc: this.detailsdata.u_doc, last_updateDate: this.detailsdata.last_updated_date };
          this.isDisabled = false;
          this.loading.loadingPresent();
          this.inventoryDetail.updateInventoryById(data).subscribe((data: any) => {
            if (data.success) {
              this.loading.loadingDismiss();
              this.modal.dismiss({ data: data.data, submit: true });
              this.isDisabled = true;
              this.showError(this.translate.instant('flashMessages.changesSaved'));
            } else {
              this.loading.loadingDismiss();
              this.isDisabled = true;
              this.loading.loadingDismiss();
              console.log('edit inventory failed', 162);
            }
          })
        } else {
          console.log('error')
        }
      } else {
        let msg;
        if (!this.date_check_cond && !this.avg_weight_check) {
          msg = this.translate.instant('flashMessages.youCantChooseFeatureDates');
        } else if (this.avg_weight_check) {
          msg = this.translate.instant('flashMessages.averageWeightNotAllowedMoreThan') + '4';
        }
        this.showError(msg)
      }
      // } else {
      //   return new Promise((resolve, reject) => {
      //     this.alertController.create({
      //       message: this.translate.instant('alertMessages.dontHaveStock'),
      //       buttons: [{
      //         text: this.translate.instant('buttons.ok'),
      //         role: "dismiss"
      //       }]
      //     }).then(alrt => {
      //       alrt.present();
      //       alrt.onDidDismiss().then(resolve).catch(reject);
      //     });
      //   });
      // }
    } else {
      this.modal.dismiss({ submit: false })
    }
  }
  chooseFile() {
    let imagePickerOptions: ImagePickerOptions;
    imagePickerOptions = { maximumImagesCount: 5, height: 800, width: 800, quality: 100, allow_video: true }
    this.imagePicker.getPictures(imagePickerOptions).then(async (filepaths) => {
      let uploadFileArr = [];
      for (var i = 0; i < filepaths.length; i++) {
        let options: FileUploadOptions = {};
        let currentFileName = filepaths[i].substring(filepaths[i].lastIndexOf('/') + 1, filepaths[i].lastIndexOf('?'))
        options.fileKey = 'file';
        options.fileName = filepaths[i].substr(filepaths[i].lastIndexOf('/') + 1);
        options.mimeType = `image/${currentFileName.substr(currentFileName.lastIndexOf('.') + 1)}`;
        options.headers = {};
        uploadFileArr = [...uploadFileArr, { filepath: filepaths[i], options }];
        this.uploadFileArr = uploadFileArr;
      }
    }, (err) => { });
  }

  uploadImages = (uploadFileArr: any[]): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      let result = await Promise.all(uploadFileArr.map(fileObject => this.transferFiles(fileObject)));
      result = result.filter((result => result != false))
      resolve(result)
    }).catch(console.log)
  }

  transferFiles = (fileObject) => {
    return new Promise((resolve, reject) => {
      let transfer = this.fileTransfer.create();
      transfer.upload(fileObject.filepath, `${this.baseUrl}imageUpload/insertlease2`, fileObject.options).then((result: any) => {
        if (result && result.responseCode === 200) {
          let file = JSON.parse(result.response);
          resolve(file.file)
        } else {
          console.log("error occured")
          resolve(false)
        }
      }).catch(error => {
        resolve(false)
      });
    })
  }

  ionViewDidEnter() {
    this.detailsdata = this.inventoryInfo.getta();
    // if (this.navParams.get('data').from == 'update') {
    //   let updatedDate = this.navParams.get('data').details;
    //   this.minDate = new Date(this.detailsdata.arrival_date).toISOString();
    //   console.log(this.minDate);
    // }
    if (JSON.stringify(this.detailsdata) !== '{}') {
      this.inventory_add.controls['day'].setValue(this.detailsdata.d_transferdFeed);
      this.inventory_add.controls['date'].setValue(this.detailsdata.d_date);
      this.inventory_add.controls['avg_weight'].setValue(this.detailsdata.d_avg_weight);
      this.inventory_add.controls['feed'].setValue(this.detailsdata.d_c_feed);
      this.inventory_add.controls['mortality'].setValue(this.detailsdata.d_mortality);
      this.inventory_add.controls['stock'].setValue(this.detailsdata.d_stock);
      this.inventory_add.controls['remark'].setValue(this.detailsdata.d_remark);
      this.minDate = new Date(this.detailsdata.arrival_date).toISOString();
    } else {
      this.inventory_id = this.inventoryInfo.getData();
      this.editData = this.inventoryDetail.getData();
      this.inventory_add = this.formbuilder.group({
        date: ['', Validators.required],
        day: [this.defaultValue, Validators.required],
        stock: [this.defaultValue, Validators.required],
        remark: [""],
        feed: ['', Validators.required],
        mortality: [this.defaultValue, Validators.required],
        avg_weight: ["", Validators.required]
      });
    }
  }


  resetForm() {
    this.inventory_add.reset();
    this.inventoryInfo.setta({});
  }

  async showError(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 3000,
      position: 'bottom',
      buttons: [
        {
          text: this.translate.instant('buttons.done'),
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        }
      ],
      animated: true,
      cssClass: "my-custom-classInventory",
    });
    toast.present();
  }

  pastdateCheck(a) {
    this.inventoryCreation = false;
    if (a) {
      this.loading.loadingPresent();
      this.inventoryDetail.pastDataCheck({ date: a, inventory_id: this.inventory_id.id }).subscribe(async (data: any) => {
        console.log(data, 302);
        if (data.success && data.code == 204) {
          this.loading.loadingDismiss();
          this.inventory_add.controls['day'].setValue(this.defaultValue);
          this.inventory_add.controls['avg_weight'].setValue(this.defaultValue);
          this.inventory_add.controls['feed'].setValue(this.defaultValue);
          this.inventory_add.controls['mortality'].setValue(this.defaultValue);
          this.inventory_add.controls['stock'].setValue(this.defaultValue);
          this.inventory_add.controls['remark'].setValue('');
          this.totalAvalibleBirds = this.cardData.available;
          this.totalFeed = this.cardData.available_stock;
          this.settingValue = "update";
          this.editPage = false;
        } else {
          this.editPage = true;
          this.detailsdata = data.data[0];
          console.log(this.detailsdata, 410);
          this.dateData = data.data[0];
          this.settingValue = "edit";
          this.totalAvalibleBirds = this.cardData.available + this.detailsdata.mortality_rate;
          this.f_consumed = this.detailsdata.consumed_feed;
          this.f_trasfermed = this.detailsdata.d_transferdFeed;
          this.totalFeed = this.cardData.available_stock + this.detailsdata.consumed_feed + this.detailsdata.transferdFeed || 0;
          this.removeFeed = this.detailsdata.stock;
          this.inventory_add.controls['day'].setValue(this.detailsdata.transferdFeed);
          // this.inventory_add.controls['date'].setValue(this.detailsdata.date);
          this.inventory_add.controls['avg_weight'].setValue(this.detailsdata.avg_weight);
          this.inventory_add.controls['feed'].setValue(this.detailsdata.consumed_feed);
          this.inventory_add.controls['mortality'].setValue(this.detailsdata.mortality_rate);
          this.inventory_add.controls['stock'].setValue(this.detailsdata.stock);
          this.inventory_add.controls['remark'].setValue(this.detailsdata.remark);
          this.loading.loadingDismiss();
          // const alert = await this.alertController.create({
          //   message: this.translate.instant('alertMessages.alreadyUpdated'),
          //   buttons: [
          //     {
          //       text: this.translate.instant('buttons.ok'),
          //       handler: () => {
          //         console.log('update allert')
          //       }
          //     }
          //   ]
          // });
          // await alert.present();
        }
      })
    }
  }

  weightVrify(e) {
    if (Number(e.target.value) < 0 && e.target.value != '') {
      this.inventory_add.controls['avg_weight'].setValue('0');
      this.showError(this.translate.instant('flashMessages.negativeValuesNotAllowed'));
    } else {
      if (e.target.value > 10) {
        this.showError(this.translate.instant('flashMessages.averageWeightlessThan'));
        this.inventory_add.controls['avg_weight'].setValue('');
      }
    }
  }
  avialableBirdsCheck(triggedValue) {
    if (Number(triggedValue) < 0 && triggedValue != '') {
      this.inventory_add.controls['mortality'].setValue('0');
      this.showError(this.translate.instant('flashMessages.negativeValuesNotAllowed'));
    } else {
      if (triggedValue > this.totalAvalibleBirds) {
        this.inventoryCreation = true;
      } else {
        this.inventoryCreation = false;
      }
    }
  }
  testingForm(data) {
    if (this.settingValue == "update") {
      let enterdFeed = this.inventory_add.value.day + this.inventory_add.value.feed;
      let totalFeedWeHave = this.cardData.available_stock + this.inventory_add.value.stock;
      var condition = totalFeedWeHave >= enterdFeed;
      var condtitionTwo = (this.totalAvalibleBirds) >= (this.inventory_add.value.mortality);
      if (condition && condtitionTwo) {
        this.onSumbmitInventory(true);
      }
      else if (!condition) {
        this.showError(this.translate.instant('alertMessages.dontHaveStock'));
      }
      else if (!condtitionTwo) {
        this.showError(this.translate.instant('flashMessages.graterMortality'));
      }
    }  else if (this.settingValue == "edit") {
      let enterdFeed = this.inventory_add.value.day + this.inventory_add.value.feed;
      console.log(enterdFeed,this.cardData.available_stock, this.f_consumed,this.f_trasfermed, this.removeFeed, 481);
      let feedCheck = (this.cardData.available_stock + this.f_consumed + this.f_trasfermed) - this.removeFeed;
      console.log(feedCheck, 483);
      let totalFeedWeHave = feedCheck + this.inventory_add.value.stock;
      console.log(totalFeedWeHave, 485);
      var condition = totalFeedWeHave >= enterdFeed;
      var condtitionTwo = (this.totalAvalibleBirds) >= (this.inventory_add.value.mortality);
      if (condition && condtitionTwo) {
        this.onSumbmitInventory(true);
      }
      else if (!condition) {
        this.showError(this.translate.instant('alertMessages.dontHaveStock'));
      }
      else if (!condtitionTwo) {
        this.showError(this.translate.instant('flashMessages.graterMortality'));
      }
    }
  }

  numberOnlyValidation(e, formcontrol) {
    if (Number(e.target.value) < 0 && e.target.value != '') {
      this.inventory_add.controls[formcontrol].setValue('0');
      this.showError(this.translate.instant('flashMessages.negativeValuesNotAllowed'))
    } else {
      // console.log(e.target.value, 469)
    }
  }
  // stockCheck(e){
  //   if (Number(e.target.value) < 0 && e.target.value != '') {
  //     this.inventory_add.controls['stock'].setValue('');
  //     this.showError(this.translate.instant('flashMessages.negativeValuesNotAllowed'))
  //   } else {
  //     let expenceFeed = Number(this.inventory_add.controls['day'].value) + Number(this.inventory_add.controls['feed'].value)
  //     let balanceFeed = Number(this.cardData.balance_stock) + Number(this.inventory_add.controls['stock'].value)
  //    if(expenceFeed > balanceFeed){
  //     this.inventory_add.controls['stock'].setValue('');
  //     this.showError('expence feed is graterthan available feed')
  //    }
  //   }
  // }
  stockCalculation(event, keyType) {
    if ((this.navParams.get('data').from == 'edit')){
      this.mainShow = this.mainStock + this.L_Stock - ( this.N_Consumed +  this.O__transfered );
      this.totalFeed = this.mainShow;
      // if (keyType == 'stock') {
      //   this.mainShow = this.mainStock + this.L_Stock - ( this.N_Consumed +  this.O__transfered );
      //   console.log(this.mainShow, 546);
      // } else if (keyType == "transfer") {
      //   this.mainShow = this.mainStock + this.L_Stock - ( this.N_Consumed +  this.O__transfered );
      //   console.log(this.mainShow, 549);
      // } else {
      //   this.mainShow = this.mainStock + this.L_Stock - ( this.N_Consumed +  this.O__transfered );
      //   console.log(this.mainShow, 552);
      // }
      // console.log("edit");
      // let tempValue = Number(event);
      // if (keyType == 'stock') {
      //   this.totalFeed = this.tempAvailableStock + this.addStock;
      //   this.tempAvailableStock = this.tempAvailableStock; 
      // } else if (keyType == "transfer") {
      //   console.log(this.tempStock,+ this.tempAvailableStock, this.transferFeed, this.tempFeed);
      //   this.totalFeed = (this.tempStock + this.tempAvailableStock + this.tempFeed) - (this.transferFeed);
      //   this.tempAvailableStock = this.tempAvailableStock; 
      // } else {
      //   console.log(this.consumedFeed ,  this.tempFeed,539);
      //   if( this.consumedFeed <=  this.tempFeed) {
      //     let addValue = this.tempFeed - this.consumedFeed;
      //     console.log(addValue,542);
      //     this.totalFeed = (this.tempStock + this.tempAvailableStock + this.tempFeedTransfer + this.tempFeed +  addValue);
      //     this.tempAvailableStock = this.tempAvailableStock; 
      //     this.tempFeed =  this.tempFeed;
      //     this.tempStock = this.tempStock;
      //     this.tempFeedTransfer = this.tempFeedTransfer;
      //   } else {
      //     let addValue = this.consumedFeed - this.tempFeed ;
      //     this.totalFeed = (this.tempStock + this.tempAvailableStock + this.tempFeedTransfer) - (addValue);
      //     this.tempAvailableStock = this.tempAvailableStock; 
      //     this.tempFeed =  this.tempFeed;
      //     this.tempStock = this.tempStock;
      //     this.tempFeedTransfer = this.tempFeedTransfer;
      //   }
      // }
    } else {
      let tempValue = Number(event.target.value);
      if (keyType == 'stock') {
        this.tempStock = tempValue;
        this.totalFeed = (tempValue + this.tempAvailableStock) - (this.tempFeedTransfer + this.tempFeed);
      } else if (keyType == "transfer") {
        this.tempFeedTransfer = tempValue;
        this.totalFeed = (this.tempStock + this.tempAvailableStock) - (tempValue + this.tempFeed);
      } else {
        this.tempFeed = tempValue;
        this.totalFeed = (this.tempStock + this.tempAvailableStock) - (this.tempFeedTransfer + tempValue);
      }
    }
  }
} 