import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from "../helper/config";
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { distinctUntilChanged } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class LayersService {
  tempInventory: any;
  layerInventoryData: any;
  layerUpdateinventoryData: any;
  public setLayerFarmerAutoOrderCount: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  layerFarmerAutoOrderCount: any = this.setLayerFarmerAutoOrderCount.asObservable();
  protected layerInventories = new BehaviorSubject<any>([]);
  protected layerFarms = new BehaviorSubject<any>([]);
  dailyInventoryData: any;
  frmlaDetls: any;
  feedstock: any;
  stockDetails = [];
  storeFormValues: any = {};
  placeFeedNames = [];
  farmeAutoOrderData: any;
  constructor(
    private httpRequest: HttpClient
  ) { }

  // updateinventory set data
  updateInventory(data) {
    this.layerUpdateinventoryData = data;
  }
  dailyInventoryView(data) {
    this.dailyInventoryData = data;
  }
  dailyData() {
    return this.dailyInventoryData
  }
  feedstockmore(feedstocks) {
    return this.feedstock = feedstocks;
  }
  feedstockmoredetail() {
    return this.feedstock
  }

  feedstockmoreDetailsView(stock) {
    this.stockDetails = stock;
  }
  getFeedstockmoreDetailsView() {
    return this.stockDetails;
  }
  // get updateInventoryData
  getupdateInventory() {
    return this.layerUpdateinventoryData;
  }
  //get layer inventory Date
  getLayerInventory() {
    return this.layerInventoryData
  }
  //set inventoryData
  setInventoryData(data) {
    this.layerInventoryData = data
  }
  //create nre layer inventory
  layerInventoryCreation(inventoryCreate): Observable<any> {
    return this.httpRequest.post(config.api + "layers/insertLayerInventory", inventoryCreate);
  }
  // total inventory list 
  getLayerInventoryList(): Observable<any> {
    return this.httpRequest.get(config.api + "layers/layersinventoryList").pipe(map((response: any) => {
      if (response.success) {
        this.tempInventory = response.data;
        this.layerSettingInventories(response.data);
        return response
      } else {
        return response;
      }
    }))
  }


  //updateing daily inventory
  dailyUpdate(updateData): Observable<any> {
    return this.httpRequest.post(config.api + "layers/dailyInventoyUpdate", updateData);
  }
  dailyUpdateEdit(data): Observable<any> {
    return this.httpRequest.post(config.api + "layers/dailyInventoyedit", data);
  }

  salesInventory(data): Observable<any> {
    return this.httpRequest.get(config.api + `layers/salesInvetoriesList?u_id=${data}`,);
  }

  layersManualOrderBooking(ordeData): Observable<any> {
    return this.httpRequest.post(config.api + "layers/layerManualOrder", ordeData);
  }
  inventoryClose(data): Observable<any> {
    return this.httpRequest.post(config.api + "layers/layerCloseInventory", data);
  }
  deleteInventoryDocs(data): Observable<any> {
    return this.httpRequest.post(config.api + "layers/deleteLayersInventory", data);
  }

  inventoryDetaileSaleList(id): Observable<any> {
    return this.httpRequest.post(config.api + "layers/layerInvntorySaleDetailes", id);

    // if(id.pdf){
    //   return this.httpRequest.post(config.api + "layers/layerInvntorySaleDetailes",{responseType: 'text'});
    // }else{
    //   return this.httpRequest.post(config.api + "layers/layerInvntorySaleDetailes", id,{responseType: 'text'});

    // }
  }
  getDailyInventory(id): Observable<any> {
    return this.httpRequest.post(config.api + "layers/getDailyInventory", id);
  }

  pastDataCheck(date): Observable<any> {
    return this.httpRequest.post(config.api + "layers/pastDataCheck", date);
  }
  removeDoc(data): Observable<any> {
    return this.httpRequest.post(config.api + "auth/removePDF", data);
  }
  layerInventoryPrice(id): Observable<any> {
    return this.httpRequest.post(config.api + "layers/layerInventoryPrice", id);
  }

  layerNearestPriceCity(argument): Observable<any> {
    return this.httpRequest.post(config.api + "layers/showNearPriceCity", argument);
  }

  layersExpenses(data): Observable<any> {
    return this.httpRequest.get(config.api + `layers/expenses?u_id=${data}`);
  }

  layersExpensesDetailed(id): Observable<any> {
    return this.httpRequest.post(config.api + "layers/layersExpensesDetailes", id);
  }

  getLayerFarmsList(): Observable<any> {
    return this.httpRequest.get(config.api + "layers/layerFarms");
  }

  createFarmLayer(data): Observable<any> {
    return this.httpRequest.post(config.api + "layers/layerInsert", data);
  }
  //behavioural subject 
  LayerInventoriesList() {
    return this.layerInventories.asObservable().pipe(distinctUntilChanged());
  }

  layerSettingInventories(data) {
    this.layerInventories.next(data);
  }


  LayerFarmsList() {
    return this.layerFarms.asObservable().pipe(distinctUntilChanged());
  }

  layerSettingFarms(data) {
    // this.layerFarms.next(data);
  }

  // get auto order
  getWholsellerOrder(id): Observable<any> {
    return this.httpRequest.post(config.api + "layers/getAutoOrder", id);
  }
  getInventoryData(id): Observable<any> {
    return this.httpRequest.post(config.api + "layers/getInventoryData", id);
  }
  updateAutoOrder(data): Observable<any> {
    return this.httpRequest.post(config.api + "layers/autoOrderUpdate", data);
  }
  layeraddfeedstock(data): Observable<any> {
    return this.httpRequest.post(config.api + "layers/insertFeed", data);
  }
  addfeedstockForInv(data): Observable<any> {
    return this.httpRequest.post(config.api + "layers/insertFeedForInv", data);
  }
  addfeedstockForInvEdit(data): Observable<any> {
    return this.httpRequest.post(config.api + "layers/editFeedForInv", data);
  }
  feedStockEdit(data): Observable<any> {
    return this.httpRequest.post(config.api + "layers/editFeed", data);
  }
  getFeedNames(data) {
    return this.httpRequest.post(config.api + "layers/getFeedNamesList", data);
  }

  getStandardFeedNames(data) {
    return this.httpRequest.post(config.api + "layers/getStandardFeedNamesList", data);
  }

  getfeedstock(sort): Observable<any> {
    return this.httpRequest.post(config.api + "layers/getStockData", sort);
  }

  addFeedFormulas(formula): Observable<any> {
    return this.httpRequest.post(config.api + "layers/addFormula", formula);
  }

  getFormulasList(index): Observable<any> {
    return this.httpRequest.post(config.api + "layers/getFormulas", index);
  }

  calcBasedOnFormula(calcPart): Observable<any> {
    return this.httpRequest.post(config.api + "layers/calFeed", calcPart);
  }
  addNewFeed(data): Observable<any> {
    return this.httpRequest.post(config.api + "layers/newFeedName", data);
  }
  fetchPreviousFeeds(): Observable<any> {
    return this.httpRequest.get(config.api + "layers/prevSavFeed");
  }
  carryFrmula(data) {
    this.frmlaDetls = data;
  }
  dltIngredient(data): Observable<any> {
    return this.httpRequest.post(config.api + "layers/dltFeedIng", data);
  };
  addNwIngre(data): Observable<any> {
    return this.httpRequest.post(config.api + "layers/addNwIngred", data);
  };
  // genrtPDF(data): Observable<any> {
  //   return this.httpRequest.post(config.api + "layers/dwnldPDF", data)
  // };

  storeStockDetails(data) {
    this.storeFormValues = data;
  }

  getStoredValues() {
    return this.storeFormValues;
  }

  priceSettingUpdate(updatedData): Observable<any> {
    return this.httpRequest.post(config.api + "layers/updatePriceSettings", updatedData);
  }

  storeFeedNames(data) {
    this.placeFeedNames = data;
  }

  getstoreFeedNames() {
    return this.placeFeedNames;
  }

  getLastUpdatedDoc(inventoryId): Observable<any> {
    return this.httpRequest.post(config.api + "layers/lastUpdateDoc", inventoryId);
  }
  rejectFarmerAutoOrder(data) {
    return this.httpRequest.post(config.api + "layers/rejectFarmerAutoOrder", data);
  }

  formulaUsedOrNot(formulaId) {
    return this.httpRequest.post(config.api + "layers/checkFormulaUsed", formulaId);
  }
  getLayerFarmerNotification(): Observable<any> {
    return this.httpRequest.get(config.api + "notification/getlayerFarmer");
  }
  getAutoOrderCount(): Observable<any> {
    return this.httpRequest.get(config.api + "layers/getFarmerAutoCount");
  }
  setAuoOrderCount(value: number) {
    console.log(value)
    this.setLayerFarmerAutoOrderCount.next(value);
  }

  addedFarmerInventories(data): Observable<any> {
    return this.httpRequest.post(config.api + "layers/supervisorRequestedInventory", data);
  }
  deleteFeed(data): Observable<any> {
    return this.httpRequest.post(config.api + "layers/deleteStock", data);
  }
  checkfeedStockDoc(edit): Observable<any> {
    return this.httpRequest.post(config.api + "layers/checkfeedStock", edit);
  }
  farmerAutoOrderDetails(data) {
    return this.farmeAutoOrderData = data;
  }
  getFarmerAutoOrder() {
    return this.farmeAutoOrderData
  }

  inventoryOpen(id) {
    return this.httpRequest.post(config.api + "pastInventory/openBooking", id);
  }


}
