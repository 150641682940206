export const countryList =[
    {
        "_id" : "5f4af1fb1344ee5fd4279726",
        "region" : "Africa",
        "subregion" : "Eastern Africa",
        "latlng" : [
            -20,
            30
        ],
        "timezones" : [
            "UTC+02:00"
        ],
        "countryCode" : "ZW",
        "currencySymbol" : [
            {
                "code" : "BWP",
                "name" : "Botswana pula",
                "symbol" : "P"
            },
            {
                "code" : "GBP",
                "name" : "British pound",
                "symbol" : "£"
            },
            {
                "code" : "CNY",
                "name" : "Chinese yuan",
                "symbol" : "¥"
            },
            {
                "code" : "EUR",
                "name" : "Euro",
                "symbol" : "€"
            },
            {
                "code" : "INR",
                "name" : "Indian rupee",
                "symbol" : "₹"
            },
            {
                "code" : "JPY",
                "name" : "Japanese yen",
                "symbol" : "¥"
            },
            {
                "code" : "ZAR",
                "name" : "South African rand",
                "symbol" : "Rs"
            },
            {
                "code" : "USD",
                "name" : "United States dollar",
                "symbol" : "$"
            },
            // {
            //     "code" : "(none)",
            //     "name" : null,
            //     "symbol" : null
            // }
        ],
        "country" : "Zimbabwe",
        "countryCallingCode" : "263"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279725",
        "region" : "Africa",
        "subregion" : "Eastern Africa",
        "latlng" : [
            -15,
            30
        ],
        "timezones" : [
            "UTC+02:00"
        ],
        "countryCode" : "ZM",
        "currencySymbol" : [
            {
                "code" : "ZMW",
                "name" : "Zambian kwacha",
                "symbol" : "ZK"
            }
        ],
        "country" : "Zambia",
        "countryCallingCode" : "260"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279724",
        "region" : "Asia",
        "subregion" : "Western Asia",
        "latlng" : [
            15,
            48
        ],
        "timezones" : [
            "UTC+03:00"
        ],
        "countryCode" : "YE",
        "currencySymbol" : [
            {
                "code" : "YER",
                "name" : "Yemeni rial",
                "symbol" : "﷼"
            }
        ],
        "country" : "Yemen",
        "countryCallingCode" : "967"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279723",
        "region" : "Africa",
        "subregion" : "Northern Africa",
        "latlng" : [
            24.5,
            -13
        ],
        "timezones" : [
            "UTC+00:00"
        ],
        "countryCode" : "EH",
        "currencySymbol" : [
            {
                "code" : "MAD",
                "name" : "Moroccan dirham",
                "symbol" : "د.م."
            },
            {
                "code" : "DZD",
                "name" : "Algerian dinar",
                "symbol" : "د.ج"
            }
        ],
        "country" : "Western Sahara",
        "countryCallingCode" : "212"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279722",
        "region" : "Oceania",
        "subregion" : "Polynesia",
        "latlng" : [
            -13.3,
            -176.2
        ],
        "timezones" : [
            "UTC+12:00"
        ],
        "countryCode" : "WF",
        "currencySymbol" : [
            {
                "code" : "XPF",
                "name" : "CFP franc",
                "symbol" : "Fr"
            }
        ],
        "country" : "Wallis and Futuna",
        "countryCallingCode" : "681"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279721",
        "region" : "Asia",
        "subregion" : "South-Eastern Asia",
        "latlng" : [
            16.16666666,
            107.83333333
        ],
        "timezones" : [
            "UTC+07:00"
        ],
        "countryCode" : "VN",
        "currencySymbol" : [
            {
                "code" : "VND",
                "name" : "Vietnamese đồng",
                "symbol" : "₫"
            }
        ],
        "country" : "Viet Nam",
        "countryCallingCode" : "84"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279720",
        "region" : "Americas",
        "subregion" : "South America",
        "latlng" : [
            8,
            -66
        ],
        "timezones" : [
            "UTC-04:00"
        ],
        "countryCode" : "VE",
        "currencySymbol" : [
            {
                "code" : "VEF",
                "name" : "Venezuelan bolívar",
                "symbol" : "Bs F"
            }
        ],
        "country" : "Venezuela (Bolivarian Republic of)",
        "countryCallingCode" : "58"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd427971f",
        "region" : "Oceania",
        "subregion" : "Melanesia",
        "latlng" : [
            -16,
            167
        ],
        "timezones" : [
            "UTC+11:00"
        ],
        "countryCode" : "VU",
        "currencySymbol" : [
            {
                "code" : "VUV",
                "name" : "Vanuatu vatu",
                "symbol" : "Vt"
            }
        ],
        "country" : "Vanuatu",
        "countryCallingCode" : "678"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd427971e",
        "region" : "Asia",
        "subregion" : "Central Asia",
        "latlng" : [
            41,
            64
        ],
        "timezones" : [
            "UTC+05:00"
        ],
        "countryCode" : "UZ",
        "currencySymbol" : [
            {
                "code" : "UZS",
                "name" : "Uzbekistani so'm",
                "symbol" : null
            }
        ],
        "country" : "Uzbekistan",
        "countryCallingCode" : "998"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd427971d",
        "region" : "Americas",
        "subregion" : "South America",
        "latlng" : [
            -33,
            -56
        ],
        "timezones" : [
            "UTC-03:00"
        ],
        "countryCode" : "UY",
        "currencySymbol" : [
            {
                "code" : "UYU",
                "name" : "Uruguayan peso",
                "symbol" : "$"
            }
        ],
        "country" : "Uruguay",
        "countryCallingCode" : "598"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd427971c",
        "region" : "Americas",
        "subregion" : "Northern America",
        "latlng" : [
            38,
            -97
        ],
        "timezones" : [
            "UTC-12:00",
            "UTC-11:00",
            "UTC-10:00",
            "UTC-09:00",
            "UTC-08:00",
            "UTC-07:00",
            "UTC-06:00",
            "UTC-05:00",
            "UTC-04:00",
            "UTC+10:00",
            "UTC+12:00"
        ],
        "countryCode" : "US",
        "currencySymbol" : [
            {
                "code" : "USD",
                "name" : "United States dollar",
                "symbol" : "$"
            }
        ],
        "country" : "United States of America",
        "countryCallingCode" : "1"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd427971b",
        "region" : "Europe",
        "subregion" : "Northern Europe",
        "latlng" : [
            54,
            -2
        ],
        "timezones" : [
            "UTC-08:00",
            "UTC-05:00",
            "UTC-04:00",
            "UTC-03:00",
            "UTC-02:00",
            "UTC",
            "UTC+01:00",
            "UTC+02:00",
            "UTC+06:00"
        ],
        "countryCode" : "GB",
        "currencySymbol" : [
            {
                "code" : "GBP",
                "name" : "British pound",
                "symbol" : "£"
            }
        ],
        "country" : "United Kingdom of Great Britain and Northern Ireland",
        "countryCallingCode" : "44"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd427971a",
        "region" : "Asia",
        "subregion" : "Western Asia",
        "latlng" : [
            24,
            54
        ],
        "timezones" : [
            "UTC+04"
        ],
        "countryCode" : "AE",
        "currencySymbol" : [
            {
                "code" : "AED",
                "name" : "United Arab Emirates dirham",
                "symbol" : "د.إ"
            }
        ],
        "country" : "United Arab Emirates",
        "countryCallingCode" : "971"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279719",
        "region" : "Europe",
        "subregion" : "Eastern Europe",
        "latlng" : [
            49,
            32
        ],
        "timezones" : [
            "UTC+02:00"
        ],
        "countryCode" : "UA",
        "currencySymbol" : [
            {
                "code" : "UAH",
                "name" : "Ukrainian hryvnia",
                "symbol" : "₴"
            }
        ],
        "country" : "Ukraine",
        "countryCallingCode" : "380"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279718",
        "region" : "Africa",
        "subregion" : "Eastern Africa",
        "latlng" : [
            1,
            32
        ],
        "timezones" : [
            "UTC+03:00"
        ],
        "countryCode" : "UG",
        "currencySymbol" : [
            {
                "code" : "UGX",
                "name" : "Ugandan shilling",
                "symbol" : "Sh"
            }
        ],
        "country" : "Uganda",
        "countryCallingCode" : "256"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279717",
        "region" : "Oceania",
        "subregion" : "Polynesia",
        "latlng" : [
            -8,
            178
        ],
        "timezones" : [
            "UTC+12:00"
        ],
        "countryCode" : "TV",
        "currencySymbol" : [
            {
                "code" : "AUD",
                "name" : "Australian dollar",
                "symbol" : "$"
            },
            {
                "code" : "TVD[G]",
                "name" : "Tuvaluan dollar",
                "symbol" : "$"
            }
        ],
        "country" : "Tuvalu",
        "countryCallingCode" : "688"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279716",
        "region" : "Americas",
        "subregion" : "Caribbean",
        "latlng" : [
            21.75,
            -71.58333333
        ],
        "timezones" : [
            "UTC-04:00"
        ],
        "countryCode" : "TC",
        "currencySymbol" : [
            {
                "code" : "USD",
                "name" : "United States dollar",
                "symbol" : "$"
            }
        ],
        "country" : "Turks and Caicos Islands",
        "countryCallingCode" : "1649"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279715",
        "region" : "Asia",
        "subregion" : "Central Asia",
        "latlng" : [
            40,
            60
        ],
        "timezones" : [
            "UTC+05:00"
        ],
        "countryCode" : "TM",
        "currencySymbol" : [
            {
                "code" : "TMT",
                "name" : "Turkmenistan manat",
                "symbol" : "m"
            }
        ],
        "country" : "Turkmenistan",
        "countryCallingCode" : "993"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279714",
        "region" : "Asia",
        "subregion" : "Western Asia",
        "latlng" : [
            39,
            35
        ],
        "timezones" : [
            "UTC+03:00"
        ],
        "countryCode" : "TR",
        "currencySymbol" : [
            {
                "code" : "TRY",
                "name" : "Turkish lira",
                "symbol" : null
            }
        ],
        "country" : "Turkey",
        "countryCallingCode" : "90"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279713",
        "region" : "Africa",
        "subregion" : "Northern Africa",
        "latlng" : [
            34,
            9
        ],
        "timezones" : [
            "UTC+01:00"
        ],
        "countryCode" : "TN",
        "currencySymbol" : [
            {
                "code" : "TND",
                "name" : "Tunisian dinar",
                "symbol" : "د.ت"
            }
        ],
        "country" : "Tunisia",
        "countryCallingCode" : "216"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279712",
        "region" : "Americas",
        "subregion" : "Caribbean",
        "latlng" : [
            11,
            -61
        ],
        "timezones" : [
            "UTC-04:00"
        ],
        "countryCode" : "TT",
        "currencySymbol" : [
            {
                "code" : "TTD",
                "name" : "Trinidad and Tobago dollar",
                "symbol" : "$"
            }
        ],
        "country" : "Trinidad and Tobago",
        "countryCallingCode" : "1868"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279711",
        "region" : "Oceania",
        "subregion" : "Polynesia",
        "latlng" : [
            -20,
            -175
        ],
        "timezones" : [
            "UTC+13:00"
        ],
        "countryCode" : "TO",
        "currencySymbol" : [
            {
                "code" : "TOP",
                "name" : "Tongan paʻanga",
                "symbol" : "T$"
            }
        ],
        "country" : "Tonga",
        "countryCallingCode" : "676"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279710",
        "region" : "Oceania",
        "subregion" : "Polynesia",
        "latlng" : [
            -9,
            -172
        ],
        "timezones" : [
            "UTC+13:00"
        ],
        "countryCode" : "TK",
        "currencySymbol" : [
            {
                "code" : "NZD",
                "name" : "New Zealand dollar",
                "symbol" : "$"
            }
        ],
        "country" : "Tokelau",
        "countryCallingCode" : "690"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd427970f",
        "region" : "Africa",
        "subregion" : "Western Africa",
        "latlng" : [
            8,
            1.16666666
        ],
        "timezones" : [
            "UTC"
        ],
        "countryCode" : "TG",
        "currencySymbol" : [
            {
                "code" : "XOF",
                "name" : "West African CFA franc",
                "symbol" : "Fr"
            }
        ],
        "country" : "Togo",
        "countryCallingCode" : "228"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd427970e",
        "region" : "Asia",
        "subregion" : "South-Eastern Asia",
        "latlng" : [
            -8.83333333,
            125.91666666
        ],
        "timezones" : [
            "UTC+09:00"
        ],
        "countryCode" : "TL",
        "currencySymbol" : [
            {
                "code" : "USD",
                "name" : "United States dollar",
                "symbol" : "$"
            },
            {
                "code" : null,
                "name" : null,
                "symbol" : null
            }
        ],
        "country" : "Timor-Leste",
        "countryCallingCode" : "670"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd427970d",
        "region" : "Asia",
        "subregion" : "South-Eastern Asia",
        "latlng" : [
            15,
            100
        ],
        "timezones" : [
            "UTC+07:00"
        ],
        "countryCode" : "TH",
        "currencySymbol" : [
            {
                "code" : "THB",
                "name" : "Thai baht",
                "symbol" : "฿"
            }
        ],
        "country" : "Thailand",
        "countryCallingCode" : "66"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd427970c",
        "region" : "Africa",
        "subregion" : "Eastern Africa",
        "latlng" : [
            -6,
            35
        ],
        "timezones" : [
            "UTC+03:00"
        ],
        "countryCode" : "TZ",
        "currencySymbol" : [
            {
                "code" : "TZS",
                "name" : "Tanzanian shilling",
                "symbol" : "Sh"
            }
        ],
        "country" : "Tanzania, United Republic of",
        "countryCallingCode" : "255"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd427970b",
        "region" : "Asia",
        "subregion" : "Central Asia",
        "latlng" : [
            39,
            71
        ],
        "timezones" : [
            "UTC+05:00"
        ],
        "countryCode" : "TJ",
        "currencySymbol" : [
            {
                "code" : "TJS",
                "name" : "Tajikistani somoni",
                "symbol" : "ЅМ"
            }
        ],
        "country" : "Tajikistan",
        "countryCallingCode" : "992"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd427970a",
        "region" : "Asia",
        "subregion" : "Eastern Asia",
        "latlng" : [
            23.5,
            121
        ],
        "timezones" : [
            "UTC+08:00"
        ],
        "countryCode" : "TW",
        "currencySymbol" : [
            {
                "code" : "TWD",
                "name" : "New Taiwan dollar",
                "symbol" : "$"
            }
        ],
        "country" : "Taiwan",
        "countryCallingCode" : "886"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279709",
        "region" : "Asia",
        "subregion" : "Western Asia",
        "latlng" : [
            35,
            38
        ],
        "timezones" : [
            "UTC+02:00"
        ],
        "countryCode" : "SY",
        "currencySymbol" : [
            {
                "code" : "SYP",
                "name" : "Syrian pound",
                "symbol" : "£"
            }
        ],
        "country" : "Syrian Arab Republic",
        "countryCallingCode" : "963"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279708",
        "region" : "Europe",
        "subregion" : "Western Europe",
        "latlng" : [
            47,
            8
        ],
        "timezones" : [
            "UTC+01:00"
        ],
        "countryCode" : "CH",
        "currencySymbol" : [
            {
                "code" : "CHF",
                "name" : "Swiss franc",
                "symbol" : "Fr"
            }
        ],
        "country" : "Switzerland",
        "countryCallingCode" : "41"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279707",
        "region" : "Europe",
        "subregion" : "Northern Europe",
        "latlng" : [
            62,
            15
        ],
        "timezones" : [
            "UTC+01:00"
        ],
        "countryCode" : "SE",
        "currencySymbol" : [
            {
                "code" : "SEK",
                "name" : "Swedish krona",
                "symbol" : "kr"
            }
        ],
        "country" : "Sweden",
        "countryCallingCode" : "46"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279706",
        "region" : "Africa",
        "subregion" : "Southern Africa",
        "latlng" : [
            -26.5,
            31.5
        ],
        "timezones" : [
            "UTC+02:00"
        ],
        "countryCode" : "SZ",
        "currencySymbol" : [
            {
                "code" : "SZL",
                "name" : "Swazi lilangeni",
                "symbol" : "L"
            }
        ],
        "country" : "Swaziland",
        "countryCallingCode" : "268"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279705",
        "region" : "Europe",
        "subregion" : "Northern Europe",
        "latlng" : [
            78,
            20
        ],
        "timezones" : [
            "UTC+01:00"
        ],
        "countryCode" : "SJ",
        "currencySymbol" : [
            {
                "code" : "NOK",
                "name" : "Norwegian krone",
                "symbol" : "kr"
            }
        ],
        "country" : "Svalbard and Jan Mayen",
        "countryCallingCode" : "4779"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279704",
        "region" : "Americas",
        "subregion" : "South America",
        "latlng" : [
            4,
            -56
        ],
        "timezones" : [
            "UTC-03:00"
        ],
        "countryCode" : "SR",
        "currencySymbol" : [
            {
                "code" : "SRD",
                "name" : "Surinamese dollar",
                "symbol" : "$"
            }
        ],
        "country" : "Suriname",
        "countryCallingCode" : "597"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279703",
        "region" : "Africa",
        "subregion" : "Northern Africa",
        "latlng" : [
            15,
            30
        ],
        "timezones" : [
            "UTC+03:00"
        ],
        "countryCode" : "SD",
        "currencySymbol" : [
            {
                "code" : "SDG",
                "name" : "Sudanese pound",
                "symbol" : "ج.س."
            }
        ],
        "country" : "Sudan",
        "countryCallingCode" : "249"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279702",
        "region" : "Asia",
        "subregion" : "Southern Asia",
        "latlng" : [
            7,
            81
        ],
        "timezones" : [
            "UTC+05:30"
        ],
        "countryCode" : "LK",
        "currencySymbol" : [
            {
                "code" : "LKR",
                "name" : "Sri Lankan rupee",
                "symbol" : "Rs"
            }
        ],
        "country" : "Sri Lanka",
        "countryCallingCode" : "94"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279701",
        "region" : "Europe",
        "subregion" : "Southern Europe",
        "latlng" : [
            40,
            -4
        ],
        "timezones" : [
            "UTC",
            "UTC+01:00"
        ],
        "countryCode" : "ES",
        "currencySymbol" : [
            {
                "code" : "EUR",
                "name" : "Euro",
                "symbol" : "€"
            }
        ],
        "country" : "Spain",
        "countryCallingCode" : "34"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279700",
        "region" : "Africa",
        "subregion" : "Middle Africa",
        "latlng" : [
            7,
            30
        ],
        "timezones" : [
            "UTC+03:00"
        ],
        "countryCode" : "SS",
        "currencySymbol" : [
            {
                "code" : "SSP",
                "name" : "South Sudanese pound",
                "symbol" : "£"
            }
        ],
        "country" : "South Sudan",
        "countryCallingCode" : "211"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796ff",
        "region" : "Asia",
        "subregion" : "Eastern Asia",
        "latlng" : [
            37,
            127.5
        ],
        "timezones" : [
            "UTC+09:00"
        ],
        "countryCode" : "KR",
        "currencySymbol" : [
            {
                "code" : "KRW",
                "name" : "South Korean won",
                "symbol" : "₩"
            }
        ],
        "country" : "Korea (Republic of)",
        "countryCallingCode" : "82"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796fe",
        "region" : "Americas",
        "subregion" : "South America",
        "latlng" : [
            -54.5,
            -37
        ],
        "timezones" : [
            "UTC-02:00"
        ],
        "countryCode" : "GS",
        "currencySymbol" : [
            {
                "code" : "GBP",
                "name" : "British pound",
                "symbol" : "£"
            },
            {
                "code" : "(none)",
                "name" : null,
                "symbol" : "£"
            }
        ],
        "country" : "South Georgia and the South Sandwich Islands",
        "countryCallingCode" : "500"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796fd",
        "region" : "Africa",
        "subregion" : "Southern Africa",
        "latlng" : [
            -29,
            24
        ],
        "timezones" : [
            "UTC+02:00"
        ],
        "countryCode" : "ZA",
        "currencySymbol" : [
            {
                "code" : "ZAR",
                "name" : "South African rand",
                "symbol" : "R"
            }
        ],
        "country" : "South Africa",
        "countryCallingCode" : "27"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796fc",
        "region" : "Africa",
        "subregion" : "Eastern Africa",
        "latlng" : [
            10,
            49
        ],
        "timezones" : [
            "UTC+03:00"
        ],
        "countryCode" : "SO",
        "currencySymbol" : [
            {
                "code" : "SOS",
                "name" : "Somali shilling",
                "symbol" : "Sh"
            }
        ],
        "country" : "Somalia",
        "countryCallingCode" : "252"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796fb",
        "region" : "Oceania",
        "subregion" : "Melanesia",
        "latlng" : [
            -8,
            159
        ],
        "timezones" : [
            "UTC+11:00"
        ],
        "countryCode" : "SB",
        "currencySymbol" : [
            {
                "code" : "SBD",
                "name" : "Solomon Islands dollar",
                "symbol" : "$"
            }
        ],
        "country" : "Solomon Islands",
        "countryCallingCode" : "677"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796fa",
        "region" : "Europe",
        "subregion" : "Southern Europe",
        "latlng" : [
            46.11666666,
            14.81666666
        ],
        "timezones" : [
            "UTC+01:00"
        ],
        "countryCode" : "SI",
        "currencySymbol" : [
            {
                "code" : "EUR",
                "name" : "Euro",
                "symbol" : "€"
            }
        ],
        "country" : "Slovenia",
        "countryCallingCode" : "386"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796f9",
        "region" : "Europe",
        "subregion" : "Eastern Europe",
        "latlng" : [
            48.66666666,
            19.5
        ],
        "timezones" : [
            "UTC+01:00"
        ],
        "countryCode" : "SK",
        "currencySymbol" : [
            {
                "code" : "EUR",
                "name" : "Euro",
                "symbol" : "€"
            }
        ],
        "country" : "Slovakia",
        "countryCallingCode" : "421"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796f8",
        "region" : "Americas",
        "subregion" : "Caribbean",
        "latlng" : [
            18.033333,
            -63.05
        ],
        "timezones" : [
            "UTC-04:00"
        ],
        "countryCode" : "SX",
        "currencySymbol" : [
            {
                "code" : "ANG",
                "name" : "Netherlands Antillean guilder",
                "symbol" : "ƒ"
            }
        ],
        "country" : "Sint Maarten (Dutch part)",
        "countryCallingCode" : "1721"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796f7",
        "region" : "Asia",
        "subregion" : "South-Eastern Asia",
        "latlng" : [
            1.36666666,
            103.8
        ],
        "timezones" : [
            "UTC+08:00"
        ],
        "countryCode" : "SG",
        "currencySymbol" : [
            {
                "code" : "BND",
                "name" : "Brunei dollar",
                "symbol" : "$"
            },
            {
                "code" : "SGD",
                "name" : "Singapore dollar",
                "symbol" : "$"
            }
        ],
        "country" : "Singapore",
        "countryCallingCode" : "65"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796f6",
        "region" : "Africa",
        "subregion" : "Western Africa",
        "latlng" : [
            8.5,
            -11.5
        ],
        "timezones" : [
            "UTC"
        ],
        "countryCode" : "SL",
        "currencySymbol" : [
            {
                "code" : "SLL",
                "name" : "Sierra Leonean leone",
                "symbol" : "Le"
            }
        ],
        "country" : "Sierra Leone",
        "countryCallingCode" : "232"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796f5",
        "region" : "Africa",
        "subregion" : "Eastern Africa",
        "latlng" : [
            -4.58333333,
            55.66666666
        ],
        "timezones" : [
            "UTC+04:00"
        ],
        "countryCode" : "SC",
        "currencySymbol" : [
            {
                "code" : "SCR",
                "name" : "Seychellois rupee",
                "symbol" : "₨"
            }
        ],
        "country" : "Seychelles",
        "countryCallingCode" : "248"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796f4",
        "region" : "Europe",
        "subregion" : "Southern Europe",
        "latlng" : [
            44,
            21
        ],
        "timezones" : [
            "UTC+01:00"
        ],
        "countryCode" : "RS",
        "currencySymbol" : [
            {
                "code" : "RSD",
                "name" : "Serbian dinar",
                "symbol" : "дин."
            }
        ],
        "country" : "Serbia",
        "countryCallingCode" : "381"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796f3",
        "region" : "Africa",
        "subregion" : "Western Africa",
        "latlng" : [
            14,
            -14
        ],
        "timezones" : [
            "UTC"
        ],
        "countryCode" : "SN",
        "currencySymbol" : [
            {
                "code" : "XOF",
                "name" : "West African CFA franc",
                "symbol" : "Fr"
            }
        ],
        "country" : "Senegal",
        "countryCallingCode" : "221"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796f2",
        "region" : "Asia",
        "subregion" : "Western Asia",
        "latlng" : [
            25,
            45
        ],
        "timezones" : [
            "UTC+03:00"
        ],
        "countryCode" : "SA",
        "currencySymbol" : [
            {
                "code" : "SAR",
                "name" : "Saudi riyal",
                "symbol" : "ر.س"
            }
        ],
        "country" : "Saudi Arabia",
        "countryCallingCode" : "966"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796f1",
        "region" : "Africa",
        "subregion" : "Middle Africa",
        "latlng" : [
            1,
            7
        ],
        "timezones" : [
            "UTC"
        ],
        "countryCode" : "ST",
        "currencySymbol" : [
            {
                "code" : "STD",
                "name" : "São Tomé and Príncipe dobra",
                "symbol" : "Db"
            }
        ],
        "country" : "Sao Tome and Principe",
        "countryCallingCode" : "239"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796f0",
        "region" : "Europe",
        "subregion" : "Southern Europe",
        "latlng" : [
            43.76666666,
            12.41666666
        ],
        "timezones" : [
            "UTC+01:00"
        ],
        "countryCode" : "SM",
        "currencySymbol" : [
            {
                "code" : "EUR",
                "name" : "Euro",
                "symbol" : "€"
            }
        ],
        "country" : "San Marino",
        "countryCallingCode" : "378"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796ef",
        "region" : "Oceania",
        "subregion" : "Polynesia",
        "latlng" : [
            -13.58333333,
            -172.33333333
        ],
        "timezones" : [
            "UTC+13:00"
        ],
        "countryCode" : "WS",
        "currencySymbol" : [
            {
                "code" : "WST",
                "name" : "Samoan tālā",
                "symbol" : "T"
            }
        ],
        "country" : "Samoa",
        "countryCallingCode" : "685"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796ee",
        "region" : "Americas",
        "subregion" : "Caribbean",
        "latlng" : [
            13.25,
            -61.2
        ],
        "timezones" : [
            "UTC-04:00"
        ],
        "countryCode" : "VC",
        "currencySymbol" : [
            {
                "code" : "XCD",
                "name" : "East Caribbean dollar",
                "symbol" : "$"
            }
        ],
        "country" : "Saint Vincent and the Grenadines",
        "countryCallingCode" : "1784"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796ed",
        "region" : "Americas",
        "subregion" : "Northern America",
        "latlng" : [
            46.83333333,
            -56.33333333
        ],
        "timezones" : [
            "UTC-03:00"
        ],
        "countryCode" : "PM",
        "currencySymbol" : [
            {
                "code" : "EUR",
                "name" : "Euro",
                "symbol" : "€"
            }
        ],
        "country" : "Saint Pierre and Miquelon",
        "countryCallingCode" : "508"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796ec",
        "region" : "Americas",
        "subregion" : "Caribbean",
        "latlng" : [
            18.08333333,
            -63.95
        ],
        "timezones" : [
            "UTC-04:00"
        ],
        "countryCode" : "MF",
        "currencySymbol" : [
            {
                "code" : "EUR",
                "name" : "Euro",
                "symbol" : "€"
            }
        ],
        "country" : "Saint Martin (French part)",
        "countryCallingCode" : "590"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796eb",
        "region" : "Americas",
        "subregion" : "Caribbean",
        "latlng" : [
            13.88333333,
            -60.96666666
        ],
        "timezones" : [
            "UTC-04:00"
        ],
        "countryCode" : "LC",
        "currencySymbol" : [
            {
                "code" : "XCD",
                "name" : "East Caribbean dollar",
                "symbol" : "$"
            }
        ],
        "country" : "Saint Lucia",
        "countryCallingCode" : "1758"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796ea",
        "region" : "Americas",
        "subregion" : "Caribbean",
        "latlng" : [
            17.33333333,
            -62.75
        ],
        "timezones" : [
            "UTC-04:00"
        ],
        "countryCode" : "KN",
        "currencySymbol" : [
            {
                "code" : "XCD",
                "name" : "East Caribbean dollar",
                "symbol" : "$"
            }
        ],
        "country" : "Saint Kitts and Nevis",
        "countryCallingCode" : "1869"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796e9",
        "region" : "Africa",
        "subregion" : "Western Africa",
        "latlng" : [
            -15.95,
            -5.7
        ],
        "timezones" : [
            "UTC+00:00"
        ],
        "countryCode" : "SH",
        "currencySymbol" : [
            {
                "code" : "SHP",
                "name" : "Saint Helena pound",
                "symbol" : "£"
            }
        ],
        "country" : "Saint Helena, Ascension and Tristan da Cunha",
        "countryCallingCode" : "290"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796e8",
        "region" : "Americas",
        "subregion" : "Caribbean",
        "latlng" : [
            18.5,
            -63.41666666
        ],
        "timezones" : [
            "UTC-04:00"
        ],
        "countryCode" : "BL",
        "currencySymbol" : [
            {
                "code" : "EUR",
                "name" : "Euro",
                "symbol" : "€"
            }
        ],
        "country" : "Saint Barthélemy",
        "countryCallingCode" : "590"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796e7",
        "region" : "Africa",
        "subregion" : "Eastern Africa",
        "latlng" : [
            -2,
            30
        ],
        "timezones" : [
            "UTC+02:00"
        ],
        "countryCode" : "RW",
        "currencySymbol" : [
            {
                "code" : "RWF",
                "name" : "Rwandan franc",
                "symbol" : "Fr"
            }
        ],
        "country" : "Rwanda",
        "countryCallingCode" : "250"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796e6",
        "region" : "Europe",
        "subregion" : "Eastern Europe",
        "latlng" : [
            60,
            100
        ],
        "timezones" : [
            "UTC+03:00",
            "UTC+04:00",
            "UTC+06:00",
            "UTC+07:00",
            "UTC+08:00",
            "UTC+09:00",
            "UTC+10:00",
            "UTC+11:00",
            "UTC+12:00"
        ],
        "countryCode" : "RU",
        "currencySymbol" : [
            {
                "code" : "RUB",
                "name" : "Russian ruble",
                "symbol" : "₽"
            }
        ],
        "country" : "Russian Federation",
        "countryCallingCode" : "7"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796e5",
        "region" : "Europe",
        "subregion" : "Eastern Europe",
        "latlng" : [
            46,
            25
        ],
        "timezones" : [
            "UTC+02:00"
        ],
        "countryCode" : "RO",
        "currencySymbol" : [
            {
                "code" : "RON",
                "name" : "Romanian leu",
                "symbol" : "lei"
            }
        ],
        "country" : "Romania",
        "countryCallingCode" : "40"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796e4",
        "region" : "Africa",
        "subregion" : "Eastern Africa",
        "latlng" : [
            -21.15,
            55.5
        ],
        "timezones" : [
            "UTC+04:00"
        ],
        "countryCode" : "RE",
        "currencySymbol" : [
            {
                "code" : "EUR",
                "name" : "Euro",
                "symbol" : "€"
            }
        ],
        "country" : "Réunion",
        "countryCallingCode" : "262"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796e3",
        "region" : "Europe",
        "subregion" : "Eastern Europe",
        "latlng" : [
            42.666667,
            21.166667
        ],
        "timezones" : [
            "UTC+01:00"
        ],
        "countryCode" : "XK",
        "currencySymbol" : [
            {
                "code" : "EUR",
                "name" : "Euro",
                "symbol" : "€"
            }
        ],
        "country" : "Republic of Kosovo",
        "countryCallingCode" : "383"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796e2",
        "region" : "Asia",
        "subregion" : "Western Asia",
        "latlng" : [
            25.5,
            51.25
        ],
        "timezones" : [
            "UTC+03:00"
        ],
        "countryCode" : "QA",
        "currencySymbol" : [
            {
                "code" : "QAR",
                "name" : "Qatari riyal",
                "symbol" : "ر.ق"
            }
        ],
        "country" : "Qatar",
        "countryCallingCode" : "974"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796e1",
        "region" : "Americas",
        "subregion" : "Caribbean",
        "latlng" : [
            18.25,
            -66.5
        ],
        "timezones" : [
            "UTC-04:00"
        ],
        "countryCode" : "PR",
        "currencySymbol" : [
            {
                "code" : "USD",
                "name" : "United States dollar",
                "symbol" : "$"
            }
        ],
        "country" : "Puerto Rico",
        "countryCallingCode" : "1787"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796e0",
        "region" : "Europe",
        "subregion" : "Southern Europe",
        "latlng" : [
            39.5,
            -8
        ],
        "timezones" : [
            "UTC-01:00",
            "UTC"
        ],
        "countryCode" : "PT",
        "currencySymbol" : [
            {
                "code" : "EUR",
                "name" : "Euro",
                "symbol" : "€"
            }
        ],
        "country" : "Portugal",
        "countryCallingCode" : "351"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796df",
        "region" : "Europe",
        "subregion" : "Eastern Europe",
        "latlng" : [
            52,
            20
        ],
        "timezones" : [
            "UTC+01:00"
        ],
        "countryCode" : "PL",
        "currencySymbol" : [
            {
                "code" : "PLN",
                "name" : "Polish złoty",
                "symbol" : "zł"
            }
        ],
        "country" : "Poland",
        "countryCallingCode" : "48"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796de",
        "region" : "Oceania",
        "subregion" : "Polynesia",
        "latlng" : [
            -25.06666666,
            -130.1
        ],
        "timezones" : [
            "UTC-08:00"
        ],
        "countryCode" : "PN",
        "currencySymbol" : [
            {
                "code" : "NZD",
                "name" : "New Zealand dollar",
                "symbol" : "$"
            },
            {
                "code" : null,
                "name" : "Pitcairn Islands dollar",
                "symbol" : "$"
            }
        ],
        "country" : "Pitcairn",
        "countryCallingCode" : "64"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796dd",
        "region" : "Asia",
        "subregion" : "South-Eastern Asia",
        "latlng" : [
            13,
            122
        ],
        "timezones" : [
            "UTC+08:00"
        ],
        "countryCode" : "PH",
        "currencySymbol" : [
            {
                "code" : "PHP",
                "name" : "Philippine peso",
                "symbol" : "₱"
            }
        ],
        "country" : "Philippines",
        "countryCallingCode" : "63"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796dc",
        "region" : "Americas",
        "subregion" : "South America",
        "latlng" : [
            -10,
            -76
        ],
        "timezones" : [
            "UTC-05:00"
        ],
        "countryCode" : "PE",
        "currencySymbol" : [
            {
                "code" : "PEN",
                "name" : "Peruvian sol",
                "symbol" : "S/."
            }
        ],
        "country" : "Peru",
        "countryCallingCode" : "51"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796db",
        "region" : "Americas",
        "subregion" : "South America",
        "latlng" : [
            -23,
            -58
        ],
        "timezones" : [
            "UTC-04:00"
        ],
        "countryCode" : "PY",
        "currencySymbol" : [
            {
                "code" : "PYG",
                "name" : "Paraguayan guaraní",
                "symbol" : "₲"
            }
        ],
        "country" : "Paraguay",
        "countryCallingCode" : "595"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796da",
        "region" : "Oceania",
        "subregion" : "Melanesia",
        "latlng" : [
            -6,
            147
        ],
        "timezones" : [
            "UTC+10:00"
        ],
        "countryCode" : "PG",
        "currencySymbol" : [
            {
                "code" : "PGK",
                "name" : "Papua New Guinean kina",
                "symbol" : "K"
            }
        ],
        "country" : "Papua New Guinea",
        "countryCallingCode" : "675"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796d9",
        "region" : "Americas",
        "subregion" : "Central America",
        "latlng" : [
            9,
            -80
        ],
        "timezones" : [
            "UTC-05:00"
        ],
        "countryCode" : "PA",
        "currencySymbol" : [
            {
                "code" : "PAB",
                "name" : "Panamanian balboa",
                "symbol" : "B/."
            },
            {
                "code" : "USD",
                "name" : "United States dollar",
                "symbol" : "$"
            }
        ],
        "country" : "Panama",
        "countryCallingCode" : "507"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796d8",
        "region" : "Asia",
        "subregion" : "Western Asia",
        "latlng" : [
            31.9,
            35.2
        ],
        "timezones" : [
            "UTC+02:00"
        ],
        "countryCode" : "PS",
        "currencySymbol" : [
            {
                "code" : "ILS",
                "name" : "Israeli new sheqel",
                "symbol" : "₪"
            }
        ],
        "country" : "Palestine, State of",
        "countryCallingCode" : "970"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796d7",
        "region" : "Oceania",
        "subregion" : "Micronesia",
        "latlng" : [
            7.5,
            134.5
        ],
        "timezones" : [
            "UTC+09:00"
        ],
        "countryCode" : "PW",
        "currencySymbol" : [
            {
                "code" : "(none)",
                "name" : "[E]",
                "symbol" : "$"
            },
            {
                "code" : "USD",
                "name" : "United States dollar",
                "symbol" : "$"
            }
        ],
        "country" : "Palau",
        "countryCallingCode" : "680"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796d6",
        "region" : "Asia",
        "subregion" : "Southern Asia",
        "latlng" : [
            30,
            70
        ],
        "timezones" : [
            "UTC+05:00"
        ],
        "countryCode" : "PK",
        "currencySymbol" : [
            {
                "code" : "PKR",
                "name" : "Pakistani rupee",
                "symbol" : "₨"
            }
        ],
        "country" : "Pakistan",
        "countryCallingCode" : "92"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796d5",
        "region" : "Asia",
        "subregion" : "Western Asia",
        "latlng" : [
            21,
            57
        ],
        "timezones" : [
            "UTC+04:00"
        ],
        "countryCode" : "OM",
        "currencySymbol" : [
            {
                "code" : "OMR",
                "name" : "Omani rial",
                "symbol" : "ر.ع."
            }
        ],
        "country" : "Oman",
        "countryCallingCode" : "968"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796d4",
        "region" : "Europe",
        "subregion" : "Northern Europe",
        "latlng" : [
            62,
            10
        ],
        "timezones" : [
            "UTC+01:00"
        ],
        "countryCode" : "NO",
        "currencySymbol" : [
            {
                "code" : "NOK",
                "name" : "Norwegian krone",
                "symbol" : "kr"
            }
        ],
        "country" : "Norway",
        "countryCallingCode" : "47"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796d3",
        "region" : "Oceania",
        "subregion" : "Micronesia",
        "latlng" : [
            15.2,
            145.75
        ],
        "timezones" : [
            "UTC+10:00"
        ],
        "countryCode" : "MP",
        "currencySymbol" : [
            {
                "code" : "USD",
                "name" : "United States dollar",
                "symbol" : "$"
            }
        ],
        "country" : "Northern Mariana Islands",
        "countryCallingCode" : "1670"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796d2",
        "region" : "Asia",
        "subregion" : "Eastern Asia",
        "latlng" : [
            40,
            127
        ],
        "timezones" : [
            "UTC+09:00"
        ],
        "countryCode" : "KP",
        "currencySymbol" : [
            {
                "code" : "KPW",
                "name" : "North Korean won",
                "symbol" : "₩"
            }
        ],
        "country" : "Korea (Democratic People's Republic of)",
        "countryCallingCode" : "850"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796d1",
        "region" : "Oceania",
        "subregion" : "Australia and New Zealand",
        "latlng" : [
            -29.03333333,
            167.95
        ],
        "timezones" : [
            "UTC+11:30"
        ],
        "countryCode" : "NF",
        "currencySymbol" : [
            {
                "code" : "AUD",
                "name" : "Australian dollar",
                "symbol" : "$"
            }
        ],
        "country" : "Norfolk Island",
        "countryCallingCode" : "672"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796d0",
        "region" : "Oceania",
        "subregion" : "Polynesia",
        "latlng" : [
            -19.03333333,
            -169.86666666
        ],
        "timezones" : [
            "UTC-11:00"
        ],
        "countryCode" : "NU",
        "currencySymbol" : [
            {
                "code" : "NZD",
                "name" : "New Zealand dollar",
                "symbol" : "$"
            },
            {
                "code" : "(none)",
                "name" : "Niue dollar",
                "symbol" : "$"
            }
        ],
        "country" : "Niue",
        "countryCallingCode" : "683"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796cf",
        "region" : "Africa",
        "subregion" : "Western Africa",
        "latlng" : [
            10,
            8
        ],
        "timezones" : [
            "UTC+01:00"
        ],
        "countryCode" : "NG",
        "currencySymbol" : [
            {
                "code" : "NGN",
                "name" : "Nigerian naira",
                "symbol" : "₦"
            }
        ],
        "country" : "Nigeria",
        "countryCallingCode" : "234"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796ce",
        "region" : "Africa",
        "subregion" : "Western Africa",
        "latlng" : [
            16,
            8
        ],
        "timezones" : [
            "UTC+01:00"
        ],
        "countryCode" : "NE",
        "currencySymbol" : [
            {
                "code" : "XOF",
                "name" : "West African CFA franc",
                "symbol" : "Fr"
            }
        ],
        "country" : "Niger",
        "countryCallingCode" : "227"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796cd",
        "region" : "Americas",
        "subregion" : "Central America",
        "latlng" : [
            13,
            -85
        ],
        "timezones" : [
            "UTC-06:00"
        ],
        "countryCode" : "NI",
        "currencySymbol" : [
            {
                "code" : "NIO",
                "name" : "Nicaraguan córdoba",
                "symbol" : "C$"
            }
        ],
        "country" : "Nicaragua",
        "countryCallingCode" : "505"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796cc",
        "region" : "Oceania",
        "subregion" : "Australia and New Zealand",
        "latlng" : [
            -41,
            174
        ],
        "timezones" : [
            "UTC-11:00",
            "UTC-10:00",
            "UTC+12:00",
            "UTC+12:45",
            "UTC+13:00"
        ],
        "countryCode" : "NZ",
        "currencySymbol" : [
            {
                "code" : "NZD",
                "name" : "New Zealand dollar",
                "symbol" : "$"
            }
        ],
        "country" : "New Zealand",
        "countryCallingCode" : "64"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796cb",
        "region" : "Oceania",
        "subregion" : "Melanesia",
        "latlng" : [
            -21.5,
            165.5
        ],
        "timezones" : [
            "UTC+11:00"
        ],
        "countryCode" : "NC",
        "currencySymbol" : [
            {
                "code" : "XPF",
                "name" : "CFP franc",
                "symbol" : "Fr"
            }
        ],
        "country" : "New Caledonia",
        "countryCallingCode" : "687"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796ca",
        "region" : "Europe",
        "subregion" : "Western Europe",
        "latlng" : [
            52.5,
            5.75
        ],
        "timezones" : [
            "UTC-04:00",
            "UTC+01:00"
        ],
        "countryCode" : "NL",
        "currencySymbol" : [
            {
                "code" : "EUR",
                "name" : "Euro",
                "symbol" : "€"
            }
        ],
        "country" : "Netherlands",
        "countryCallingCode" : "31"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796c9",
        "region" : "Asia",
        "subregion" : "Southern Asia",
        "latlng" : [
            28,
            84
        ],
        "timezones" : [
            "UTC+05:45"
        ],
        "countryCode" : "NP",
        "currencySymbol" : [
            {
                "code" : "NPR",
                "name" : "Nepalese rupee",
                "symbol" : "₨"
            }
        ],
        "country" : "Nepal",
        "countryCallingCode" : "977"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796c8",
        "region" : "Oceania",
        "subregion" : "Micronesia",
        "latlng" : [
            -0.53333333,
            166.91666666
        ],
        "timezones" : [
            "UTC+12:00"
        ],
        "countryCode" : "NR",
        "currencySymbol" : [
            {
                "code" : "AUD",
                "name" : "Australian dollar",
                "symbol" : "$"
            },
            {
                "code" : "(none)",
                "name" : null,
                "symbol" : "$"
            }
        ],
        "country" : "Nauru",
        "countryCallingCode" : "674"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796c7",
        "region" : "Africa",
        "subregion" : "Southern Africa",
        "latlng" : [
            -22,
            17
        ],
        "timezones" : [
            "UTC+01:00"
        ],
        "countryCode" : "NA",
        "currencySymbol" : [
            {
                "code" : "NAD",
                "name" : "Namibian dollar",
                "symbol" : "$"
            },
            {
                "code" : "ZAR",
                "name" : "South African rand",
                "symbol" : "R"
            }
        ],
        "country" : "Namibia",
        "countryCallingCode" : "264"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796c6",
        "region" : "Asia",
        "subregion" : "South-Eastern Asia",
        "latlng" : [
            22,
            98
        ],
        "timezones" : [
            "UTC+06:30"
        ],
        "countryCode" : "MM",
        "currencySymbol" : [
            {
                "code" : "MMK",
                "name" : "Burmese kyat",
                "symbol" : "Ks"
            }
        ],
        "country" : "Myanmar",
        "countryCallingCode" : "95"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796c5",
        "region" : "Africa",
        "subregion" : "Eastern Africa",
        "latlng" : [
            -18.25,
            35
        ],
        "timezones" : [
            "UTC+02:00"
        ],
        "countryCode" : "MZ",
        "currencySymbol" : [
            {
                "code" : "MZN",
                "name" : "Mozambican metical",
                "symbol" : "MT"
            }
        ],
        "country" : "Mozambique",
        "countryCallingCode" : "258"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796c4",
        "region" : "Africa",
        "subregion" : "Northern Africa",
        "latlng" : [
            32,
            -5
        ],
        "timezones" : [
            "UTC"
        ],
        "countryCode" : "MA",
        "currencySymbol" : [
            {
                "code" : "MAD",
                "name" : "Moroccan dirham",
                "symbol" : "د.م."
            }
        ],
        "country" : "Morocco",
        "countryCallingCode" : "212"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796c3",
        "region" : "Americas",
        "subregion" : "Caribbean",
        "latlng" : [
            16.75,
            -62.2
        ],
        "timezones" : [
            "UTC-04:00"
        ],
        "countryCode" : "MS",
        "currencySymbol" : [
            {
                "code" : "XCD",
                "name" : "East Caribbean dollar",
                "symbol" : "$"
            }
        ],
        "country" : "Montserrat",
        "countryCallingCode" : "1664"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796c2",
        "region" : "Europe",
        "subregion" : "Southern Europe",
        "latlng" : [
            42.5,
            19.3
        ],
        "timezones" : [
            "UTC+01:00"
        ],
        "countryCode" : "ME",
        "currencySymbol" : [
            {
                "code" : "EUR",
                "name" : "Euro",
                "symbol" : "€"
            }
        ],
        "country" : "Montenegro",
        "countryCallingCode" : "382"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796c1",
        "region" : "Asia",
        "subregion" : "Eastern Asia",
        "latlng" : [
            46,
            105
        ],
        "timezones" : [
            "UTC+07:00",
            "UTC+08:00"
        ],
        "countryCode" : "MN",
        "currencySymbol" : [
            {
                "code" : "MNT",
                "name" : "Mongolian tögrög",
                "symbol" : "₮"
            }
        ],
        "country" : "Mongolia",
        "countryCallingCode" : "976"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796c0",
        "region" : "Europe",
        "subregion" : "Western Europe",
        "latlng" : [
            43.73333333,
            7.4
        ],
        "timezones" : [
            "UTC+01:00"
        ],
        "countryCode" : "MC",
        "currencySymbol" : [
            {
                "code" : "EUR",
                "name" : "Euro",
                "symbol" : "€"
            }
        ],
        "country" : "Monaco",
        "countryCallingCode" : "377"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796bf",
        "region" : "Europe",
        "subregion" : "Eastern Europe",
        "latlng" : [
            47,
            29
        ],
        "timezones" : [
            "UTC+02:00"
        ],
        "countryCode" : "MD",
        "currencySymbol" : [
            {
                "code" : "MDL",
                "name" : "Moldovan leu",
                "symbol" : "L"
            }
        ],
        "country" : "Moldova (Republic of)",
        "countryCallingCode" : "373"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796be",
        "region" : "Oceania",
        "subregion" : "Micronesia",
        "latlng" : [
            6.91666666,
            158.25
        ],
        "timezones" : [
            "UTC+10:00",
            "UTC+11"
        ],
        "countryCode" : "FM",
        "currencySymbol" : [
            {
                "code" : null,
                "name" : "[D]",
                "symbol" : "$"
            },
            {
                "code" : "USD",
                "name" : "United States dollar",
                "symbol" : "$"
            }
        ],
        "country" : "Micronesia (Federated States of)",
        "countryCallingCode" : "691"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796bd",
        "region" : "Americas",
        "subregion" : "Central America",
        "latlng" : [
            23,
            -102
        ],
        "timezones" : [
            "UTC-08:00",
            "UTC-07:00",
            "UTC-06:00"
        ],
        "countryCode" : "MX",
        "currencySymbol" : [
            {
                "code" : "MXN",
                "name" : "Mexican peso",
                "symbol" : "$"
            }
        ],
        "country" : "Mexico",
        "countryCallingCode" : "52"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796bc",
        "region" : "Africa",
        "subregion" : "Eastern Africa",
        "latlng" : [
            -12.83333333,
            45.16666666
        ],
        "timezones" : [
            "UTC+03:00"
        ],
        "countryCode" : "YT",
        "currencySymbol" : [
            {
                "code" : "EUR",
                "name" : "Euro",
                "symbol" : "€"
            }
        ],
        "country" : "Mayotte",
        "countryCallingCode" : "262"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796bb",
        "region" : "Africa",
        "subregion" : "Eastern Africa",
        "latlng" : [
            -20.28333333,
            57.55
        ],
        "timezones" : [
            "UTC+04:00"
        ],
        "countryCode" : "MU",
        "currencySymbol" : [
            {
                "code" : "MUR",
                "name" : "Mauritian rupee",
                "symbol" : "₨"
            }
        ],
        "country" : "Mauritius",
        "countryCallingCode" : "230"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796ba",
        "region" : "Africa",
        "subregion" : "Western Africa",
        "latlng" : [
            20,
            -12
        ],
        "timezones" : [
            "UTC"
        ],
        "countryCode" : "MR",
        "currencySymbol" : [
            {
                "code" : "MRO",
                "name" : "Mauritanian ouguiya",
                "symbol" : "UM"
            }
        ],
        "country" : "Mauritania",
        "countryCallingCode" : "222"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796b9",
        "region" : "Americas",
        "subregion" : "Caribbean",
        "latlng" : [
            14.666667,
            -61
        ],
        "timezones" : [
            "UTC-04:00"
        ],
        "countryCode" : "MQ",
        "currencySymbol" : [
            {
                "code" : "EUR",
                "name" : "Euro",
                "symbol" : "€"
            }
        ],
        "country" : "Martinique",
        "countryCallingCode" : "596"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796b8",
        "region" : "Oceania",
        "subregion" : "Micronesia",
        "latlng" : [
            9,
            168
        ],
        "timezones" : [
            "UTC+12:00"
        ],
        "countryCode" : "MH",
        "currencySymbol" : [
            {
                "code" : "USD",
                "name" : "United States dollar",
                "symbol" : "$"
            }
        ],
        "country" : "Marshall Islands",
        "countryCallingCode" : "692"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796b7",
        "region" : "Europe",
        "subregion" : "Southern Europe",
        "latlng" : [
            35.83333333,
            14.58333333
        ],
        "timezones" : [
            "UTC+01:00"
        ],
        "countryCode" : "MT",
        "currencySymbol" : [
            {
                "code" : "EUR",
                "name" : "Euro",
                "symbol" : "€"
            }
        ],
        "country" : "Malta",
        "countryCallingCode" : "356"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796b6",
        "region" : "Africa",
        "subregion" : "Western Africa",
        "latlng" : [
            17,
            -4
        ],
        "timezones" : [
            "UTC"
        ],
        "countryCode" : "ML",
        "currencySymbol" : [
            {
                "code" : "XOF",
                "name" : "West African CFA franc",
                "symbol" : "Fr"
            }
        ],
        "country" : "Mali",
        "countryCallingCode" : "223"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796b5",
        "region" : "Asia",
        "subregion" : "Southern Asia",
        "latlng" : [
            3.25,
            73
        ],
        "timezones" : [
            "UTC+05:00"
        ],
        "countryCode" : "MV",
        "currencySymbol" : [
            {
                "code" : "MVR",
                "name" : "Maldivian rufiyaa",
                "symbol" : ".ރ"
            }
        ],
        "country" : "Maldives",
        "countryCallingCode" : "960"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796b4",
        "region" : "Asia",
        "subregion" : "South-Eastern Asia",
        "latlng" : [
            2.5,
            112.5
        ],
        "timezones" : [
            "UTC+08:00"
        ],
        "countryCode" : "MY",
        "currencySymbol" : [
            {
                "code" : "MYR",
                "name" : "Malaysian ringgit",
                "symbol" : "RM"
            }
        ],
        "country" : "Malaysia",
        "countryCallingCode" : "60"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796b3",
        "region" : "Africa",
        "subregion" : "Eastern Africa",
        "latlng" : [
            -13.5,
            34
        ],
        "timezones" : [
            "UTC+02:00"
        ],
        "countryCode" : "MW",
        "currencySymbol" : [
            {
                "code" : "MWK",
                "name" : "Malawian kwacha",
                "symbol" : "MK"
            }
        ],
        "country" : "Malawi",
        "countryCallingCode" : "265"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796b2",
        "region" : "Africa",
        "subregion" : "Eastern Africa",
        "latlng" : [
            -20,
            47
        ],
        "timezones" : [
            "UTC+03:00"
        ],
        "countryCode" : "MG",
        "currencySymbol" : [
            {
                "code" : "MGA",
                "name" : "Malagasy ariary",
                "symbol" : "Ar"
            }
        ],
        "country" : "Madagascar",
        "countryCallingCode" : "261"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796b1",
        "region" : "Europe",
        "subregion" : "Southern Europe",
        "latlng" : [
            41.83333333,
            22
        ],
        "timezones" : [
            "UTC+01:00"
        ],
        "countryCode" : "MK",
        "currencySymbol" : [
            {
                "code" : "MKD",
                "name" : "Macedonian denar",
                "symbol" : "ден"
            }
        ],
        "country" : "Macedonia (the former Yugoslav Republic of)",
        "countryCallingCode" : "389"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796b0",
        "region" : "Asia",
        "subregion" : "Eastern Asia",
        "latlng" : [
            22.16666666,
            113.55
        ],
        "timezones" : [
            "UTC+08:00"
        ],
        "countryCode" : "MO",
        "currencySymbol" : [
            {
                "code" : "MOP",
                "name" : "Macanese pataca",
                "symbol" : "P"
            }
        ],
        "country" : "Macao",
        "countryCallingCode" : "853"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796af",
        "region" : "Europe",
        "subregion" : "Western Europe",
        "latlng" : [
            49.75,
            6.16666666
        ],
        "timezones" : [
            "UTC+01:00"
        ],
        "countryCode" : "LU",
        "currencySymbol" : [
            {
                "code" : "EUR",
                "name" : "Euro",
                "symbol" : "€"
            }
        ],
        "country" : "Luxembourg",
        "countryCallingCode" : "352"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796ae",
        "region" : "Europe",
        "subregion" : "Northern Europe",
        "latlng" : [
            56,
            24
        ],
        "timezones" : [
            "UTC+02:00"
        ],
        "countryCode" : "LT",
        "currencySymbol" : [
            {
                "code" : "EUR",
                "name" : "Euro",
                "symbol" : "€"
            }
        ],
        "country" : "Lithuania",
        "countryCallingCode" : "370"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796ad",
        "region" : "Europe",
        "subregion" : "Western Europe",
        "latlng" : [
            47.26666666,
            9.53333333
        ],
        "timezones" : [
            "UTC+01:00"
        ],
        "countryCode" : "LI",
        "currencySymbol" : [
            {
                "code" : "CHF",
                "name" : "Swiss franc",
                "symbol" : "Fr"
            }
        ],
        "country" : "Liechtenstein",
        "countryCallingCode" : "423"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796ac",
        "region" : "Africa",
        "subregion" : "Northern Africa",
        "latlng" : [
            25,
            17
        ],
        "timezones" : [
            "UTC+01:00"
        ],
        "countryCode" : "LY",
        "currencySymbol" : [
            {
                "code" : "LYD",
                "name" : "Libyan dinar",
                "symbol" : "ل.د"
            }
        ],
        "country" : "Libya",
        "countryCallingCode" : "218"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796ab",
        "region" : "Africa",
        "subregion" : "Western Africa",
        "latlng" : [
            6.5,
            -9.5
        ],
        "timezones" : [
            "UTC"
        ],
        "countryCode" : "LR",
        "currencySymbol" : [
            {
                "code" : "LRD",
                "name" : "Liberian dollar",
                "symbol" : "$"
            }
        ],
        "country" : "Liberia",
        "countryCallingCode" : "231"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796aa",
        "region" : "Africa",
        "subregion" : "Southern Africa",
        "latlng" : [
            -29.5,
            28.5
        ],
        "timezones" : [
            "UTC+02:00"
        ],
        "countryCode" : "LS",
        "currencySymbol" : [
            {
                "code" : "LSL",
                "name" : "Lesotho loti",
                "symbol" : "L"
            },
            {
                "code" : "ZAR",
                "name" : "South African rand",
                "symbol" : "R"
            }
        ],
        "country" : "Lesotho",
        "countryCallingCode" : "266"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796a9",
        "region" : "Asia",
        "subregion" : "Western Asia",
        "latlng" : [
            33.83333333,
            35.83333333
        ],
        "timezones" : [
            "UTC+02:00"
        ],
        "countryCode" : "LB",
        "currencySymbol" : [
            {
                "code" : "LBP",
                "name" : "Lebanese pound",
                "symbol" : "ل.ل"
            }
        ],
        "country" : "Lebanon",
        "countryCallingCode" : "961"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796a8",
        "region" : "Europe",
        "subregion" : "Northern Europe",
        "latlng" : [
            57,
            25
        ],
        "timezones" : [
            "UTC+02:00"
        ],
        "countryCode" : "LV",
        "currencySymbol" : [
            {
                "code" : "EUR",
                "name" : "Euro",
                "symbol" : "€"
            }
        ],
        "country" : "Latvia",
        "countryCallingCode" : "371"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796a7",
        "region" : "Asia",
        "subregion" : "South-Eastern Asia",
        "latlng" : [
            18,
            105
        ],
        "timezones" : [
            "UTC+07:00"
        ],
        "countryCode" : "LA",
        "currencySymbol" : [
            {
                "code" : "LAK",
                "name" : "Lao kip",
                "symbol" : "₭"
            }
        ],
        "country" : "Lao People's Democratic Republic",
        "countryCallingCode" : "856"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796a6",
        "region" : "Asia",
        "subregion" : "Central Asia",
        "latlng" : [
            41,
            75
        ],
        "timezones" : [
            "UTC+06:00"
        ],
        "countryCode" : "KG",
        "currencySymbol" : [
            {
                "code" : "KGS",
                "name" : "Kyrgyzstani som",
                "symbol" : "с"
            }
        ],
        "country" : "Kyrgyzstan",
        "countryCallingCode" : "996"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796a5",
        "region" : "Asia",
        "subregion" : "Western Asia",
        "latlng" : [
            29.5,
            45.75
        ],
        "timezones" : [
            "UTC+03:00"
        ],
        "countryCode" : "KW",
        "currencySymbol" : [
            {
                "code" : "KWD",
                "name" : "Kuwaiti dinar",
                "symbol" : "د.ك"
            }
        ],
        "country" : "Kuwait",
        "countryCallingCode" : "965"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796a4",
        "region" : "Oceania",
        "subregion" : "Micronesia",
        "latlng" : [
            1.41666666,
            173
        ],
        "timezones" : [
            "UTC+12:00",
            "UTC+13:00",
            "UTC+14:00"
        ],
        "countryCode" : "KI",
        "currencySymbol" : [
            {
                "code" : "AUD",
                "name" : "Australian dollar",
                "symbol" : "$"
            },
            {
                "code" : "(none)",
                "name" : "Kiribati dollar",
                "symbol" : "$"
            }
        ],
        "country" : "Kiribati",
        "countryCallingCode" : "686"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796a3",
        "region" : "Africa",
        "subregion" : "Eastern Africa",
        "latlng" : [
            1,
            38
        ],
        "timezones" : [
            "UTC+03:00"
        ],
        "countryCode" : "KE",
        "currencySymbol" : [
            {
                "code" : "KES",
                "name" : "Kenyan shilling",
                "symbol" : "Sh"
            }
        ],
        "country" : "Kenya",
        "countryCallingCode" : "254"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796a2",
        "region" : "Asia",
        "subregion" : "Central Asia",
        "latlng" : [
            48,
            68
        ],
        "timezones" : [
            "UTC+05:00",
            "UTC+06:00"
        ],
        "countryCode" : "KZ",
        "currencySymbol" : [
            {
                "code" : "KZT",
                "name" : "Kazakhstani tenge",
                "symbol" : null
            }
        ],
        "country" : "Kazakhstan",
        "countryCallingCode" : "76"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796a1",
        "region" : "Asia",
        "subregion" : "Western Asia",
        "latlng" : [
            31,
            36
        ],
        "timezones" : [
            "UTC+03:00"
        ],
        "countryCode" : "JO",
        "currencySymbol" : [
            {
                "code" : "JOD",
                "name" : "Jordanian dinar",
                "symbol" : "د.ا"
            }
        ],
        "country" : "Jordan",
        "countryCallingCode" : "962"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd42796a0",
        "region" : "Europe",
        "subregion" : "Northern Europe",
        "latlng" : [
            49.25,
            -2.16666666
        ],
        "timezones" : [
            "UTC+01:00"
        ],
        "countryCode" : "JE",
        "currencySymbol" : [
            {
                "code" : "GBP",
                "name" : "British pound",
                "symbol" : "£"
            },
            {
                "code" : "JEP[G]",
                "name" : "Jersey pound",
                "symbol" : "£"
            }
        ],
        "country" : "Jersey",
        "countryCallingCode" : "44"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd427969f",
        "region" : "Asia",
        "subregion" : "Eastern Asia",
        "latlng" : [
            36,
            138
        ],
        "timezones" : [
            "UTC+09:00"
        ],
        "countryCode" : "JP",
        "currencySymbol" : [
            {
                "code" : "JPY",
                "name" : "Japanese yen",
                "symbol" : "¥"
            }
        ],
        "country" : "Japan",
        "countryCallingCode" : "81"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd427969e",
        "region" : "Americas",
        "subregion" : "Caribbean",
        "latlng" : [
            18.25,
            -77.5
        ],
        "timezones" : [
            "UTC-05:00"
        ],
        "countryCode" : "JM",
        "currencySymbol" : [
            {
                "code" : "JMD",
                "name" : "Jamaican dollar",
                "symbol" : "$"
            }
        ],
        "country" : "Jamaica",
        "countryCallingCode" : "1876"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd427969d",
        "region" : "Europe",
        "subregion" : "Southern Europe",
        "latlng" : [
            42.83333333,
            12.83333333
        ],
        "timezones" : [
            "UTC+01:00"
        ],
        "countryCode" : "IT",
        "currencySymbol" : [
            {
                "code" : "EUR",
                "name" : "Euro",
                "symbol" : "€"
            }
        ],
        "country" : "Italy",
        "countryCallingCode" : "39"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd427969c",
        "region" : "Asia",
        "subregion" : "Western Asia",
        "latlng" : [
            31.5,
            34.75
        ],
        "timezones" : [
            "UTC+02:00"
        ],
        "countryCode" : "IL",
        "currencySymbol" : [
            {
                "code" : "ILS",
                "name" : "Israeli new shekel",
                "symbol" : "₪"
            }
        ],
        "country" : "Israel",
        "countryCallingCode" : "972"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd427969b",
        "region" : "Europe",
        "subregion" : "Northern Europe",
        "latlng" : [
            54.25,
            -4.5
        ],
        "timezones" : [
            "UTC+00:00"
        ],
        "countryCode" : "IM",
        "currencySymbol" : [
            {
                "code" : "GBP",
                "name" : "British pound",
                "symbol" : "£"
            },
            {
                "code" : "IMP[G]",
                "name" : "Manx pound",
                "symbol" : "£"
            }
        ],
        "country" : "Isle of Man",
        "countryCallingCode" : "44"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd427969a",
        "region" : "Europe",
        "subregion" : "Northern Europe",
        "latlng" : [
            53,
            -8
        ],
        "timezones" : [
            "UTC"
        ],
        "countryCode" : "IE",
        "currencySymbol" : [
            {
                "code" : "EUR",
                "name" : "Euro",
                "symbol" : "€"
            }
        ],
        "country" : "Ireland",
        "countryCallingCode" : "353"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279699",
        "region" : "Asia",
        "subregion" : "Western Asia",
        "latlng" : [
            33,
            44
        ],
        "timezones" : [
            "UTC+03:00"
        ],
        "countryCode" : "IQ",
        "currencySymbol" : [
            {
                "code" : "IQD",
                "name" : "Iraqi dinar",
                "symbol" : "ع.د"
            }
        ],
        "country" : "Iraq",
        "countryCallingCode" : "964"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279698",
        "region" : "Asia",
        "subregion" : "Southern Asia",
        "latlng" : [
            32,
            53
        ],
        "timezones" : [
            "UTC+03:30"
        ],
        "countryCode" : "IR",
        "currencySymbol" : [
            {
                "code" : "IRR",
                "name" : "Iranian rial",
                "symbol" : "﷼"
            }
        ],
        "country" : "Iran (Islamic Republic of)",
        "countryCallingCode" : "98"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279697",
        "region" : "Africa",
        "subregion" : "Western Africa",
        "latlng" : [
            8,
            -5
        ],
        "timezones" : [
            "UTC"
        ],
        "countryCode" : "CI",
        "currencySymbol" : [
            {
                "code" : "XOF",
                "name" : "West African CFA franc",
                "symbol" : "Fr"
            }
        ],
        "country" : "Côte d'Ivoire",
        "countryCallingCode" : "225"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279696",
        "region" : "Asia",
        "subregion" : "South-Eastern Asia",
        "latlng" : [
            -5,
            120
        ],
        "timezones" : [
            "UTC+07:00",
            "UTC+08:00",
            "UTC+09:00"
        ],
        "countryCode" : "ID",
        "currencySymbol" : [
            {
                "code" : "IDR",
                "name" : "Indonesian rupiah",
                "symbol" : "Rp"
            }
        ],
        "country" : "Indonesia",
        "countryCallingCode" : "62"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279695",
        "region" : "Asia",
        "subregion" : "Southern Asia",
        "latlng" : [
            20,
            77
        ],
        "timezones" : [
            "UTC+05:30"
        ],
        "countryCode" : "IN",
        "currencySymbol" : [
            {
                "code" : "INR",
                "name" : "Indian rupee",
                "symbol" : "₹"
            }
        ],
        "country" : "India",
        "countryCallingCode" : "91"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279694",
        "region" : "Europe",
        "subregion" : "Northern Europe",
        "latlng" : [
            65,
            -18
        ],
        "timezones" : [
            "UTC"
        ],
        "countryCode" : "IS",
        "currencySymbol" : [
            {
                "code" : "ISK",
                "name" : "Icelandic króna",
                "symbol" : "kr"
            }
        ],
        "country" : "Iceland",
        "countryCallingCode" : "354"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279693",
        "region" : "Europe",
        "subregion" : "Eastern Europe",
        "latlng" : [
            47,
            20
        ],
        "timezones" : [
            "UTC+01:00"
        ],
        "countryCode" : "HU",
        "currencySymbol" : [
            {
                "code" : "HUF",
                "name" : "Hungarian forint",
                "symbol" : "Ft"
            }
        ],
        "country" : "Hungary",
        "countryCallingCode" : "36"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279692",
        "region" : "Asia",
        "subregion" : "Eastern Asia",
        "latlng" : [
            22.25,
            114.16666666
        ],
        "timezones" : [
            "UTC+08:00"
        ],
        "countryCode" : "HK",
        "currencySymbol" : [
            {
                "code" : "HKD",
                "name" : "Hong Kong dollar",
                "symbol" : "$"
            }
        ],
        "country" : "Hong Kong",
        "countryCallingCode" : "852"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279691",
        "region" : "Americas",
        "subregion" : "Central America",
        "latlng" : [
            15,
            -86.5
        ],
        "timezones" : [
            "UTC-06:00"
        ],
        "countryCode" : "HN",
        "currencySymbol" : [
            {
                "code" : "HNL",
                "name" : "Honduran lempira",
                "symbol" : "L"
            }
        ],
        "country" : "Honduras",
        "countryCallingCode" : "504"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279690",
        "region" : "Europe",
        "subregion" : "Southern Europe",
        "latlng" : [
            41.9,
            12.45
        ],
        "timezones" : [
            "UTC+01:00"
        ],
        "countryCode" : "VA",
        "currencySymbol" : [
            {
                "code" : "EUR",
                "name" : "Euro",
                "symbol" : "€"
            }
        ],
        "country" : "Holy See",
        "countryCallingCode" : "379"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd427968f",
        "region" : "",
        "subregion" : "",
        "latlng" : [
            -53.1,
            72.51666666
        ],
        "timezones" : [
            "UTC+05:00"
        ],
        "countryCode" : "HM",
        "currencySymbol" : [
            {
                "code" : "AUD",
                "name" : "Australian dollar",
                "symbol" : "$"
            }
        ],
        "country" : "Heard Island and McDonald Islands",
        "countryCallingCode" : ""
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd427968e",
        "region" : "Americas",
        "subregion" : "Caribbean",
        "latlng" : [
            19,
            -72.41666666
        ],
        "timezones" : [
            "UTC-05:00"
        ],
        "countryCode" : "HT",
        "currencySymbol" : [
            {
                "code" : "HTG",
                "name" : "Haitian gourde",
                "symbol" : "G"
            }
        ],
        "country" : "Haiti",
        "countryCallingCode" : "509"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd427968d",
        "region" : "Americas",
        "subregion" : "South America",
        "latlng" : [
            5,
            -59
        ],
        "timezones" : [
            "UTC-04:00"
        ],
        "countryCode" : "GY",
        "currencySymbol" : [
            {
                "code" : "GYD",
                "name" : "Guyanese dollar",
                "symbol" : "$"
            }
        ],
        "country" : "Guyana",
        "countryCallingCode" : "592"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd427968c",
        "region" : "Africa",
        "subregion" : "Western Africa",
        "latlng" : [
            12,
            -15
        ],
        "timezones" : [
            "UTC"
        ],
        "countryCode" : "GW",
        "currencySymbol" : [
            {
                "code" : "XOF",
                "name" : "West African CFA franc",
                "symbol" : "Fr"
            }
        ],
        "country" : "Guinea-Bissau",
        "countryCallingCode" : "245"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd427968b",
        "region" : "Africa",
        "subregion" : "Western Africa",
        "latlng" : [
            11,
            -10
        ],
        "timezones" : [
            "UTC"
        ],
        "countryCode" : "GN",
        "currencySymbol" : [
            {
                "code" : "GNF",
                "name" : "Guinean franc",
                "symbol" : "Fr"
            }
        ],
        "country" : "Guinea",
        "countryCallingCode" : "224"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd427968a",
        "region" : "Europe",
        "subregion" : "Northern Europe",
        "latlng" : [
            49.46666666,
            -2.58333333
        ],
        "timezones" : [
            "UTC+00:00"
        ],
        "countryCode" : "GG",
        "currencySymbol" : [
            {
                "code" : "GBP",
                "name" : "British pound",
                "symbol" : "£"
            },
            {
                "code" : "(none)",
                "name" : "Guernsey pound",
                "symbol" : "£"
            }
        ],
        "country" : "Guernsey",
        "countryCallingCode" : "44"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279689",
        "region" : "Americas",
        "subregion" : "Central America",
        "latlng" : [
            15.5,
            -90.25
        ],
        "timezones" : [
            "UTC-06:00"
        ],
        "countryCode" : "GT",
        "currencySymbol" : [
            {
                "code" : "GTQ",
                "name" : "Guatemalan quetzal",
                "symbol" : "Q"
            }
        ],
        "country" : "Guatemala",
        "countryCallingCode" : "502"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279688",
        "region" : "Oceania",
        "subregion" : "Micronesia",
        "latlng" : [
            13.46666666,
            144.78333333
        ],
        "timezones" : [
            "UTC+10:00"
        ],
        "countryCode" : "GU",
        "currencySymbol" : [
            {
                "code" : "USD",
                "name" : "United States dollar",
                "symbol" : "$"
            }
        ],
        "country" : "Guam",
        "countryCallingCode" : "1671"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279687",
        "region" : "Americas",
        "subregion" : "Caribbean",
        "latlng" : [
            16.25,
            -61.583333
        ],
        "timezones" : [
            "UTC-04:00"
        ],
        "countryCode" : "GP",
        "currencySymbol" : [
            {
                "code" : "EUR",
                "name" : "Euro",
                "symbol" : "€"
            }
        ],
        "country" : "Guadeloupe",
        "countryCallingCode" : "590"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279686",
        "region" : "Americas",
        "subregion" : "Caribbean",
        "latlng" : [
            12.11666666,
            -61.66666666
        ],
        "timezones" : [
            "UTC-04:00"
        ],
        "countryCode" : "GD",
        "currencySymbol" : [
            {
                "code" : "XCD",
                "name" : "East Caribbean dollar",
                "symbol" : "$"
            }
        ],
        "country" : "Grenada",
        "countryCallingCode" : "1473"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279685",
        "region" : "Americas",
        "subregion" : "Northern America",
        "latlng" : [
            72,
            -40
        ],
        "timezones" : [
            "UTC-04:00",
            "UTC-03:00",
            "UTC-01:00",
            "UTC+00:00"
        ],
        "countryCode" : "GL",
        "currencySymbol" : [
            {
                "code" : "DKK",
                "name" : "Danish krone",
                "symbol" : "kr"
            }
        ],
        "country" : "Greenland",
        "countryCallingCode" : "299"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279684",
        "region" : "Europe",
        "subregion" : "Southern Europe",
        "latlng" : [
            39,
            22
        ],
        "timezones" : [
            "UTC+02:00"
        ],
        "countryCode" : "GR",
        "currencySymbol" : [
            {
                "code" : "EUR",
                "name" : "Euro",
                "symbol" : "€"
            }
        ],
        "country" : "Greece",
        "countryCallingCode" : "30"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279683",
        "region" : "Europe",
        "subregion" : "Southern Europe",
        "latlng" : [
            36.13333333,
            -5.35
        ],
        "timezones" : [
            "UTC+01:00"
        ],
        "countryCode" : "GI",
        "currencySymbol" : [
            {
                "code" : "GIP",
                "name" : "Gibraltar pound",
                "symbol" : "£"
            }
        ],
        "country" : "Gibraltar",
        "countryCallingCode" : "350"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279682",
        "region" : "Africa",
        "subregion" : "Western Africa",
        "latlng" : [
            8,
            -2
        ],
        "timezones" : [
            "UTC"
        ],
        "countryCode" : "GH",
        "currencySymbol" : [
            {
                "code" : "GHS",
                "name" : "Ghanaian cedi",
                "symbol" : "₵"
            }
        ],
        "country" : "Ghana",
        "countryCallingCode" : "233"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279681",
        "region" : "Europe",
        "subregion" : "Western Europe",
        "latlng" : [
            51,
            9
        ],
        "timezones" : [
            "UTC+01:00"
        ],
        "countryCode" : "DE",
        "currencySymbol" : [
            {
                "code" : "EUR",
                "name" : "Euro",
                "symbol" : "€"
            }
        ],
        "country" : "Germany",
        "countryCallingCode" : "49"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279680",
        "region" : "Asia",
        "subregion" : "Western Asia",
        "latlng" : [
            42,
            43.5
        ],
        "timezones" : [
            "UTC-05:00"
        ],
        "countryCode" : "GE",
        "currencySymbol" : [
            {
                "code" : "GEL",
                "name" : "Georgian Lari",
                "symbol" : "ლ"
            }
        ],
        "country" : "Georgia",
        "countryCallingCode" : "995"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd427967f",
        "region" : "Africa",
        "subregion" : "Western Africa",
        "latlng" : [
            13.46666666,
            -16.56666666
        ],
        "timezones" : [
            "UTC+00:00"
        ],
        "countryCode" : "GM",
        "currencySymbol" : [
            {
                "code" : "GMD",
                "name" : "Gambian dalasi",
                "symbol" : "D"
            }
        ],
        "country" : "Gambia",
        "countryCallingCode" : "220"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd427967e",
        "region" : "Africa",
        "subregion" : "Middle Africa",
        "latlng" : [
            -1,
            11.75
        ],
        "timezones" : [
            "UTC+01:00"
        ],
        "countryCode" : "GA",
        "currencySymbol" : [
            {
                "code" : "XAF",
                "name" : "Central African CFA franc",
                "symbol" : "Fr"
            }
        ],
        "country" : "Gabon",
        "countryCallingCode" : "241"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd427967d",
        "region" : "Africa",
        "subregion" : "Southern Africa",
        "latlng" : [
            -49.25,
            69.167
        ],
        "timezones" : [
            "UTC+05:00"
        ],
        "countryCode" : "TF",
        "currencySymbol" : [
            {
                "code" : "EUR",
                "name" : "Euro",
                "symbol" : "€"
            }
        ],
        "country" : "French Southern Territories",
        "countryCallingCode" : ""
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd427967c",
        "region" : "Oceania",
        "subregion" : "Polynesia",
        "latlng" : [
            -15,
            -140
        ],
        "timezones" : [
            "UTC-10:00",
            "UTC-09:30",
            "UTC-09:00"
        ],
        "countryCode" : "PF",
        "currencySymbol" : [
            {
                "code" : "XPF",
                "name" : "CFP franc",
                "symbol" : "Fr"
            }
        ],
        "country" : "French Polynesia",
        "countryCallingCode" : "689"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd427967b",
        "region" : "Americas",
        "subregion" : "South America",
        "latlng" : [
            4,
            -53
        ],
        "timezones" : [
            "UTC-03:00"
        ],
        "countryCode" : "GF",
        "currencySymbol" : [
            {
                "code" : "EUR",
                "name" : "Euro",
                "symbol" : "€"
            }
        ],
        "country" : "French Guiana",
        "countryCallingCode" : "594"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd427967a",
        "region" : "Europe",
        "subregion" : "Western Europe",
        "latlng" : [
            46,
            2
        ],
        "timezones" : [
            "UTC-10:00",
            "UTC-09:30",
            "UTC-09:00",
            "UTC-08:00",
            "UTC-04:00",
            "UTC-03:00",
            "UTC+01:00",
            "UTC+03:00",
            "UTC+04:00",
            "UTC+05:00",
            "UTC+11:00",
            "UTC+12:00"
        ],
        "countryCode" : "FR",
        "currencySymbol" : [
            {
                "code" : "EUR",
                "name" : "Euro",
                "symbol" : "€"
            }
        ],
        "country" : "France",
        "countryCallingCode" : "33"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279679",
        "region" : "Europe",
        "subregion" : "Northern Europe",
        "latlng" : [
            64,
            26
        ],
        "timezones" : [
            "UTC+02:00"
        ],
        "countryCode" : "FI",
        "currencySymbol" : [
            {
                "code" : "EUR",
                "name" : "Euro",
                "symbol" : "€"
            }
        ],
        "country" : "Finland",
        "countryCallingCode" : "358"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279678",
        "region" : "Oceania",
        "subregion" : "Melanesia",
        "latlng" : [
            -18,
            175
        ],
        "timezones" : [
            "UTC+12:00"
        ],
        "countryCode" : "FJ",
        "currencySymbol" : [
            {
                "code" : "FJD",
                "name" : "Fijian dollar",
                "symbol" : "$"
            }
        ],
        "country" : "Fiji",
        "countryCallingCode" : "679"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279677",
        "region" : "Europe",
        "subregion" : "Northern Europe",
        "latlng" : [
            62,
            -7
        ],
        "timezones" : [
            "UTC+00:00"
        ],
        "countryCode" : "FO",
        "currencySymbol" : [
            {
                "code" : "DKK",
                "name" : "Danish krone",
                "symbol" : "kr"
            },
            {
                "code" : "(none)",
                "name" : "Faroese króna",
                "symbol" : "kr"
            }
        ],
        "country" : "Faroe Islands",
        "countryCallingCode" : "298"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279676",
        "region" : "Americas",
        "subregion" : "South America",
        "latlng" : [
            -51.75,
            -59
        ],
        "timezones" : [
            "UTC-04:00"
        ],
        "countryCode" : "FK",
        "currencySymbol" : [
            {
                "code" : "FKP",
                "name" : "Falkland Islands pound",
                "symbol" : "£"
            }
        ],
        "country" : "Falkland Islands (Malvinas)",
        "countryCallingCode" : "500"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279675",
        "region" : "Africa",
        "subregion" : "Eastern Africa",
        "latlng" : [
            8,
            38
        ],
        "timezones" : [
            "UTC+03:00"
        ],
        "countryCode" : "ET",
        "currencySymbol" : [
            {
                "code" : "ETB",
                "name" : "Ethiopian birr",
                "symbol" : "Br"
            }
        ],
        "country" : "Ethiopia",
        "countryCallingCode" : "251"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279674",
        "region" : "Europe",
        "subregion" : "Northern Europe",
        "latlng" : [
            59,
            26
        ],
        "timezones" : [
            "UTC+02:00"
        ],
        "countryCode" : "EE",
        "currencySymbol" : [
            {
                "code" : "EUR",
                "name" : "Euro",
                "symbol" : "€"
            }
        ],
        "country" : "Estonia",
        "countryCallingCode" : "372"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279673",
        "region" : "Africa",
        "subregion" : "Eastern Africa",
        "latlng" : [
            15,
            39
        ],
        "timezones" : [
            "UTC+03:00"
        ],
        "countryCode" : "ER",
        "currencySymbol" : [
            {
                "code" : "ERN",
                "name" : "Eritrean nakfa",
                "symbol" : "Nfk"
            }
        ],
        "country" : "Eritrea",
        "countryCallingCode" : "291"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279672",
        "region" : "Africa",
        "subregion" : "Middle Africa",
        "latlng" : [
            2,
            10
        ],
        "timezones" : [
            "UTC+01:00"
        ],
        "countryCode" : "GQ",
        "currencySymbol" : [
            {
                "code" : "XAF",
                "name" : "Central African CFA franc",
                "symbol" : "Fr"
            }
        ],
        "country" : "Equatorial Guinea",
        "countryCallingCode" : "240"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279671",
        "region" : "Americas",
        "subregion" : "Central America",
        "latlng" : [
            13.83333333,
            -88.91666666
        ],
        "timezones" : [
            "UTC-06:00"
        ],
        "countryCode" : "SV",
        "currencySymbol" : [
            {
                "code" : "USD",
                "name" : "United States dollar",
                "symbol" : "$"
            }
        ],
        "country" : "El Salvador",
        "countryCallingCode" : "503"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279670",
        "region" : "Africa",
        "subregion" : "Northern Africa",
        "latlng" : [
            27,
            30
        ],
        "timezones" : [
            "UTC+02:00"
        ],
        "countryCode" : "EG",
        "currencySymbol" : [
            {
                "code" : "EGP",
                "name" : "Egyptian pound",
                "symbol" : "£"
            }
        ],
        "country" : "Egypt",
        "countryCallingCode" : "20"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd427966f",
        "region" : "Americas",
        "subregion" : "South America",
        "latlng" : [
            -2,
            -77.5
        ],
        "timezones" : [
            "UTC-06:00",
            "UTC-05:00"
        ],
        "countryCode" : "EC",
        "currencySymbol" : [
            {
                "code" : "USD",
                "name" : "United States dollar",
                "symbol" : "$"
            }
        ],
        "country" : "Ecuador",
        "countryCallingCode" : "593"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd427966e",
        "region" : "Americas",
        "subregion" : "Caribbean",
        "latlng" : [
            19,
            -70.66666666
        ],
        "timezones" : [
            "UTC-04:00"
        ],
        "countryCode" : "DO",
        "currencySymbol" : [
            {
                "code" : "DOP",
                "name" : "Dominican peso",
                "symbol" : "$"
            }
        ],
        "country" : "Dominican Republic",
        "countryCallingCode" : "1809"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd427966d",
        "region" : "Americas",
        "subregion" : "Caribbean",
        "latlng" : [
            15.41666666,
            -61.33333333
        ],
        "timezones" : [
            "UTC-04:00"
        ],
        "countryCode" : "DM",
        "currencySymbol" : [
            {
                "code" : "XCD",
                "name" : "East Caribbean dollar",
                "symbol" : "$"
            }
        ],
        "country" : "Dominica",
        "countryCallingCode" : "1767"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd427966c",
        "region" : "Africa",
        "subregion" : "Eastern Africa",
        "latlng" : [
            11.5,
            43
        ],
        "timezones" : [
            "UTC+03:00"
        ],
        "countryCode" : "DJ",
        "currencySymbol" : [
            {
                "code" : "DJF",
                "name" : "Djiboutian franc",
                "symbol" : "Fr"
            }
        ],
        "country" : "Djibouti",
        "countryCallingCode" : "253"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd427966b",
        "region" : "Europe",
        "subregion" : "Northern Europe",
        "latlng" : [
            56,
            10
        ],
        "timezones" : [
            "UTC-04:00",
            "UTC-03:00",
            "UTC-01:00",
            "UTC",
            "UTC+01:00"
        ],
        "countryCode" : "DK",
        "currencySymbol" : [
            {
                "code" : "DKK",
                "name" : "Danish krone",
                "symbol" : "kr"
            }
        ],
        "country" : "Denmark",
        "countryCallingCode" : "45"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd427966a",
        "region" : "Europe",
        "subregion" : "Eastern Europe",
        "latlng" : [
            49.75,
            15.5
        ],
        "timezones" : [
            "UTC+01:00"
        ],
        "countryCode" : "CZ",
        "currencySymbol" : [
            {
                "code" : "CZK",
                "name" : "Czech koruna",
                "symbol" : "Kč"
            }
        ],
        "country" : "Czech Republic",
        "countryCallingCode" : "420"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279669",
        "region" : "Europe",
        "subregion" : "Southern Europe",
        "latlng" : [
            35,
            33
        ],
        "timezones" : [
            "UTC+02:00"
        ],
        "countryCode" : "CY",
        "currencySymbol" : [
            {
                "code" : "EUR",
                "name" : "Euro",
                "symbol" : "€"
            }
        ],
        "country" : "Cyprus",
        "countryCallingCode" : "357"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279668",
        "region" : "Americas",
        "subregion" : "Caribbean",
        "latlng" : [
            12.116667,
            -68.933333
        ],
        "timezones" : [
            "UTC-04:00"
        ],
        "countryCode" : "CW",
        "currencySymbol" : [
            {
                "code" : "ANG",
                "name" : "Netherlands Antillean guilder",
                "symbol" : "ƒ"
            }
        ],
        "country" : "Curaçao",
        "countryCallingCode" : "599"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279667",
        "region" : "Americas",
        "subregion" : "Caribbean",
        "latlng" : [
            21.5,
            -80
        ],
        "timezones" : [
            "UTC-05:00"
        ],
        "countryCode" : "CU",
        "currencySymbol" : [
            {
                "code" : "CUC",
                "name" : "Cuban convertible peso",
                "symbol" : "$"
            },
            {
                "code" : "CUP",
                "name" : "Cuban peso",
                "symbol" : "$"
            }
        ],
        "country" : "Cuba",
        "countryCallingCode" : "53"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279666",
        "region" : "Europe",
        "subregion" : "Southern Europe",
        "latlng" : [
            45.16666666,
            15.5
        ],
        "timezones" : [
            "UTC+01:00"
        ],
        "countryCode" : "HR",
        "currencySymbol" : [
            {
                "code" : "HRK",
                "name" : "Croatian kuna",
                "symbol" : "kn"
            }
        ],
        "country" : "Croatia",
        "countryCallingCode" : "385"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279665",
        "region" : "Americas",
        "subregion" : "Central America",
        "latlng" : [
            10,
            -84
        ],
        "timezones" : [
            "UTC-06:00"
        ],
        "countryCode" : "CR",
        "currencySymbol" : [
            {
                "code" : "CRC",
                "name" : "Costa Rican colón",
                "symbol" : "₡"
            }
        ],
        "country" : "Costa Rica",
        "countryCallingCode" : "506"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279664",
        "region" : "Oceania",
        "subregion" : "Polynesia",
        "latlng" : [
            -21.23333333,
            -159.76666666
        ],
        "timezones" : [
            "UTC-10:00"
        ],
        "countryCode" : "CK",
        "currencySymbol" : [
            {
                "code" : "NZD",
                "name" : "New Zealand dollar",
                "symbol" : "$"
            },
            {
                "code" : "CKD",
                "name" : "Cook Islands dollar",
                "symbol" : "$"
            }
        ],
        "country" : "Cook Islands",
        "countryCallingCode" : "682"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279663",
        "region" : "Africa",
        "subregion" : "Middle Africa",
        "latlng" : [
            0,
            25
        ],
        "timezones" : [
            "UTC+01:00",
            "UTC+02:00"
        ],
        "countryCode" : "CD",
        "currencySymbol" : [
            {
                "code" : "CDF",
                "name" : "Congolese franc",
                "symbol" : "Fr"
            }
        ],
        "country" : "Congo (Democratic Republic of the)",
        "countryCallingCode" : "243"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279662",
        "region" : "Africa",
        "subregion" : "Middle Africa",
        "latlng" : [
            -1,
            15
        ],
        "timezones" : [
            "UTC+01:00"
        ],
        "countryCode" : "CG",
        "currencySymbol" : [
            {
                "code" : "XAF",
                "name" : "Central African CFA franc",
                "symbol" : "Fr"
            }
        ],
        "country" : "Congo",
        "countryCallingCode" : "242"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279661",
        "region" : "Africa",
        "subregion" : "Eastern Africa",
        "latlng" : [
            -12.16666666,
            44.25
        ],
        "timezones" : [
            "UTC+03:00"
        ],
        "countryCode" : "KM",
        "currencySymbol" : [
            {
                "code" : "KMF",
                "name" : "Comorian franc",
                "symbol" : "Fr"
            }
        ],
        "country" : "Comoros",
        "countryCallingCode" : "269"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279660",
        "region" : "Americas",
        "subregion" : "South America",
        "latlng" : [
            4,
            -72
        ],
        "timezones" : [
            "UTC-05:00"
        ],
        "countryCode" : "CO",
        "currencySymbol" : [
            {
                "code" : "COP",
                "name" : "Colombian peso",
                "symbol" : "$"
            }
        ],
        "country" : "Colombia",
        "countryCallingCode" : "57"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd427965f",
        "region" : "Oceania",
        "subregion" : "Australia and New Zealand",
        "latlng" : [
            -12.5,
            96.83333333
        ],
        "timezones" : [
            "UTC+06:30"
        ],
        "countryCode" : "CC",
        "currencySymbol" : [
            {
                "code" : "AUD",
                "name" : "Australian dollar",
                "symbol" : "$"
            }
        ],
        "country" : "Cocos (Keeling) Islands",
        "countryCallingCode" : "61"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd427965e",
        "region" : "Oceania",
        "subregion" : "Australia and New Zealand",
        "latlng" : [
            -10.5,
            105.66666666
        ],
        "timezones" : [
            "UTC+07:00"
        ],
        "countryCode" : "CX",
        "currencySymbol" : [
            {
                "code" : "AUD",
                "name" : "Australian dollar",
                "symbol" : "$"
            }
        ],
        "country" : "Christmas Island",
        "countryCallingCode" : "61"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd427965d",
        "region" : "Asia",
        "subregion" : "Eastern Asia",
        "latlng" : [
            35,
            105
        ],
        "timezones" : [
            "UTC+08:00"
        ],
        "countryCode" : "CN",
        "currencySymbol" : [
            {
                "code" : "CNY",
                "name" : "Chinese yuan",
                "symbol" : "¥"
            }
        ],
        "country" : "China",
        "countryCallingCode" : "86"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd427965c",
        "region" : "Americas",
        "subregion" : "South America",
        "latlng" : [
            -30,
            -71
        ],
        "timezones" : [
            "UTC-06:00",
            "UTC-04:00"
        ],
        "countryCode" : "CL",
        "currencySymbol" : [
            {
                "code" : "CLP",
                "name" : "Chilean peso",
                "symbol" : "$"
            }
        ],
        "country" : "Chile",
        "countryCallingCode" : "56"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd427965b",
        "region" : "Africa",
        "subregion" : "Middle Africa",
        "latlng" : [
            15,
            19
        ],
        "timezones" : [
            "UTC+01:00"
        ],
        "countryCode" : "TD",
        "currencySymbol" : [
            {
                "code" : "XAF",
                "name" : "Central African CFA franc",
                "symbol" : "Fr"
            }
        ],
        "country" : "Chad",
        "countryCallingCode" : "235"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd427965a",
        "region" : "Africa",
        "subregion" : "Middle Africa",
        "latlng" : [
            7,
            21
        ],
        "timezones" : [
            "UTC+01:00"
        ],
        "countryCode" : "CF",
        "currencySymbol" : [
            {
                "code" : "XAF",
                "name" : "Central African CFA franc",
                "symbol" : "Fr"
            }
        ],
        "country" : "Central African Republic",
        "countryCallingCode" : "236"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279659",
        "region" : "Americas",
        "subregion" : "Caribbean",
        "latlng" : [
            19.5,
            -80.5
        ],
        "timezones" : [
            "UTC-05:00"
        ],
        "countryCode" : "KY",
        "currencySymbol" : [
            {
                "code" : "KYD",
                "name" : "Cayman Islands dollar",
                "symbol" : "$"
            }
        ],
        "country" : "Cayman Islands",
        "countryCallingCode" : "1345"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279658",
        "region" : "Africa",
        "subregion" : "Western Africa",
        "latlng" : [
            16,
            -24
        ],
        "timezones" : [
            "UTC-01:00"
        ],
        "countryCode" : "CV",
        "currencySymbol" : [
            {
                "code" : "CVE",
                "name" : "Cape Verdean escudo",
                "symbol" : "Esc"
            }
        ],
        "country" : "Cabo Verde",
        "countryCallingCode" : "238"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279657",
        "region" : "Americas",
        "subregion" : "Northern America",
        "latlng" : [
            60,
            -95
        ],
        "timezones" : [
            "UTC-08:00",
            "UTC-07:00",
            "UTC-06:00",
            "UTC-05:00",
            "UTC-04:00",
            "UTC-03:30"
        ],
        "countryCode" : "CA",
        "currencySymbol" : [
            {
                "code" : "CAD",
                "name" : "Canadian dollar",
                "symbol" : "$"
            }
        ],
        "country" : "Canada",
        "countryCallingCode" : "1"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279656",
        "region" : "Africa",
        "subregion" : "Middle Africa",
        "latlng" : [
            6,
            12
        ],
        "timezones" : [
            "UTC+01:00"
        ],
        "countryCode" : "CM",
        "currencySymbol" : [
            {
                "code" : "XAF",
                "name" : "Central African CFA franc",
                "symbol" : "Fr"
            }
        ],
        "country" : "Cameroon",
        "countryCallingCode" : "237"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279655",
        "region" : "Asia",
        "subregion" : "South-Eastern Asia",
        "latlng" : [
            13,
            105
        ],
        "timezones" : [
            "UTC+07:00"
        ],
        "countryCode" : "KH",
        "currencySymbol" : [
            {
                "code" : "KHR",
                "name" : "Cambodian riel",
                "symbol" : "៛"
            },
            {
                "code" : "USD",
                "name" : "United States dollar",
                "symbol" : "$"
            }
        ],
        "country" : "Cambodia",
        "countryCallingCode" : "855"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279654",
        "region" : "Africa",
        "subregion" : "Eastern Africa",
        "latlng" : [
            -3.5,
            30
        ],
        "timezones" : [
            "UTC+02:00"
        ],
        "countryCode" : "BI",
        "currencySymbol" : [
            {
                "code" : "BIF",
                "name" : "Burundian franc",
                "symbol" : "Fr"
            }
        ],
        "country" : "Burundi",
        "countryCallingCode" : "257"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279653",
        "region" : "Africa",
        "subregion" : "Western Africa",
        "latlng" : [
            13,
            -2
        ],
        "timezones" : [
            "UTC"
        ],
        "countryCode" : "BF",
        "currencySymbol" : [
            {
                "code" : "XOF",
                "name" : "West African CFA franc",
                "symbol" : "Fr"
            }
        ],
        "country" : "Burkina Faso",
        "countryCallingCode" : "226"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279652",
        "region" : "Europe",
        "subregion" : "Eastern Europe",
        "latlng" : [
            43,
            25
        ],
        "timezones" : [
            "UTC+02:00"
        ],
        "countryCode" : "BG",
        "currencySymbol" : [
            {
                "code" : "BGN",
                "name" : "Bulgarian lev",
                "symbol" : "лв"
            }
        ],
        "country" : "Bulgaria",
        "countryCallingCode" : "359"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279651",
        "region" : "Asia",
        "subregion" : "South-Eastern Asia",
        "latlng" : [
            4.5,
            114.66666666
        ],
        "timezones" : [
            "UTC+08:00"
        ],
        "countryCode" : "BN",
        "currencySymbol" : [
            {
                "code" : "BND",
                "name" : "Brunei dollar",
                "symbol" : "$"
            },
            {
                "code" : "SGD",
                "name" : "Singapore dollar",
                "symbol" : "$"
            }
        ],
        "country" : "Brunei Darussalam",
        "countryCallingCode" : "673"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279650",
        "region" : "Americas",
        "subregion" : "Caribbean",
        "latlng" : [
            18.34,
            -64.93
        ],
        "timezones" : [
            "UTC-04:00"
        ],
        "countryCode" : "VI",
        "currencySymbol" : [
            {
                "code" : "USD",
                "name" : "United States dollar",
                "symbol" : "$"
            }
        ],
        "country" : "Virgin Islands (U.S.)",
        "countryCallingCode" : "1 340"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd427964f",
        "region" : "Americas",
        "subregion" : "Caribbean",
        "latlng" : [
            18.431383,
            -64.62305
        ],
        "timezones" : [
            "UTC-04:00"
        ],
        "countryCode" : "VG",
        "currencySymbol" : [
            {
                "code" : null,
                "name" : "[D]",
                "symbol" : "$"
            },
            {
                "code" : "USD",
                "name" : "United States dollar",
                "symbol" : "$"
            }
        ],
        "country" : "Virgin Islands (British)",
        "countryCallingCode" : "1284"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd427964e",
        "region" : "Americas",
        "subregion" : "Northern America",
        "latlng" : [ ],
        "timezones" : [
            "UTC-11:00",
            "UTC-10:00",
            "UTC+12:00"
        ],
        "countryCode" : "UM",
        "currencySymbol" : [
            {
                "code" : "USD",
                "name" : "United States Dollar",
                "symbol" : "$"
            }
        ],
        "country" : "United States Minor Outlying Islands",
        "countryCallingCode" : ""
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd427964d",
        "region" : "Africa",
        "subregion" : "Eastern Africa",
        "latlng" : [
            -6,
            71.5
        ],
        "timezones" : [
            "UTC+06:00"
        ],
        "countryCode" : "IO",
        "currencySymbol" : [
            {
                "code" : "USD",
                "name" : "United States dollar",
                "symbol" : "$"
            }
        ],
        "country" : "British Indian Ocean Territory",
        "countryCallingCode" : "246"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd427964c",
        "region" : "Americas",
        "subregion" : "South America",
        "latlng" : [
            -10,
            -55
        ],
        "timezones" : [
            "UTC-05:00",
            "UTC-04:00",
            "UTC-03:00",
            "UTC-02:00"
        ],
        "countryCode" : "BR",
        "currencySymbol" : [
            {
                "code" : "BRL",
                "name" : "Brazilian real",
                "symbol" : "R$"
            }
        ],
        "country" : "Brazil",
        "countryCallingCode" : "55"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd427964b",
        "region" : "",
        "subregion" : "",
        "latlng" : [
            -54.43333333,
            3.4
        ],
        "timezones" : [
            "UTC+01:00"
        ],
        "countryCode" : "BV",
        "currencySymbol" : [
            {
                "code" : "NOK",
                "name" : "Norwegian krone",
                "symbol" : "kr"
            }
        ],
        "country" : "Bouvet Island",
        "countryCallingCode" : ""
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd427964a",
        "region" : "Africa",
        "subregion" : "Southern Africa",
        "latlng" : [
            -22,
            24
        ],
        "timezones" : [
            "UTC+02:00"
        ],
        "countryCode" : "BW",
        "currencySymbol" : [
            {
                "code" : "BWP",
                "name" : "Botswana pula",
                "symbol" : "P"
            }
        ],
        "country" : "Botswana",
        "countryCallingCode" : "267"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279649",
        "region" : "Europe",
        "subregion" : "Southern Europe",
        "latlng" : [
            44,
            18
        ],
        "timezones" : [
            "UTC+01:00"
        ],
        "countryCode" : "BA",
        "currencySymbol" : [
            {
                "code" : "BAM",
                "name" : "Bosnia and Herzegovina convertible mark",
                "symbol" : null
            }
        ],
        "country" : "Bosnia and Herzegovina",
        "countryCallingCode" : "387"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279648",
        "region" : "Americas",
        "subregion" : "Caribbean",
        "latlng" : [
            12.15,
            -68.266667
        ],
        "timezones" : [
            "UTC-04:00"
        ],
        "countryCode" : "BQ",
        "currencySymbol" : [
            {
                "code" : "USD",
                "name" : "United States dollar",
                "symbol" : "$"
            }
        ],
        "country" : "Bonaire, Sint Eustatius and Saba",
        "countryCallingCode" : "5997"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279647",
        "region" : "Americas",
        "subregion" : "South America",
        "latlng" : [
            -17,
            -65
        ],
        "timezones" : [
            "UTC-04:00"
        ],
        "countryCode" : "BO",
        "currencySymbol" : [
            {
                "code" : "BOB",
                "name" : "Bolivian boliviano",
                "symbol" : "Bs."
            }
        ],
        "country" : "Bolivia (Plurinational State of)",
        "countryCallingCode" : "591"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279646",
        "region" : "Asia",
        "subregion" : "Southern Asia",
        "latlng" : [
            27.5,
            90.5
        ],
        "timezones" : [
            "UTC+06:00"
        ],
        "countryCode" : "BT",
        "currencySymbol" : [
            {
                "code" : "BTN",
                "name" : "Bhutanese ngultrum",
                "symbol" : "Nu."
            },
            {
                "code" : "INR",
                "name" : "Indian rupee",
                "symbol" : "₹"
            }
        ],
        "country" : "Bhutan",
        "countryCallingCode" : "975"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279645",
        "region" : "Americas",
        "subregion" : "Northern America",
        "latlng" : [
            32.33333333,
            -64.75
        ],
        "timezones" : [
            "UTC-04:00"
        ],
        "countryCode" : "BM",
        "currencySymbol" : [
            {
                "code" : "BMD",
                "name" : "Bermudian dollar",
                "symbol" : "$"
            }
        ],
        "country" : "Bermuda",
        "countryCallingCode" : "1441"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279644",
        "region" : "Africa",
        "subregion" : "Western Africa",
        "latlng" : [
            9.5,
            2.25
        ],
        "timezones" : [
            "UTC+01:00"
        ],
        "countryCode" : "BJ",
        "currencySymbol" : [
            {
                "code" : "XOF",
                "name" : "West African CFA franc",
                "symbol" : "Fr"
            }
        ],
        "country" : "Benin",
        "countryCallingCode" : "229"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279643",
        "region" : "Americas",
        "subregion" : "Central America",
        "latlng" : [
            17.25,
            -88.75
        ],
        "timezones" : [
            "UTC-06:00"
        ],
        "countryCode" : "BZ",
        "currencySymbol" : [
            {
                "code" : "BZD",
                "name" : "Belize dollar",
                "symbol" : "$"
            }
        ],
        "country" : "Belize",
        "countryCallingCode" : "501"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279642",
        "region" : "Europe",
        "subregion" : "Western Europe",
        "latlng" : [
            50.83333333,
            4
        ],
        "timezones" : [
            "UTC+01:00"
        ],
        "countryCode" : "BE",
        "currencySymbol" : [
            {
                "code" : "EUR",
                "name" : "Euro",
                "symbol" : "€"
            }
        ],
        "country" : "Belgium",
        "countryCallingCode" : "32"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279641",
        "region" : "Europe",
        "subregion" : "Eastern Europe",
        "latlng" : [
            53,
            28
        ],
        "timezones" : [
            "UTC+03:00"
        ],
        "countryCode" : "BY",
        "currencySymbol" : [
            {
                "code" : "BYN",
                "name" : "New Belarusian ruble",
                "symbol" : "Br"
            },
            {
                "code" : "BYR",
                "name" : "Old Belarusian ruble",
                "symbol" : "Br"
            }
        ],
        "country" : "Belarus",
        "countryCallingCode" : "375"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279640",
        "region" : "Americas",
        "subregion" : "Caribbean",
        "latlng" : [
            13.16666666,
            -59.53333333
        ],
        "timezones" : [
            "UTC-04:00"
        ],
        "countryCode" : "BB",
        "currencySymbol" : [
            {
                "code" : "BBD",
                "name" : "Barbadian dollar",
                "symbol" : "$"
            }
        ],
        "country" : "Barbados",
        "countryCallingCode" : "1246"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd427963f",
        "region" : "Asia",
        "subregion" : "Southern Asia",
        "latlng" : [
            24,
            90
        ],
        "timezones" : [
            "UTC+06:00"
        ],
        "countryCode" : "BD",
        "currencySymbol" : [
            {
                "code" : "BDT",
                "name" : "Bangladeshi taka",
                "symbol" : "৳"
            }
        ],
        "country" : "Bangladesh",
        "countryCallingCode" : "880"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd427963e",
        "region" : "Asia",
        "subregion" : "Western Asia",
        "latlng" : [
            26,
            50.55
        ],
        "timezones" : [
            "UTC+03:00"
        ],
        "countryCode" : "BH",
        "currencySymbol" : [
            {
                "code" : "BHD",
                "name" : "Bahraini dinar",
                "symbol" : ".د.ب"
            }
        ],
        "country" : "Bahrain",
        "countryCallingCode" : "973"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd427963d",
        "region" : "Americas",
        "subregion" : "Caribbean",
        "latlng" : [
            24.25,
            -76
        ],
        "timezones" : [
            "UTC-05:00"
        ],
        "countryCode" : "BS",
        "currencySymbol" : [
            {
                "code" : "BSD",
                "name" : "Bahamian dollar",
                "symbol" : "$"
            }
        ],
        "country" : "Bahamas",
        "countryCallingCode" : "1242"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd427963c",
        "region" : "Asia",
        "subregion" : "Western Asia",
        "latlng" : [
            40.5,
            47.5
        ],
        "timezones" : [
            "UTC+04:00"
        ],
        "countryCode" : "AZ",
        "currencySymbol" : [
            {
                "code" : "AZN",
                "name" : "Azerbaijani manat",
                "symbol" : null
            }
        ],
        "country" : "Azerbaijan",
        "countryCallingCode" : "994"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd427963b",
        "region" : "Europe",
        "subregion" : "Western Europe",
        "latlng" : [
            47.33333333,
            13.33333333
        ],
        "timezones" : [
            "UTC+01:00"
        ],
        "countryCode" : "AT",
        "currencySymbol" : [
            {
                "code" : "EUR",
                "name" : "Euro",
                "symbol" : "€"
            }
        ],
        "country" : "Austria",
        "countryCallingCode" : "43"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd427963a",
        "region" : "Oceania",
        "subregion" : "Australia and New Zealand",
        "latlng" : [
            -27,
            133
        ],
        "timezones" : [
            "UTC+05:00",
            "UTC+06:30",
            "UTC+07:00",
            "UTC+08:00",
            "UTC+09:30",
            "UTC+10:00",
            "UTC+10:30",
            "UTC+11:30"
        ],
        "countryCode" : "AU",
        "currencySymbol" : [
            {
                "code" : "AUD",
                "name" : "Australian dollar",
                "symbol" : "$"
            }
        ],
        "country" : "Australia",
        "countryCallingCode" : "61"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279639",
        "region" : "Americas",
        "subregion" : "Caribbean",
        "latlng" : [
            12.5,
            -69.96666666
        ],
        "timezones" : [
            "UTC-04:00"
        ],
        "countryCode" : "AW",
        "currencySymbol" : [
            {
                "code" : "AWG",
                "name" : "Aruban florin",
                "symbol" : "ƒ"
            }
        ],
        "country" : "Aruba",
        "countryCallingCode" : "297"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279638",
        "region" : "Asia",
        "subregion" : "Western Asia",
        "latlng" : [
            40,
            45
        ],
        "timezones" : [
            "UTC+04:00"
        ],
        "countryCode" : "AM",
        "currencySymbol" : [
            {
                "code" : "AMD",
                "name" : "Armenian dram",
                "symbol" : null
            }
        ],
        "country" : "Armenia",
        "countryCallingCode" : "374"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279637",
        "region" : "Americas",
        "subregion" : "South America",
        "latlng" : [
            -34,
            -64
        ],
        "timezones" : [
            "UTC-03:00"
        ],
        "countryCode" : "AR",
        "currencySymbol" : [
            {
                "code" : "ARS",
                "name" : "Argentine peso",
                "symbol" : "$"
            }
        ],
        "country" : "Argentina",
        "countryCallingCode" : "54"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279636",
        "region" : "Americas",
        "subregion" : "Caribbean",
        "latlng" : [
            17.05,
            -61.8
        ],
        "timezones" : [
            "UTC-04:00"
        ],
        "countryCode" : "AG",
        "currencySymbol" : [
            {
                "code" : "XCD",
                "name" : "East Caribbean dollar",
                "symbol" : "$"
            }
        ],
        "country" : "Antigua and Barbuda",
        "countryCallingCode" : "1268"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279635",
        "region" : "Polar",
        "subregion" : "",
        "latlng" : [
            -74.65,
            4.48
        ],
        "timezones" : [
            "UTC-03:00",
            "UTC+03:00",
            "UTC+05:00",
            "UTC+06:00",
            "UTC+07:00",
            "UTC+08:00",
            "UTC+10:00",
            "UTC+12:00"
        ],
        "countryCode" : "AQ",
        "currencySymbol" : [
            {
                "code" : "AUD",
                "name" : "Australian dollar",
                "symbol" : "$"
            },
            {
                "code" : "GBP",
                "name" : "British pound",
                "symbol" : "£"
            }
        ],
        "country" : "Antarctica",
        "countryCallingCode" : "672"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279634",
        "region" : "Americas",
        "subregion" : "Caribbean",
        "latlng" : [
            18.25,
            -63.16666666
        ],
        "timezones" : [
            "UTC-04:00"
        ],
        "countryCode" : "AI",
        "currencySymbol" : [
            {
                "code" : "XCD",
                "name" : "East Caribbean dollar",
                "symbol" : "$"
            }
        ],
        "country" : "Anguilla",
        "countryCallingCode" : "1264"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279633",
        "region" : "Africa",
        "subregion" : "Middle Africa",
        "latlng" : [
            -12.5,
            18.5
        ],
        "timezones" : [
            "UTC+01:00"
        ],
        "countryCode" : "AO",
        "currencySymbol" : [
            {
                "code" : "AOA",
                "name" : "Angolan kwanza",
                "symbol" : "Kz"
            }
        ],
        "country" : "Angola",
        "countryCallingCode" : "244"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279632",
        "region" : "Europe",
        "subregion" : "Southern Europe",
        "latlng" : [
            42.5,
            1.5
        ],
        "timezones" : [
            "UTC+01:00"
        ],
        "countryCode" : "AD",
        "currencySymbol" : [
            {
                "code" : "EUR",
                "name" : "Euro",
                "symbol" : "€"
            }
        ],
        "country" : "Andorra",
        "countryCallingCode" : "376"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279631",
        "region" : "Oceania",
        "subregion" : "Polynesia",
        "latlng" : [
            -14.33333333,
            -170
        ],
        "timezones" : [
            "UTC-11:00"
        ],
        "countryCode" : "AS",
        "currencySymbol" : [
            {
                "code" : "USD",
                "name" : "United State Dollar",
                "symbol" : "$"
            }
        ],
        "country" : "American Samoa",
        "countryCallingCode" : "1684"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd4279630",
        "region" : "Africa",
        "subregion" : "Northern Africa",
        "latlng" : [
            28,
            3
        ],
        "timezones" : [
            "UTC+01:00"
        ],
        "countryCode" : "DZ",
        "currencySymbol" : [
            {
                "code" : "DZD",
                "name" : "Algerian dinar",
                "symbol" : "د.ج"
            }
        ],
        "country" : "Algeria",
        "countryCallingCode" : "213"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd427962f",
        "region" : "Europe",
        "subregion" : "Southern Europe",
        "latlng" : [
            41,
            20
        ],
        "timezones" : [
            "UTC+01:00"
        ],
        "countryCode" : "AL",
        "currencySymbol" : [
            {
                "code" : "ALL",
                "name" : "Albanian lek",
                "symbol" : "L"
            }
        ],
        "country" : "Albania",
        "countryCallingCode" : "355"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd427962e",
        "region" : "Europe",
        "subregion" : "Northern Europe",
        "latlng" : [
            60.116667,
            19.9
        ],
        "timezones" : [
            "UTC+02:00"
        ],
        "countryCode" : "AX",
        "currencySymbol" : [
            {
                "code" : "EUR",
                "name" : "Euro",
                "symbol" : "€"
            }
        ],
        "country" : "Åland Islands",
        "countryCallingCode" : "358"
    },
    
    
    {
        "_id" : "5f4af1fb1344ee5fd427962d",
        "region" : "Asia",
        "subregion" : "Southern Asia",
        "latlng" : [
            33,
            65
        ],
        "timezones" : [
            "UTC+04:30"
        ],
        "countryCode" : "AF",
        "currencySymbol" : [
            {
                "code" : "AFN",
                "name" : "Afghan afghani",
                "symbol" : "؋"
            }
        ],
        "country" : "Afghanistan",
        "countryCallingCode" : "93"
    }
    ];