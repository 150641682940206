import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-customers-sort',
  templateUrl: './customers-sort.component.html',
  styleUrls: ['./customers-sort.component.scss'],
})
export class CustomersSortComponent implements OnInit {
  items = ['Date', 'Location'];
  sort;
  constructor( private popctrl: PopoverController) {
    this.sort = localStorage.getItem('Csort');
   }

  ngOnInit() { }
  
  onsort(val) {
    this.popctrl.dismiss({
      success:true,
      val:val
    })
  }
}
