import { SupervisorService } from './../../../services/supervisor.service';
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LoadingserviceService } from 'src/app/loadingservice.service';
import { LayerfarmerSupervisorService } from 'src/app/services/layerfarmer-supervisor.service';
import { LayersService } from 'src/app/services/layers.service';

@Component({
  selector: 'app-layer-feed-details',
  templateUrl: './layer-feed-details.component.html',
  styleUrls: ['./layer-feed-details.component.scss'],
})
export class LayerFeedDetailsComponent implements OnInit {
  currencySymbol: string = localStorage.getItem('currencySymbol');
  FeedDetails = [];
  mainStockOverView = [];
  addedFarmer: any;

  solidUnits = localStorage.getItem('solidUnits');

  constructor(
    public layerService: LayersService,
    private loader: LoadingserviceService,
    public translate: TranslateService,
    public modal: ModalController,
    private layerSupervisorService: LayerfarmerSupervisorService,
    private supervisor: SupervisorService
  ) { }

  ngOnInit() { }
  ionViewWillEnter() {
    this.addedFarmer = this.layerSupervisorService.returnAddedFarmer();
    this.FeedDetails = this.layerService.feedstockmoredetail();
    console.log(this.FeedDetails, 2543);
    this.mainStockOverView = this.layerService.getFeedstockmoreDetailsView();
    console.log(this.mainStockOverView, 24);
    if (localStorage.getItem('role_id') == '3') {
      let temp = this.supervisor.locallySaved()
      this.solidUnits = temp['solidUnits'];
    } else if (localStorage.getItem('role_id') == '11') {
      let temp = this.layerSupervisorService.returnAddedFarmer()
      this.solidUnits = temp['solidUnits'];
    }
  };
  dismissMdal() {
    this.modal.dismiss();
  }
}
