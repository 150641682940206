import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { CommonExportsModule } from "../common-exports/common-exports.module";
import { IonicModule } from '@ionic/angular';
import { OtpService } from './otp.service';
import { OtpPage } from './otp.page';
import { SharedModule } from '../shared.module';


const routes: Routes = [
  {
    path: '',
    component: OtpPage
  }
];

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CommonExportsModule,
    IonicModule,
    RouterModule.forChild(routes)
  ],
  declarations: [OtpPage],
  providers:[OtpService]
})
export class OtpPageModule {}
