import { rejects } from 'assert';
import { async } from '@angular/core/testing';
import { AlertController } from '@ionic/angular';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SelectService {
  inpts = [];

  constructor(
    private alertController: AlertController
  ) { }
  prevState = null;
  //sortpopoverFilter takes 2 options (heading, input options )
  async SortPopoverFilter(heading, inputOptions) {
    return new Promise(async (resolve, reject) => {
      this.inpts = [];
      inputOptions.forEach(element1 => {
        let temp = {
          label: element1,
          type: 'radio',
          value: element1,
          checked: this.prevState == element1,
          handler: async () => {
            this.prevState = element1;
            resolve(element1)
            await alert.dismiss();
          }
        }
        this.inpts.push(temp)
      });
      const alert = await this.alertController.create({
        header: heading,
        cssClass: "alertTraderHome",
        mode: "ios",
        inputs: this.inpts,
        buttons: [],
      })
      await alert.present();
    })



  }
}
