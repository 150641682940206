import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'feedUnits'
})
export class FeedUnitsPipe implements PipeTransform {

  transform(value: unknown[], ...args: unknown[]): unknown {
    if (value == null) {
      return '';
    } else {
      var filterFeed = [];
      value.map((feed: any) => {
        if (feed.showStockFormat == 'kg') {
          feed.ReamaingFeedStock = feed.ReamaingFeedStock;
          feed.totalTypeAddStock = feed.totalTypeAddStock;
          feed.consumedFeed = feed.consumedFeed;
          feed.typeStockPrice =  feed.typeStockPrice;
          filterFeed.push(feed);
        } else if (feed.showStockFormat == 'tonn') {
          feed.ReamaingFeedStock = feed.ReamaingFeedStock / 1000;
          feed.totalTypeAddStock = feed.totalTypeAddStock / 1000;
          feed.consumedFeed = feed.consumedFeed / 1000;
          feed.typeStockPrice = (feed.ReamaingFeedStock * feed.typeStockPrice) * 1000;
          filterFeed.push(feed);
        } else if (feed.showStockFormat == '50kg') {
          feed.ReamaingFeedStock = feed.ReamaingFeedStock / 50;
          feed.totalTypeAddStock = feed.totalTypeAddStock / 50;
          feed.consumedFeed = feed.consumedFeed / 50;
          feed.typeStockPrice = (feed.ReamaingFeedStock * feed.typeStockPrice) * 50;
          filterFeed.push(feed);
        } else if (feed.showStockFormat == '60kg') {
          feed.ReamaingFeedStock = feed.ReamaingFeedStock / 60;
          feed.totalTypeAddStock = feed.totalTypeAddStock / 60;
          feed.consumedFeed = feed.consumedFeed / 60;
          feed.typeStockPrice = (feed.ReamaingFeedStock * feed.typeStockPrice) * 60;
          filterFeed.push(feed);
        } else if (feed.showStockFormat == '80kg') {
          feed.ReamaingFeedStock = feed.ReamaingFeedStock / 80;
          feed.totalTypeAddStock = feed.totalTypeAddStock / 80;
          feed.consumedFeed = feed.consumedFeed / 80;
          feed.typeStockPrice = (feed.ReamaingFeedStock * feed.typeStockPrice) * 80;
          filterFeed.push(feed);
        } else if (feed.showStockFormat == '100kg') {
          feed.ReamaingFeedStock = feed.ReamaingFeedStock / 100;
          feed.totalTypeAddStock = feed.totalTypeAddStock / 100;
          feed.consumedFeed = feed.consumedFeed / 100;
          feed.typeStockPrice = (feed.ReamaingFeedStock * feed.typeStockPrice) * 100;
          filterFeed.push(feed);
        }
      });
      console.log(filterFeed, 40);
      return filterFeed;
    }
  }

}
