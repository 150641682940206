import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs/internal/Observable';
import { config } from "../helper/config";
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
@Injectable({
  providedIn: 'root'
})
export class StaticticsService {
  localStat: any;


  public expensivies = new BehaviorSubject<any>([]);
  public inventoryDetail$ = this.expensivies.asObservable();
  updateData: any;
  constructor(
    private httpRequest: HttpClient
  ) { }
  updateExpenses(data){
    this.updateData = data
  }

  statSummary(id): Observable<any> {
    return this.httpRequest.post(config.api + "statistics/statSummary",id)
  }

  insertData(requestParams): Observable<any> {
    return this.httpRequest.post(config.api + "statistics/insert", requestParams)
  }

  getInventoryExpensive(id): Observable<any> {
    return this.httpRequest.post(config.api + "statistics/inventoryExpensive", id)
  }


}
