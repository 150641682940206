import { Component, OnInit } from '@angular/core';
import { ChickenService } from '../chicken.service';
import { ModalController } from '@ionic/angular';
import { CommonService } from 'src/services/common.service';
import { AddDriverService } from 'src/services/add-driver.service';
import { NotificationService } from '../services/notification.service';

@Component({
  selector: 'app-instant-notifications',
  templateUrl: './instant-notifications.component.html',
  styleUrls: ['./instant-notifications.component.scss'],
})
export class InstantNotificationsComponent implements OnInit {
  user_role = localStorage.getItem('role_id');
  notifyMessages: any;
  ccNotificationReq: any;
  tabAddress: string;
  usersRequests: any;
  notificationList: any;
  constructor(
    protected chicken: ChickenService,
    protected modalCtrl: ModalController,
    protected commonService: CommonService,
    protected driverService: AddDriverService,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
    if (this.user_role == '10') {
      console.log("user role accepted");
      this.tabAddress = "chicken-center-home/tabs/chicken-home";
      this.chicken.ccNotificationRef$.subscribe(
        (data) => {
          console.log(data);
          if (data.success) {
            console.log(data);
            this.notifyMessages = data.data;
            this.ccNotificationReq = data.account_request;
          }
        }
      );
    } else if (this.user_role == '8') {
      this.tabAddress = 'trader-van-home/tabs/van-home';
      // this.driverService.clearDriverNotifications({}).subscribe((result) => {
      //   if (result.success) {
      //     console.log("data notifications");
      //     console.log(result);
      //   }
      // })
      this.driverService.driverNotificationObserv$.subscribe((data) => {
        console.log(data, 1233);
        if (data.success) {
          this.usersRequests = data.data[0]['requests'];
          this.notificationList = data.data[0]['notifications'];
          console.log(this.usersRequests, this.notificationList, 12332);
        }
      })
    } else {
      console.warn("notifications roles are not available");
    }


    this.notificationService.notificationsList({ skip: 0 }).subscribe((data) => {
      if (data.success) {
        console.log(data);
        this.notificationList = data.data;
      }
    }, (error) => {
      console.log(error);
    });
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }


  userAddOrReject(docId, responseStatus, senderId) {
    if (this.user_role == '10') {
      this.commonService.updateNotification({ _id: docId, added: responseStatus, senderId: senderId }).subscribe(data => {
        if (data.success) {
          this.modalCtrl.dismiss({
            status: true,
            clear: false,
            data: docId
          });
        }
      });
    } else if (this.user_role == '8') {
      console.log({ _id: docId, status: responseStatus, senderId: senderId }, 123);
      this.driverService.driverConformationUpdate({ _id: docId, added: responseStatus, senderId: senderId }).subscribe((data) => {
        if (data.success) {
          // this.driverService.driverNotificationObserv$.subscribe(
          //   (data) => {
          //     console.log(data, 232);
          //     let anew = data;

          //     anew.data[0]['requests'] = [];

          //     this.driverService.driverNotification.next(anew);
          //     // console.log(anew, 1223);
          //   }
          // )
          console.log("instant notifications");
          this.modalCtrl.dismiss(
            {
              status: true,
              clear: false,
              docId: docId
            }
          )
        } else {
          console.warn("something went wrong");
        }
      });
    }
  }
  markAllAsRead() {
    if (this.user_role == '10') {
      this.chicken.readCCNotifications({}).subscribe((data) => {
        console.log(data);
        let newData = this.chicken.savedLocalRequestData();
        newData['data'] = [];
        this.chicken.localSaveRequestRecived(newData);
        console.log(newData, 1234);
        this.modalCtrl.dismiss(
          {
            status: true, clear: true
          }
        )
      })
    } else if (this.user_role == '8') {
      this.driverService.clearDriverNotifications({}).subscribe((data) => {
        if (data.success) {
          this.modalCtrl.dismiss(
            {
              status: true, clear: true
            }
          )
        }
      })
    }
  }


  requestAction(data, action) {
    this.notificationService.requestNotification({ senderId: data.senderId, action: action, receiverId: data.receiverId, notificationId: data._id }).subscribe((data) => {
      if (data.success) {
        console.log(data);
      }
    }, (error) => {
      console.log(error)
    })
  }
}
