import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs/internal/Observable';
import { config } from "../helper/config";
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
@Injectable({
  providedIn: 'root'
})
export class RolechangeService {

  constructor(
    private httpRequest: HttpClient
  ) { }

  public roleChange = new BehaviorSubject<any>({});
  public roleChange$ = this.roleChange.asObservable();
  updateUserRole(postData):Observable<any>{
    return this.httpRequest.post( config.api+"auth/updateRole",postData)
  }
}
