import { Injectable } from '@angular/core';
import { HttpClient,  } from "@angular/common/http";
import { config } from '../helper/config'
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  public useredit = new Subject<any>();
  public editdata$ = this.useredit.asObservable();
  protected addressChange = new BehaviorSubject<boolean>(false);
  currentAdd: any;
  currentLat: any;
  currentLng: any;
  constructor(protected http: HttpClient) {
    this.currentAdd = localStorage.getItem("address");
    this.currentLat = localStorage.getItem("lat");
    this.currentLng = localStorage.getItem("lng");
  }

  editprofile(data): Observable<any> {
    return this.http.post(config.api + "auth/editProfile", data).pipe(map((res) => {
      if (res) {
        (data.lname) ? data.lname = data.lname : data.lname = '';
        data.name = data.lname + data.fname;
        if (this.currentLat == data.latitude && this.currentLng == data.longitude) {
          this.addressChange.next(false);
        } else {
          console.log(this.addressChange);
          this.addressChange.next(true);
        }
        this.currentAdd = data.address;
        this.currentLat = data.latitude;
        this.currentLng = data.longitude;
        this.setlocalstorage(data);
      }
      return data;
    }));
  }
  setlocalstorage(data) {
    localStorage.setItem('fname', data.fname);
    localStorage.setItem('lname', data.lname);
    localStorage.setItem('name', data.name);
    localStorage.setItem("email", data.email);
    localStorage.setItem("address", data.address);
    localStorage.setItem('lat', data.latitude);
    localStorage.setItem('lng', data.longitude);
    this.useredit.next(data);
  }
  updateUserInfo(data): Observable<any> {
    return this.http.post(config.api + "auth/updateUserInfoProfile", data);
  }

  updateProfile(data): Observable<any> {
    return this.http.post(config.api + "auth/updateProfile", data);
  }

  profile_details(data): Observable<any> {
    return this.http.post(config.api + 'auth/profiledetails', data);
  }

  passwordChange(data): Observable<any> {
    return this.http.post(config.api + "auth/passwordChange", data)
  }

  notificationOnOf(data) {
    return this.http.post(config.api + 'auth/notificationsOnOf', data);
  }

  getBehaviourSubjectAddressData() {
    return this.addressChange.asObservable().pipe(distinctUntilChanged());
  }
  deleteUser(){
    return this.http.get(config.api + 'auth/deleteAccount');
  }

  emailVaidate(email): Observable<any>{
    return this.http.post(config.api + "auth/chekEmailIdExistance", email)
  }

  updateSkipDate(uid):Observable<any>{
    return this.http.post(config.api + "auth/updateSkipDate", uid)
  }

  getUpdateDate(uid):Observable<any>{
    return this.http.post(config.api + "auth/getUpdateDate", uid)
  }

}
