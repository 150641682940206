import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from '../app/helper/config';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from "rxjs/operators"

@Injectable({
    providedIn: "root"
})

export class LiftingsService {
    totalvanDetails: any;
    selectedInventory: any;
    alertOpt;
    // private van = new BehaviorSubject<any>([]);
    // public van$ = this.van.asObservable();
    public orderSubject = new BehaviorSubject<any>([]);
    // private liftCall = new BehaviorSubject<any>(false);
    public pendingOrdersObserve$ = this.orderSubject.asObservable();
    constructor(
        protected http: HttpClient
    ) { }

    setInvenList(data) {
        this.alertOpt = data;
    }

    getInvenList() {
        return this.alertOpt
    }

    loaded(data): Observable<any> {
        return this.http.post(config.api + "liftings/updateLoad1", data);
    }
    loadTrue(data): Observable<any> {
        console.log(data)
        return this.http.post(config.api + "liftings/Loadtrue", data);
    }
    addSignatures(data): Observable<any> {
        return this.http.post(config.api + "liftings/addsignature", data);
    }

    getInventoriesList(data) {
        return this.http.post(config.api + 'liftings/list', data);
    }
    getSelectedInventory(data) {
        return this.http.post(config.api + 'liftings/getSelectedInventory', data);
    }

    getinventoryForVan() {
        return this.http.get(config.api + 'liftings/listvanview');
    }
    getVehiclesList(data) {
        return this.http.post(config.api + 'liftings/vehicles', data);
    }

    getLiftings(data) {
        return this.http.post(config.api + 'liftings/liftings', data);
    }
    getliftingsrefresh(data) {
        return this.http.post(config.api + 'liftings/liftingsrefresh', data);
    }

    addVehicle(data) {
        return this.http.post(config.api + 'liftings/addliftingsandvans', data);
    }

    deleteVan(data): Observable<any> {
        return this.http.post(config.api + "liftings/deleteVan", data)
    }

    addFarmerOrder(data) {
        return this.http.post(config.api + 'liftings/addFarmerOrder', data);
    }

    intime(data) {
        return this.http.post(config.api + 'liftings/timeinsert', data);
    }
    getBoxes(data) {
        return this.http.post(config.api + 'liftings/liftingboxes/getboxes', data);
    }
    addBox(data) {
        return this.http.post(config.api + 'liftings/liftingboxes/addboxes', data);
    }
    addBoxNew(data) {
        return this.http.post(config.api + 'liftings/liftingboxes/addBoxNew', data)
    }
    newGetBoxes(data): Observable<any> {
        return this.http.post(config.api + 'liftings/liftingboxes/newGetBoxes', data)
    }
    editBox(data) {
        return this.http.post(config.api + 'liftings/liftingboxes/editboxes', data);
    }
    traderBoxStart(data) {
        return this.http.post(config.api + 'liftings/liftingboxes/traderBoxStart', data);
    }
    completeLoadingBox(data) {
        return this.http.post(config.api + 'liftings/completeloading', data);
    }
    edit(data): Observable<any> {
        return this.http.post(config.api + 'liftings/editBoxData', data);
    }
    settingLiftingsPrice(data): Observable<any> {
        return this.http.post(config.api + 'liftings/settingLiftingsPrice', data)
    }
    liftingPrices(data): Observable<any> {
        // console.log(data)
        return this.http.post(config.api + 'liftings/getLiftingsPrice', data)
    }
    dcShow(data): Observable<any> {
        return this.http.post(config.api + 'liftings/dcShow', data)
    }
    getFarmerList(): Observable<any> {
        return this.http.get(config.api + 'liftings/getAddedFarmers')
    }
    dcs(): Observable<any> {
        return this.http.get(config.api + 'farms/poultrydcs')
    }
    pdf(data): Observable<any> {
        return this.http.post(config.api + 'lease/getfile', data)
    }
    bookClose(data): Observable<any> {
        return this.http.post(config.api + 'liftings/bookClose', data)
    }
    vanDetails(data) {
        this.totalvanDetails = data;
    }
    priceSettingChange(data): Observable<any> {
        return this.http.post(config.api + "liftings/priceChanges", data)
    }
    inventoryAdvertise(data): Observable<any> {
        return this.http.post(config.api + "liftings/inventoryAdvertise", data)
    }
    getInventoryPrice(data): Observable<any> {
        return this.http.post(config.api + "liftings/getFarmerPriceSetting", data)
    }
    nearsCityPrice(data): Observable<any> {
        return this.http.post(config.api + "liftings/nearAvailableCity", data)
    }
    getPendingOrders(): Observable<any> {
        return this.http.get(config.api + "liftings/getPendingOrders").pipe(
            map((res) => {
                this.orderSubject.next(res);
                return res;
            })
        )
    }
    setSelectedInventoryData(data) {
        this.selectedInventory = data
    }
    getSelectedInventoryData() {
        return this.selectedInventory;
    }
    editVanDetails(data): Observable<any> {
        return this.http.post(config.api + "liftings/editvanDetails", data);
    }
    generalEdit(data): Observable<any> {
        return this.http.post(config.api + "liftings/generalEdit", data);
    }
    generatepdf(id): Observable<any> {
        return this.http.post(config.api + "liftings/generatePdf", id);
    }
    overAllLiftingsPdf(id): Observable<any> {
        return this.http.post(config.api + "liftings/overAllLiftingsPdf", id);
    }
    findTraderUser(data): Observable<any> {
        return this.http.post(config.api + 'trader/findTraderUser', data)
    }

    createOrderForTrader(data): Observable<any> {
        return this.http.post(config.api + 'lifting/api', data)
    }

    getUnassignedAutoOrders(data): Observable<any> {
        return this.http.post(config.api + "liftings/getUnassignedOrder", data)
    }

    farmerAssignInventory(data): Observable<any> {
        return this.http.post(config.api + "liftings/farmerAssignInventory", data)
    }

    acceptLifting(data): Observable<any> {
        return this.http.post(config.api + "liftings/acceptLifting", data)
    }

    assignInventoryToOrder(data): Observable<any> {
        return this.http.post(config.api + "liftings/assignInventoryToOrder", data)
    }

    tradersList(data): Observable<any> {
        return this.http.post(config.api + "contacts/tradersList", { term: data })
    }

    boxmodelEdit(data): Observable<any> {
        return this.http.post(config.api + "contacts/boxmodelEdit", data)
    }
}