import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { LoadingserviceService } from '../../loadingservice.service';
import { NavigationExtras, Router } from '@angular/router';
@Component({
  selector: 'app-chicken-center-orderinfo',
  templateUrl: './chicken-center-orderinfo.component.html',
  styleUrls: ['./chicken-center-orderinfo.component.scss'],
})
export class ChickenCenterOrderinfoComponent implements OnInit {
  orderSummary:any;

  constructor(
    protected navParams: NavParams,
    protected modal: ModalController,
    protected ls: LoadingserviceService,
    private router: Router
  ) { }

  ngOnInit() {
    this.orderSummary = this.navParams.get('orderData');
    console.log(this.orderSummary)
  }

  dismiss(as) {
    this.modal.dismiss()
  }

  dial(number) {
    this.ls.dial(number)
  }

  async trackSingleOrder(Order) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        pagename: 'chickenhome',
        Od_id: Order.OrderId,
      }
    };
    this.router.navigate(['/google-maps-show'], navigationExtras);
  }

}
