import { Pipe, PipeTransform } from '@angular/core';
// import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'ordinalSuffix'
})
export class OrdinalSuffixPipe implements PipeTransform {
constructor(
  // private translate:TranslateService
  ){}
  transform(i: any, args?: any): any {
    var j = i % 10,
      k = i % 100; if (j == 1 && k != 11) { return 'st'; } 
      if (j == 2 && k != 12) { return 'nd'; } 
      if (j == 3 && k != 13) { return 'rd'; } return 'th';
    return i+''+k;
  }

}




// transform(i: any, args?: any): any {
//   var j = i % 10,
//     k = i % 100; if (j == 1 && k != 11) { return this.translate.instant('common.st'); } 
//     if (j == 2 && k != 12) { return this.translate.instant('common.nd'); } 
//     if (j == 3 && k != 13) { return this.translate.instant('common.rd'); } return this.translate.instant('common.th');
//   return i+''+k;
// }