import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-marquee-prices',
  templateUrl: './marquee-prices.component.html',
  styleUrls: ['./marquee-prices.component.scss'],
})
export class MarqueePricesComponent implements OnInit {
  @Input('allPrices') prices: any;
  country: string = localStorage.getItem('country');
  constructor(public route: Router) { }

  ngOnInit() {
  }
  ionViewWillEnter() {
    console.log(this.prices, 4758);
  }
  adjustPrices() {
    this.route.navigate(['/market-indicators'])
  }
}
