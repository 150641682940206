import { Injectable } from '@angular/core';
import { config } from '../app/helper/config';
import { HttpClient,  } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class DealersService {
  tempData: any;
  constructor(public http: HttpClient) { }
  commentEditData(data) {
    this.tempData = data;
    return this.http.post(config.api + 'dealer/getcomentreview', data);
  }
  commentEditGetData() {
    return this.tempData;
  }
  dealerInfo(data): Observable<any> {
    return this.http.post(config.api + 'dealer/dealerInfo', data);
  }
  dealers_List(data): Observable<any> {
    return this.http.post(config.api + 'dealer/dealersList', data);
  }
  dealers_List1(data): Observable<any> {
    return this.http.post(config.api + 'dealer/dealersListrefresh', data);
  }
  locationsort(data): Observable<any> {
    return this.http.post(config.api + 'dealer/locationsort', data);
  }
  //to get price data
  dealer_data(data): Observable<any> {
    return this.http.post(config.api + 'dealer/dealerData', data);
  }

  dealer_farmInsert(data): Observable<any> {
    return this.http.post(config.api + 'dealer/insertPrice', data);
  }
  updateFarmById(data): Observable<any> {
    return this.http.post(config.api + "dealer/updateFarmById", data);
  }
  dealer_info(roll_id): Observable<any> {
    return this.http.post(config.api + "dealer/dealer_info", roll_id);
  }
  Dealer_review_data(data): Observable<any> {
    return this.http.post(config.api + "dealer/ratingByUser", data);
  }
  delaer_overall_rating(data): Observable<any> {
    return this.http.post(config.api + "dealer/dealer_overall_review", data);
  }
  myReviews(): Observable<any> {
    return this.http.get(config.api + "dealer/reviews");
  }
  userComments(): Observable<any> {
    return this.http.get(config.api + "dealer/commentData");
  }
  //when farmer click on dealer its add to his view count
  clickCount(data): Observable<any> {
    return this.http.post(config.api + "dealer/countCal", data);
  }
  //edit your add comment
  editComment(data): Observable<any> {
    return this.http.post(config.api + "dealer/editComment", data);
  }
  //deleted the add comment
  deleteComment(data): Observable<any> {
    return this.http.post(config.api + "dealer/deleteComment", data);
  }
  Status(toggleValue){
    console.log(toggleValue)
    return this.http.post(config.api + 'dealer/pricetoggle', toggleValue);
  }
}
