import { AppVersion } from '@ionic-native/app-version/ngx';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { config } from '../helper/config';
import { countryList } from '../helper/country';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  public loginsubject = new Subject<any>();
  public loginSubject$ = this.loginsubject.asObservable();
  loginThrough: any;
  tempUser: any;
  constructor(
    public http: HttpClient,
  ) {
  }
  setLoginThrough(data) {
    this.loginThrough = data;
  }
  login(data): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.post(config.api + 'auth/login', data, {
      headers:
        new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
          }
        )
    }).pipe(
      map((res: ILoginObj) => {
        if (res.success) {
          this.setlocalstorage(res);
        }
        return res;
      })
    );
  }

  setlocalstorage(data: ILoginObj) {
    console.log(data.data, typeof data.data[0].linked);

    if (!data && data.data.length == 0) {
      return false
    }
    localStorage.setItem('token', data.token);
    localStorage.setItem('u_id', data.data[0].u_id);
    localStorage.setItem('phone', data.data[0].phone);
    localStorage.setItem('role_id', data.data[0].role_Id);
    localStorage.setItem("name", data.data[0].name);
    localStorage.setItem('fname', data.data['0']['fname']);
    localStorage.setItem('lname', data.data['0']['lname']);
    localStorage.setItem('address', data.data['0']['address']);
    localStorage.setItem('email', data.data['0']['email']);
    localStorage.setItem("lat", data.data[0].location.coordinates[1]);
    localStorage.setItem("lng", data.data[0].location.coordinates[0]);
    localStorage.setItem("profile_img_id", data.data[0]['profile_img_id']);
    localStorage.setItem("roleName", data.data[0]['roleName']);
    localStorage.setItem("lang", data.data[0].lang);
    localStorage.setItem("lngCode", data.data[0].lngCode);
    localStorage.setItem("country", data.data[0].country);
    localStorage.setItem("currencySymbol", data.data[0].currencySymbol);
    localStorage.setItem("currencyCode", data.data[0].currencyCode);
    localStorage.setItem("countryCallingCode", data.data[0].countryCallingCode);
    localStorage.setItem("countryCode", data.data[0].countryCode);
    localStorage.setItem('pushNotifyStatus', data.data[0].pushNotifyStatus);
    localStorage.setItem("enrolled", JSON.stringify(data.data[0].enrolled));
    localStorage.setItem("solidUnits", data.data[0].solidUnits);
    localStorage.setItem("liquidUnits", data.data[0].liquidUnits);
    if (data.data[0].linked == "undefined" || data.data[0].linked == undefined) {
      if (localStorage.getItem('role_id') == '8') {
        localStorage.setItem("linked", "false");
      } else {
        localStorage.removeItem("linked");
      }
    }
    else {
      localStorage.setItem("linked", data.data[0].linked);
    }
    localStorage.setItem("roleId", data.data[0].roleSettingId);
    localStorage.setItem('coord', data.data[0].location)
    data.data[0].role_Id == 15 || data.data[0].role_Id == 18 ? localStorage.setItem("Csort", 'Date') : '';//wholeSeller/retail supplier customers sorting option
    countryList.filter(val => {
      if (val.countryCode == data.data[0].countryCode) {
        localStorage.setItem("currenciesList", JSON.stringify(val.currencySymbol));
      }
    });
    // if (data.data['role_id'] != '8') {
    //   localStorage.setItem('vanNumber', data.data[0]['vanNumber']);
    // }
    if (data.data[0].role_Id == '18') {
      localStorage.setItem('vanNumber', data.data[0]['vanNumber']);
    }
    this.loginsubject.next(data);
  }

  upDateRetailerSupplier(reatilaer) {
    return this.http.post(config.api + 'auth/addVehicleNumber', reatilaer)
  }
  checkUserVannumber(uid) {
    return this.http.post(config.api + 'auth/checkVanNumber', uid)
  }

  updaterawMaterialSettings(priceDetails) {
    return this.http.post(config.api + 'auth/rawMaterialSettings', priceDetails)
  }

  AppVersion(): Observable<any> {
    return this.http.post(config.api + 'auth/appVersion', { version: "2.1.0", skipDate: localStorage.getItem })
  };


  tempUserData(data) {
    this.tempUser = data
  };

  getTempUserData() {
    return this.tempUser;
  };
}
export interface ILoginObj {
  success: boolean,
  data: any[],
  CodeNode: number,
  token: any
}