import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthServicesService } from "./auth-services.service";
@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(
    private authService: AuthServicesService,
    private router: Router) {
       }
  canActivate(route: ActivatedRouteSnapshot): any {
    // return this.authService.isAuthenticate();
    if (!this.authService.isAuthenticate()) {
      return false;
    } else {
      return true;
    }
  }


}
