import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { LayersService } from '../services/layers.service';

@Component({
  selector: 'app-layer-farmer-auto-order-details',
  templateUrl: './layer-farmer-auto-order-details.component.html',
  styleUrls: ['./layer-farmer-auto-order-details.component.scss'],
})
export class LayerFarmerAutoOrderDetailsComponent implements OnInit {
  orderDetail: any;
  constructor(private modal: ModalController, private layerService: LayersService) {
    this.orderDetail = this.layerService.getFarmerAutoOrder()
    console.log(this.orderDetail)
  }

  ngOnInit() { }
  back(val) {
    if (!val) {
      this.modal.dismiss();
    }
  }
}
