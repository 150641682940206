import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { AutoCompletePalcesDirective } from './../directives/auto-complete-palces.directive';
import { BackFlipDirective } from './../back-flip.directive';
import { CommonService } from '../../services/common.service';
import { DealersService } from '../../services/dealers.service';
import { AddDriverService } from '../../services/add-driver.service';
import { PageRefresherService } from '../page-refresher/page-refresher.service';
import { TraderService } from "./../../services/trader.service";
import { LoadingserviceService } from '../loadingservice.service';
import { StaticticsService } from '../services/statictics.service';


@NgModule({
    declarations: [],
    imports: [],
    exports: [],
    providers: [CommonService, DealersService, AddDriverService, PageRefresherService, TraderService,LoadingserviceService,StaticticsService]
})

export class CommonServicesModule { }

// export class CommonServicesModule {
//     static forRoot() {
//         return {
//             ngModule: CommonServicesModule,
//             providers: [CommonService, DealersService, AddDriverService, PageRefresherService, TraderService]
//         }
//     }
// }