import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { config } from '../helper/config';
import { countryList } from '../helper/country';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  private loginsubject = new Subject<any>();
  public loginSubject$ = this.loginsubject.asObservable();
  signupFormFilledData: any;
  tempUser: any;

  constructor(public http: HttpClient) { }
  getRolesList(data): Observable<any> {
    return this.http.post(config.api + 'auth/getRoles', data);
  }
  signup(data): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json")
    return this.http.post(config.api + 'auth/signup', data, { headers }).pipe(
      map((res: ILoginObj) => {
        console.log(res, 111244);
        if (res.success) {
          console.log(res, 12512);
          this.setlocalstorage(res);
        }
        return res;
      })
    );
  }
  setlocalstorage(data: ILoginObj) {
    if (!data && data.data.length == 0) {
      return false
    }
    // console.log(data.token, 4000)
    localStorage.setItem('token', data.token);
    localStorage.setItem('u_id', data.data[0].u_id);
    localStorage.setItem('phone', data.data[0].phone);
    localStorage.setItem('role_id', data.data[0].role_Id);
    localStorage.setItem('fname', data.data['0']['fname']);
    localStorage.setItem('lname', data.data['0']['lname']);
    localStorage.setItem('email', data.data['0']['email']);
    localStorage.setItem('country', data.data['0']['country']);
    data.data[0].role_Id == 15 || data.data[0].role_Id == 18 ? localStorage.setItem("Csort", 'Date') : ''; //wholeSeller/retail supplier customers sorting option
    this.loginsubject.next(data);
  }

  // countrySearch(searchTerm):Observable<any>{
  //   return this.http.get(`https://restcountries.eu/rest/v2/name/${searchTerm.name}`);
  // }
  countrySearch(searchTerm): Observable<any> {
    // return this.http.get(`https://restcountries.eu/rest/v2/name/${searchTerm}`)// THIRD PURTY 
    return this.http.post(config.api + 'auth/coutrySearch', { searchFor: searchTerm })
  }
  Checking(email): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json")
    return this.http.post(config.api + 'auth/check', email, { headers })
  }

  registeredData(data) {
    this.signupFormFilledData = data;
  }
  getSignupData() {
    console.log(this.signupFormFilledData, 70)
    return this.signupFormFilledData
  }

  AppVersion(data): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json")
    return this.http.post(config.api + 'auth/appVersion', data, { headers });
  }
  
  tempUserEdit(data): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json")
    return this.http.post(config.api + 'auth/tempUserToUser', data, { headers }).pipe(
      map((res: ILoginObj) => {
        console.log(res, 111244);
        if (res.success) {
          console.log(res, 12512);
          this.setlocalstorage(res);
        }
        return res;
      })
    );
  }
  




}
export interface ILoginObj {
  success: boolean, data: any[], CodeNode: number,
  token: any
}
