import { Injectable } from '@angular/core';
import { config } from '../helper/config';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class InventoryDetailService {

  constructor(private http: HttpClient, private route: ActivatedRoute) {

  }
  inventoryInfo(data): Observable<any> {
    return this.http.post(config.api + "inventory/oneInventory", data);
  }

  public data: any;

  setData(data) {
    this.data = data
  }

  getData() {
    return this.data;
  }

  closeInventory(data): Observable<any> {
    return this.http.post(config.api + "inventory/inventoryClose", data);
  }
  deleteInvetory(id): Observable<any> {
    return this.http.post(config.api + "inventory/deleteInventorydata", id);
  }
  moreInInventory(data): Observable<any> {
    return this.http.post(config.api + "inventory/moreInventory", data);
  }

  ///edit servise calls
  insertInventory(data): Observable<any> {
    return this.http.post(config.api + "inventory/insertInventory", data);
  }

  updateInventoryById(data): Observable<any> {
    return this.http.post(config.api + "inventory/updateInventoryById", data);
  }

  getInvententoryById(data): Observable<any> {
    return this.http.post(config.api + "inventory/getInvententoryById", data);
  }

  pastDataCheck(data): Observable<any> {
    return this.http.post(config.api + "inventory/pastDataCheck", data);
  }

  removeDoc(data): Observable<any> {
    return this.http.post(config.api + "auth/removePDF", data);
  }

  getActiveInv(data) {
    return this.http.post(config.api + "inventory/getActiveInv", data);
  }

  updateInventoryByDay(dayData) {
    return this.http.post(config.api + "inventory/updateInventoryByDay", dayData)
  }
  editAlreadyExitData(editData) {
    return this.http.post(config.api + "inventory/editInventoryDoc", editData);
  }
  // genrtPDF(data): Observable<any> {
  //   return this.http.post(config.api + "inventory/dwnldBoilrsPDF", data)
  // }
  // addfeedstockForInvEditDayWise(data): Observable<any> {
  //   return this.http.post(config.api + "inventory/editFeedForInv", data);
  // }

  addfeedstockForInvDayWise(data): Observable<any> {
    return this.http.post(config.api + "inventory/insertFeedForInv", data);
  }

  consumedFeedForInvDayWise(data): Observable<any> {
    return this.http.post(config.api + "inventory/comsumedFeedForInv", data);
  }

  transferFeedForInvDayWise(data): Observable<any> {
    return this.http.post(config.api + "inventory/transferFeedForInv", data);
  }

  getStockForInv(data): Observable<any> {
    return this.http.post(config.api + "inventory/getFeedStockForInventory", data);
  }

  removeAddedFeed(data): Observable<any> {
    return this.http.post(config.api + "inventory/removeFeedDoc", data);
  }

  removeConsumedFeed(data): Observable<any> {
    return this.http.post(config.api + "inventory/removeFeedDoc", data);
  }

  removeTransferedFeed(data): Observable<any> {
    return this.http.post(config.api + "inventory/removeFeedDoc", data);
  }

  normalFeedSystem(data): Observable<any> {
    return this.http.post(config.api + "inventory/normalFeedSystem", data);
  }

  checkfeedStockDoc(edit): Observable<any> {
    return this.http.post(config.api + "inventory/checkfeedStock", edit);
  }
  checkEditStock(editDoc): Observable<any> {
    return this.http.post(config.api + "inventory/checkEditFeedStock", editDoc);
  }
  removeAddFeedDocs(docs)  {
    return this.http.post(config.api + "inventory/removeAddDocs", docs);
  }
}
