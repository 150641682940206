
import { SharingService } from './../services/sharing.service';
import { TraderService } from './../../services/trader.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { TraderOrderShowSortPopupComponent } from "../trader-order-show-sort-popup/trader-order-show-sort-popup.component";
import { IonSlides, Platform, PopoverController, ToastController } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { TraderCreateOrderComponent } from '../trader-create-order/trader-create-order.component';
import { AlertController } from '@ionic/angular';
import { CommonService } from 'src/services/common.service';
import { TranslateConfigService } from '../services/translate-config.service';
import { TranslateService } from '@ngx-translate/core';
import { LoadingserviceService } from '../loadingservice.service';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { LiftingsPriceSettingComponent } from '../liftings-price-setting/liftings-price-setting.component';
import { OrderLiftingvanComponent } from '../trader/order-liftingvan/order-liftingvan.component';
import { Events } from '../services/events.service';
import { BoxviewOrderInfoComponent } from '../boxview-order-info/boxview-order-info.component';
import { DatamanageService } from '../services/datamanage.service';
import { SupervisorService } from 'src/services/supervisor.service';
import { RolechangeService } from '../services/rolechange.service';
import { MarketService } from '../market-price-indicators/market.service';
import { LeasePage } from '../lease/lease/lease.page';
import { LeaseService } from 'src/services/lease.service';
@Component({
  selector: 'app-trader-order-show',
  templateUrl: './trader-order-show.page.html',
  styleUrls: ['./trader-order-show.page.scss'],
})

export class TraderOrderShowPage implements OnInit {
  @ViewChild(IonSlides) slides: IonSlides;
  linked: string = localStorage.getItem('linked');
  countryCallingCode: string = localStorage.getItem('countryCallingCode');
  country = localStorage.getItem('country').toLowerCase();
  currencySymbol: string = localStorage.getItem('currencySymbol');
  userRole: any = localStorage.getItem('role_id');
  segmentButtons = [
    { name: "Dashboard", value: "allOrders" },
    { name: "UnassignedOrders", value: "unassignedOrders" }
  ];
  incomingOrders = [];
  sortOption;
  tradeEndAt: any;
  tradeStartAt: any;
  ordersList = [];
  vechilesData = [];
  summaryData: any;
  selected: any;
  vanId: any;
  count = 0;
  vanModel: any;
  numberCount = 0;
  pendingSalesCount: number = 0;
  noMsg = false;
  userAccessRole: string = '';
  pendingOrderCount: number;
  // selectedTab: string = "allOrders";
  buttonShow: boolean = false;
  index: any;
  assignedOrdersList = [];
  rolesList = [
    {
      role_id: "1",
      roleName: 'Farmer',
      icon: '../assets/roles/farmer.svg'
    },
    {
      role_id: "3",
      roleName: 'Supervisor',
      icon: '../assets/roles/supervisor.svg'
    },
    {
      role_id: "2",
      roleName: 'Trader',
      icon: '../assets/roles/trader.svg'
    },
    {
      role_id: "8",
      roleName: 'Trader-Supervisor',
      icon: '../assets/roles/vandriver.svg'
    },
    {
      role_id: "10",
      roleName: 'Chicken Shop',
      icon: '../assets/roles/shop.svg'
    }
  ];

  broilerprice: any;
  market_price: any;
  chickprice: any;
  hatchprice: any;
  eggsprice: any;

  sliderOptions = {
    initialSlide: 0,
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true
    }
  }

  selectedRole = localStorage.getItem('roleName');
  default: string;
  units = localStorage.getItem("solidUnits");
  currency = localStorage.getItem("currencySymbol")
  TraderBookedDetails: any;
  orders: any;
  curDate = new Date();
  postings = [];
  cardData: {
    shopName: string;
    requiredWeight: number;
    numberOfBirds: number;
    status: string;
    statusName: any;
    price: number;
    type: string;
  }[];
  slidesData = [
    {
      name: "broiler"
    },
    {
      name: "chick"
    },
    {
      name: "eggs"
    },
    {
      name: "hatchEggs"
    },
  ];
  commonArray;
  public totalLeases: any;

  constructor(
    protected modalCtrl: ModalController,
    private alertController: AlertController,
    public traderservice: TraderService,
    private toastController: ToastController,
    private route: Router,
    private translate: TranslateService,
    private popoverCtrl: PopoverController,
    private firebaseX: FirebaseX,
    private commonService: CommonService,
    public loading: LoadingserviceService,
    public events: Events,
    public shareData: SharingService,
    public platform: Platform,
    public handler: DatamanageService,
    private translateConfigService: TranslateConfigService,
    private supervisorService: SupervisorService,
    public roleChangeService: RolechangeService,
    private activeAoute: ActivatedRoute,
    private marketService: MarketService,
    public LeaseService: LeaseService,
  ) {

    this.default = localStorage.getItem("lngCode")
    this.commonService.setLang(this.default);
    this.translateConfigService.setLanguage(this.default);
    this.activeAoute.queryParams.subscribe((params) => {
      let getInfo = {
        skip: 0
      }
      this.getIncomingTraderOrders(getInfo);
      // if (params) {
      //   if (params.createNewOrder || params.acceptSale) {
      //     let getInfo = {
      //       skip: 0
      //     }
      //     // this.getTraderPriceSummery();
      //   };
      // };
    });
  }

  priceStart = true;
  ionViewWillEnter() {
    this.loadNext();

    // this.commonService.marketPricesSummary().subscribe((data: any) => {
    //   if (data.length) {
    //     this.broilerprice = data[0];
    //     this.chickprice = data[1];
    //     this.eggsprice = data[2];
    //     this.hatchprice = data[3];
    //   }
    // });

    this.supervisorService.orderProcessClear();
    // this.supervisorService.orderProcessSet({});
    this.selectedRole = localStorage.getItem('roleName');
    this.loading.loadingPresent();
    setTimeout(() => {
      this.loading.loadingDismiss();
    }, 1500);
  }

  postingsGet() {
    this.postings = [];
    this.commonService.ViewLeasePostings({ skip: 0, category: "all", from: "home" }).subscribe(data => {
      if (data) {
        data.forEach(element => {
          if (element.description.length > 25) {
            element['showMore'] = true;
            this.postings.push(element)
          } else {
            element['showMore'] = false
            this.postings.push(element)
          }
        });
        // this.postings = data;
      }
      else {
        this.postings = [];
      }
    })
  }

  capitalise(word) {
    if (!word) return word;
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }

  getDate(data) {
    let todayDate = new Date();
    let sentOnDate = new Date(data);
    sentOnDate.setDate(sentOnDate.getDate());
    let differenceInTime = todayDate.getTime() - sentOnDate.getTime();
    // To calculate the no. of days between two dates
    let differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
    return differenceInDays;
  }

  async createLease() {
    this.modalCtrl.create({
      component: LeasePage
    }).then(modal => {
      modal.present();
      modal.onDidDismiss().then(data => {
        console.log(data, 228)
        if (data.data.farm && !data.data.newData.latitude && !data.data.newData.longitude) {
          console.log("error in location");
          this.loading.showError("error in location selection")
        }
        else {
          if (data.data.farm) {
            this.loading.loadingPresent();
            this.LeaseService.postLease(data.data.newData).subscribe(result => {
              console.log(result, 235)
              if (result.success) {
                this.loading.loadingDismiss();
                this.postingsGet();
              }
            })
          }
        }
      });
    });
  }

  showMore(data) {
    data['showMore'] = !data['showMore']
  }

  leaseDetails(data) {
    this.route.navigate(['/lease-home/lease-details']);
    this.LeaseService.viewLeaseData(data)
  }

  ionViewDidEnter() {
    //code for data with events
    // this.events.subscribe('user:created', (data: any) => {    // initial line. now changed to user:trader
    this.events.subscribe('user:trader', (data: any) => {
      if (data) {
        let role = data.user['role_Id'];
        this.userRole = role;
        if (this.userRole == 2 || this.userRole == 8) {
          let getInfo = {
            skip: 0
          }
          // this.getIncomingTraderOrders(getInfo);
          // this.events.destroy('user:created');
        }
      }
      this.events.destroy('user:trader')  // destroy trader role event.
    });
    if (localStorage.getItem('role_id') == "8") {
      this.traderservice.adminAccessCheck({ uId: localStorage.getItem("u_id") }).subscribe((res: any) => {
        if (res.success) {
          this.userAccessRole = "admin";
          this.supervisorService.setTraderData(res.data)
          if (localStorage.getItem('role_id') == "8") {
            localStorage.setItem("linked", "true");
          } else {
            localStorage.removeItem("linked");
          }
        } else if (!res.success) {
          if (localStorage.getItem('role_id') == "8") {
            localStorage.setItem("linked", "false");
          } else {
            localStorage.removeItem("linked");
          }
        }
      });
    }
  }

  loadNext() {
    this.priceStart = true;
    this.commonArray = [];
    this.slides.getActiveIndex().then((val) => {
      console.log(val);
      let rates;
      if (val == 0) {
        rates = "broiler"
      }
      else if (val == 1) {
        rates = "chick"
      }
      else if (val == 2) {
        rates = "eggs"
      }
      else if (val == 3) {
        rates = "hatchEggs"
      }
      this.commonService.marketIndicators({
        priceFor: rates
      }).subscribe((res) => {
        if (res.success) {
          this.commonArray = res.data;
          this.priceStart = false;
        }
      })
    })
  }

  loadPrev(data) {
    this.priceStart = true;
    this.commonArray = [];
    this.slides.getActiveIndex().then((val) => {
      console.log(val);
      let rates;
      if (val == 0) {
        rates = "broiler"
      }
      else if (val == 1) {
        rates = "chick"
      }
      else if (val == 2) {
        rates = "eggs"
      }
      else if (val == 3) {
        rates = "hatchEggs"
      }
      this.commonService.marketIndicators({
        priceFor: rates
      }).subscribe((res) => {
        if (res.success) {
          this.commonArray = res.data;
          this.priceStart = false;
        }
      })
    })
  }

  getName(data) {
    if (data == "broiler") {
      return "Broiler Rates"
    }
    else if (data == "chick") {
      return "Chick Rates"
    }
    else if (data == "eggs") {
      return "Egg Rates"
    }
    else {
      return "Hatch Egg Rates"
    }
  }

  stepOrders = [];
  contacts = [];
  contactsCount: number = 0;
  getIncomingTraderOrders(info) {
    this.postingsGet();
    if (this.userRole == 2) {
      this.traderservice.farmerListForTrader({ skip: 0, contactType: "trader-farmer" }).subscribe((res) => {
        this.contacts = [];
        this.contactsCount = 0;
        this.contacts = res.data;
        this.contacts.forEach(element => {
          if (element['requestAccepted'] == false && (element['requestedRole'] == 1 || element['requestedRole'] == 3)) {
            this.contactsCount++;
          }
        });
      })
    }
    else {
      this.traderservice.ContactsList({ skip: 0, contactType: "trader-farmer" }).subscribe((res) => {
        this.contacts = [];
        this.contactsCount = 0;
        this.contacts = res.data;
        this.contacts.forEach(element => {
          if (element['requestAccepted'] == false && (element['requestedRole'] == 1 || element['requestedRole'] == 3)) {
            this.contactsCount++;
          }
        });
      })
    }

    this.traderservice.unassignedOrders({ skip: this.ordersList.length }).subscribe((orders: any) => {
      this.supervisorService.orderSales.next(orders);
      if (orders.success) {
        this.supervisorService.pendingOrderSales$.subscribe((orderCount) => {
          if (orderCount.data.length) {
            this.pendingSalesCount = orderCount.data.length;
          } else {
            this.pendingSalesCount = 0;
          }
        });
      }
    })

    this.traderservice.getIncomingOrders(info).subscribe((data: any) => {
      if (data.success) {
        if (data.data.length > 0) {
          this.supervisorService.purchaseCountSet({ count: data.data.length });
          this.noMsg = false;
          this.incomingOrders = data.data;
          this.incomingOrders.forEach((order, i) => {
            if (i < 5) {
              this.incomingOrders[i]['disabled'] = false;
            } else {
              this.incomingOrders[i]['disabled'] = true;
            }
          })
        }
        else {
          this.supervisorService.purchaseCountSet({ count: 0 });
          this.incomingOrders = [];
        }
      }
    });
  }

  receivedSales() {
    this.route.navigate(['/unassigned-sales'])
  }
  receivedRequests() {
    this.route.navigate(['/linked-contacts'])
  }

  //android (or) ios push token fetching from device 
  pushNotificationSetUp() {
    // save the token server-side and use it to push notifications to this device
    this.firebaseX.getToken().then(token => {
      if (token) {
        this.commonService.savePushToken(Object.assign({ pushToken: token }, { u_id: localStorage.getItem('u_id') })).subscribe(
          (result) => {
            if (result.success) {

            } else {

            }
          }, (error) => {

          }
        )
      }
    }).catch(error => {
      console.error('Error getting token', error)
    });

    this.firebaseX.onMessageReceived().subscribe((data: any) => {
      if (data.success) {
        this.route.navigateByUrl("/addfarmer-supervisor");
      }
      // this.router.navigateByUrl("/notifications");
    }, (error) => {
    });

    this.firebaseX.onTokenRefresh().subscribe((token: string) => {
    });
  }

  async purchaseDetails(purchase) {
    this.supervisorService.orderProcessClear();
    // let temp = {
    //   id: data._id, days: data.days, info: data
    // }
    // this.inventory.setData(temp)
    if (purchase['disabled']) {
      console.log("disabled true");
      const alert = await this.alertController.create({
        header: "Purchase Limit",
        cssClass: "wholeSellerAlert",
        message: "You already have 5 purchases. You cannot edit this purchase. Please delete a purchase to edit this one",
        buttons: [
          {
            text: this.translate.instant('buttons.cancel')
          },
          {
            text: this.translate.instant('buttons.ok'),
            handler: () => {

            }
          },
        ]
      })
      await alert.present();

    } else {
      console.log(this.route.url)
      let navigationExtras: NavigationExtras = { state: { urlFrom: this.route.url, incomingOrderId: purchase._id, order: purchase } };
      this.route.navigate(['purchase-detail-show'], navigationExtras);
    }
  }


  async createPurchase() {
    console.log(this.ordersList, "list");
    if (this.ordersList.length >= 5) {
      console.log("cannot add. delete an order first");
      const alert = await this.alertController.create({
        header: "Purchase Limit",
        cssClass: "wholeSellerAlert",
        message: "You already have 5 purchases. Please delete a purchase to add a new one.",
        buttons: [
          {
            text: this.translate.instant('buttons.cancel')
          },
          {
            text: this.translate.instant('buttons.ok'),
            handler: () => {

            }
          },
        ]
      })
      await alert.present();
    } else {
      this.supervisorService.setOrderType({ orderType: "purchase", stepEnable: true })
      this.supervisorService.orderProcessSet({
        orderProcessTab: "purchase",
        orderProcess: "newOrder",
        orderProcessBy: localStorage.getItem('role_id')
      });
      this.route.navigate(["/order-process-select-van"]);
    }
  }

  disclaimer() {
    this.route.navigate(['/disclaimer']);
  }

  marketpricesPage(tab) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "active": tab,
      }
    };
    this.route.navigate(["/home/tabs/market-indicators"], navigationExtras);
    this.marketService._marketQueryParams = navigationExtras;
  }

  transactionsPage(item) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        vanId: item.contactSettingId,
        from: "fullDetails",
        vanNumber: item.vanNumber,
      }
    };
    this.route.navigate(['trader/liftingvan-details'], navigationExtras)
  };

  farmerDetailsPage(item) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        farmerData: JSON.stringify(item.farmerId),
      }
    };
    this.route.navigate(["trader/farmer-order-details"], navigationExtras)
  };

  roleChangeEvent(event) {

  }

  async adjustPrices() {
    this.modalCtrl.create({
      component: LiftingsPriceSettingComponent,
      componentProps: { inventoryId: 0, invenLocation: "" }
    }).then(modal => {
      modal.present();
      modal.onDidDismiss().then(data => {
        if (data.data.status) {
          // this.loading.loadingPresent();
          // this.layerService.salesInventory().subscribe((data: any) => {
          //   if (data.success) {
          //     this.farms = data.data;
          //     
          //     (this.farms.length > 0) ? this.loaderOff = false : this.loaderOff = true;
          //     this.loading.loadingDismiss();
          //   } else {
          //     this.loading.loadingDismiss();
          //   }
          // });
        } else {

        }
      });
    });
  }

  doRefresh(event) {
    this.supervisorService.orderProcessClear();
    this.getIncomingTraderOrders({ skip: 0 });
    // if (localStorage.getItem('country') == "India") {
    //   this.commonService.marketPricesSummary().subscribe((data: any) => {
    //     if (data.length) {
    //       this.broilerprice = data[0];
    //       this.chickprice = data[1];
    //       this.eggsprice = data[2];
    //       this.hatchprice = data[3];
    //     }
    //   });
    // }
    setTimeout(() => {
      event.target.complete();
    }, 1000);
  }

  async createPurchaseSale() {
    console.log(this.ordersList.length, "create purchase saleF");

    if (this.ordersList.length >= 5) {
      const alert = await this.alertController.create({
        header: "Purchase Limit",
        cssClass: "wholeSellerAlert",
        message: "You already have 5 purchases. Please delete a purchase to create a new purchase.",
        buttons: [
          {
            text: this.translate.instant('buttons.cancel')
          },
          {
            text: this.translate.instant('buttons.ok'),
            handler: () => {

            }
          },
        ]
      })
      await alert.present();
    }
    else {
      this.supervisorService.orderProcessClear();
      this.supervisorService.setOrderType({ orderType: "purchase", stepEnable: true });
      this.supervisorService.orderProcessSet({
        orderProcessTab: "purchase",
        orderProcessBy: localStorage.getItem('role_id'),
        incomingOrder: {},
        orderProcess: "newOrder"
      });
      this.route.navigate(["/order-process-select-van"]);
    }
  }

  async showError(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 3000,
      position: 'bottom',
      animated: true,
      cssClass: "my-custom-class",
    });
    toast.present();
  }

  // if (localStorage.getItem("role_id") == "2" || localStorage.getItem("role_id") == "8") {
  //   this.traderservice.incomingList$.subscribe((data: any) => {
  //     this.ordersList = data;
  //     if (this.ordersList.length > 0) {
  //       this.noMsg = false;
  //       setTimeout(() => {
  //         this.noMsg = false;
  //       }, 100)
  //     }
  //   });
  // }

  // sorting functionality starts
  // async SortPopover(event) {
  //   const popover = await this.popoverCtrl.create({
  //     component: TraderOrderShowSortPopupComponent,
  //     componentProps: {
  //       data: 'orders',
  //       for: 'traderorders',
  //       selected: this.selected || "price",
  //     },
  //     event: event,
  //     mode: "ios",
  //     cssClass: 'sort-popover',
  //   });
  //   popover.onDidDismiss().then((result: any) => {
  //     this.selected = result.data.newdata;
  //     console.log(this.selected, 251);
  //     // if (result.data == undefined) {

  //     // } else {
  //     //   this.traderservice.traderHomePageData({ skip: 0, sort: result.data.newdata }).subscribe((data) => {
  //     //     if (data.success) {
  //     //       if (data.data[0]['roughEstimatedWeight']) {
  //     //         this.ordersList = data.data;
  //     //       }
  //     //       this.summaryData = data.data[0];
  //     //     }
  //     //   });
  //     // }
  //   });
  //   return await popover.present();
  // }


  // getTraderPriceSummery() {
  //   this.traderservice.traderPriceSummary({}).subscribe((data: any) => {
  //     if (data.success) {
  //       this.summaryData = data.data[0];
  //     }
  //   });
  // }

  // //laoding more data.
  // loadMoreData(infiniteScroll) {
  //   setTimeout(() => {
  //     infiniteScroll.target.complete();
  //   }, 2000);
  //   setTimeout(() => {
  //     if (!(this.incomingOrders.length % 10)) {
  //       // if (this.selectedTab == "unassignedOrders") {
  //       //   // this.tabSegmentChange(this.selectedTab, this.ordersList.length);
  //       // } else {
  //       //   infiniteScroll.target.complete();
  //       // }
  //     } else {
  //       infiniteScroll.target.complete();
  //     }

  //     // if (this.selectedTab == 'allOrders') {
  //     //   if (!(this.ordersList.length % 10)) {
  //     //     this.traderservice.getIncomingOrders({ skip: this.ordersList.length }).subscribe((dat: any) => {
  //     //       if (dat.success) {
  //     //         if (dat.data.length) {
  //     //           dat.data.forEach((ele) => {
  //     //             this.ordersList.push(ele);
  //     //           });
  //     //           this.noMsg = false;
  //     //           this.traderservice.saveOrdersLocal(dat.data);
  //     //         }
  //     //         else {
  //     //           this.noMsg = true;
  //     //           this.ordersList = [];
  //     //         }
  //     //       }
  //     //     });
  //     //   } else {
  //     //     infiniteScroll.target.complete();
  //     //   }
  //     // } else if (this.selectedTab == 'unassignedOrders') {
  //     //   if (!(this.ordersList.length % 10)) {
  //     //     this.traderservice.unassignedOrders({ skip: this.ordersList.length }).subscribe((orders: any) => {
  //     //       if (orders.success) {
  //     //         if (orders.data.length) {
  //     //           this.traderservice.unassignedOrdersList.next(orders.data)
  //     //           orders.data.forEach((ele) => {
  //     //             this.ordersList.push(ele);
  //     //           });
  //     //           this.noMsg = false;
  //     //         }
  //     //         else {
  //     //           this.noMsg = true;
  //     //           this.ordersList = [];
  //     //         }
  //     //       }
  //     //     })
  //     //   }
  //     //   else {
  //     //     infiniteScroll.target.complete();
  //     //   }
  //     // }
  //   }, 1000);
  // }

  ngOnInit() {
    // this.traderservice.unassignedOrders({ skip: 0 }).subscribe((orders: any) => {
    //   if (orders.success) {
    //     if (orders.data.length > 0) {
    //       this.pendingOrderCount = orders.data.length;
    //       console.log(this.pendingOrderCount, "pending");
    //     } else {
    //       this.pendingOrderCount = 0;
    //     }
    //   }
    // });
  }

  // async deleteIncoming(data) {
  //   const alert = await this.alertController.create({
  //     cssClass: 'wholeSellerAlert',
  //     header: "Delete Order?",
  //     message: "Are you sure you want to delete this purchase?",
  //     buttons: [
  //       {
  //         text: this.translate.instant('buttons.cancel'),
  //         role: 'cancel',
  //         cssClass: 'secondary',
  //         handler: () => {
  //         }
  //       },
  //       {
  //         text: this.translate.instant('buttons.ok'),
  //         handler: (res) => {
  //           console.log("ok clicked")
  //           let id = { _id: data['_id'] }
  //           this.supervisorService.deleteIncomingOrder(id).subscribe((res) => {
  //             console.log("deleted successfully");
  //             if (res.success) {
  //               this.getIncomingTraderOrders({ skip: 0 });
  //               // this.getTraderPriceSummery();
  //             }
  //           })
  //         }
  //       }
  //     ]
  //   });
  //   await alert.present();
  // }


  // async deleteOutgoing(data) {
  //   const alert = await this.alertController.create({
  //     cssClass: 'wholeSellerAlert',
  //     header: "Delete Order?",
  //     message: "Are you sure you want to delete this sale?",
  //     buttons: [
  //       {
  //         text: this.translate.instant('buttons.cancel'),
  //         role: 'cancel',
  //         cssClass: 'secondary',
  //         handler: () => {
  //         }
  //       },
  //       {
  //         text: this.translate.instant('buttons.ok'),
  //         handler: (res) => {
  //           console.log(data, "ok clicked")
  //           this.supervisorService.deleteOutgoingOrder(data).subscribe((res) => {
  //             console.log(res);
  //             this.getIncomingTraderOrders({ skip: 0 });
  //             // this.getTraderPriceSummery();

  //           })
  //         }
  //       }
  //     ]
  //   });
  //   await alert.present();
  // }



  // detailsPage(item) {
  //   let navigationExtras: NavigationExtras = {
  //     queryParams: {
  //       vanId: item.contactSettingId,
  //       from: "fullDetails"
  //     }
  //   };
  //   this.route.navigate(['trader/liftingvan-details'], navigationExtras)
  // }

  // detailsDoc(item) {
  //   let navigationExtras: NavigationExtras = {
  //     queryParams: {
  //       vanId: item.contactSettingId,
  //       from: "detailsDoc"
  //     }
  //   };
  //   this.route.navigate(['trader/liftingvan-details'], navigationExtras)
  // }

  // input = [];
  // async openAlertCheck(data, i) {
  //   this.input = [
  //     {
  //       name: 'Edit Order',
  //       type: 'radio',
  //       label: "Edit Order",
  //       value: "editOrder",
  //       disabled: data.orderStatus == "delivery" || data.orderStatus == "paid"
  //     },
  //     {
  //       name: 'Boxes Assign',
  //       type: 'radio',
  //       label: "Boxes Assign",
  //       value: "boxesAssign"
  //     },
  //     {
  //       name: 'Status Update',
  //       type: 'radio',
  //       label: "Status Update",
  //       value: "statusUpdate"
  //     }
  //   ]
  //   if (this.selectedTab == "outgoingOrders") {
  //     const alert = await this.alertController.create({
  //       cssClass: 'wholeSellerAlert',
  //       header: "Choose an Option",
  //       inputs: this.input,
  //       buttons: [
  //         {
  //           text: this.translate.instant('buttons.cancel'),
  //           role: 'cancel',
  //           cssClass: 'secondary',
  //           handler: () => {

  //           }
  //         },
  //         {
  //           text: this.translate.instant('buttons.ok'),
  //           handler: (res) => {
  //             if (res == "editOrder") {
  //               this.orderProcessCreate('editIndependentOrder', data)
  //             } else if (res == "boxesAssign") {
  //               let order = this.incomingOrders.filter(xd => xd._id == data['incomingOrderId']);
  //               // if (order.length) {
  //               // this.boxAssinge(order[0], this.selectedTab, data)
  //               // } else {
  //               // }
  //               // if(this.selectedTab == 'incomingOrders')
  //               // var navigationExtras: NavigationExtras = {
  //               //   state: {
  //               //     CCdetails: this.behaviorArray,
  //               //     from: "",
  //               //     activeTab: this.selectedTab,
  //               //   }
  //               // };
  //               // 
  //               // this.route.navigate(["boxesView"], navigationExtras)
  //             } else if (res == "statusUpdate") {
  //               // this.openAlert(data, i);
  //             }
  //           }
  //         }
  //       ]
  //     });
  //     await alert.present();
  //   } else if (this.selectedTab == 'allOrders' && data.orderStatus != "waiting") {

  //   } else {
  //     this.openAlert(data, i)
  //   }
  // }

  // async openAlert(data, i) {
  //   console.log(data, this.selectedTab);
  //   this.supervisorService.setInputData(data, this.selectedTab);
  //   this.vanId = data.contactSettingId;
  //   const alert = await this.alertController.create({
  //     cssClass: 'wholeSellerAlert',
  //     header: this.translate.instant('supplier.StatusUpdate'),
  //     inputs: this.supervisorService.getInputValues(),
  //     buttons: [
  //       {
  //         text: this.translate.instant('buttons.cancel'),
  //         role: 'cancel',
  //         cssClass: 'secondary',
  //         handler: () => {

  //         }
  //       }, {
  //         text: this.translate.instant('buttons.ok'),
  //         handler: (res) => {
  //           if (res == 'accept') {
  //             if (data.orderStatus == "waiting" && data.orderCategory == "outgoingorder") {
  //               this.supervisorService.orderProcessClear();
  //               Object.assign(data, { orderProcess: data['orderCreatedRole'] == 10 ? "unassignedOrder" : 'newOrder' })
  //               this.supervisorService.orderProcessClear();
  //               this.supervisorService.orderProcessSet({ unassignedOrder: data, orderFrom: data['orderProcess'], orderProcess: data['orderProcess'] });
  //               this.supervisorService.setOrderType({ orderType: "sale", stepEnable: true })
  //               this.route.navigate(["/order-process-select-van"])
  //             } else if ((this.selectedTab == "incomingOrders" || this.selectedTab == 'allOrders') && data.orderStatus == "waiting" && data['orderCategory'] == 'incomingorder') {
  //               this.traderservice.updateOrderStatus({ _id: data._id, OrderStatus: "inventoryassigned" }).subscribe((res) => {
  //                 if (res.success) {
  //                   let getInfo = {
  //                     skip: 0
  //                   }
  //                   this.getIncomingTraderOrders(getInfo);
  //                   // this.traderservice.getIncomingOrders({ skip: 0 }).subscribe((data) => {
  //                   //   if (data.success) {
  //                   //     this.ordersList = [];
  //                   //     if (data.data.length) {
  //                   //       this.ordersList = data.data;
  //                   //       this.noMsg = false;
  //                   //       this.incomingOrders = data.data;
  //                   //     }
  //                   //     else {
  //                   //       this.noMsg = true;
  //                   //       this.ordersList = [];
  //                   //     }
  //                   //   }
  //                   // });
  //                 }
  //               })
  //             } else {
  //               this.orderProcessCreate('chickenshop', data)
  //             }
  //           } else if (res == 'reject') {

  //             if ((this.selectedTab == "incomingOrders" || this.selectedTab == 'allOrders') && data['orderCategory'] == 'incomingorder' && data.orderStatus == "waiting") {
  //               this.traderservice.updateOrderStatus({ _id: data._id, OrderStatus: "reject" }).subscribe((res) => {
  //                 this.ordersList = this.ordersList.filter(x => x._id != data._id)
  //               })
  //             } else {

  //               this.traderservice.cancelAutoOrder({ _id: data._id }).subscribe((deleteOrder) => {
  //                 if (deleteOrder.success) {
  //                   this.ordersList = this.ordersList.filter(x => x._id != data._id)
  //                 }
  //               })
  //             }
  //           } else if (res == 'boxassigned') {
  //             if (data.orderCategory == "outgoingorder") {
  //               // this.boxAssinge(this.ordersList[i], this.selectedTab, data)
  //             } else {
  //               // this.boxAssinge(data, this.selectedTab, { orderCategory: 'incomingorder' })
  //             }
  //           } else if (res == "delivery" || res == 'paid') {
  //             if (this.selectedTab == 'allOrders' || this.selectedTab == "outgoingOrders") {
  //               if (data.orderCategory == "incomingorder") {
  //                 if (res == "delivery") {

  //                   // start comment from here
  //                   this.traderservice.incomingOrderStatus({ 'orderDocId': data._id, "status": res }).subscribe((updateStatus) => {
  //                     this.ionViewDidEnter()
  //                     if (updateStatus.success) {

  //                       if (this.selectedTab == 'incomingOrders') {
  //                         if (res == 'delivery') {
  //                           // this.ordersList.map(xd => {
  //                           //   if (xd._id == data._id) {
  //                           //     xd['orderStatus'] = 'delivery';
  //                           //   }
  //                           // })
  //                         } else if (res == "paid") {
  //                           this.ordersList = this.ordersList.filter(xd => xd._id != data._id);
  //                         }

  //                       }
  //                     }
  //                   })
  //                   // stop comment here
  //                 }
  //               }
  //               else if (this.selectedTab == "outgoingOrders" || data.orderCategory == "outgoingorder") {

  //                 // start comment here
  //                 this.traderservice.outgoingOrderStatus({ 'orderDocId': data._id, "status": res }).subscribe((updateStatus) => {
  //                   if (updateStatus.success) {
  //                     this.ionViewDidEnter();
  //                     if (this.selectedTab == 'outgoingOrders') {

  //                       if (res == "paid") {
  //                         // this.ordersList = this.ordersList.filter(xd => xd._id != data._id);
  //                       } else if (res == 'delivery') {

  //                         // this.ordersList.map(xd => {
  //                         //   if (xd._id == data._id) {
  //                         //     xd['orderStatus'] = 'delivery';
  //                         //   }
  //                         // })
  //                       }
  //                     }
  //                   }
  //                 })
  //                 // stop comment here
  //               }
  //             }
  //           }

  //         }
  //       }
  //     ]
  //   });
  //   await alert.present();
  // }


  // behaviorArray = [];
  // forbehaviorData() {
  //   this.behaviorArray = [];
  //   for (var j = 0; j < this.ordersList.length; j++) {
  //     if (this.vanId == this.ordersList[j].vanId) {

  //       if (this.ordersList[j].orderStatus == "farmassigned" || this.ordersList[j].orderStatus == "boxassigned") {
  //         this.behaviorArray.push(this.ordersList[j]);
  //       }
  //     }
  //   }
  // }

  // async openInfo(item) {
  //   console.log(this.selectedTab);

  //   const popover = await this.popoverCtrl.create({
  //     component: BoxviewOrderInfoComponent,
  //     componentProps: {
  //       CCorder: item,
  //       reason: this.selectedTab
  //     },
  //     translucent: true
  //   });
  //   return await popover.present();
  // }


  // tabSegmentChange(event, ln) {
  //   // this.selectedTab = event.target.value;
  //   this.selectedTab = event;
  //   console.log(this.selectedTab, ln, 524);
  //   let getInfoOrders = {
  //     skip: ln
  //   }
  //   if (this.selectedTab == "unassignedOrders") {
  //     this.ordersList = [];
  //     this.traderservice.unassignedOrders(getInfoOrders).subscribe((orders: any) => {
  //       if (orders.success) {
  //         this.traderservice.unassignedOrdersList.next(orders.data)
  //         if (orders.data.length > 0) {
  //           this.ordersList = orders.data;
  //           this.noMsg = false;
  //         } else {
  //           this.noMsg = true;
  //         }
  //       }
  //     });
  //   } else {
  //     this.ordersList = [];
  //     this.traderservice.getIncomingOrders(getInfoOrders).subscribe((data: any) => {
  //       if (data.success) {
  //         this.stepOrders = [];
  //         if (data.data.length) {
  //           this.supervisorService.purchaseCountSet({ count: data.data.length })
  //           this.stepOrders = data.data;
  //           this.noMsg = false;
  //           this.incomingOrders = data.data;
  //         }
  //         else {
  //           this.supervisorService.purchaseCountSet({ count: 0 })
  //         }

  //         if (this.stepOrders) {
  //           for (let i = 0; i < this.stepOrders.length; i++) {
  //             if (i <= 4) {
  //               this.stepOrders[i]['disabled'] = false;
  //               this.ordersList.push(this.stepOrders[i])
  //             }
  //             else {
  //               this.stepOrders[i]['disabled'] = true;
  //               this.ordersList.push(this.stepOrders[i])
  //             }
  //           }
  //         }
  //         if (!this.ordersList.length) {
  //           this.noMsg = true;
  //           this.ordersList = [];
  //         }
  //       }
  //     });
  //   };
  // }

  // amountEdit(item, order) {
  //   console.log(item, "item");
  //   this.supervisorService.orderProcessClear();
  //   this.supervisorService.orderProcessSet({ orderData: item, unassignedOrder: order })
  //   this.supervisorService.setOrderType({ amountFor: "outgoingOrder", stepEnable: true })
  //   this.route.navigate(['/order-summary']);
  // }

  // amountEditPurchase(data) {
  //   this.supervisorService.orderProcessClear();
  //   this.supervisorService.orderProcessSet({ orderData: data })
  //   this.supervisorService.setOrderType({ amountFor: "incomingOrder", stepEnable: true })
  //   this.route.navigate(['/order-summary']);
  // }

  // showSales(data, id) {
  //   this.ordersList[data].buttonShowNow = !this.ordersList[data].buttonShowNow;
  //   this.buttonShow = !this.buttonShow;
  // }

  // detailsShow(data, id) {
  //   // if (this.selectedTab == "allOrders") {
  //   // this.index = data;
  //   // this.traderservice.incomingOrdersAssignedList({ incomingOrderId: id, from: "orderHome" }).subscribe((data) => {
  //   //   if (data.success) {
  //   //     if (data.data.length) {
  //   //       this.assignedOrdersList = data.data;
  //   //       this.traderservice.setIncomingOrdersList(data.data);
  //   //     }
  //   //     else {
  //   //       this.assignedOrdersList = [];
  //   //     }
  //   //   }
  //   // })
  //   // } else {
  //   // this.buttonShow = false;
  //   // }
  // }


  // orderAssignToIncoming(orderData, unassigned) {
  //   this.boxAssinge(orderData, this.selectedTab, { orderCategory: 'incomingorder' }, unassigned);
  // }

  // orderProcessCreate(reason, orderData) {
  //   this.modalCtrl.create({
  //     component: TraderCreateOrderComponent,
  //     componentProps: {
  //       reason: reason,
  //       orderData: orderData
  //     }
  //   }).then(modal => {
  //     modal.present();
  //     modal.onDidDismiss().then(data => {

  //       if (data.data.reason == 'editedOrder') {

  //         this.ordersList.map(xd => {
  //           if (xd._id == data.data.data['_id']) {
  //             xd = data.data.data;
  //           }
  //         })

  //       } else {
  //         if (data.data.status == 'success') {

  //           this.ordersList = this.ordersList.filter(item => item._id != data.data.data._id);

  //         }
  //       }
  //     })
  //   })
  // }

  // boxAssinge(orderData, sectedTab, orderCategory, unassigned) {
  //   console.log(unassigned);
  //   this.supervisorService.orderProcessClear();
  //   this.supervisorService.orderProcessSet({ orderType: unassigned['_id'] ? "sale" : "purchase", orderProcessTab: unassigned['_id'] ? "sale" : "purchase", orderData: orderData, unassignedOrder: unassigned, orderProcess: 'boxassinged' })
  //   this.handler.setData('orderDataForBox', orderData);
  //   if (orderCategory['orderCategory'] != "outgoingorder" && (orderData['orderCreatedRole'] == "1" || orderData['orderCreatedRole'] == "3")) {

  //     var navigationExtras: NavigationExtras = {
  //       state: {
  //         CCdetails: orderData,
  //         from: "farmer",
  //         tab: sectedTab,
  //         orderId: ''
  //       }
  //     };
  //   } else {

  //     var navigationExtras: NavigationExtras = {
  //       state: {
  //         CCdetails: this.behaviorArray,
  //         from: "",
  //         tab: sectedTab,
  //         incomingOrders: orderData,
  //         orderId: orderData._id,
  //         preSelected: orderCategory['orderCategory'] == "outgoingorder" ? true : false,
  //         saleData: orderCategory['orderCategory'] == "outgoingorder" ? orderCategory : {}
  //       }
  //     };
  //   }
  //   // 
  //   //   tab: sectedTab,
  //   //   incomingOrders: orderData,
  //   //   orderId: orderData._id
  //   // })

  //   this.route.navigate(['boxesView'], navigationExtras);
  // }


  // needed code. please do not remove.

  // async createPurchaseSale() {
  //   if (this.ordersList.length >= 5) {
  //     const alert = await this.alertController.create({
  //       header: "Purchase Limit",
  //       cssClass: "wholeSellerAlert",
  //       message: "You already have 5 purchases. You will be redirected to sale create page. Do you want to continue?",
  //       buttons: [
  //         {
  //           text: this.translate.instant('buttons.cancel')
  //         },
  //         {
  //           text: this.translate.instant('buttons.ok'),
  //           handler: () => {
  //             this.supervisorService.setOrderType({ orderType: "sale", stepEnable: true })
  //             this.supervisorService.orderProcessClear();
  //             this.supervisorService.orderProcessSet({
  //               orderProcess: 'newOrder',
  //               orderProcessTab: 'sale',
  //             });
  //             this.route.navigateByUrl("/order-process-select-van")

  //           }
  //         },
  //       ]
  //     })
  //     await alert.present();

  //   } else {
  //     if (localStorage.getItem("role_id") == "2") {
  //       this.supervisorService.orderProcessClear();
  //       this.supervisorService.orderProcessSet({ orderProcess: 'newOrder' });
  //       this.route.navigate(["order-type-select"]);
  //     } else if (localStorage.getItem("role_id") == "8") {
  //       if (localStorage.getItem('linked') == "true") {
  //         this.supervisorService.orderProcessClear();
  //         this.supervisorService.orderProcessSet({ orderProcess: 'newOrder' });
  //         this.route.navigate(["order-type-select"]);
  //       } else {
  //         this.route.navigateByUrl('/linked-contacts');
  //       };
  //     };
  //   }
  // };


  // editIncomingOrder(orderData) {
  //   this.supervisorService.setOrderType({ orderType: "purchase", stepEnable: true })
  //   // this.orderAssignToIncoming(orderData, {})
  // };

  // showDcs(item) {
  //   this.modalCtrl.create({
  //     component: TraderCreateOrderComponent,
  //     componentProps: {
  //       reason: "liftingDOCS",
  //       orderData: item
  //     }
  //   }).then(modal => {
  //     modal.present();
  //     modal.onDidDismiss().then(data => {
  //     });
  //   });
  // };



  // traderHomePageUpdate() {
  //   this.traderservice.getIncomingOrders({ skip: 0 }).subscribe((data) => {
  //     if (data.success) {
  //       if (data.data.length) {
  //         this.supervisorService.purchaseCountSet({ count: data.data.length })
  //         this.stepOrders = data.data;
  //         this.noMsg = false;
  //         this.incomingOrders = data.data;
  //       }
  //       else {
  //         this.supervisorService.purchaseCountSet({ count: 0 })
  //       }

  //       if (this.stepOrders) {
  //         for (let i = 0; i < this.stepOrders.length; i++) {
  //           if (i <= 4) {
  //             this.stepOrders[i]['disabled'] = false;
  //             this.ordersList.push(this.stepOrders[i])
  //           }
  //           else {
  //             this.stepOrders[i]['disabled'] = true;
  //             this.ordersList.push(this.stepOrders[i])
  //           }
  //         }

  //         if (!this.ordersList.length) {
  //           this.noMsg = true;
  //           this.ordersList = [];
  //         }
  //         console.log(this.ordersList);
  //       }
  //     }
  //   })
  //   this.traderservice.traderPriceSummary({}).subscribe((data) => {

  //     if (data.success) {
  //       this.summaryData = data.data[0];

  //     }
  //   });
  // };

  // traderSupervisorHomePageUpdate() {
  //   this.traderservice.adminAccessCheck({ uId: localStorage.getItem("u_id") }).subscribe((res) => {
  //     if (res.success) {
  //       this.userAccessRole = "admin";
  //       // this.summaryData = res.data[0];
  //       if (localStorage.getItem('role_id') == "8") {
  //         localStorage.setItem("linked", "true")
  //       } else {
  //         localStorage.removeItem("linked")
  //       }
  //     }
  //     else if (!res.success) {
  //       this.userAccessRole = "";
  //       if (localStorage.getItem('role_id') == "8") {
  //         localStorage.setItem("linked", "false")
  //       } else {
  //         localStorage.removeItem("linked")
  //       }
  //     };
  //   });
  // };

  // editOutgoingOrder(unassignedOrder, incomingOrder) {
  //   this.supervisorService.setOrderType({ orderType: "sale", stepEnable: true })
  //   // if (incomingOrder['orderStatus'] != "boxassigned") {
  //   //   this.supervisorService.orderProcessClear();
  //   //   this.supervisorService.orderProcessSet({
  //   //     orderProcess: 'editOutgoingOrder',
  //   //     editOrderData: unassignedOrder,
  //   //     orderProcessTab: 'sale',
  //   //     skipTo: 1,
  //   //     incomingOrderDetails: incomingOrder
  //   //   });
  //   //   // this.route.navigate(["order-type-select"]);
  //   //   this.route.navigate(["/order-process-select-van"]);
  //   // }
  //   // else{
  //   this.supervisorService.orderProcessClear();
  //   this.supervisorService.orderProcessSet({
  //     orderType: "sale",
  //     orderProcessTab: "sale",
  //     orderData: incomingOrder,
  //     unassignedOrder: unassignedOrder,
  //     orderProcess: 'boxassinged'
  //   });
  //   this.route.navigate(['boxesView']);
  //   // }
  // }

  // detailedView(shop) {
  //   this.supervisorService.chickenshopName({ name: shop.chickenCenterName });
  //   // let str = JSON.stringify(shop._id)
  //   let navigationExtras: NavigationExtras = {
  //     queryParams: { id: shop.chickenCenterId }
  //   };
  //   this.route.navigate(["/chickenshopDetailed"], navigationExtras);
  // };

}





    // if (this.selectedTab != 'allOrders') {
    //   this.buttonShow = false;
    // }
    // if (this.selectedTab == 'allOrders') {
    //   this.traderservice.getIncomingOrders({ skip: 0 }).subscribe((data) => {
    //     if (data.success) {
    //       this.ordersList = [];
    //       if (data.data.length) {
    //         this.ordersList = data.data;
    //         this.noMsg = false;
    //         // this.ordersList.forEach((ele) => {
    //         //   Object.assign(ele, { buttonShowNow: false })
    //         // })
    //         this.incomingOrders = data.data;
    //       }
    //       else {
    //         this.noMsg = true;
    //         this.ordersList = [];
    //       }
    //     }
    //   })

    // } else if (this.selectedTab == 'outgoingOrders') {
    //   this.traderservice.traderHomePageData({ skip: 0, sort: 'price' }).subscribe((data) => {
    //     if (data.success) {
    //       if (data.data[0]['roughEstimatedWeight']) {
    //         this.ordersList = data.data;

    //       }
    //     }
    //   })
    // } else if (this.selectedTab == "unassignedOrders") {
    //   this.traderservice.unassignedOrders({ skip: 0 }).subscribe((orders) => {
    //     if (orders.success) {
    //       this.traderservice.unassignedOrdersList.next(orders.data)
    //       this.ordersList = [];
    //       if (orders.data.length) {
    //         this.ordersList = orders.data;
    //         this.noMsg = false;
    //       }
    //       else {
    //         this.noMsg = true;
    //         this.ordersList = [];
    //       }
    //     }
    //   })
    // } else {
    //   // this.traderservice.traderAllOrders({}).subscribe((data) => {
    //   //   if (data.success) {
    //   //     // this.ordersList = data.data;
    //   //     data.data.forEach(element => {
    //   //       
    //   //       // if (data.data[0]['orderedBirds']) {
    //   //         this.ordersList = data.data;
    //   //       // }
    //   //       this.summaryData = data.data[0];
    //   //       if (element['orderCategory'] == "incomingorder") {
    //   //         this.incomingOrders.push(element)
    //   //       }
    //   //     });
    //   //   }
    //   // })
    // }






     //code for data without events
    // this.traderservice.getIncomingOrders({ skip: 0 }).subscribe((data) => {
    //   if (data.success) {
    //     this.ordersList = [];
    //     if (data.data.length) {
    //       this.traderservice.incomingOrders.next(data.data)
    //       this.ordersList = data.data;
    //       this.noMsg = false;
    //       // this.ordersList.forEach((ele) => {
    //       //   Object.assign(ele, { buttonShowNow: false })
    //       // })
    //       this.incomingOrders = data.data;
    //     }
    //     else {
    //       this.noMsg = true;
    //       this.ordersList = [];
    //     }
    //   }
    // });

    // this.traderservice.traderPriceSummary({}).subscribe((data: any) => {
    //   if (data.success) {
    //     this.summaryData = data.data[0];
    //   }
    // });







    // createOrder(orderCategory, orderData) {
  //   if (localStorage.getItem('role_id') == '2') {
  //     this.createOrderFun(orderCategory, orderData);
  //   } else if (localStorage.getItem('role_id') == '8' && (localStorage.getItem('linked') == "true")) {
  //     this.createOrderFun(orderCategory, orderData);
  //   } else {
  //     this.route.navigateByUrl('/linked-contacts');
  //   }
  // }









  // createOrderFun(orderCategory, orderData) {
  //   this.supervisorService.orderProcessClear();
  //   this.supervisorService.orderProcessSet({})
  //   this.modalCtrl.create({
  //     component: TraderCreateOrderComponent,
  //     componentProps: {
  //       reason: orderCategory == 'createNewOrder' ? "newOrder" : "editOrder",
  //       orderData: orderCategory == 'createNewOrder' ? Object.assign(this.summaryData, { availableBookingBirds: this.summaryData['capacity'] - this.summaryData['totalOrderedBirds'] }) : orderData
  //     }
  //   }).then(modal => {
  //     modal.present();
  //     modal.onDidDismiss().then(data => {

  //       if (data.data.form) {
  //         // this.TraderBookedDetails.toatalVansCapacity = this.TraderBookedDetails.toatalVansCapacity - data.data.newData.numberofbirds;
  //         this.summaryData.totalOrderedBirds = this.summaryData.totalOrderedBirds + data.data.newData.numberofbirds;
  //         let traderData = {
  //           u_id: localStorage.getItem(('u_id')),
  //           traderName: localStorage.getItem(('name')),
  //           traderPhnumber: localStorage.getItem(('phone')),
  //           order_type: "manualbooked",
  //         }
  //         // 
  //         let c = Object.assign(data.data.newData, traderData);

  //         if (orderCategory == 'editOrder') {

  //           let editOrderData = {
  //             price: data.data.newData.price,
  //             orderStatus: data.data.newData.status,
  //             numberOfBirds: data.data.newData.numberofbirds,
  //             estimatedWeight: data.data.newData.estimatedWeight,
  //             _id: data.data.newData._id
  //           }
  //           if (data.data.newData.newVan) {
  //             Object.assign(editOrderData, {
  //               inventoryName: data.data.newData.inventoryName,
  //               inventoryId: data.data.newData.inventoryId,
  //               farmeruId: data.data.newData.farmeruId,
  //               vanId: data.data.newData.vanId,
  //               vanStatus: data.data.newData.vanStatus,
  //               vanCapacity: data.data.newData.vanCapacity,
  //               vanNumber: data.data.newData.vanNumber,
  //               newVan: true
  //             })
  //           }

  //           this.traderservice.editOrder(editOrderData).subscribe((data) => {

  //             this.traderservice.traderVechilesList({ skip: 0, sort: "orderCount" }).subscribe((data1) => {
  //               this.vechilesData = data1.data;
  //             })
  //           })
  //         } else {
  //           this.traderservice.createOrders(c).subscribe((data: any) => {
  //             if (data.success) {
  //               this.ordersList = [];
  //               this.ordersList = data.data;
  //               this.traderservice.traderVechilesList({ skip: 0, sort: "orderCount" }).subscribe((data1) => {
  //                 this.vechilesData = data1.data;
  //               })
  //               this.showError(this.translate.instant('flashMessages.submited'));
  //               // this.TraderBookedDetails.birds_order = this.TraderBookedDetails.birds_order + c.numberofbirds;
  //             } else {
  //               this.showError(this.translate.instant('flashMessages.somethingWrong'));
  //             }
  //           });
  //         }
  //       } else if (data.data.delete) {

  //         this.homePageData();
  //       } else {
  //         this.orders = this.orders;
  //       }
  //     })
  //   })
  // }









   // enterBoxes(orderData) {
  //   this.handler.setData('orderDataForBox', orderData);
  //   if (orderData.orderCreatedRole == "1" || orderData.orderCreatedRole == "3") {
  //     var navigationExtras: NavigationExtras = {
  //       state: {
  //         CCdetails: orderData,
  //         from: "farmer",
  //       }
  //     };
  //   } else {
  //     var navigationExtras: NavigationExtras = {
  //       state: {
  //         CCdetails: this.behaviorArray,
  //         from: ""
  //       }
  //     };
  //   };
  //   this.route.navigate(['boxesView'], navigationExtras);
  // }








  // async selectVan(id) {
  //   this.modalCtrl.create({
  //     component: OrderLiftingvanComponent,
  //     componentProps: { selected: id.vanNumber },
  //   }).then(modal => {
  //     modal.present();
  //     modal.onDidDismiss().then(data => {
  //       if (data.data.reason == 'selected') {
  //         let updateData = { vanNumber: data.data.data.vanNumber, vanId: data.data.data.contactSettingId, vanModel: data.data.data.vanModel, orderDocId: id._id };
  //         if (data.data.data.vanStatus == "farmassigned") {
  //           Object.assign(updateData, {
  //             farmeruId: data.data.data.farmeruId,
  //             inventoryId: data.data.data.inventoryId,
  //             inventoryName: data.data.data.inventoryName,
  //             vehicleStatus: data.data.data.vanStatus
  //           });
  //         };
  //         this.traderservice.traderVehicleAssign(updateData).subscribe((data) => {
  //         });
  //       };
  //     });
  //   });
  // }









   // homePageData() {
  //   this.events.subscribe('user:distroy', (data: any) => {

  //     this.vechilesData = [];
  //     this.ordersList = [];
  //     this.userAccessRole = '';
  //   })
  //   this.events.subscribe('user:created', (data: any) => {
  //     let role = data.user['role_Id'];
  //     this.userRole = role;



  //     if (role == '8') {
  //       this.vechilesData = [];
  //       this.ordersList = [];
  //       this.traderservice.adminAccessCheck({ uId: localStorage.getItem("u_id") }).subscribe((res) => {
  //         if (res.success) {
  //           this.userAccessRole = "admin";
  //           // this.summaryData = res.data[0];

  //           if (localStorage.getItem('role_id') == "8") {
  //             localStorage.setItem("linked", "true")
  //           } else {
  //             localStorage.removeItem("linked")
  //           }
  //         }
  //         else if (!res.success) {

  //           if (localStorage.getItem('role_id') == "8") {
  //             localStorage.setItem("linked", "false")
  //           } else {
  //             localStorage.removeItem("linked")
  //           }
  //         }
  //       })
  //     }
  //     if (this.userRole != role || this.userRole == '8') {

  //       this.userRole = localStorage.getItem('role_id');
  //       this.selectedTab == 'vans';
  //       this.traderservice.traderSupervisorVanSummary({}).subscribe((data) => {
  //         if (data.success) {
  //           this.vechilesData = data.data;
  //         }
  //       });
  //       this.traderservice.traderSupervisorOrdersSummary({}).subscribe((data) => {
  //         if (data.success) {

  //           this.ordersList = data.data;
  //           if (data.data[0]['_id'] == null) {
  //             this.ordersList = [];
  //           }
  //         }
  //       });
  //     } else {

  //     }
  //   });
  //   if (this.userRole == '2') {
  //     localStorage.removeItem("linked");
  //     this.traderservice.traderHomePageData({ skip: 0, sort: "price" }).subscribe((data) => {
  //       this.ordersList.length = 0;

  //       if (data.success) {
  //         // this.ordersList = data.data;
  //         if (data.data[0]['orderedBirds']) {
  //           this.ordersList = data.data;
  //         }
  //         if (data.data[0]['_id'] == null) {
  //           this.ordersList = [];
  //         }
  //         this.summaryData = data.data[0];

  //         this.count = 0;
  //         data.data.forEach(element => {
  //           if (element.orderType == "auto" && element.orderStatus == "waiting") {
  //             this.count++;
  //           }
  //         });
  //         if (this.count > 0) {
  //           this.pendingOrderCount = this.count;
  //         } else {
  //           this.pendingOrderCount = this.count;
  //         }
  //         // this.supervisorService.traderOrdersCount.next(this.count);
  //         // this.loading.loadingDismiss();
  //       } else {
  //         // this.loading.loadingDismiss();
  //       }
  //     }, (error) => {

  //       // this.loading.loadingDismiss();
  //     })
  //   }

  //   if (localStorage.getItem('role_id') != "2") {
  //     this.traderservice.adminAccessCheck({ uId: localStorage.getItem("u_id") }).subscribe((res) => {
  //       if (res.success) {
  //         this.userAccessRole = "admin";
  //         // this.summaryData = res.data[0];

  //         if (localStorage.getItem('role_id') == '8') {
  //           localStorage.setItem("linked", "true")
  //         } else {
  //           localStorage.removeItem('linked');
  //         }
  //       }
  //       else if (!res.success) {
  //         if (localStorage.getItem('role_id') == '8') {
  //           localStorage.setItem("linked", "false")
  //         } else {
  //           localStorage.removeItem('linked');
  //         }

  //       }
  //     })
  //     this.traderservice.traderSupervisorOrdersSummary({}).subscribe((data) => {
  //       if (data.success) {
  //         this.ordersList = [];
  //         if (data.data[0]['roughEstimatedWeight']) {
  //           this.ordersList = data.data;
  //         } else {
  //           this.ordersList = [];
  //         }
  //         this.summaryData = data.data[0];

  //         // this.loading.loadingDismiss();
  //       }
  //     });
  //     this.traderservice.traderSupervisorVanSummary({}).subscribe((data) => {

  //       if (data.success && data.data.length) {
  //         this.vechilesData = data.data;
  //       }
  //     })
  //   } else {
  //     // this.traderservice.addedVans$.subscribe((data1) => {
  //     //   if (data1.length <= 0) {
  //     this.traderservice.traderVechilesList({ skip: 0, sort: "orderCount" }).subscribe((data) => {
  //       this.vechilesData = data.data;
  //       // this.traderservice.addedVans.next(data.data);
  //       // this.loading.loadingDismiss();
  //     })
  //   }
  //   // else{
  //   //   this.vechilesData = data1
  //   // }
  //   // })
  //   // }
  //   this.pushNotificationSetUp()
  // }





   // input = [];
  // async openAlertCheck(data, i) {
  //   this.input = [
  //     {
  //       name: 'Edit Order',
  //       type: 'radio',
  //       label: "Edit Order",
  //       value: "editOrder",
  //       disabled: data.orderStatus == "delivery" || data.orderStatus == "paid"
  //     },
  //     {
  //       name: 'Boxes Assign',
  //       type: 'radio',
  //       label: "Boxes Assign",
  //       value: "boxesAssign"
  //     },
  //     {
  //       name: 'Status Update',
  //       type: 'radio',
  //       label: "Status Update",
  //       value: "statusUpdate"
  //     }
  //   ]
  //   if (this.selectedTab == "outgoingOrders") {
  //     const alert = await this.alertController.create({
  //       cssClass: 'wholeSellerAlert',
  //       header: "Choose an Option",
  //       inputs: this.input,
  //       buttons: [
  //         {
  //           text: this.translate.instant('buttons.cancel'),
  //           role: 'cancel',
  //           cssClass: 'secondary',
  //           handler: () => {

  //           }
  //         },
  //         {
  //           text: this.translate.instant('buttons.ok'),
  //           handler: (res) => {
  //             if (res == "editOrder") {
  //               this.orderProcessCreate('editIndependentOrder', data)
  //             } else if (res == "boxesAssign") {
  //               let order = this.incomingOrders.filter(xd => xd._id == data['incomingOrderId']);
  //               if (order.length) {
  //                 // this.boxAssinge(order[0], this.selectedTab, data)
  //               } else {
  //               }
  //               // if(this.selectedTab == 'incomingOrders')
  //               // var navigationExtras: NavigationExtras = {
  //               //   state: {
  //               //     CCdetails: this.behaviorArray,
  //               //     from: "",
  //               //     activeTab: this.selectedTab,
  //               //   }
  //               // };
  //               // 
  //               // this.route.navigate(["boxesView"], navigationExtras)
  //             }
  //             else if (res == "statusUpdate") {
  //               // this.openAlert(data, i);
  //             }
  //           }
  //         }
  //       ]
  //     });
  //     await alert.present();
  //   } else {
  //     this.openAlert(data, i)
  //   }
  // }






  // async openAlert(data, i) {
  //   this.supervisorService.setInputData(data, this.selectedTab);
  //   this.vanId = data.contactSettingId;
  //   const alert = await this.alertController.create({
  //     cssClass: 'wholeSellerAlert',
  //     header: this.translate.instant('supplier.StatusUpdate'),
  //     inputs: this.supervisorService.getInputValues(),
  //     buttons: [
  //       {
  //         text: this.translate.instant('buttons.cancel'),
  //         role: 'cancel',
  //         cssClass: 'secondary',
  //         handler: () => {

  //         }
  //       }, {
  //         text: this.translate.instant('buttons.ok'),
  //         handler: (res) => {

  //           if (res == 'accept') {

  //             if (data.orderStatus == "waiting" && data.orderCategory == "outgoingorder") {
  //               this.supervisorService.orderProcessClear();
  //               // let data = {...data,...{order:'unassin'}}
  //               Object.assign(data, { orderProcess: data['orderCreatedRole'] == 10 ? "unassignedOrder" : 'newOrder' })
  //               this.supervisorService.orderProcessClear();
  //               this.supervisorService.orderProcessSet({ unassignedOrder: data, orderFrom: data['orderProcess'], orderProcess: data['orderProcess'] });
  //               // this.route.navigate(["order-type-select"])
  //               this.supervisorService.setOrderType({ orderType: "sale", stepEnable: true })
  //               this.route.navigate(["/order-process-select-van"])
  //             }
  //             else if ((this.selectedTab == "incomingOrders" || this.selectedTab == 'allOrders') && data.orderStatus == "waiting" && data['orderCategory'] == 'incomingorder') {
  //               this.traderservice.updateOrderStatus({ _id: data._id, OrderStatus: "inventoryassigned" }).subscribe((res) => {
  //                 if (res.success) {
  //                   this.traderservice.getIncomingOrders({ skip: 0 }).subscribe((data) => {
  //                     if (data.success) {
  //                       this.ordersList = [];
  //                       if (data.data.length) {
  //                         this.ordersList = data.data;
  //                         this.noMsg = false;
  //                         // this.ordersList.forEach((ele) => {
  //                         //   Object.assign(ele, { buttonShowNow: false })
  //                         // })
  //                         this.incomingOrders = data.data;
  //                       }
  //                       else {
  //                         this.noMsg = true;
  //                         this.ordersList = [];
  //                       }
  //                     }
  //                   })
  //                 }
  //               })
  //             } else {
  //               this.orderProcessCreate('chickenshop', data)
  //             }
  //           } else if (res == 'reject') {

  //             if ((this.selectedTab == "incomingOrders" || this.selectedTab == 'allOrders') && data['orderCategory'] == 'incomingorder' && data.orderStatus == "waiting") {
  //               this.traderservice.updateOrderStatus({ _id: data._id, OrderStatus: "reject" }).subscribe((res) => {
  //                 this.ordersList = this.ordersList.filter(x => x._id != data._id)
  //               })
  //             } else {

  //               this.traderservice.cancelAutoOrder({ _id: data._id }).subscribe((deleteOrder) => {
  //                 if (deleteOrder.success) {
  //                   this.ordersList = this.ordersList.filter(x => x._id != data._id)
  //                 }
  //               })
  //             }
  //           } else if (res == 'boxassigned') {
  //             if (data.orderCategory == "outgoingorder") {
  //               // this.boxAssinge(this.ordersList[i], this.selectedTab, data)
  //             } else {
  //               // this.boxAssinge(data, this.selectedTab, { orderCategory: 'incomingorder' })
  //             }
  //           } else if (res == "delivery" || res == 'paid') {
  //             if (this.selectedTab == 'allOrders' || this.selectedTab == "outgoingOrders") {
  //               if (data.orderCategory == "incomingorder") {
  //                 if (res == "delivery") {

  //                   // start comment from here
  //                   this.traderservice.incomingOrderStatus({ 'orderDocId': data._id, "status": res }).subscribe((updateStatus) => {
  //                     this.ionViewDidEnter()
  //                     if (updateStatus.success) {

  //                       if (this.selectedTab == 'incomingOrders') {
  //                         if (res == 'delivery') {
  //                           // this.ordersList.map(xd => {
  //                           //   if (xd._id == data._id) {
  //                           //     xd['orderStatus'] = 'delivery';
  //                           //   }
  //                           // })
  //                         } else if (res == "paid") {
  //                           this.ordersList = this.ordersList.filter(xd => xd._id != data._id);
  //                         }

  //                       }
  //                     }
  //                   })
  //                   // stop comment here
  //                 }
  //               }
  //               else if (this.selectedTab == "outgoingOrders" || data.orderCategory == "outgoingorder") {

  //                 // start comment here
  //                 this.traderservice.outgoingOrderStatus({ 'orderDocId': data._id, "status": res }).subscribe((updateStatus) => {
  //                   if (updateStatus.success) {
  //                     this.ionViewDidEnter();
  //                     if (this.selectedTab == 'outgoingOrders') {

  //                       if (res == "paid") {
  //                         // this.ordersList = this.ordersList.filter(xd => xd._id != data._id);
  //                       } else if (res == 'delivery') {

  //                         // this.ordersList.map(xd => {
  //                         //   if (xd._id == data._id) {
  //                         //     xd['orderStatus'] = 'delivery';
  //                         //   }
  //                         // })
  //                       }
  //                     }
  //                   }
  //                 })
  //                 // stop comment here
  //               }
  //             }
  //           }

  //         }
  //       }
  //     ]
  //   });
  //   await alert.present();
  // }



   // checked(event) {
  //   this.supervisorService.routeChange({ segment: "" });
  //   this.selectedTab = event.target.value;
  //   if (this.selectedTab != 'allOrders') {
  //     this.buttonShow = false;
  //   }
  // }




    // if (this.selectedTab == 'unassignedOrders') {
    //   console.log("1")
    // this.traderservice.unassignedOrders({ skip: 0 }).subscribe((orders:any) => {
    //   if (orders.success) {
    //     if (orders.data.length) {
    //       this.ordersList = orders.data;
    //       this.noMsg = false;
    //     }
    //     else {
    //       this.noMsg = true;
    //       this.ordersList = [];
    //     }
    //   }
    // });
    // } else {
    // console.log("4")
    // this.traderservice.getIncomingOrders({ skip: 0 }).subscribe((data:any) => {
    //   if (data.success) {
    //     this.ordersList = [];
    //     if (data.data.length) {
    //       this.ordersList = data.data;
    //       this.noMsg = false;
    //       this.incomingOrders = data.data;
    //     }
    //     else {
    //       this.noMsg = true;
    //       this.ordersList = [];
    //     }
    //   }
    // });
    // }