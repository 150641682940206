import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arraylimit'
})
export class ArraylimitPipe implements PipeTransform {

  transform(value: any, limit?: any): any {
    if(value){
      value = value.slice(0,limit);
    }
    return value;
  }


}
