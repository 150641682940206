import { Injectable } from '@angular/core';
import { config } from "../helper/config";
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { distinctUntilChanged } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FeedrelatedService {

  constructor(
    private httpRequest: HttpClient
  ) { }
  addFeedFormulas(formula): Observable<any> {
    return this.httpRequest.post(config.api + "layers/addFormula", formula);
  }

  getFormulasList(index): Observable<any> {
    return this.httpRequest.post(config.api + "layers/getFormulas",index);
  }
  calcBasedOnFormula(calcPart): Observable<any> {
    return this.httpRequest.post(config.api + "layers/calFeed", calcPart);
  }
  getFeedNames(data) {
    return this.httpRequest.post(config.api + "layers/getFeedNamesList",data);
  }
  addfeedstock(data): Observable<any> {
    return this.httpRequest.post(config.api + "layers/insertFeed", data);
  }
  addNewFeed(data): Observable<any> {
    return this.httpRequest.post(config.api + "layers/newFeedName", data);
  }

  stockForInv(data): Observable<any> {
    return this.httpRequest.post(config.api + "layers/stockForInventory", data);
  }
}
