import { Directive, HostListener, Input } from '@angular/core';
import { MenuController } from '@ionic/angular';

@Directive({
  selector: '[appMenuopen]'
})
export class MenuopenDirective {
  @Input('appMenuopen') menuId: string;

  @HostListener('click', ['$event']) onclick() {
    this.openMenu();
  }

  constructor( protected menu: MenuController) { }
  openMenu() {
    
    this.menu.isEnabled().then(enabled => {
    
      if(!enabled) {
        this.menu.enable(true);
      }

      this.menu.open();
    }).catch(console.log);
  }
}
