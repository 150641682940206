import { Component, OnInit, NgZone } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/services/common.service';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';
import { ActionSheetController, Platform, ToastController, ModalController, NavController } from '@ionic/angular';
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { Camera, CameraOptions, PictureSourceType } from '@ionic-native/camera/ngx';
import { File, FileEntry } from '@ionic-native/File/ngx';
import { config } from '../../helper/config';
import { NativeGeocoder, NativeGeocoderOptions, NativeGeocoderResult } from '@ionic-native/native-geocoder/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { ImagePicker, ImagePickerOptions } from '@ionic-native/image-picker/ngx';
import { LeaseService } from 'src/services/lease.service';
import { LoadingController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import { max } from 'rxjs/operators';
import { toBase64String } from '@angular/compiler/src/output/source_map';
import { HttpHeaders } from '@angular/common/http';
import { LoadingserviceService } from 'src/app/loadingservice.service';
declare var google: any;

@Component({
  selector: 'app-lease',
  templateUrl: './lease.page.html',
  styleUrls: ['./lease.page.scss'],
})

export class LeasePage implements OnInit {
  countryCallingCode;
  pics: any = [];
  images: any[] = [];
  fileLoactionPath: string;
  currentFileName: any;
  public baseUrl = config.api;
  submitted = false;
  loginError = '';
  mapvalues: boolean = false;
  public leaseForm: FormGroup;
  errorcode: any;
  placeName: string;
  iddata: any;
  type: any;
  leasefarmDataById: any;
  EditLocation;
  // location = "";
  // category = "";
  // description = "";
  // phonenum = "";

  GoogleAutocomplete = new google.maps.places.AutocompleteService();
  autocomplete = { input: '' };
  autocompleteItems = [];
  id = "";
  dataid: any;
  detailData: any;
  latValue = ""; langvalue = "";
  place = "";
  address: any;
  latitude: any;
  longitude: any;
  showimg: any;
  geoencoderOptions: NativeGeocoderOptions = {
    useLocale: true,
    maxResults: 5
  };
  geoAddress: string;
  uploadFileArr: any[];

  CategoryOptions = [
    "FarmLease", "Equipment", "Man Power", "others"
  ]
  editData: any;
  hideEditpics: boolean = true;
  countryCode: string = localStorage.getItem('countryCode');
  locationError: boolean = false;

  constructor(
    private toastController: ToastController, private nav: NavParams,
    private router: Router, private modal: ModalController,
    private route: ActivatedRoute,
    private CommonService: CommonService,
    private file: File,
    private webview: WebView,
    private filePath: FilePath,
    private fileTransfer: FileTransfer,
    private actionSheetController: ActionSheetController,
    private platform: Platform,
    public fb: FormBuilder, private nativeGeocoder: NativeGeocoder,
    private imagePicker: ImagePicker,
    private leaseService: LeaseService,
    private camera: Camera,
    public zone: NgZone,
    private geolocation: Geolocation,
    public loadingController: LoadingController,
    private loading: LoadingserviceService) {
    this.countryCallingCode = localStorage.getItem('countryCallingCode')
    //form validation
    this.leaseForm = this.fb.group({
      location: ['', Validators.required],
      category: ['', Validators.required],
      descriptionTitle: ['', Validators.required],
      description: ['', Validators.required],
      phonenum: ['', [Validators.required]],
      fileName: [''],
      files: ['']
    });
    //if edit detail come then values patch to the form fields
    if (this.nav.get('data')) {
      this.hideEditpics = false
      console.log(this.hideEditpics)
      console.log(this.nav.get('data'));
      this.editData = this.nav.get('data');
      this.autocomplete.input = this.editData.address;
      this.editData.lease_image.forEach(pics => {

        this.pics.push(pics)
        console.log(pics, 101)
      })
      // this.pics.push(this.editData.lease_image)
      this.leaseForm.patchValue({
        description: this.editData.description,
        category: this.editData.category,
        phonenum: this.editData.phonenum,
        descriptionTitle: this.editData.title

      })
    }



  }
  validation_messages = {
    'location': [{ type: 'required', message: 'locationRequired' }],
    'category': [{ type: 'required', message: 'categoryRequired' }],
    'description': [{ type: 'required', message: 'descriptionRequired' }],
    'phonenum': [{ type: 'required', message: 'phnNoRequired' },
    { type: 'pattern', message: 'leasePhnPattern' }
    ],
    'descriptionTitle': [{ type: 'required', message: 'descriptionTitleRequired' }]
  }
  ngOnInit() {
    console.log(this.pics.length);
    // this.address = localStorage.getItem("address");
    // this.leaseForm.get("location").setValue(this.address);
    // this.autocomplete.input = this.address;
  }

  getGeoLocation() {
    this.autocompleteItems = [];
    this.geolocation.getCurrentPosition().then((resp) => {
      this.latitude = resp.coords.latitude
      this.longitude = resp.coords.longitude;
      this.getGeoencoder(this.latitude, this.longitude);
    }).catch((error) => {
      console.log('Error getting location', error);
    });
  }
  getGeoencoder(latitude, longitude) {
    this.nativeGeocoder.reverseGeocode(latitude, longitude, this.geoencoderOptions)
      .then((result: NativeGeocoderResult[]) => {
        if (result[0].countryName == localStorage.getItem('country')) {
          this.geoAddress = this.generateAddress(result[0]);
          this.leaseForm.controls.location.setValue(this.geoAddress);
        } else {
          this.loading.showError('Your country and location are mismatched')
        }

      })
      .catch((error: any) => {
        alert('Error getting location' + JSON.stringify(error));
      });
  }
  selectSearchResult(item) {
    this.autocomplete.input = item.description;
    this.geoAddress = this.autocomplete.input;
    this.geoCode(this.autocomplete.input);

    if (this.autocomplete.input) {
      this.mapvalues = true;
    }
    else {
      this.mapvalues = false;
      this.updateSearchResults()
    }
  }
  async submitForm(farm: boolean) {
    if (farm) {
      if (this.pics.length <= 5) {
        //insert posting
        if (!this.editData) {
          let createdLease = { ...this.leaseForm.value };
          createdLease.latitude = this.latitude;
          createdLease.longitude = this.longitude;
          createdLease.headers = {};
          createdLease.u_id = localStorage.getItem('u_id');
          if (this.uploadFileArr) {
            let fileIDs = await this.uploadImages(this.uploadFileArr);
            console.log(189)
            createdLease.files = fileIDs;
          }
          this.modal.dismiss({
            farm: farm,
            newData: createdLease
          });
        }
        //edit posting
        else {
          this.longitude = this.longitude || this.editData.location.coordinates[0];
          this.latitude = this.latitude || this.editData.location.coordinates[1];
          let vampire = { ...this.leaseForm.value, longitude: this.longitude, latitude: this.latitude, u_id: this.editData.u_id, _id: this.editData._id };
          this.modal.dismiss({
            farm: farm,
            newData: vampire
          });
        }
      } else {
        this.showError('max 5 pics allowed')
        console.log('delete pics')
      }
    }
    else {
      this.modal.dismiss({
        farm: farm,
      });
    }
  }

  geoCode(address: any) {
    let geocoder = new google.maps.Geocoder();
    geocoder.geocode({ 'address': address }, (results, status) => {
      this.latitude = results[0].geometry.location.lat();
      this.longitude = results[0].geometry.location.lng();
    });
  }
  generateAddress(addressObj) {
    let obj = [];
    let address = "";
    for (let key in addressObj) {
      obj.push(addressObj[key]);
    }
    obj.reverse();
    for (let val in obj) {
      if (obj[val].length)
        address += obj[val] + ', ';
    }
    return address.slice(0, -2);
  }
  // pathForImage(img) {
  //   if (img === null) {
  //     return '';
  //   } else {
  //     let converted = this.webview.convertFileSrc(img);
  //     return converted;
  //   }
  // }

  updateSearchResults() {
    if (this.autocomplete.input == '') {
      this.autocompleteItems = [];
      return;
    }
    this.GoogleAutocomplete.getPlacePredictions({
      input: this.autocomplete.input, types: ['(cities)'],
      // componentRestrictions: { country: "IN" }
      componentRestrictions: { country: localStorage.getItem('countryCode') }
    },
      (predictions, status) => {
        if (predictions != null) {
          this.locationError = false;
          this.autocompleteItems = [];
          this.zone.run(() => {
            predictions.forEach((prediction) => {
              this.autocompleteItems.push(prediction);
            });
          });
        }
        else {
          this.locationError = true;
        }
      })
  }
  //click chooseFile function in front end
  // chooseFile() {
  //   let imagePickerOptions: ImagePickerOptions;
  //   imagePickerOptions = { maximumImagesCount: 5, width: 800, height: 800, quality: 100 }
  //   this.imagePicker.getPictures(imagePickerOptions).then(async (filepaths) => {
  //     let uploadFileArr = [];
  //     for (var i = 0; i < filepaths.length; i++) {
  //       //front end show pics
  //       let fileNAme = filepaths[i].substring(filepaths[i].lastIndexOf('/') + 1);
  //       let path = filepaths[i].substring(0, filepaths[i].lastIndexOf('/') + 1);
  //       this.file.readAsDataURL(path, fileNAme).then((base64String) => {
  //         this.pics.push(base64String);
  //       })
  //       //server insert
  //       let options: FileUploadOptions = {};
  //       let currentFileName = filepaths[i].substring(filepaths[i].lastIndexOf('/') + 1, filepaths[i].lastIndexOf('?'))
  //       options.fileKey = 'file';
  //       options.fileName = filepaths[i].substr(filepaths[i].lastIndexOf('/') + 1);
  //       options.mimeType = `image/${currentFileName.substr(currentFileName.lastIndexOf('.') + 1)}`;
  //       options.headers = {};
  //       uploadFileArr = [...uploadFileArr, { filepath: filepaths[i], options }];
  //       this.uploadFileArr = uploadFileArr;
  //     }
  //   }, (err) => { });
  // }
  chooseFile() {
    let imagePickerOptions: ImagePickerOptions;
    imagePickerOptions = { maximumImagesCount: 5, width: 800, height: 800, quality: 100 }
    this.imagePicker.getPictures(imagePickerOptions).then(async (filepaths) => {
      if (filepaths === 'OK' || filepaths === 'O K') {
        this.showimg = false;
        return;
      } else {
        this.showimg = true;
        let uploadFileArr = [];
        for (var i = 0; i < filepaths.length; i++) {

          let options: FileUploadOptions = {};
          let currentFileName = filepaths[i].substring(filepaths[i].lastIndexOf('/') + 1, filepaths[i].lastIndexOf('?'))
          options.fileKey = 'file';
          options.fileName = filepaths[i].substr(filepaths[i].lastIndexOf('/') + 1);
          options.mimeType = `image/${currentFileName.substr(currentFileName.lastIndexOf('.') + 1)}`;
          options.headers = {};
          // let resPath = this.pathForImage(filepaths[i]);
          uploadFileArr = [...uploadFileArr, { filepath: filepaths[i], options }];
          this.uploadFileArr = uploadFileArr;
        }
      }
    }, (err) => { console.log(err, 1234) });
  }
  uploadImages = (uploadFileArr: any[]): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      let result = await Promise.all(uploadFileArr.map(fileObject => this.transferFiles(fileObject)));
      // result = result.filter((result => result != false))
      console.log(result, 286)
      resolve(result)
    }).catch(console.log)
  }
  transferFiles = (fileObject) => {
    return new Promise((resolve, reject) => {
      let transfer = this.fileTransfer.create();
      transfer.upload(fileObject.filepath, `${this.baseUrl}imageUpload/insertlease2`, fileObject.options).then((result: any) => {
        if (result && result.responseCode === 200) {
          let file = JSON.parse(result.response);
          resolve(file.file)
        } else {
          resolve(false)
        }
      }).catch(error => {
        resolve(false)
      });
    })
  }

  //delete extra images
  deleteImage(img) {
    this.pics = this.pics.filter((data) => {
      return data != img;
    })
  }
  // toast message
  async showError(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 3000,
      position: 'bottom',
      animated: true,
      cssClass: "my-custom-class",
    });
    toast.present();
  }

}

//transfer files
   // let token= localStorage.getItem('token');
      //  let setHeaders = {
      //   Authorization: `${localStorage.getItem('token')}`,
      //   headers: 'Content-Type, application/json'
      // }
      // let headers = new HttpHeaders();
      // headers = headers.append("Content-Type", "application/json", );
      // headers.append("Authorization",  token);
      // let headers: new HttpHeaders( { 'Content-Type': 'application/x-www-form-urlencoded' }, { 'Authorization' , token });





//display selected images to the front end
  // pickMultipleImages() {
  //   let opt: ImagePickerOptions = {
  //     maximumImagesCount: 5,
  //   }
  //   this.imagePicker.getPictures(opt).then((results) => {
  //     for (var i = 0; i < results.length; i++) {
  //       let fileNAme = results[i].substring(results[i].lastIndexOf('/') + 1);
  //       let path = results[i].substring(0, results[i].lastIndexOf('/') + 1);
  //       this.file.readAsDataURL(path, fileNAme).then((base64String) => {
  //         this.pics.push(base64String);
  //       })
  //     }
  //   })
  // }