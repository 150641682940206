import { ThrowStmt } from '@angular/compiler';
import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AutomaticSystemService } from 'src/app/services/automatic-system.service';


@Component({
  selector: 'app-alarm-modal',
  templateUrl: './alarm-modal.page.html',
  styleUrls: ['./alarm-modal.page.scss'],
})
export class AlarmModalPage implements OnInit {

  alarmValues: FormGroup;
  date: any;
  hI; aF; aM; cO2; cO; o2;

  params: any[] = ["heatIndex", "airFlow", "ammoniaGas", "co2Gas", "coGas", "o2Gas"]
  ltgtCondition: any;
  sample: any;
  alarmV: any;
  deviceId: any;

  constructor(private modalController: ModalController,
    public formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private alertController: AlertController,
    public translate: TranslateService,
    private AutomaticSystemService: AutomaticSystemService) {

    this.alarmValues = this.formBuilder.group({
      deviceId: [""],
      heatIndex: this.formBuilder.group(this.controls()),
      airFlow: this.formBuilder.group(this.controls()),
      ammoniaGas: this.formBuilder.group(this.controls()),
      co2Gas: this.formBuilder.group(this.controls()),
      coGas: this.formBuilder.group(this.controls()),
      o2Gas: this.formBuilder.group(this.controls()),
    })
    this.route.queryParams.subscribe((params) => {
      this.deviceId = params.deviceId;
    });
    this.alarmValues.get("deviceId").setValue(this.deviceId);
    this.date = new Date().toISOString()
  }

  controls() {
    return {
      userValue: [""],
      ltgtCondition: [""],
      alarm: [""],
      currentValue: [""],
      dateTime: [""]
    }
  }

  ngOnInit() { }

  ionViewDidEnter() {
    this.params.forEach((key) => {
      this.propertyChanged(key)
    })
  }

  ionViewWillEnter() {
    this.AutomaticSystemService.userSensorAlarmSettings(this.alarmValues.value.deviceId).subscribe(
      (result) => {
        if (result.msg.length > 0) {
          this.alarmV = result.msg[0];
          this.params.forEach((key) => {
            if (key !== "airFlow") {
              this.alarmValues.get(key).get("userValue").setValue(this.alarmV[key].userValue);
              this.alarmValues.get(key).get("ltgtCondition").setValue(this.alarmV[key].ltgtCondition);
              this.alarmValues.get(key).get("alarm").setValue(this.alarmV[key].alarm);
              this.alarmValues.get(key).get("currentValue").setValue(this.alarmV.data[key]);
            }
            else if (key == "airFlow") {
              this.alarmValues.get(key).get("userValue").setValue(this.alarmV["airPressure"].userValue);
              this.alarmValues.get(key).get("ltgtCondition").setValue(this.alarmV["airPressure"].ltgtCondition);
              this.alarmValues.get(key).get("alarm").setValue(this.alarmV["airPressure"].alarm);
              this.alarmValues.get(key).get("currentValue").setValue(this.alarmV.data["airPressure"]);
            }
          });
        }
        else if (result.msg.length == 0) {
          this.alarmV = true;
          this.params.forEach((key) => {
            if (key == "heatIndex" || key == "ammoniaGas" || key == "coGas") {
              this.alarmValues.get(key).get("userValue").setValue(+35);
              this.alarmValues.get(key).get("ltgtCondition").setValue("gt");
              this.alarmValues.get(key).get("alarm").setValue(true);
              this.alarmValues.get(key).get("currentValue").setValue(+45);
            }
            else {
              this.alarmValues.get(key).get("userValue").setValue(+45);
              this.alarmValues.get(key).get("ltgtCondition").setValue("lt");
              this.alarmValues.get(key).get("alarm").setValue(false);
              this.alarmValues.get(key).get("currentValue").setValue(+35);
            }
          }
          )
        }
      })
  }

  propertyChanged(key) {
    let temp = this.checkChange(this.getValue(key, "ltgtCondition"), this.getValue(key, "currentValue"), this.getValue(key, "userValue"));
    switch (key) {
      case 'heatIndex': this.hI = temp; break;
      case 'airFlow': this.aF = temp; break;
      case 'ammoniaGas': this.aM = temp; break;
      case 'co2Gas': this.cO2 = temp; break;
      case 'coGas': this.cO = temp; break;
      case 'o2Gas': this.o2 = temp; break;
      default: break;
    }
  }

  checkChange(ltgt, currV, userV) {
    userV = +userV;
    currV = +currV;
    if (userV == 0 || userV == null) {
      return true;
    }
    else {
      if (ltgt == "lt") {
        if (userV < currV) {
          return true;
        }
        else {
          return false;
        }
      }
      else if (ltgt == "gt") {
        if (userV > currV) {
          return true;
        }
        else {
          return false;
        }
      }
    }
  }

  getName(key) {
    switch (key) {
      case 'heatIndex': return this.translate.instant('farmautomate.heatIndex');
      case 'airFlow': return this.translate.instant('farmautomate.airflow');
      case 'ammoniaGas': return this.translate.instant('farmautomate.ammonia');
      case 'co2Gas': return "CO<sub>2</sub>" + this.translate.instant('farmautomate.gas');
      case 'coGas': return "CO" + this.translate.instant('farmautomate.gas');
      case 'o2Gas': return "O<sub>2</sub>" + this.translate.instant('farmautomate.gas');
      default: break;
    }
  }

  getValue(key, property) {
    return this.alarmValues.get(key).get(property).value;
  }

  saveAndClose() {
    this.params.forEach((ele) => {
      this.alarmValues.get(ele).get("dateTime").setValue(this.date)
    })
    console.log(this.alarmValues.value);
    this.AutomaticSystemService.updateSystemSettings(this.alarmValues.value).subscribe((result) => {
      console.log(result);
    });
    this.modalController.dismiss({ submitted: true })
  }


  closeModal() {
    this.modalController.dismiss({ submitted: false })
  }


  async openAlert(name) {
    const alert = await this.alertController.create({
      header: "Choose Option",
      cssClass: "wholeSellerAlert",
      inputs: [
        {
          name: 'less than',
          type: 'radio',
          label: 'less than (<)',
          value: 'lt',
          checked: this.getValue(name, "ltgtCondition") == "lt",
        },
        {
          name: 'greater than',
          type: 'radio',
          label: 'greater than (>)',
          value: 'gt',
          checked: this.getValue(name, "ltgtCondition") == "gt",
        }],
      buttons: [{
        text: this.translate.instant('buttons.cancel'),
        role: 'cancel',
        handler: (val) => { console.log("cancelled") }
      }, {
        text: this.translate.instant('buttons.ok'),
        handler: (val) => {
          this.params.forEach((btn) => {
            if (btn == name) {
              this.alarmValues.get(name).get("ltgtCondition").setValue(val);
            }
          })
          this.propertyChanged(name)
        }
      }]
    })
    await alert.present();
  }


}