import { Component, OnInit } from '@angular/core';
import { PopoverController, NavParams } from '@ionic/angular';
import { TraderService } from '../../services/trader.service';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { CommonService } from './../../services/common.service';




@Component({
  selector: 'app-trader-order-show-sort-popup',
  templateUrl: './trader-order-show-sort-popup.component.html',
  styleUrls: ['./trader-order-show-sort-popup.component.scss'],
})
export class TraderOrderShowSortPopupComponent implements OnInit {
  items: any[];
  sortingValues: any;
  sortingVans: any;
  sortigFrom: string;
  radiusActive = false;
  radius = localStorage.getItem('distance');
  option;
  selected = "distance";
  
  tabValue;
  constructor(private popoverCtrl: PopoverController,
    private traderservice: TraderService,
    private commanService: CommonService,
    private NavParams: NavParams) {
      this.sortigFrom = this.NavParams.get('data');
      this.option = this.NavParams.get("for");
      // this.selected = this.NavParams.get("selected")
      console.log(this.selected)
      if (this.sortigFrom === 'liftings') {
        this.items = ['orderCount', 'VanCapacity'];
        // this.getVans();
      } else if (this.sortigFrom === 'orders') {
        this.tabValue = this.NavParams.get('aciveTabValue');
      this.items = ['price', 'Booked'];
      // this.getTraderOrders()
    } else if (this.sortigFrom == 'Home') {
      this.radiusActive = true;
      // this.getNearestForms()
      this.items = ['distance', 'capacity']
    }
  }

  ngOnInit() {
  }


  //using ternary operator    ---->sorting options for trader vans,lifting vans,trader farms
  sort(data) {
    console.log(data, this.radius, 39)
    this.selected = data;
    this.popoverCtrl.dismiss({newdata : data, select : this.option});

    // data === 'Price' ? [this.sortingValues.sort((a, b) => a.price - b.price), this.popoverCtrl.dismiss({ newdata: this.sortingValues })] :
    //   data === 'KM' ? [this.sortingValues.sort((a, b) => a.distance - b.distance), this.popoverCtrl.dismiss({ newdata: this.sortingValues })] :
    //     data === 'Booked' ? [this.sortingValues.sort((a, b) => b.birds_order - a.birds_order), this.popoverCtrl.dismiss({ newdata: this.sortingValues })] :
    //       data === 'Distance' ? [this.sortingValues.sort((a, b) => a.distance - b.distance), this.popoverCtrl.dismiss({ newdata: this.sortingValues })] :
    //         data === 'Van Capacity' ? [this.sortingValues.sort((a, b) => b.capacity - a.capacity), this.popoverCtrl.dismiss({ newdata: this.sortingValues })] :
    //           data === 'Capacity' ? [this.sortingValues.sort((a, b) => b.alive_birds - a.alive_birds), this.popoverCtrl.dismiss({ newdata: this.sortingValues })] :
    //             data === 'Day' ? [this.sortingValues.sort((a, b) => a.day - b.day), this.popoverCtrl.dismiss({ newdata: this.sortingValues })] : this.popoverCtrl.dismiss()
  }
  getTraderOrders() {
    this.sortingValues = this.traderservice.getSavedLocalOrders();
  }
  getVans() {
    // this.traderservice.getVans({ trader_uid: localStorage.getItem('u_id') }).subscribe(d => {
    //   this.sortingValues = d.data;
    // })
    // let vans = this.traderservice.getLocalSaveFarms();
    this.sortingValues = this.commanService.getVanList();
  }
  getNearestForms() {

    this.traderservice.getFarmsList({ skip: 0 }).subscribe(data => {
      this.sortingValues = data
      console.log(this.sortingValues, 91)
    })

    // let data = this.traderservice.getLocalSaveFarms();
    // console.log(data,4567);
  }

sortFilter(data){
  this.popoverCtrl.dismiss({ newdata: data })
}

  distance(data) {
    console.log(data);
    localStorage.setItem("distance",data)
  }

  distanceEnter(data) {
    localStorage.setItem("distance",data)
    this.popoverCtrl.dismiss({ newdata: data , select:"radius"})
  }
}
