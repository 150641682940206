import { Component, OnInit, ViewContainerRef, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { LiftingsService } from '../../services/liftings.service';
import { TraderService } from './../../services/trader.service';
import { Router } from '@angular/router';
import { NavParams, ModalController } from '@ionic/angular';
import { LoadingserviceService } from '../loadingservice.service';
@Component({
  selector: 'app-liftings-price-setting',
  templateUrl: './liftings-price-setting.component.html',
  styleUrls: ['./liftings-price-setting.component.scss'],
})
export class LiftingsPriceSettingComponent implements OnInit {
  currencySymbol: string = localStorage.getItem('currencySymbol');
  country = localStorage.getItem('country').toLowerCase();
  solidUnits = localStorage.getItem("solidUnits");
  settingsForm: FormGroup;
  activeTab: Boolean = true;
  cities: any;
  _inventoryId: any;
  priceStatus: boolean = true;
  cityStatus: boolean = false;
  cityname: any;
  invenLocation: any;
  totalPrice: any;
  // solidUnits: any;
  tradeStartAt: any;
  tradeEndAt: any;
  userRole = localStorage.getItem('role_id');
  constructor(
    private fb: FormBuilder,
    protected liftingService: LiftingsService,
    private traderService: TraderService,
    private router: Router,
    private navParams: NavParams,
    protected modalCtrl: ModalController,
    private ls: LoadingserviceService,
    private cdr: ChangeDetectorRef
  ) {
    this.settingsForm = this.fb.group({
      // tradeBookingDate: ['', Validators.required],
      marketPrice: ['', Validators.required],
      city: ['', Validators.required],
      margin: ['', [Validators.required, Validators.pattern('^[0-9 ()+-]+$')]],
    });
  }
  validation_messages = {
    // 'tradeBookingDate': [{ type: 'required', message: 'required.' }],
    'marketPrice': [{ type: 'required', message: 'required.' }],
    'automaticCounter': [{ type: 'required', message: 'required.' }],
    'margin': [{ type: 'required', message: 'required.' }, { type: 'pattern', message: '- number allowed' }],
  }


  tabChange(type) {
    if (type === 'auto') {
      this.activeTab = true;
      this.settingsForm.get('marketPrice').disable();
      this.formControlValuesAdd();
      this.cdr.detectChanges();
    } else {
      this.activeTab = false;
      this.settingsForm.get('marketPrice').enable();
      this.formControlValueRemove();
      this.cdr.detectChanges();
    }
  }

  ionViewWillEnter() {  }

  ngOnInit() {
    this._inventoryId = this.navParams.get("inventoryId");
    this.invenLocation = this.navParams.get("invenLocation");
    this.ls.loadingPresent();
    this.traderService.nearesetPriceAvailableCity({ _id: this._inventoryId, location: this.invenLocation }).subscribe((response: any) => {
      if (response.success) {
        this.ls.loadingDismiss();
        this.cities = response.data;
        if (this.cities.length > 0) {
          if (!this.cityname) {
            this.cityname = this.cities[0].city_name;
            this.cdr.detectChanges();
          }
          this.cityStatus = true;
        } else {
          this.cityname = '';
          this.cdr.detectChanges();
          this.cityStatus = true;
        }

      } else {
        this.ls.loadingDismiss();
      }
    });
    this.liftingService.getInventoryPrice({ "_id": this._inventoryId }).subscribe((data: any) => {
      if (data.success) {
        if (data.data.length > 0) {
          console.log(data.data, 98);
          this.solidUnits = data.data[0]['solidUnits'] || this.solidUnits;
          this.currencySymbol = data.data[0]['currencySymbol'] || this.currencySymbol;
          if (data.data[0].priceSettingType == 'manual') {
            this.tabChange('manual');
            this.settingsForm.controls['margin'].setValue((data.data[0].margin));
            this.settingsForm.controls['marketPrice'].setValue((data.data[0].price));
            this.priceStatus = data.data[0].broadCasting;
            this.priceStatus = data.data[0].priceSetting;
            this.totalPrice = data.data[0]['price'] + data.data[0]['margin'];
            this.cdr.detectChanges();
          } else {
            this.tabChange('auto');
            this.settingsForm.controls['margin'].setValue((data.data[0].margin));
            this.settingsForm.controls['marketPrice'].setValue((data.data[0].price));
            this.cityname = data.data[0]['cityName'];
            this.priceStatus = data.data[0].broadCasting;
            this.priceStatus = data.data[0].priceSetting;
            this.totalPrice = data.data[0]['price'] + data.data[0]['margin'];
            this.cdr.detectChanges();
          }
          if(localStorage.getItem("role_id") == "2" || localStorage.getItem("role_id") == "8"){
            this.priceStatus = data.data[0]['broadCasting']
          }
        }
      } else {
        console.log("get inventory price Failed", 85);
      }
    });
  }

  formControlValueRemove() {
    const city = this.settingsForm.get('city');
    if (!this.activeTab) {
      city.clearValidators();
    } else {
      city.setValidators([Validators.required]);
    }
    city.updateValueAndValidity();
  }

  formControlValuesAdd() {
    const city = this.settingsForm.get('city');
    if (this.activeTab) {
      city.setValidators([Validators.required]);
    }
    city.updateValueAndValidity();
  }



  priceSubmit(submitStatus) {
    console.log(this.settingsForm.value, 191);
    if (submitStatus) {
      if (!this.activeTab) {
        delete this.settingsForm.value.city;
        Object.assign(this.settingsForm.value, { priceSettingType: "manual", _id: this._inventoryId })
      } else {
        if (this.cities && this.cities.length > 0) {
          this.cities.filter((ele) => {
            if (ele.city_name == this.cityname) {
              Object.assign(this.settingsForm.value, { priceSettingType: "auto", cityId: ele.city_id, cityName: ele.city_name, _id: this._inventoryId })
            }
          });
        } else {
          console.log("no city", 122);
        }
      }
      this.liftingService.settingLiftingsPrice(this.settingsForm.value).subscribe((response: any) => {
        if (response.success) {
          this.modalCtrl.dismiss({ status: submitStatus, data: this.settingsForm.value });
        } else {
          console.log("setting price failed", 141);
        }
      });
    } else {
      this.modalCtrl.dismiss({ status: submitStatus });
    }
  }

  inventoryAdvertise(event) {
    if(this.userRole == "2" || this.userRole == "8"){
      this.liftingService.inventoryAdvertise(Object.assign({ _id: localStorage.getItem("u_id"), inventoryAdvertise: (!event.target.checked) })).subscribe((respond: any) => {
        console.log(respond);
      });
    }else{
      this.liftingService.inventoryAdvertise(Object.assign({ _id: this._inventoryId, inventoryAdvertise: (!event.target.checked) })).subscribe((respond: any) => {
        console.log(respond);
      });
    }
  }
  cityPrice(priceObj) {
    if (priceObj && this.cities && this.cities.length > 0) {
      this.cities.filter(ele => {
        if (ele.city_name == priceObj) {
          this.settingsForm.controls['marketPrice'].setValue(ele.price);
          this.totalPrice = parseInt(this.settingsForm.get('margin').value) + parseInt(ele.price);
        }
      });
    } else {
      console.log("no select city");
    }
  }

  priceCalc(event, type) {
    if (type == 'margin') {
      let margin = parseInt(event.target.value || 0);
      this.totalPrice = margin + this.settingsForm.get('marketPrice').value;
    }
    else {
      let price = parseInt(event.target.value || 0);
      this.totalPrice = price + this.settingsForm.get('margin').value;
    }
  }
}
