import { LoadingserviceService } from './../../loadingservice.service';
import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams, ToastController, PopoverController, AlertController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
// import { Router, navParams } from '@angular/router';

@Component({
  selector: 'app-price-modal',
  templateUrl: './price-modal.component.html',
  styleUrls: ['./price-modal.component.scss'],
})
export class PriceModalComponent implements OnInit {
  currencySymbol: string = localStorage.getItem('currencySymbol');
  solidUnits: string = localStorage.getItem('solidUnits');
  roleId: any;
  priceLabel: any;
  typelabel = [];
  typeName: any;
  units: any;
  roleName: any;
  dropDownType: boolean = false;
  tookPrice: FormGroup;
  validate_message = {
    price: [
      { type: 'required', message: 'priceRequired' },
    
    ],
    type: [
      { type: 'required', message: 'typeRequired' },
    ],
  };
  constructor(
    private modal: ModalController,
    private navParams: NavParams,
    private alert: AlertController,
    private fb: FormBuilder,
    private loader : LoadingserviceService
  ) {

  }

  ngOnInit() {
    this.tookPrice = this.fb.group({
      price: ['', Validators.required],
      type: ['',]
    });

    this.roleId = this.navParams.get('roleId');
    console.log(this.roleId, 22);
    if (this.roleId == "4") {
      this.priceLabel = "Price";
      this.units = "tonn";
      this.typeName = "Type(Optional)";
      this.dropDownType = false;
      this.roleName = "Rice husk supplier";
      this.tookPrice.controls['type'].clearValidators();
    } else if (this.roleId == "5") {
      this.priceLabel = "Price";
      this.units = "kg";
      this.typelabel = [
        "Charcoal",
        "Wood Charcoal",
      ];
      this.dropDownType = true;
      this.roleName = "Coal supplier";
    } else if (this.roleId == "6") {
      this.priceLabel = "Price";
      this.units = "tonn";
      this.typelabel = [
        "Broiler",
        "Layer",
      ];
      this.dropDownType = true;
      this.roleName = "Litter dealer";
    } else if (this.roleId == "7") {
      this.priceLabel = "Price";
      this.units = "Bag";
      this.typelabel = [
        "Small",
        "Medium",
        "Large"
      ];
      this.dropDownType = true;
      this.roleName = "Scrap bag dealer";
    } else if (this.roleId == "14") {
      this.priceLabel = "Chick price";
      this.typeName = "Breed name";
      this.units = "Chick";
      this.dropDownType = false;
      this.roleName = "Chick Supplier";
    } else if (this.roleId == "19") {
      this.priceLabel = "Feed price";
      this.units = "kg";
      this.typeName = "Feed type";
      this.dropDownType = false;
      this.roleName = "Feed Supplier";
    } else {
      console.log("no rawMaterialDealers");
    }
  }

  cancel() {
    this.modal.dismiss({status:false});
  }

  proceed(e) {
    this.modal.dismiss({
      roleId: this.roleId,
      formValues: this.tookPrice.value,
      status:true
    });
  }


}
