import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { config } from '../helper/config';
@Injectable({
  providedIn: 'root'
})
export class LayerWholeSellerService {
  public wholesellerAutoOrderCount: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  cuurentAutoCount:any= this.wholesellerAutoOrderCount.asObservable();
  customerCreateOrEdit: any;
  autoOrderCount:number;
  wipeOff: boolean;
  autoOrderDataStore: any;
  homeorderdetails: any;
  allExpenses: any;
  bookinngOrder = {
    farm: false,
    orderFarm: ''
  }
  optedBuyer = {
    buyer: false
  };
  vehicle: any;
  slctdVhclObj = {
    van: false
  };
  layerFarm: any;
  totalAvailableEggsData: any;
  fromVanDetail = {
    from: false,
    vanDetails : {},
  };
  constructor(private http: HttpClient) { }
  createOredit(val) {
    this.customerCreateOrEdit = val;
  }
  getCreateOreditCustomer() {
    return this.customerCreateOrEdit;
  }
  createSale(data): Observable<any> {
    console.log(data, 35);
    return this.http.post(config.api + "wholeseller/createSale", data);
  }

  autoOrderUpdate(autoOrder) {
    return this.http.post(config.api + "wholeseller/autoOrderUpdate", autoOrder);
  }
  
  rejectionStatus(status) {
    return this.http.post(config.api + 'wholeseller/rejectionStatus', status);
  }

  addStock(data): Observable<any> {
    return this.http.post(config.api + "wholeseller/addStock", data);
  }
  loadToVehicle(data): Observable<any> {
    return this.http.post(config.api + "wholeseller/loadVehicleSubmit", data);
  }
  getVehicleList(data) {
    return this.http.post(config.api + "wholeseller/getVehicleList",data);
  }
  addCustomer(data): Observable<any> {
    return this.http.post(config.api + "wholeseller/addCustomer", data);
  }
  resendCustomerRequest(data): Observable<any> {
    return this.http.post(config.api + "wholeseller/customerResendRequest", data);
  }
  //get buyer names
  getBuyersList(): Observable<any> {
    return this.http.get(config.api + "wholeseller/BuyersList");
  }
  // get total available vans
  getVehicles(data): Observable<any> {
    return this.http.post(config.api + "wholeseller/getWholesellerVehicles", data);
  }
  // get single van data
  // getSingleVanData(day): Observable<any> {
  //   return this.http.post(config.api + "wholeseller/getSingleVan", day)
  // }
  orderdetails(data) {
    this.homeorderdetails = data;
  }
  getOrderDetailes(){
    return this.homeorderdetails
  }

  getLayerFarms(param): Observable<any> {
    return this.http.post(config.api + 'wholeseller/getLayerFarms', param);
  }

  getLayerCustomer(data): Observable<any> {
    return this.http.post(config.api + 'wholeseller/customersList', data);
  }
  expenses(data): Observable<any> {
    return this.http.post(config.api + 'wholeseller/expenses', data);
  };
  availableEggs(): Observable<any> {
    return this.http.get(config.api + 'wholeseller/getAvlblEggs');
  }
  // get home sale and Stock
  getStockandSale(data): Observable<any> {
    return this.http.post(config.api + 'wholeseller/getStockandSale', data);
  }
  buyerDetailsTransfer(data) {
    this.optedBuyer.buyer = true;
    Object.assign(this.optedBuyer, { customer: data });
  }
  vehicleDetailsTransfer(dat) {
    this.slctdVhclObj.van = true;
    Object.assign(this.slctdVhclObj, { vehicle: dat });
    console.log(this.slctdVhclObj, 92)
  }
  returnVhcl() {
    return this.slctdVhclObj;
  }
  returnByr() {
    return this.optedBuyer;
  }
  vanDetailedData(data): Observable<any> {
    console.log(data,118)
    return this.http.post(config.api + 'wholeseller/vanDetailedData', data);
  }
  //farm more details
  sigleFarmdata(farm) {
    this.layerFarm = farm;
  }
  // return farm value
  getFarmdata() {
    return this.layerFarm;
  };
  booKingOrder(data) {
    this.bookinngOrder = {
      farm: data.farm,
      orderFarm: data.farmData
    }
  }
  getFarmBookings(data): Observable<any> {
    return this.http.post(config.api + 'wholeseller/getFarmBookings', data);
  }
  checkCustomerRegisterOrNot(phn): Observable<any> {
    return this.http.post(config.api + 'wholeseller/customerRegisterCheck', phn);
  }

  blendFormvalues(data) {
    this.allExpenses = data
  }

  saveAutoOrderData(autoOrder) {
    console.log(autoOrder, 126);
    this.autoOrderDataStore = autoOrder;
  }

  getAutoOrderData() {
    return this.autoOrderDataStore;
  }
  updateAndInsertExpenditureData(data): Observable<any> {
    return this.http.post(config.api + 'wholeseller/updtInsrtExp', data);
  }




  layerAdvertising(object):Observable<any>{
    return this.http.post(config.api + 'layers/advertiseUser',object);
  };

  getWholeSellerSettings():Observable<any>{
    return this.http.get(config.api + 'auth/getUserSettings');
  };


  clearSaleForm(data: boolean) {
    this.wipeOff = data;
  }
  fromVanDetails(prepareVanDetails, data: boolean){
    this.fromVanDetail = {
       from: data,
       vanDetails : prepareVanDetails,
    };
  }
  availableEggsData(eggs) {
    this.totalAvailableEggsData = eggs;
  };
  unlinkVan(data):Observable<any>{
    return this.http.post(config.api + 'wholeseller/whlSellerUnlinkVan',data);
  }

  getLatestLocation(data):Observable<any>{
    return this.http.post(config.api + 'wholeseller/locateMe',data);
  }
  setAuoOrderCount(value:number){
    this.wholesellerAutoOrderCount.next(value);
  }
  getAvailableDataForLoading():Observable<any>{
    return this.http.get(config.api + 'wholeseller/getAvailableEggsDataForloading');
  }
}