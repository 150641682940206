import { RoledashboardPage } from './../roledashboard/roledashboard.page';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingserviceService } from '../loadingservice.service';
import { RolechangeService } from '../services/rolechange.service';
import { Events } from '../services/events.service';

@Component({
  selector: 'app-rolesheader',
  templateUrl: './rolesheader.component.html',
  styleUrls: ['./rolesheader.component.scss'],
})
export class RolesheaderComponent implements OnInit {
  selectedRole = localStorage.getItem('roleName');
  currentRole = localStorage.getItem('roleName');

  @Output('roleChangeEvent') roleChangeEvent: EventEmitter<any> = new EventEmitter();
  constructor(
    public router: Router,
    public loader: LoadingserviceService,
    private roleDashboardService: RolechangeService,
    public events: Events
  ) { }

  ngOnInit() {
  }

  rolesList = [
    {
      role_id: "1",
      roleName: 'Farmer',
      icon: '../assets/roles/farmer.svg'
    },
    {
      role_id: "3",
      roleName: 'Farmer-Supervisor',
      icon: '../assets/roles/supervisor.svg'
    },
    {
      role_id: "2",
      roleName: 'Trader',
      icon: '../assets/roles/trader.svg'
    },
    {
      role_id: "8",
      roleName: 'Trader-Supervisor',
      icon: '../assets/roles/vandriver.svg'
    },
    {
      role_id: "10",
      roleName: 'Chicken Shop',
      icon: '../assets/roles/shop.svg'
    }
  ];

  roleChange(event) {
    this.selectedRole = event.target.value;
    this.roleChangeRequest(this.selectedRole);
    // this.roleChangeEvent.emit(this.selectedRole);
  }

  roleChangeRequest(selectedRole) {
    if (selectedRole != localStorage.getItem('roleName')) {
      let roleObj = this.rolesList.find(x => x.roleName == selectedRole);
      this.changeUserRole(roleObj);
    }
  }



  changeUserRole(roleObj) {
    Object.assign(roleObj, { countryName: localStorage.getItem('country') });
    this.roleDashboardService.updateUserRole(roleObj).subscribe(async (data: any) => {
      if (data.success) {
        this.loader.loadingDismiss();
        this.roleDashboardService.roleChange.next(roleObj);
        localStorage.setItem('role_id', roleObj.role_id);
        localStorage.setItem('roleName', roleObj.roleName);
        this.selectedRole = localStorage.getItem('roleName');
        let role = Number(roleObj.role_id);
        let category = [9, 11, 15, 17, 18].includes(role) ? "layers" : [1, 2, 3, 8, 10].includes(role) ? 'broiler' : "rawMaterialDealers";
        if (category == 'layers') {
          if (role == 9) {
            this.events.publish('user:created', { user: data.data });
            this.router.navigateByUrl('/layerfarmer');
          } else if (role == 15) {
            this.events.publish('user:created', { user: data.data });
            this.router.navigateByUrl('/wholeseller');
          } else if (role == 18) {
            this.events.publish('user:created', { user: data.data });
            localStorage.setItem('vanNumber', roleObj.vanNumber);
            this.router.navigateByUrl('/retailer');
          } else if (role == 17) {
            this.events.publish('user:created', { user: data.data });
            this.router.navigateByUrl('/layer-seller');
          } else if (role == 11) {
            this.events.publish('user:created', { user: data.data });
            this.router.navigateByUrl('/layersupervisor');
          } else {
            this.events.publish('user:created', { user: data.data });
            this.router.navigateByUrl('/home');
          }
        } else if (category == 'broiler') {
          if (role == 1) {
            console.log("check data", role)
            localStorage.setItem('roleName', roleObj.roleName)
            this.events.publish('user:created', { user: data.data });
            this.events.publish('user:farmer', { user: data.data });
            this.selectedRole = roleObj.roleName;
            this.router.navigate(['/home/tabs/poultry-home'], { replaceUrl: true });
          } else if (role == 3) {
            localStorage.setItem('roleName', roleObj.roleName);
            this.events.publish('user:created', { user: data.data });
            this.selectedRole = roleObj.roleName;
            this.router.navigate(['/supervisor'], { replaceUrl: true });

          } else if (role == 2) {
            localStorage.setItem('roleName', roleObj.roleName)
            this.events.publish('user:created', { user: data.data });
            this.events.publish('user:trader', { user: data.data });
            this.router.navigate(['/trader/tabs/orders'], { replaceUrl: true });

          } else if (role == 8) {
            this.events.publish('user:created', { user: data.data });
            localStorage.setItem('vanNumber', roleObj.vanNumber);
            this.router.navigate(['/contact-not-add'], { replaceUrl: true });

          } else if (role == 10) {
            this.events.publish('user:created', { user: data.data });
            this.router.navigate(['/chicken-center-home/tabs/chicken-home'], { replaceUrl: true });

          } else {
            this.events.publish('user:created', { user: data.data });
            this.router.navigateByUrl('/home');

          }
        } else if (category == 'rawMaterialDealers') {
          if (role == 4) {
            localStorage.setItem('enrolled', JSON.stringify(data.data.enrolled));
            this.router.navigateByUrl('/dealer/tabs/home');
          } else if (role == 5) {
            localStorage.setItem('enrolled', JSON.stringify(data.data.enrolled));
            this.router.navigateByUrl('/dealer/tabs/home');
          } else if (role == 14) {
            localStorage.setItem('enrolled', JSON.stringify(data.data.enrolled));
            this.router.navigateByUrl('/dealer/tabs/home');
          } else if (role == 19) {
            localStorage.setItem('enrolled', JSON.stringify(data.data.enrolled));
            this.router.navigateByUrl('/dealer/tabs/home');
          } else if (role == 6) {
            localStorage.setItem('enrolled', JSON.stringify(data.data.enrolled));
            this.router.navigateByUrl('/dealer/tabs/home');
          } else if (role == 7) {
            localStorage.setItem('enrolled', JSON.stringify(data.data.enrolled));
            this.router.navigateByUrl('/dealer/tabs/home');
          }
        } else {
          console.log("category not selected", 288);
        }
      } else {
        this.loader.loadingDismiss();
      }
    });
  }
}
