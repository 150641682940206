import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from './../app/helper/config';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class TraderService {
  public incomingOrdersList = [];
  boxData = new Subject<any>();
  public incomingOrders = new BehaviorSubject<any>([]);
  public incomingList$ = this.incomingOrders.asObservable();
  traderinformation: any;
  vans: any;
  result: any;
  exits_forms;
  // public addedVans = new BehaviorSubject<any>([]);
  // public addedVans$ = this.addedVans.asObservable();
  // public farms = new BehaviorSubject<any>([]);
  // navItem$ = this.farms.asObservable();
  public myData: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public acountCount: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  private pendingOrderSubject = new BehaviorSubject<any>([]);
  public pendingOrdersCountObserve$ = this.pendingOrderSubject.asObservable();
  public unassignedOrdersList = new BehaviorSubject<any>([]);
  public unassignedOrdersList$ = this.unassignedOrdersList.asObservable();

  navItem$ = this.myData.asObservable();
  account$ = this.acountCount.asObservable();
  farms: any;

  count: any;
  saveLocalFarm: any;
  localOrders: any;
  localSavedVan: any;
  saveCCLocal: any;
  traderLocalSavedNotification: any;
  selectedVechileOrOrder: any;
  orderDataForBox: any;
  boxesDataSummary: orderDataForBoxExtend;
  public farmerDetailes: any;
  inventories: any;
  pickedInventory: any;
  boxesList: any;
  public ordersObj;
  constructor(
    protected http: HttpClient
  ) {
  }

  getFarmsList(data: any): Observable<any> {
    return this.http.post(config.api + 'trader/nearest-farms', data)
      .pipe(map((resForms: any) => {
        if (resForms.success) {
          this.count = resForms.count;
          this.exits_forms = resForms.data;
          console.log(resForms.data);
          this.myData.next(this.exits_forms);
          this.setLocalSaveFarms(this.exits_forms);
          this.acountCount.next(this.count);
          return resForms.data;
        } else {
          return resForms;
        }
      }))
  }

  getAllFarmsList(data: any): Observable<any> {
    return this.http.post(config.api + 'trader/allFarms', data);
  }

  getFarmsAdded(data: any): Observable<any> {
    return this.http.post(config.api + 'trader/getFarmsAdded', data);
  }
  tempFarmsNew(data): Observable<any> {
    return this.http.post(config.api + 'trader/tempfarms', data)
  }

  setLocalSaveFarms(data) {
    console.log(data, 6789)
    this.saveLocalFarm = data;
  }
  getLocalSaveFarms() {
    return this.saveLocalFarm;
  }


  getAcountCount() {
    return this.count;
  }
  set Forms(forms) {
    this.farms = forms;
  }
  get Forms() {
    return this.farms;
  }

  getFarmInfo(data) {
    return this.http.post(config.api + 'trader/farm-info', data);
  }

  getVan(data) {
    return this.http.post(config.api + 'trader/get-van', data);
  }

  updateVans(data) {
    return this.http.post(config.api + 'trader/update-vans', data);
  }
  particularUserinfo(data): Observable<any> {
    return this.http.post(config.api + 'auth/particularUserinfo', data);
  }
  traderinfo(data) {
    this.traderinformation = data;
  }
  info() {
    const singletrader = this.traderinformation;
    return singletrader;
  }
  location(data) {
    return this.http.post(config.api + 'trader/nearestTrader', data);
  }
  addChickenCenter(data) {
    return this.http.post(config.api + 'trader/addchickencenter', data);
  }
  addTempChickenCenter(data) {
    return this.http.post(config.api + 'trader/addTempChickenCenter', data);
  }
  getChickenCenters(data) {
    return this.http.post(config.api + 'trader/getChickenCenters', data);
  }
  getCCForOrder(): Observable<any> {
    return this.http.get(config.api + 'trader/getCCForOrder');
  }
  addVanInTrader(data): Observable<any> {
    return this.http.post(config.api + 'trader/addVanInTrader', data);
  }
  addRequested(data): Observable<any> {
    return this.http.post(config.api + 'trader/addRequested', data);
  }

  getVans(data) {
    this.vans = this.http.post(config.api + 'trader/getVans', data);
    return this.vans;
  }
  getLiftingVans(data) {
    return this.http.post(config.api + 'trader/getLiftingVans', data);
  }
  createOrders(data) {
    return this.http.post(config.api + 'trader/createOrders', data);
  }
  getTraderOrders(data) {
    console.log(data, 90)
    return this.http.post(config.api + 'trader/getTraderOrders', data);
  }
  getPendingOrders(data) {
    return this.http.post(config.api + 'trader/getTraderOrders', data).pipe(
      map((pedingOrders: any) => {
        if (pedingOrders.success) {
          this.pendingOrderSubject.next(pedingOrders.data.length)
        } else {

        }
        return pedingOrders
      })
    )
  }

  saveTraderNotificationsLocal(data) {
    this.traderLocalSavedNotification = data;
  }
  localSavedTraderNotification() {
    return this.traderLocalSavedNotification;
  }
  saveOrdersLocal(data) {
    this.localOrders = data;
  }
  updateStatus(data): Observable<any> {
    return this.http.post(config.api + 'trader/viewStatus', data);
  }
  getSavedLocalOrders() {
    return this.localOrders;
  }
  // getTraderOrders() {
  //   return this.http.get(config.api + 'trader/getTraderOrders',)
  // }
  getTraderDetails() {
    return this.http.get(config.api + 'trader/getTraderDetails');
  }
  priceSettings(data): Observable<any> {

    return this.http.post(config.api + 'trader/traderPriceSetting', data);
  }
  getPrice(): Observable<any> {
    return this.http.get(config.api + 'trader/traderGetPrice');
  }

  // Added code for traders
  inserTrader(trader) {
    return this.http.post(config.api + 'trader/insertrader', trader);
  }

  // Added code for traders
  getTraderData() {
    return this.http.get(config.api + 'trader/traderGetData');
  }
  deleteOrder(id) {
    return this.http.post(config.api + 'trader/trderDeleteOrder', id);
  }

  cancelStatus(deleteData) {
    return this.http.post(config.api + 'trader/trderDeleteOrder', deleteData);
  }

  addFarmerContact(data): Observable<any> {
    return this.http.post(config.api + "trader/addFarmerContact", data)
  }

  recordsSearch(searchData): Observable<any> {
    return this.http.post(config.api + 'trader/searchRecord', searchData);
  }

  TraderAddingVan(data): Observable<any> {
    return this.http.post(config.api + 'trader/traderAddingVan', data);
  }

  editOrders(data) {
    return this.http.post(config.api + 'trader/editOrders', data);
  }

  chickenCenterAvilabiltyCheck(data): Observable<any> {
    return this.http.post(config.api + 'trader/chickenCenterAvailabiltyChecking', data);
  }
  getTempCC(data): Observable<any> {
    return this.http.post(config.api + 'trader/getTempChickenCenters', data);
  }

  addSignaturesChicken(data): Observable<any> {
    console.log(data);
    return this.http.post(config.api + 'trader/addsignature', data);
  }

  getVechilesList(): Observable<any> {
    this.vans = this.http.get(config.api + 'trader/vechileList');
    return this.vans;
  }
  assignVechiles(data): Observable<any> {
    return this.http.post(config.api + 'trader/vechilesAssigns', data);
  }

  ordersVanData(): Observable<any> {
    return this.http.get(config.api + 'trader/ordersVanData')
  }

  assignFarm(data): Observable<any> {
    console.log(data);
    return this.http.post(config.api + 'trader/assignFarmToDriver', data)
  }
  specificvan() {
    console.log(this.vans, 252525)
    return this.vans;
    // return this.http.post(config.api + "trader/pastOrder", dataz
  }

  singleVanOrder(data) {
    return this.http.post(config.api + 'trader/pastOrder', data)
  }

  getallInformationFordriver(data) {
    return this.http.post(config.api + 'trader/getallDataFordriver', data);
  }
  getExitOrders(data) {
    return this.http.post(config.api + 'trader/getOrdersForVan', data);
  }
  getExitFarm(data) {
    return this.http.post(config.api + 'trader/getExitFarm', data);
  }
  // getVanDetails() {
  //    return  new Promise((resolve, reject) => {
  //       this.http.get(config.api + 'trader/getVanDetails').toPromise()
  //       .then((res) => {
  //       this.result = res;
  //       resolve(this.result);
  //     }).catch(err => {
  //       reject(err);
  //     });
  //   });
  // }
  getVanDetails(): Observable<any> {
    this.vans = this.http.get(config.api + 'trader/getVanDetails')
    return this.vans;
  }
  temporaryFarm(data): Observable<any> {
    return this.http.post(config.api + 'trader/temporaryFarmCreate', data)
  }

  deleteFarm(data): Observable<any> {
    return this.http.post(config.api + 'trader/deleteTempFarm', data)
  }

  deliveryStatus(data): Observable<any> {
    return this.http.post(config.api + 'driver/delivered', data)
  }
  //   Farms;
  //   storeFarms(data) {
  //  this.Farms = data;
  //  console.log(this.Farms);
  //   }
  dltLiftingVan(id): Observable<any> {
    return this.http.post(config.api + 'trader/dltVan', id)
  }
  getTotalOrders(id): Observable<any> {
    return this.http.post(config.api + 'trader/getTraderTotalOrders', id);
  }
  automaticprice(): Observable<any> {
    return this.http.get(config.api + 'trader/automaticPriceFetch')
  }
  getTempVans(data): Observable<any> {
    return this.http.post(config.api + 'trader/getTempVans', data)
  }

  saveVansLocal(data) {
    this.localSavedVan = data
  }

  getLocalSavedVans() {
    return this.localSavedVan;
  }
  tempFarmsList(data): Observable<any> {
    return this.http.post(config.api + 'trader/tempFarmsList', data)
  }
  ccSearch(data): Observable<any> {
    return this.http.post(config.api + 'trader/ccSearch', data)
  }

  farmsSearch(searchItem): Observable<any> {
    return this.http.post(config.api + 'trader/farmsSearch', searchItem)
  }
  tempFarmsSearch(searchItem): Observable<any> {
    return this.http.post(config.api + 'trader/tempFarmsSearch', searchItem)
  }
  vechileSearch(searchItem): Observable<any> {
    return this.http.post(config.api + 'trader/LiftingVanSearch', searchItem)
  }
  tempVechileSearch(searchItem): Observable<any> {
    return this.http.post(config.api + 'trader/tempVanSearch', searchItem)
  }
  deleteTempCC(id): Observable<any> {
    return this.http.post(config.api + 'trader/deleteTempCC', id)
  }
  nearesetPriceAvailableCity(data): Observable<any> {
    return this.http.post(config.api + 'trader/nearPriceAvailable', data)
  }

  farmAssignData(data): Observable<any> {
    return this.http.post(config.api + 'trader/farmAssignedData', data)
  }
  priceSettingUpdate(data): Observable<any> {
    return this.http.post(config.api + 'trader/PriceStatus', data)
  }
  resendResquest(data): Observable<any> {
    return this.http.post(config.api + 'trader/resendRequest', data)
  }

  deliveryConfirm(data): Observable<any> {
    return this.http.post(config.api + 'trader/deliveryConfirm', data)
  }

  traderContactAccountSearch(data): Observable<any> {
    return this.http.post(config.api + 'trader/contactUserSearch', data)
  }

  getFilteredFarmsList(data): Observable<any> {
    return data;
  }

  traderHomePageData(data): Observable<any> {
    return this.http.post(config.api + 'trader/homepageData', data)
  }
  orderStatusUpdate(data): Observable<any> {
    return this.http.post(config.api + 'trader/orderStatusUpdate', data)
  }

  traderContactAdd(data): Observable<any> {
    return this.http.post(config.api + 'trader/vanContactAdd', data)
  }
  editvan(data): Observable<any> {
    return this.http.post(config.api + 'trader/editvan', data);
  }

  traderVehicleAssign(data): Observable<any> {
    return this.http.post(config.api + 'trader/vehicleAssign', data)
  }

  traderVechilesList(data): Observable<any> {
    return this.http.post(config.api + 'trader/traderVehicles', data)
  }

  getLiftingVanDetails(data): Observable<any> {
    return this.http.post(config.api + 'trader/liftingVanDetails', data)
  }

  traderVehicleStatusUpdate(data): Observable<any> {
    return this.http.post(config.api + 'trader/vehicleStatusUpdate', data)
  }

  farmAssignToOrder(data): Observable<any> {
    return this.http.post(config.api + 'trader/farmStatusUpdate', data)
  }

  vehicleAssignToFarm(data): Observable<any> {
    return this.http.post(config.api + 'trader/vanAssignToFarm', data)
  }

  boxSelectForOrder(data): Observable<any> {
    return this.http.post(config.api + 'trader/boxesAssignToOrder', data)
  }

  getBlockedBoxesList(data): Observable<any> {
    return this.http.post(config.api + 'trader/getBoxesList', data)
  }

  vehicleDeliveryStatusUpdate(data): Observable<any> {
    return this.http.post(config.api + 'trader/vanDeliveryStatusUpdate', data)
  }

  editOrder(data): Observable<any> {
    return this.http.post(config.api + 'trader/editOrder', data)
  }

  searchForContact(data): Observable<any> {
    console.log(data, "service");

    return this.http.post(config.api + 'trader/findContactData', data)
  }

  farmerListForTrader(data): Observable<any> {
    return this.http.post(config.api + 'trader/farmerListForTrader', data)
  }

  farmerAccess(data): Observable<any> {
    return this.http.post(config.api + 'trader/farmerRequestAccept', data)
  }

  farmerReject(data): Observable<any> {
    return this.http.post(config.api + 'trader/farmerRequestReject', data)
  }

  ContactsList(data): Observable<any> {
    return this.http.post(config.api + 'trader/userContatsList', data)
  }

  traderListForSupervisor(data): Observable<any> {
    return this.http.post(config.api + 'traderSupervisor/traderListForSupervisor', data)
  }

  contactListForFarmer(data): Observable<any> {
    return this.http.post(config.api + 'trader/contactListForFarmer', data)
  }
  //trader supervisor home pagef

  traderSupervisorVanSummary(data): Observable<any> {
    return this.http.post(config.api + 'traderSupervisor/traderSupervisorVanSummary', data)
  }

  adminAccessCheck(data): Observable<any> {
    return this.http.post(config.api + "traderSupervisor/adminAccessCheck", data)
  }

  traderSupervisorOrdersSummary(data): Observable<any> {
    return this.http.post(config.api + 'traderSupervisor/traderSupervisorOrdersSummary', data)
  }



  contactUnlink(data): Observable<any> {
    return this.http.post(config.api + 'trader/unlinkContact', data)
  }


  traderPermissionToSupervisor(data): Observable<any> {
    return this.http.post(config.api + 'trader/accessGrantToTrader', data)
  }

  setOrderData(data) {
    this.orderDataForBox = data;
  }

  getOrderDataForBox() {
    return this.orderDataForBox;
  }

  setBoxesData(data: orderDataForBoxExtend) {
    this.boxesDataSummary = data;
  }
  getBoxesData() {
    return this.boxesDataSummary
  }

  addNewBox(data): Observable<any> {
    return this.http.post(config.api + 'trader/addNewBox', data);
  }

  editBoxData(data): Observable<any> {
    return this.http.post(config.api + 'trader/updateBox', data);
  }

  traderBoxEdit(data): Observable<any> {
    return this.http.post(config.api + "trader/traderBoxEdit", data);
  }
  boxEditForTrader(data): Observable<any> {
    return this.http.post(config.api + 'trader/boxEditForTrader', data);
  }

  updateWeightGeneral(data): Observable<any> {
    return this.http.post(config.api + "trader/generalWeightUpdate", data);
  }

  orderDetails(data): Observable<any> {
    return this.http.post(config.api + 'trader/orderDetails', data);
  }

  paid(data): Observable<any> {
    return this.http.post(config.api + 'trader/paymentUpdate', data);
  }

  dataForNotification(data): Observable<any> {
    return this.http.post(config.api + "/tradersupervisor/dataForNotification", data);
  }

  notificationUser(data): Observable<any> {
    return this.http.post(config.api + "/notify/notificationUser", data);
  }

  traderData(data): Observable<any> {
    return this.http.post(config.api + "/trader/senderDataRequired", data);
  }

  traderAddChickenCenters(data): Observable<any> {
    return this.http.post(config.api + 'contacts/getTraderContactForCC', data);
  }

  contactPermissions(data): Observable<any> {
    return this.http.post(config.api + 'contacts/contactPemissionChange', data);
  }

  unlinkChickenShop(data): Observable<any> {
    return this.http.post(config.api + 'contacts/trader-shop-brokeup', data);
  }

  traderAccountSearch(data): Observable<any> {
    return this.http.post(config.api + 'contacts/traderAddAsContact', data);
  }
  roughEstimatedOrders(data): Observable<any> {
    return this.http.post(config.api + 'trader/getRoughEstimatedOrders', data);
  }

  createOrderForFarmer(orderData): Observable<any> {
    return this.http.post(config.api + 'trader/createOrderToFarmer', orderData);
  }

  editPurchaseOrder(data): Observable<any> {
    return this.http.post(config.api + 'trader/traderIncomingOrderEdit', data);
  }

  getIncomingOrders(data): Observable<any> {
    return this.http.post(config.api + 'trader/getTraderIncomingOrders', data);
  }

  unassignedOrders(data): Observable<any> {
    return this.http.post(config.api + 'trader/getUnassingedOrders', data).pipe(
      map((orders: any) => {
        if (orders.success) {
          this.unassignedOrdersList.next(orders.data)
        }
        return orders
      })
    )
  }

  inventoryListFarmer(data): Observable<any> {
    return this.http.get(config.api + `inventory/getinventories?u_id=${data.farmerUid}`)
  }

  setInventoriesData(data) {
    this.inventories = data;
  }
  getInventoriesData() {
    return this.inventories;
  }

  setPickedInventory(data) {
    this.pickedInventory = data;
  }

  getPickedInventory() {
    return this.pickedInventory
  }

  // accept received order from chicken shop
  acceptOrderFromChickenShop(data): Observable<any> {
    return this.http.post(config.api + 'trader/processAutoOrder', data)
  }

  createManualOrderForChickenShop(data): Observable<any> {
    return this.http.post(config.api + 'trader/manualOrderForChickenShop', data)
  }

  incomingOrdersAssignedList(data): Observable<any> {
    return this.http.post(config.api + 'trader/assignedOrdersList', data)
  }

  cancelAutoOrder(data): Observable<any> {
    return this.http.post(config.api + 'trader/cancelAutoOrder', data)
  }

  updateOrderStatus(data): Observable<any> {
    return this.http.post(config.api + 'trader/updateOrderStatus', data)
  }

  public setIncomingOrdersList(data) {
    this.incomingOrdersList = data;
  }
  public getIncomingOrdersList() {
    return this.incomingOrdersList
  }

  getOrdersListForBoxAssign(data): Observable<any> {
    return this.http.post(config.api + 'trader/ordersListToAssignBoxes', data)
  }

  getOrdersListForBoxAssignPromise(data) {
    return this.http.post(config.api + 'trader/ordersListToAssignBoxes', data)
  }

  updateAutoSale(data): Observable<any> {
    return this.http.post(config.api + 'trader/updateAutoSale', data)
  }

  saveBoxes(data): Observable<any> {
    return this.http.post(config.api + 'trader/saveBoxes', data)
  }

  saveAllBoxes(data): Observable<any> {
    return this.http.post(config.api + 'trader/saveAllBoxes', data)
  }

  incomingOrderStatus(data): Observable<any> {
    return this.http.post(config.api + 'trader/incomingOrderStatusUpdate', data)
  }

  outgoingOrderStatus(data): Observable<any> {
    return this.http.post(config.api + 'trader/outgoingOrderStatusUpdate', data)
  }

  traderAllOrders(data): Observable<any> {
    return this.http.post(config.api + 'trader/traderOrdersAll', data)
  }

  traderPriceSummary(data): Observable<any> {
    return this.http.post(config.api + 'trader/traderPricesSummary', data)
  }

  setBoxesList(data) {
    this.boxesList = data;
  }

  getBoxesList() {
    return this.boxesList;
  }

  traderEditorder(data): Observable<any> {
    return this.http.post(config.api + 'trader/traderEditOrder', data)
  }

  stepSaleEdit(data): Observable<any> {
    return this.http.post(config.api + 'trader/stepSaleEdit', data)
  }

  chickenShopDetailedView(data): Observable<any> {
    return this.http.post(config.api + 'trader/chickenCenterDetails', data)
  }

  chickenShopSummary(data): Observable<any> {
    return this.http.post(config.api + "trader/chickenShopSummary", data)
  }

  farmerVanSummary(data): Observable<any> {
    return this.http.post(config.api + "trader/farmerVanSummary", data)
  }

  getFarmerTransactions(data): Observable<any> {
    return this.http.post(config.api + "trader/traderFarmerTransactions", data)
  }

  updateOrderPrice(data): Observable<any> {
    return this.http.post(config.api + "trader/updateOrderPrice", data)
  }

  traderPastTransactions(data): Observable<any> {
    return this.http.post(config.api + "trader/traderPastTransactions", data)
  }
  traderLiftingDc(data): Observable<any> {
    return this.http.post(config.api + "trader/traderLiftingDoc", data)
  }
}


export interface orderDataForBoxExtend {
  action: string,
  "data": any[],
  box: Number
}