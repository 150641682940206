import { Directive, Input, ElementRef, OnInit, OnChanges } from '@angular/core';
import * as Highcharts from 'highcharts';
@Directive({
  selector: '[appLayerGraph]'
})
export class LayerGraphDirective implements OnChanges {

  @Input('appLayerGraph') totalGraph: any;
  @Input('dropDown') dropDown: any;
  @Input('first') first: any;
  @Input('second') second: any;
  @Input('max') max: any;
  chartOptions: any = {
    chart: {
      type: 'column',
      zoomType: 'xy',
      height: 160,
    
    },
    title: {
      text: ''
    },
    xAxis: {
      categories: this.dropDown,
      
    },
    yAxis: {
      title: {
        style: {
          fontSize: '18px',
          display: 'none',
        }
      },
      min: 0,
      minRange: 0.1,
     
    },

    plotOptions: {
      column: {
        pointPadding: 0.1,
        borderWidth: 0,
        dataLabels: {
          enabled: false,  //label showing
          distance: -40,
          style: {
            fontSize: "6",
            fontWeight: 'bold',
            color: 'gray',
          }
        },
      },
    },
    credits: {
      enabled: false
    },
   
 legend: {
      itemStyle: {
        font: '9px Roboto',
        align: 'left',
        width: 250,
      },
      itemMarginBottom: 8,
      align: 'center',
     layout: 'horizontal',
      verticalAlign: 'top',
      floating: false,
    
      
    },
    series: <any>[{
      name: 'Collected Eggs',
      color: '#ffba31',
      data: this.first
    }, {
      name: 'Spoiled Eggs',
      color: '#ff3931',
      data: this.second
    }]
  }

  constructor(private ele: ElementRef) {

  }
  ngOnInit() {
    this.chartOptions.series[0].data = this.first;
    this.chartOptions.series[1].data = this.second;
    this.chartOptions.xAxis.categories = this.dropDown;
    this.chartOptions.yAxis.max = Math.max(...this.max);
    setTimeout(() => {
      Highcharts.chart(this.ele.nativeElement, this.chartOptions);
    }, 500)

  }
  //if any changes come from dom then graph values are changed
  ngOnChanges(changes) {
    if (changes.dropDown) {
      this.chartOptions.series[0].data = this.first;
      this.chartOptions.series[1].data = this.second;
      this.chartOptions.xAxis.categories = this.dropDown;
      this.chartOptions.yAxis.max = Math.max(...this.max);
      Highcharts.chart(this.ele.nativeElement, this.chartOptions);
      console.log(this.ele.nativeElement,'ele');
      console.log(this.chartOptions,'chartOpt')
    }
  }
}









// //////mountain graph///
// import { Directive, Input, ElementRef, OnInit, OnChanges } from '@angular/core';
// import * as Highcharts from 'highcharts';
// @Directive({
//   selector: '[appLayerGraph]'
// })
// export class LayerGraphDirective implements OnChanges {

//   @Input('appLayerGraph') totalGraph: any;
//   @Input('dropDown') dropDown: any;
//   @Input('first') first: any;
//   @Input('second') second: any;
//   @Input('max') max: any;
//   chartOptions: any = {
//     chart: {
//       type: 'areaspline',
//        height: 200,
//   },
//     title: {
//       text: ''
//     },
//     xAxis: {
//       categories: this.dropDown,
//     },
 
//     yAxis: {
//       title: {
//           text: ''
//       }
//   },
//   tooltip: {
//     shared: true,
//     valueSuffix: ' units'
// },
// credits: {
//     enabled: false
// },
// plotOptions: {
//   areaspline: {
//       fillOpacity: 0.5
//   }
// },
//  legend: {
//       itemStyle: {
//         font: '9px Roboto',
//         align: 'left',
//         width: 250,
//       },
//       itemMarginBottom: 8,
//       align: 'left',
//      layout: 'horizontal',
//       verticalAlign: 'bottom',
//       floating: false,
//       width: 220,
      
//     },

//     series: <any>[{
//       name: 'Collected Eggs',
//       data: this.first
//     }, {
//       name: 'Spoiled Eggs',
//       data: this.second
//     }]
//   }
//   constructor(private ele: ElementRef) {
//   }
//   ngOnInit() {
//     this.chartOptions.series[0].data = this.first;
//     this.chartOptions.series[1].data = this.second;
//     this.chartOptions.xAxis.categories = this.dropDown;
//     this.chartOptions.yAxis.max = Math.max(...this.max);
//     setTimeout(() => {
//       Highcharts.chart(this.ele.nativeElement, this.chartOptions);
//     }, 500)

//   }
//   //if any changes come from dom then graph values are changed
//   ngOnChanges(changes) {
//     if (changes.dropDown) {
//       this.chartOptions.series[0].data = this.first;
//       this.chartOptions.series[1].data = this.second;
//       this.chartOptions.xAxis.categories = this.dropDown;
//       this.chartOptions.yAxis.max = Math.max(...this.max);
//       Highcharts.chart(this.ele.nativeElement, this.chartOptions);
//       console.log(this.ele.nativeElement,'ele');
//       console.log(this.chartOptions,'chartOpt')
//     }
//   }
// }



