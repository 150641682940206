import { Injectable } from '@angular/core';
import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class MissingTranslationService implements MissingTranslationHandler {

  constructor() { }
  handle(params: MissingTranslationHandlerParams) {
    let value = params['translateService']['store']['translations']['en'];
    if (params.interpolateParams) {
      return params.interpolateParams["Default"] || params.key;
    }
    return "trnslation Not provides";
  }
}
