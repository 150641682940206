import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { LeasePage } from './lease.page';
import { FileTransfer, FileTransferObject, FileUploadOptions } from '@ionic-native/file-transfer/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx'
import { CommonExportsModule } from '../../common-exports/common-exports.module';
import { FilePath } from '@ionic-native/file-path/ngx';
import { File, FileEntry } from '@ionic-native/File/ngx';
import { FileChooser } from '@ionic-native/file-chooser/ngx';
import { ImagePicker } from '@ionic-native/image-picker/ngx';
import { LeaseService } from 'src/services/lease.service';
import { SharedModule } from 'src/app/shared.module';

// import { GetfilePipe } from 'src/app/getfile.pipe';


const routes: Routes = [
  {
    path: '',
    component: LeasePage
  }
];

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    IonicModule,
  //  RouterModule.forChild(routes),
    CommonExportsModule
  ],
  providers: [
    File,
    FileTransferObject,
    FileTransfer,
    Camera,
    WebView,
    FilePath,
    FileChooser,
    ImagePicker,
    LeaseService
  ],
  declarations: [LeasePage,]
})
export class LeasePageModule { }
