import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'Lease'
})
export class LeasePipe implements PipeTransform {
  transform(items: any[], args: any): any {
    if (items == null) {
      return null;
    }
    if (args == 'all') {
      var data1 = items.filter(item => item.category === "poultry lease " || item.category === "FarmLease" || item.category === "Man Power" || item.category === "Equipment" || item.category === "others");
      return data1
    } else if (args == "FarmLease") {
      var data = items.filter(item => item.category === "FarmLease");
      return data
    } else if (args == 'Equipment') {
      var data1 = items.filter(item => item.category === "Equipment");
      return data1
    } else if (args == 'Man Power') {
      var data2 = items.filter(item => item.category === "Man Power");
      return data2
    } else {
      var data3 = items.filter(item => item.category == 'others');
      return data3
    }
  }
}
