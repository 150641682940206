import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberFormat'
})
export class NumberFormatPipe implements PipeTransform {

  transform(value: any): Object {
    if (typeof value == "string") {
      let spli = value.split(",");
      let joi = spli.join("");
      if (joi.match(/^[0-9]*$/)) {
        console.log(joi, typeof joi, "number string");
        let numb = + joi
        return this.localeString(numb);
      }
      else {
        console.log(joi, typeof joi, "number string");
        return false;
      }
    } else {
      return this.localeString(value);
    }
  }

  localeString(nStr) {
    var n1, n2;
    nStr = nStr + '' || '';
    let numbe = nStr + '' || '';
    // works for integer and floating as well
    n1 = nStr.split('.');
    n2 = n1[1] || null;
    n1 = n1[0].replace(/(\d)(?=(\d\d)+\d$)/g, "$1,");
    nStr = n2 ? n1 + '.' + n2 : n1;
    return { changed: nStr, number: +numbe };
  }

}
