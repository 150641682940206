import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'lifting'
})
export class LiftingPipe implements PipeTransform {
  transform(items: any[], args: any): any {
    if (items == null) {
      return null;
    }
    if (args == "All" || args == "") {
      var data = items.filter(item => item);
      return data;
    } else if (args == "Lifted") {
      var data1 = items.filter(item => item.orderCreatedRole === true);
      return data1;
    } else if (args == "Manual booked") {
      if (localStorage.getItem('role_id') == '1' || localStorage.getItem('role_id') == '3') {
        var manual = items.filter(item => {
          if (item.orderCreatedRole == 1 || item.orderCreatedRole == 3) {
            return item;
          }
        });
      } else {
        var manual = items.filter(item => item.orderCreatedRole === true);
      }
      return manual;
    }
    else if (args == "Auto booked") {
      if (localStorage.getItem('role_id') == '1' || localStorage.getItem('role_id') == '3') {
        var auto = items.filter(item => {
          if (item.orderCreatedRole != 1 && item.orderCreatedRole != 3) {
            return item;
          }
        });
      } else {
        var auto = items.filter(item => item.order_type == true && item.loaded_status == false)
      }
      return auto;
    }
  }
}