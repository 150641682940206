import { LayerfarmerSupervisorService } from 'src/app/services/layerfarmer-supervisor.service';
import { SupervisorService } from 'src/services/supervisor.service';
import { Component, Input, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { LayersService } from 'src/app/services/layers.service';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Platform, PopoverController, ToastController, ModalController, AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { InventoryInfoService } from 'src/app/inventory-info/inventory-info.service';


@Component({
  selector: 'app-add-feed-stock',
  templateUrl: './add-feed-stock.page.html',
  styleUrls: ['./add-feed-stock.page.scss'],
})
export class AddFeedStockPage implements OnInit {
  inventoryData = [{ inventory_name: 'Overall' }];
  stockFor: any;
  editFeedData: any;
  edit = false;
  currencySymbol = localStorage.getItem('currencySymbol');
  solidUnits = localStorage.getItem('solidUnits');
  addfeedstock: FormGroup;
  selectedunits: any = "kg";
  display: any = [];
  select: any;

  feed: string;
  @Input() openFromPage: any;
  unitsnum;
  fromUpdatePage: boolean = false;
  inventoryUpdateDetails: any;
  formulaDetails: any;
  validation_messages = {
    type: { type: 'required', message: 'type is required' },
    Quantity: { type: 'required', message: 'Quantity is required' },
    Price: { type: 'required', message: 'price is required' }
  };
  selectFeedId: any;
  default;
  feedName: void;
  pertonnInlbs: any;
  date_check_cond: boolean = false;
  todayDate = new Date().toISOString();
  editableNo: boolean = false;
  noAccess: boolean = false;

  constructor(
    private fb: FormBuilder,
    public layerService: LayersService,
    private alert: AlertController,
    private router: Router,
    private route: ActivatedRoute,
    private ModalController: ModalController,
    public navParams: NavParams,
    public translate: TranslateService,
    public supervisorService: SupervisorService,
    public layerSupervisor: LayerfarmerSupervisorService,
    public toast: ToastController,
    private inventory: InventoryInfoService,
  ) {

    this.addfeedstock = this.fb.group({
      date: ['', [Validators.required]],
      type: ['', [Validators.required]],
      Quantity: ['', [Validators.required]],
      Price: ['', [Validators.required]],
      // stockFor: ['', [Validators.required]]
    });
  }

  validate_message = {
    date: [
      { type: "required", message: "dateRequired" }
    ],
  }

  ngOnInit() { }


  ionViewWillEnter() {
    // if (localStorage.getItem('role_id') == '3' || localStorage.getItem('role_id') == '1') {
    //   let inventories = this.inventory.getInventoryData();
    //   this.inventoryData = this.inventoryData.concat(inventories);
    // } else {
    //   this.layerService.getLayerInventoryList().subscribe((inventoryList: any) => {
    //     this.inventoryData = this.inventoryData.concat(inventoryList.data);
    //   });
    // }

    this.default = localStorage.getItem('lngCode');
    if (this.navParams.get('openFromPage') == 'editFormula') {
      this.editFeedData = this.navParams.get('feedData');
      this.edit = true;
      this.layerService.checkfeedStockDoc(this.editFeedData).subscribe((check: any) => {
        if (check.success) {
          console.log(this.navParams.get('feedData'))
          this.addfeedstock.controls['date'].setValue(this.navParams.get('feedData')?.created_Date);
          this.addfeedstock.controls['type'].setValue(this.navParams.get('feedData')?.feedType);
          this.selectedunits = this.navParams.get('feedData').units;
          let exitStock = this.navParams.get('feedData').quantity;
          let exitKgPrice = this.navParams.get('feedData').price;
          let exitStocCalc = (this.selectedunits == 'kg') ? exitStock / 1 : (this.selectedunits == 'tonn') ? exitStock / 1000 : (this.selectedunits == '50kg') ? exitStock / 50 : (this.selectedunits == '60kg') ? exitStock / 60 : (this.selectedunits == '80kg') ? exitStock / 80 : exitStock / 100;
          let exitStockPrice = (this.selectedunits == 'kg') ? exitKgPrice * 1 : (this.selectedunits == 'tonn') ? exitKgPrice * 1000 : (this.selectedunits == '50kg') ? exitKgPrice * 50 : (this.selectedunits == '60kg') ? exitKgPrice * 60 : (this.selectedunits == '80kg') ? exitKgPrice * 80 : exitKgPrice * 100;
          this.addfeedstock.controls['Price'].setValue(exitStockPrice);
          this.addfeedstock.controls['Quantity'].setValue(exitStocCalc);
          // this.addfeedstock.controls['stockFor'].setValue(this.editFeedData.stockFor);
          this.editableNo = true;
          console.log(check.data[0].used);
          if (check.data[0].used) {
            this.noAccess = true;
          } else {
            this.noAccess = false;
          }
        }
      });
    } else {
      let getFeedNames = this.layerService.getstoreFeedNames();
      if (getFeedNames.length == 0) {
        this.getFeedNames();
      } else {
        this.select = getFeedNames;
      }
      if (this.navParams.get('date')) {
        console.log(this.navParams.get('date'));
        this.addfeedstock.controls['date'].setValue(this.navParams.get('date'));
        this.selectedunits = this.navParams.get('selectedUnits');
        this.edit = false;
        this.editableNo = false;
      } else {
        console.log('caseeeee')
        this.addfeedstock.controls['date'].setValue(this.todayDate);
        this.selectedunits = "kg";
        this.edit = false;
        this.editableNo = false;
      }
    }



    if (this.solidUnits == "pound") {
      this.pertonnInlbs = "1000lbs";
    } else {
      this.pertonnInlbs = "Tonn";
    }

    if (localStorage.getItem('role_id') == '3') {
      let data = this.supervisorService.locallySaved();
      this.solidUnits = data['solidUnits'];
    } else if ((localStorage.getItem('role_id') == '11')) {
      let data = this.layerSupervisor.returnAddedFarmer();
      this.solidUnits = data['solidUnits'];
    }
  }
  // selectedInventory(selected) {
  //   console.log(selected.target.value, 141)
  //   if (selected.target.value.inventory_name == 'Overall') {
  //     this.stockFor = {
  //       inventoryName: selected.target.value.inventory_name
  //     }
  //   } else {
  //     this.stockFor = {
  //       inventoryName: selected.target.value.inventory_name,
  //       inventory_id: selected.target.value._id
  //     }
  //   }
  // }
  getFeedNames() {
    let data = {};
    if (localStorage.getItem('role_id') == '3') {
      let tempUser = this.supervisorService.locallySaved();
      data['u_id'] = tempUser['u_id'];
    } else if (localStorage.getItem('role_id') == '11') {
      let tempUser = this.layerSupervisor.returnAddedFarmer();
      data['u_id'] = tempUser['u_id'];
    }
    this.layerService.getFeedNames(data).subscribe((list: any) => {
      if (list.success) {
        this.select = list.data;
        this.layerService.storeFeedNames(this.select);
      } else {
        this.select = [];
      }
    });
  }

  selectId(e) {
    if (e.target.value.feedName == "OTHER") {
      this.askNewFeedName();
    } else {
      this.selectFeedId = e.target.value._id;
    }
  }

  addstock() {
    this.date_check_cond = new Date(new Date().setHours(0, 0, 0, 0)) >= new Date(new Date(this.addfeedstock.value.date).setHours(0, 0, 0, 0));
    if (!this.date_check_cond) {
      let msg;
      msg = this.translate.instant('flashMessages.youCantChooseFeatureDates');
      this.showError(msg);
    } else {
      if (!this.edit) {
        let feedstock = {
          feedAddDate: this.addfeedstock.value.date,
          feedName: this.addfeedstock.value.type.feedName,
          selectFeedId: this.selectFeedId,
          quantity: this.addfeedstock.value.Quantity,
          price: this.addfeedstock.value.Price,
          units: this.selectedunits,
        }
        Object.assign(feedstock, this.stockFor);
        if (localStorage.getItem('role_id') == "3") {
          let selectedFarmer = this.supervisorService.locallySaved();
          Object.assign(feedstock, { farmerId: selectedFarmer['u_id'] })
        } else if (localStorage.getItem('role_id') == '11') {
          let selectedFarmer = this.layerSupervisor.returnAddedFarmer();
          Object.assign(feedstock, { farmerId: selectedFarmer['u_id'] })
        }
        this.layerService.layeraddfeedstock(feedstock).subscribe((data: any) => {
          if (data.success) {
            this.ModalController.dismiss({
              status: true,
            });
          }
        });
      } else {
        let feedstock = {
          doc_id: this.editFeedData._id,
          feedName: this.addfeedstock.value.type,
          selectFeedId: this.editFeedData.selectFeed_Id,
          quantity: this.addfeedstock.value.Quantity,
          price: this.addfeedstock.value.Price,
          units: this.selectedunits,
          edit: true,
        }
        if (localStorage.getItem('role_id') == "3") {
          let selectedFarmer = this.supervisorService.locallySaved();
          Object.assign(feedstock, { farmerId: selectedFarmer['u_id'] })
        } else if (localStorage.getItem('role_id') == '11') {
          let selectedFarmer = this.layerSupervisor.returnAddedFarmer();
          Object.assign(feedstock, { farmerId: selectedFarmer['u_id'] })
        }
        this.layerService.feedStockEdit(feedstock).subscribe((data: any) => {
          if (data.success) {
            this.ModalController.dismiss({
              status: true,
            });
          }
        });
      }
    }
  }

  async showError(msg) {
    const toast = await this.toast.create({
      message: msg,
      duration: 3000,
      position: 'bottom',
      buttons: [
        {
          text: this.translate.instant('buttons.done'),
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        }
      ],
      animated: true,
      cssClass: "my-custom-classInventory",
    });
    toast.present();
  }

  onSelect() {
    this.ModalController.dismiss({
      status: false,
    });
  }

  unitsInput: any = [];

  async units() {
    console.log(this.solidUnits, 274)
    this.unitsInput = [
      {
        label: this.translate.instant("common." + this.solidUnits),
        value: 'kg',
        type: 'radio',
        checked: this.selectedunits == 'kg',
      },
      {
        label: this.translate.instant("common." + 'tonn'),
        value: 'tonn',
        type: 'radio',
        checked: this.selectedunits == "tonn"
      },
      {
        label: "50" + this.translate.instant("common." + this.solidUnits),
        value: '50kg',
        type: 'radio',
        checked: this.selectedunits == '50kg',
      },
      {
        label: "60" + this.translate.instant("common." + this.solidUnits),
        value: '60kg',
        type: 'radio',
        checked: this.selectedunits == '60kg',
      },
      {
        label: "80" + this.translate.instant("common." + this.solidUnits),
        value: '80kg',
        type: 'radio',
        checked: this.selectedunits == '80kg',
      },
      {
        label: "100" + this.translate.instant("common." + this.solidUnits),
        value: '100kg',
        type: 'radio',
        checked: this.selectedunits == '100kg',
      },
    ];
    this.unitsInput.forEach(element => {
      if (element.label == "Tonn") {
        if (this.solidUnits == "pound") {
          element.label = "1000lbs";
          this.pertonnInlbs = "1000lbs";
        } else {
          element.label = "Tonn";
          this.pertonnInlbs = "Tonn";
        }
      }
    });
    const alert = await this.alert.create({
      inputs: this.unitsInput,
      header: this.translate.instant('formLabels.Units'),
      cssClass: 'wholeSellerAlert',
      buttons: [
        {
          text: this.translate.instant('buttons.cancel'),
          role: 'cancel',
          handler: (blah) => {
            console.log('you cont choose any account');
          }
        },
        {
          text: this.translate.instant('buttons.ok'),
          handler: (result) => {
            this.selectedunits = (result == 'kg') ? 'kg' : (result == 'tonn') ? 'tonn' : (result == '50kg') ? '50kg' : (result == '60kg') ? '60kg' : (result == '80kg') ? '80kg' : '100kg';
            this.unitsnum = this.selectedunits == 'kg' ? 1 : this.selectedunits == 'tonn' ? 1000 : this.selectedunits == '50kg' ? 50 : this.selectedunits == '60kg' ? 60 : this.selectedunits == '80kg' ? 80 : 100;
            if (this.selectedunits == 'tonn') {
              if (this.solidUnits == "pound") {
                this.pertonnInlbs = "1000lbs";
              } else {
                this.pertonnInlbs = "Tonn";
              }
            }
          }
        }
      ]
    });
    await alert.present();
  }

  // async selectFeed(i) {
  //   this.display.splice(0, this.display.length);
  //   for (let obj of this.select) {
  //     let data = {
  //       label: obj.feedName,
  //       value: obj.feedName,
  //       type: 'radio',
  //       checked: this.feed == obj.feedName,
  //     };
  //     if (obj.feedType == 'Standard') {
  //       data.label = `${obj.feedName}(Standard)`
  //     }
  //     this.display.push(data);
  //   };

  //   this.display.push(
  //     {
  //       label: "OTHER",
  //       value: "Other",
  //       type: 'radio',
  //       checked: this.feed == "Other",
  //     }
  //   )
  //   console.log(this.display, 225);
  //   const alert = await this.alert.create({
  //     inputs: this.display,
  //     header: this.translate.instant('dealerView.feedType'),
  //     cssClass: 'wholeSellerAlert',
  //     buttons: [
  //       {
  //         text: this.translate.instant('buttons.cancel'),
  //         role: 'cancel',
  //         handler: (blah) => {
  //           console.log('you havent choosed feed');
  //         }
  //       },
  //       {
  //         text: this.translate.instant('buttons.ok'),
  //         handler: (result) => {
  //           if (result == "Other") {
  //             this.askNewFeedName();
  //           } else {
  //             this.addfeedstock['controls'].type.setValue(result);
  //             this.feed = result;
  //             this.select.forEach((element, i) => {
  //               if (result == element.feedName) {
  //                 this.selectFeedId = this.select[i]._id;
  //               }
  //             });
  //           }
  //         }
  //       }
  //     ],
  //   });
  //   await alert.present();
  // };


  async askNewFeedName() {
    this.addfeedstock['controls'].type.setValue('');
    const alert = await this.alert.create({
      inputs: [{
        name: "newFeed",
        type: 'text',
        // placeholder: 'e.g : Bran'
        placeholder: this.translate.instant('Lfarmer.egBran')
      }],
      // header: 'Name of feed',
      header: this.translate.instant('Lfarmer.nameoffeed'),
      cssClass: 'wholeSellerAlert',
      buttons: [
        {
          text: this.translate.instant('buttons.cancel'),
          role: 'cancel',
          handler: (blah) => {
            console.log('you havent choosed feed');
          }
        },
        {
          text: this.translate.instant('buttons.ok'),
          handler: (result) => {
            result = result.newFeed[0].toUpperCase() + result.newFeed.slice(1).toLowerCase();
            this.feed = result;
            if (this.select.includes(result)) {
              this.addfeedstock['controls'].type.setValue(result);
            } else {
              this.layerService.addNewFeed({ feedName: result }).subscribe((data: any) => {
                if (data.success) {
                  this.select.push(result);
                  this.addfeedstock['controls'].type.setValue(result);
                  this.getFeedNames();
                } else {
                  console.log(data.msg);
                }
              })
            }
          }
        }
      ],
    })
    await alert.present();
  }

  // fetchFeeds() {
  //   this.layerService.fetchPreviousFeeds().subscribe(data => {
  //     if (data.success) {
  //       this.select = data.data.concat(this.select);
  //     } else {
  //       console.log(data.msg);
  //     }
  //   })
  // }

  dateChange(event) {
    console.log("date change event triggred");
    console.log(event.target.value);
    let dateVar = this.addfeedstock.controls['date'].value.toString();
    
    let today = new Date();
    console.log(dateVar.split('T')[0] , today.toISOString().split('T')[0])
    if(dateVar.split('T')[0] > today.toISOString().split('T')[0]){
      this.addfeedstock.controls['date'].setValue(this.todayDate);
    }else {
      console.log("date not changes");
    }
  }
}
