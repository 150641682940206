// import { ConnectionStatus } from './../services/network.service';
import { Component, OnInit } from '@angular/core';
// import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
// import { Router } from '@angular/router';
import { TraderService } from '../../services/trader.service';
import { ModalController, NavParams, ToastController, AlertController } from '@ionic/angular';
// import { TraderAddliftingVansComponent } from '../trader-addlifting-vans/trader-addlifting-vans.component';
// import { TranslateService } from '@ngx-translate/core';
// import { OrderChickencenterComponent } from '../trader/order-chickencenter/order-chickencenter.component';
// import { OrderLiftingvanComponent } from '../trader/order-liftingvan/order-liftingvan.component';
// import { AddChickenCentersComponent } from '../add-chicken-centers/add-chicken-centers.component';
// import { VanContactAdddPage } from '../trader/van-contact-addd/van-contact-addd.page';

// import { IncomingOrdersListComponent } from '../trader/incoming-orders-list/incoming-orders-list.component';
import { LoadingserviceService } from '../loadingservice.service';


@Component({
  selector: 'app-trader-create-order',
  templateUrl: './trader-create-order.component.html',
  styleUrls: ['./trader-create-order.component.scss'],
})
export class TraderCreateOrderComponent implements OnInit {

  orderSummary: any;
  assignedOrdersList = [];
  noMsg = false;
  solidUnits = localStorage.getItem("solidUnits")
  constructor(
    protected navParams: NavParams,
    protected modal: ModalController,
    protected ls: LoadingserviceService,
    // private router: Router,
    private traderservice: TraderService
  ) { }

  ngOnInit() {
    this.orderSummary = this.navParams.get('orderData');
    console.log(this.orderSummary);
    // this.traderservice.incomingOrdersAssignedList({ incomingOrderId: this.orderSummary._id }).subscribe((data) => {
    //   if (data.success) {
    //     console.log(data)
    //     this.assignedOrdersList = data.data;
    //     // this.assignedOrdersList = [];
    //   }
    // })
    this.traderservice.traderLiftingDc({ purchaseOrderId: this.orderSummary._id }).subscribe((data) => {
      if (data.success) {
        console.log(data)
        if(data.data.length){
          this.assignedOrdersList = data.data;
          this.noMsg = false;
        }
        else{
          this.noMsg = true;
        }
        // this.assignedOrdersList = [];
      }
    })
  }

  dismiss(as) {
    this.modal.dismiss()
  }


}



