import { Component, OnInit } from '@angular/core';
import { PopoverController, ToastController, Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { DealersService } from 'src/services/dealers.service';

@Component({
  selector: 'app-review-delete',
  templateUrl: './review-delete.component.html',
  styleUrls: ['./review-delete.component.scss'],
})
export class ReviewDeleteComponent implements OnInit {
  data;

  constructor(private tost: ToastController, private pop: PopoverController,private router: Router ,private dealer: DealersService,) { }

  ngOnInit() {

   
    console.log(this.data,18);;
  }
  delete(){
    this.pop.dismiss(this.data);

// let index:number = this.reviews.indexOf(data);
//     if(index>-1){
//       this.reviews.splice(index,1);
//     }
//     this.dealer.deleteComment({_id:data._id}).subscribe(
//       (result) => {
//         console.log(result);
//         console.log(data)
//       }
//     )
  }

}
