import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { ModalController, LoadingController, AlertController, ActionSheetController, NavParams } from '@ionic/angular';
import { Geolocation } from '@ionic-native/geolocation/ngx';

import { SocketService } from '../socket.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NativeGeocoder, NativeGeocoderResult, NativeGeocoderOptions } from '@ionic-native/native-geocoder/ngx';
// import { GoogleMaps, GoogleMap, Environment, GoogleMapsEvent, GoogleMapOptions, CameraPosition, MarkerOptions, Marker, LocationService, MyLocation, GoogleMapsAnimation, } from '@ionic-native/google-maps';
// import { LatLngBounds, ILatLng, BaseArrayClass } from '@ionic-native/google-maps';
// declare var google: any;
@Component({
  selector: 'app-livetracking',
  templateUrl: './livetracking.component.html',
  styleUrls: ['./livetracking.component.scss'],
})
export class LivetrackingComponent implements OnInit {
  vanData;
  latitide;
  longitude;
  vanlat;
  vanlon;
  get;
  locations = [];
  address = [];
  vaninfo = [];
  addresslatlng;
  marker = [];
  data23 = [];
  multiMarkers = [];
  map;
  public unsubscriber$: Subject<any>;
  constructor(private modalCtrl: ModalController,
    protected router: Router,
    public geoLocation: Geolocation,
    private socketService: SocketService,
    protected navParams: NavParams,
    private nativeGeocoder: NativeGeocoder,
  ) {
    this.vanData = this.navParams.data.VanData;
    let newloc = [] as any;
    let newAddress = [] as any;
    newloc = this.vanData.location.coordinates;
    newAddress = this.vanData.address;
    this.data23.push({ time: new Date(), address:newAddress})
    this.address.push(this.vanData.address);
    this.locations.push(newloc.concat(newAddress));
    this.vaninfo.push(this.vanData);
    console.log(this.vaninfo,57);
    console.log(this.locations,57);
  }

  ngOnInit() {
    // this.unsubscriber$ = new Subject<any>();
    // if (!this.socketService.getSocket()) {
    //   this.socketService.initSocket();
    // }
    // this.socketService.vanLocation().pipe(takeUntil(this.unsubscriber$)).subscribe(async(vandata: any) => {
    //   this.vanlat = vandata.location.coordinates[0];
    //   this.vanlon = vandata.location.coordinates[1];
    //   // let oldlatlongs = this.vaninfo.find(allv => allv.driver_uid == vandata.driver_u_id);
    //   // let newlatlongs = vandata;
    //   // let currentMarker = this.marker.find(markr => markr.id == vandata.driver_u_id);
    //   // // console.log(oldlatlongs);
    //   // // console.log(newlatlongs);
    //   // console.log(currentMarker);
    //   // // move marker
    //   // this.animatedMove(currentMarker, 10, oldlatlongs.location, newlatlongs.location);

    //   // this.getAddressFromCoords(  this.vanlat, this.vanlon );
    //   // oldlatlongs.location = { "type": "Point", "coordinates": [vandata.location.coordinates[1], vandata.location.coordinates[0]] };
    //   // oldlatlongs.address = this.addresslatlng;
    //   // this.data23 = [];
    //   // this.data23.push({ time: vandata.created_date, address: this.addresslatlng});
    //   // console.log( this.vaninfo, 96);
   
    // });
   

  }

  async ionViewDidEnter() {
    // this.viewMap();
    // this.loadMap();
  }
  // loadMap() {
  //   // console.log("hello");
  //   // Environment.setEnv({
  //   //   'API_KEY_FOR_BROWSER_RELEASE': 'AIzaSyB-EbCaPeaG2lxYOhNNHhruzGO2IKL8vtg',
  //   //   'API_KEY_FOR_BROWSER_DEBUG': 'AIzaSyB-EbCaPeaG2lxYOhNNHhruzGO2IKL8vtg'
  //   // })
  //   LocationService.getMyLocation().then(async (myLocation: MyLocation) => {
  //     console.log(myLocation);
     
  //     let options: GoogleMapOptions = {
  //       camera: {
  //         target: myLocation.latLng
  //       },
  //     };
  //     this.map = GoogleMaps.create('map_canvas', options);
  //     this.map.animateCamera({
  //       target: myLocation.latLng,
  //       zoom: 17,
  //       tilt: 30
  //     });
  //     for (let i = 0; i < this.vaninfo.length; i++) {
  //       console.log(this.vaninfo[i].location);
  //       if (this.vaninfo[i].location) {
  //         let latlng = { "lat": this.vaninfo[i].location.coordinates[1], "lng": this.vaninfo[i].location.coordinates[0] };
  //         let markers = {
  //           title: this.vaninfo[i].address,
  //           icon: {
  //             url: 'assets/Group 1400.png',
  //             size: {
  //               width: 50,
  //               height: 50
  //             }
  //           },
  //           animation: GoogleMapsAnimation.BOUNCE,
  //           position: latlng,
  //           id: this.vaninfo[i].driver_uid,
  //           iconData: {
  //             url: 'assets/Group 1400.png',
  //             size: {
  //               width: 50,
  //               height: 50
  //             }
  //           },
  //         }
  //         this.multiMarkers.push(markers);
  //       }
  //     }
  //     let POINTS: BaseArrayClass<any> = new BaseArrayClass<any>(this.multiMarkers);
  //     POINTS.forEach((data: any, i) => {
  //       let marker: Marker = this.map.addMarkerSync(data);
  //       marker.on(GoogleMapsEvent.INFO_CLICK).subscribe(this.onMarkerClick);
  //       // console.log("loop end of", i)
  //     });
  //   });
  // }
  // onMarkerClick(params: any) {
  //   let marker: Marker = <Marker>params[1];
  //   let iconData: any = marker.get('iconData');
  //   marker.setIcon(iconData);
  // }
  submit(data: boolean) {
    if (data) {
      this.modalCtrl.dismiss({
        status: data,
      });
    } else {
      this.modalCtrl.dismiss({
        status: data,
      });
    }
  }
  ngOnDestroy() {
    this.unsubscriber$.complete();
  }
  ionViewWillLeave() {
    this.unsubscriber$.complete();
    this.socketService.vanLocation();
  }
}


  // this.socketService.disk();
    // this.socketService.sendVanId(this.vanData);
    // let sss = await this.socketService.disk();
    // this.latitide = sss.location.coordinates[0]

    // this.socketService._value.subscribe(r => {
    //   console.log(r);
    // });

    // this.socketService.vanLocation().pipe(first(data => {
    //   console.log(data)
    //   return false
    // })).subscribe(vandata => {
    //   console.log({ first: vandata });
    // })\

    // this.makeMarker(map, latLng, '/assets/van.svg', 'driver');

      // var directionsDisplay = new google.maps.DirectionsRenderer();
      // var directionsService = new google.maps.DirectionsService();
      // directionsDisplay.setMap(map);
      // directionsDisplay.setOptions({ suppressMarkers: true });
      // this.calcRoute(map, directionsService, directionsDisplay);

      // google.maps.event.addListenerOnce(map, 'idle', function () {

      //   var marker = new google.maps.Marker({
      //     map: map,
      //     animation: google.maps.Animation.DROP,
      //     position: latLng
      //   });

      //   var infoWindow = new google.maps.InfoWindow({
      //     content: "hello",
      //   });

      //   google.maps.event.addListener(marker, 'click', function () {
      //     infoWindow.open(map, marker);
      //   });

      // });

       // calcRoute(map, directionsService, directionsDisplay) {
  //   // var start = new google.maps.LatLng(this.vanlat, this.vanlon);
  //   console.log( this.vanlat, this.vanlon, 196);
  //   var start = new google.maps.LatLng( this.vanlat, this.vanlon);
  //   var end = new google.maps.LatLng(17.448294, 78.391487);
  //   this.makeMarker(map, start, '/assets/van.svg', 'driver');
  //   this.makeMarker(map, end, '/assets/farm-dest.svg', 'Farm');
  //   var bounds = new google.maps.LatLngBounds();
  //   bounds.extend(start);
  //   bounds.extend(end);
  //   map.fitBounds(bounds);
  //   var request = {
  //     origin: start,
  //     destination: end,
  //     travelMode: google.maps.TravelMode.DRIVING
  //   };
  //   directionsService.route(request, function (response, status) {
  //     if (status == google.maps.DirectionsStatus.OK) {
  //       directionsDisplay.setDirections(response);
  //       directionsDisplay.setMap(map);
  //     } else {
  //       alert("Directions Request from " + start.toUrlValue(6) + " to " + end.toUrlValue(6) + "failed: " + status);
  //     }
  //   });
  // }
   // currentId = 0;
  // uniqueId = function () {
  //   return ++this.currentId;
  // }

  // markers = {};

  // makeMarker(map, position, icon, title) {
  //   var id = this.uniqueId(); // get new id
  //   // var infowindow = new google.maps.InfoWindow({
  //   //   content: title
  //   // });

  //   var marker = new google.maps.Marker({
  //     id: id,
  //     position: position,
  //     map: map,
  //     icon: icon,
  //     title: title,
  //     draggable: true,
  //     animation: google.maps.Animation.DROP
  //   });
  //   this.markers[id] = marker; // cache created marker to markers object with id as its key
  //   return marker;
  //   // marker.addListener('click', function () {
  //   //   infowindow.open(map, marker);
  //   // });

  // }
  // deleteMarker = function (id) {
  //   console.log(id,206);
  //   var marker = this.markers[id]; // find the marker by given id
  //   marker.setMap(null);
  // }
   // this.makeMarker(map, latLng, '/assets/van.svg', 'driver');
      // this.viewMap();
      // this.getAddressFromCoords(this.vanlat, this.vanlon);
      // let latLng = new google.maps.LatLng( this.vanlat,  this.vanlon);
      // let mapProp = {
      //   center: latLng,
      //   zoom: 10,
      //   mapTypeId: google.maps.MapTypeId.ROADMAP,
      //   mapTypeControl: false,
      //   disableDefaultUI: true,
      // };

      // let map = new google.maps.Map(document.getElementById("googleMapLifting3"), mapProp);
      // google.maps.event.addListenerOnce(map, 'idle', function () {

      //   var marker = new google.maps.Marker({
      //     map: map,
      //     animation: google.maps.Animation.DROP,
      //     position: latLng
      //   });

      //   var infoWindow = new google.maps.InfoWindow({
      //     content: "hello",
      //   });

      //   google.maps.event.addListener(marker, 'click', function () {
      //     infoWindow.open(map, marker);
      //   });

      // });


       //  await this.getAddressFromCoords(vandata.location.coordinates[0], vandata.location.coordinates[1]);
    //   this.vaninfo.forEach(element => {
    //     if (element.driver_uid == vandata.driver_u_id) {
    //       this.data23 = [];
    //       this.data23.push({ time: vandata.created_date, address: this.addresslatlng});
    //       element.location = { "type": "Point", "coordinates": [vandata.location.coordinates[1], vandata.location.coordinates[0]] };
    //       element.address = this.addresslatlng
    //     }
    //   });
    //   this.vaninfo.forEach(element => {
    //     if (element.location) {
    //       let newloc = [] as any;
    //       let newAddress = [] as any;
    //       newloc = element.location.coordinates;
    //       newAddress = element.address;
    //       this.address.push(element.address);
    //       this.locations.push(newloc.concat(newAddress));
    //     }
    //   });
    //   let latLng = new google.maps.LatLng(this.vanlat, this.vanlon);
    //   let mapProp = {
    //     center: latLng,
    //     zoom: 10,
    //     mapTypeId: google.maps.MapTypeId.ROADMAP,
    //     mapTypeControl: false,
    //     disableDefaultUI: true,
    //   };

    //   let map = new google.maps.Map(document.getElementById("googleMapLifting3"), mapProp);
    //   this.addMarkers(map, this.vaninfo);









    // async viewMap() {
    //   var options = { timeout: 10000, enableHighAccuracy: true };
    //   this.geoLocation.getCurrentPosition(options).then((resp) => {
    //     let latLng = new google.maps.LatLng(resp.coords.latitude, resp.coords.longitude);
    //     let mapProp = {
    //       center: latLng,
    //       zoom: 10,
    //       mapTypeId: google.maps.MapTypeId.ROADMAP,
    //       mapTypeControl: false,
    //       disableDefaultUI: true,
    //     };
  
    //     this.map = new google.maps.Map(document.getElementById("googleMapLifting3"), mapProp);
    //     this.addMarkers(this.map, this.vaninfo);
    //   });
    // }
    // addMarkers(map, data) {
    //   var latlngList = [];
    //   var i;
    //   const bounds: LatLngBounds = new google.maps.LatLngBounds();
    //   for (i = 0; i < this.locations.length; i++) {
    //     latlngList.push((this.locations[i][1], this.locations[i][0]));
    //     let marker = new google.maps.Marker({
    //       map: map,
    //       icon: {
    //         url: 'assets/van.svg',
    //         scaledSize: new google.maps.Size(45, 45)
    //       },
    //       title: `farms${[i]}`,
    //       id: data[i].driver_uid,
    //       position: new google.maps.LatLng(this.locations[i][1], this.locations[i][0])
    //     });
    //     this.marker.push(marker);
    //     bounds.extend(new google.maps.LatLng(this.locations[i][1], this.locations[i][0]));
    //     let infowindow = new google.maps.InfoWindow({});
    //     let content = `<pre style="padding: 0px;margin: 0px">
    //         <span style="font-size: 16px;color: #757575;font-weight: bold">
    //      ${data[i]['vanNumber']}</span>
    //         ${data[i]['distance'].toFixed(2)}Km
    //      phnumber-${data[i]['phone']}
    //         </pre>`;
    //     google.maps.event.addListener(marker, 'click', (function (marker, content, infowindow) {
    //       return function () {
    //         infowindow.setContent(content);
    //         infowindow.open(map, marker);
    //       }
    //     })(marker, content, infowindow));
    //     map.fitBounds(bounds);
    //   }
    //   this.locations = [];
    // }
    // animatedMove(marker, n, current, moveto) {
    // console.log(current);
    // console.log(moveto);
    //   var deltalat = (moveto.coordinates[0] - current.coordinates[1]) / 100;
    //   var deltalng = (moveto.coordinates[1] - current.coordinates[0]) / 100;
    
    //   for (var i = 0; i < 100; i++) {
    //     (function(ind) {
    //       setTimeout(
    //         function() {
    //           var lat = marker.position.lat();
    //           var lng = marker.position.lng();
    
    //           lat += deltalat;
    //           lng += deltalng;
    //           let latlng = new google.maps.LatLng(lat, lng);
    //           marker.setPosition(latlng);
    //         }, 10 * ind
    //       );
    //     })(i)
    //   }
    // }
    // getAddressFromCoords(lattitude, longitude) {
    //   let options: NativeGeocoderOptions = {
    //     useLocale: true,
    //     maxResults: 5
    //   };
  
    //   this.nativeGeocoder.reverseGeocode(lattitude, longitude, options)
    //     .then((result: NativeGeocoderResult[]) => {
    //       this.addresslatlng = "";
    //       let responseAddress = [];
    //       for (let [key, value] of Object.entries(result[0])) {
    //         if (value.length > 0)
    //           responseAddress.push(value);
  
    //       }
    //       responseAddress.reverse();
    //       for (let value of responseAddress) {
    //         this.addresslatlng += value + ", ";
    //       }
    //       this.addresslatlng = this.addresslatlng.slice(0, -2);
    //       console.log(this.addresslatlng,119);
    //     })
    //     .catch((error: any) => {
    //       this.addresslatlng = "Address Not Available!";
    //     });
  
    // }