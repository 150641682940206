import { Component, OnInit, NgZone, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
// import { InventoryService } from './inventory.service';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { Router } from '@angular/router';
import { NativeGeocoder, NativeGeocoderOptions, NativeGeocoderResult } from '@ionic-native/native-geocoder/ngx';
import { LoadingController, ToastController, ModalController, AlertController } from '@ionic/angular';
import { Platform, PopoverController } from '@ionic/angular';
import { InventoryInfoService } from '../../inventory-info/inventory-info.service';
import { LoadingserviceService } from 'src/app/loadingservice.service';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/services/common.service';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { NumberFormatPipe } from 'src/app/pipes/number-format.pipe';
declare var google: any;
@Component({
  selector: 'app-inventory-create',
  templateUrl: './inventory-create.component.html',
  styleUrls: ['./inventory-create.component.scss'],
})
export class InventoryCreateComponent implements OnInit {
  currencySymbol: string = localStorage.getItem('currencySymbol');
  myHomeAddress = localStorage.getItem('address');

  bagWeights = [
    { option: 1, weight: 50 },
    { option: 2, weight: 55 },
    { option: 3, weight: 60 },
    { option: 4, weight: 65 },
    { option: 5, weight: 70 },
    { option: 6, weight: 75 },
    { option: 7, weight: 80 },
    { option: 8, weight: 85 },
    { option: 9, weight: 90 },
    { option: 10, weight: 95 },
    { option: 11, weight: 100 },
  ];

  initWeight = [50];
  totalFarms: boolean = false
  userRole = localStorage.getItem('role_id');
  today = new Date();
  default;
  role_id: any;
  noOfBirdsError: boolean = false;
  minDate: string = new Date('2018-09-28T00:00:00.000Z').toISOString();
  maxDateLimit = this.today.toISOString().substr(0, 10);
  DateChange(data) {
    console.log(data);
  }
  // isDisabled: boolean = true;
  public inventory_add: FormGroup;
  public submitted = false;
  public nav: any;

  public GoogleAutocomplete = new google.maps.places.AutocompleteService();
  public location = '';
  public autocompleteItems = [];
  autocomplete = { input: '' };
  mapvalues: boolean = false;
  latitude: number;
  longitude: number;
  geoAddress: any;
  geoencoderOptions: NativeGeocoderOptions = {
    useLocale: true,
    maxResults: 5
  };
  statusButton: boolean = true;
  inventoryCreation: boolean = false;
  dateError: any;
  countryCode: string = localStorage.getItem('countryCode');

  feedSystemTypes = [
    {
      type: "Normal"
    },
    {
      type: "Feed plant"
    }
  ]

  constructor(public formbuilder: FormBuilder,
    private modal: ModalController,
    private geolocation: Geolocation,
    private nativeGeocoder: NativeGeocoder,
    public zone: NgZone,
    private commonService: CommonService,
    private router: Router,
    private popover: PopoverController,
    private toastController: ToastController,
    public loadingController: LoadingController,
    protected inventoryInfoService: InventoryInfoService,
    protected LoadingserviceService: LoadingserviceService,
    private translate: TranslateService,
    public loading: LoadingserviceService,
    private cdRef: ChangeDetectorRef,
    private androidPermissions: AndroidPermissions,
    private AlertController: AlertController,
    private locationAccuracy: LocationAccuracy,
    private numberFormat: NumberFormatPipe
  ) {

    this.role_id = localStorage.getItem('role_id')
    this.inventory_add = this.formbuilder.group({
      inventory_name: ['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(16)])],
      no_of_birds: ["", Validators.required],
      location: ["", Validators.required],
      arrival_date: ["", Validators.required],
      // bags_weight: ["", Validators.required],
      chick_price: [""],
      formId: ["",],
      feedSystem: ["Normal"]
      // feedPrice: ["", Validators.compose([Validators.required, Validators.min(1), Validators.max(100)])],
      // farmCreate: [true, Validators.required],
    });
    this.autocomplete.input = this.myHomeAddress;
    this.latitude = Number(localStorage.getItem('lat'));
    this.longitude = Number(localStorage.getItem('lng'));

  }
  validate_message = {
    inventory_name: [
      { type: "required", message: "nameRequired" },
      { type: 'minlength', message: 'farmCreateNameMin' },
      { type: 'maxlength', message: 'farmCreateNameMax' }
    ],
    no_of_birds: [
      { type: "required", message: "birdsRequired" }
    ],
    location: [
      { type: "required", message: "locationRequired" }
    ],
    arrival_date: [
      { type: "required", message: "dateRequired" }
    ],
    // bags_weight: [
    //   { type: "required", message: "bagWeightRequired" }
    // ],
    chick_price: [],
    formId: [],
    // feedPrice: [
    //   { type: "required", message: "priceRequired" },
    //   { type: "min", message: "minPriceRequiredInventory" },
    //   { type: "max", message: "maxPriceRequiredInventory" }
    // ],
    // farmCreate: [
    //   { type: "required", message: "priceRequired" },
    // ]


  }
  ngOnInit() { }
  ionViewWillEnter() {
    this.default = localStorage.getItem('lngCode');
    // this.getGeoLocation();
  }
  geoCode(address: any) {
    let geocoder = new google.maps.Geocoder();
    geocoder.geocode({ 'address': address }, (results, status) => {
      this.latitude = results[0].geometry.location.lat();
      this.longitude = results[0].geometry.location.lng();
    });
  }
  updateSearchResults() {
    console.log(this.autocomplete.input, 112);
    if (this.autocomplete.input == '') {
      this.autocompleteItems = [];
      return;
    }
    this.GoogleAutocomplete.getPlacePredictions({
      input: this.autocomplete.input,
      types: ['(cities)'],
      componentRestrictions: { country: localStorage.getItem('countryCode') }

    }, (predictions, status) => {
      this.autocompleteItems = [];
      this.zone.run(() => {
        console.log(predictions);
        if (predictions != null) {
          predictions.forEach((prediction) => {
            this.autocompleteItems.push(prediction);
          });
        } else {
          console.log("No result");
        }
      });
    });
  }

  selectSearchResult(item) {
    this.autocomplete.input = item.description;
    this.geoAddress = this.autocomplete.input;
    this.geoCode(this.autocomplete.input);
    if (this.autocomplete.input) {
      this.mapvalues = true;
    }
    else {
      this.mapvalues = false;
      this.updateSearchResults()
    }
  }

  onSumbmitInventory(inventoryTF: boolean) {
    if (inventoryTF) {
      if ((new Date(this.inventory_add.value.arrival_date) < new Date())) {
        this.submitted = true;
        if (this.inventory_add.invalid) {
          return;
        }
        if (this.userRole != '9') {
          delete this.inventory_add.value.age;
          delete this.inventory_add.value.eggTray;
          delete this.inventory_add.value.breed;
        }
        let frmData = { ...this.inventory_add.value, u_id: localStorage.getItem('u_id'), latitude: this.latitude, longitude: this.longitude };
        const keepValues = [
          this.inventory_add.controls.location.value
        ]
        this.modal.dismiss({
          inven: inventoryTF,
          farmData: frmData,
          created: localStorage.getItem("role_id")
        })
        // this.inventoryInfoService.addInventory(frmData).subscribe((data: any) => {
        //   if (data.success) {
        //     this.inventory_add.reset();
        //     this.inventory_add.controls.location.patchValue(keepValues[0]);
        //     this.LoadingserviceService.loadingDismiss();
        //     console.log(data.farms)
        //     this.commonService.local_myfarms = data.farms;
        //     this.modal.dismiss({
        //       inven: inventoryTF,
        //       newData: data
        //     })
        //   } else {
        //     console.log("create inventory failed", 204);
        //     this.modal.dismiss()
        //     this.LoadingserviceService.loadingDismiss();
        //   }
        // });
      } else {
        console.log("out side");
        // this.LoadingserviceService.loadingDismiss();
        if (new Date(this.inventory_add.value.arrival_date) > new Date()) {
          this.showError("you can't choose future Dates");
        } else {
          this.showError("please check form");
        }
      }
    } else {
      // this.LoadingserviceService.loadingDismiss();
      this.modal.dismiss({
        inven: inventoryTF,
        newData: this.inventory_add.value,
        created: localStorage.getItem("role_id")
      });

    }
  }
  //loader
  async presentLoadingWithOptions() {
    const loading = await this.loadingController.create({
      spinner: 'lines-small',
      duration: 2000,
      message: this.translate.instant('common.pleaseWait'),
      translucent: true,
      backdropDismiss: false,
      cssClass: 'custom-class custom-loading'
    });
    return await loading.present();
  }
  //toast controlle msg
  async showError(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 3000,
      position: 'bottom',
      buttons: [
        {
          text: this.translate.instant('buttons.done'),
          role: this.translate.instant('buttons.cancel'),
          handler: () => {
            console.log('Cancel clicked');
          }
        }
      ],
      animated: true,
      cssClass: "my-custom-class",
    });
    toast.present();
  }

  avialableBirdsCheck(triggedValue) {
    let value = triggedValue;
    if (this.inventory_add.get('no_of_birds').value >= value) {
      this.inventoryCreation = false;
    } else {
      this.inventoryCreation = true;
    }
  }

  obj: Object;
  birds;
  numberBirds(event) {
    if (event < 100) {
      this.noOfBirdsError = true;
    } else {
      this.noOfBirdsError = false
      this.obj = this.numberFormat.transform(event)
      if (this.obj == false) {
        this.noOfBirdsError = true;
        this.inventory_add.get("no_of_birds").setValue("");
        this.birds = "";
      }
      else if (this.obj['changed'] != 'NaN') {
        this.birds = this.obj['changed'];
        this.inventory_add.get("no_of_birds").setValue(this.obj['number']);
      } else {
        this.noOfBirdsError = true;
        this.inventory_add.get("no_of_birds").setValue("");
        this.birds = null;
      }
    }
    // console.log(this.inventory_add.controls.mortality_rate.value)
    // console.log(this.inventory_add.get('mortality_rate').value)
    // this.insideCheck(this.inventory_add.get('mortality_rate').value, event);
  }
  insideCheck(triggedValue, event) {
    if (event >= triggedValue) {
      this.inventoryCreation = false;
    } else {
      this.inventoryCreation = true;
    }
  }
  morethen60DysCheck(data) {
    let today = new Date();
    let sixtyDay = today.setDate(today.getDate() - 61);
    let check = new Date(sixtyDay) > new Date(data);
    this.dateError = check;
  }
  dateCheck(e) {
    new Date(new Date(e).setHours(0, 0, 0, 0)) > new Date(new Date().setHours(0, 0, 0, 0)) ?
      [this.inventory_add.controls['arrival_date'].setValue(''),
      this.showError("You can't choose future dates")] : ''
  }

  getGeoLocation() {
    this.LoadingserviceService.loadingPresent();
    var positionOption = { timeout: 7000, enableHighAccuracy: true, maximumAge: 70000, };
    this.geolocation.getCurrentPosition(positionOption).then((resp: any) => {
      this.latitude = resp.coords.latitude
      this.longitude = resp.coords.longitude;
      this.getGeoencoder(this.latitude, this.longitude);
    }).catch((error) => {
      console.log('Error getting location', error);
      this.LoadingserviceService.loadingDismiss();
      this.checkGPSPermission();
    });
  }

  getGeoencoder(latitude, longitude) {
    console.log("geo cordinates ", latitude, longitude);
    this.nativeGeocoder.reverseGeocode(latitude, longitude, this.geoencoderOptions)
      .then((result: NativeGeocoderResult[]) => {
        this.LoadingserviceService.loadingDismiss();
        if (result[0].countryName == localStorage.getItem('country')) {
          this.geoAddress = this.generateAddress(result[0]);
          this.inventory_add.controls.location.setValue(this.geoAddress);
        } else {
          this.LoadingserviceService.showError('Your country and location are mismatched')
        }
      })
      .catch((error: any) => {
        this.LoadingserviceService.loadingDismiss();
        alert('Error getting location' + JSON.stringify(error));
      });
  }

  generateAddress(addressObj) {
    console.log(addressObj, 125);
    let obj = [];
    let address = "";
    for (let key in addressObj) {
      obj.push(addressObj[key]);
    }
    obj.reverse();
    for (let val in obj) {
      if (obj[val].length)
        address += obj[val] + ', ';
    }
    return address.slice(0, -2);
  }




  // /////////////////this code is used for activate mobile location service/////
  checkGPSPermission() {
    this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.ACCESS_COARSE_LOCATION).then(result => {
      console.log(result, 258);
      if (result.hasPermission) {
        //If having permission show 'Turn On GPS' dialogue
        this.askToTurnOnGPS();
        // this.currentLocation();
      } else {
        //If not having permission ask for permission
        this.requestGPSPermission();
      }
    }, async err => {
      console.log(err, 267);
      const alert = await this.AlertController.create({
        header: this.translate.instant('alertMessages.alert'),
        cssClass: 'singleButtonAlert',
        message: "Something went wrong, Please try again once",
        buttons: [
          {
            text: this.translate.instant('buttons.ok'),
            role: 'Ok',
            handler: data => {
              console.log('already add this day');
            }
          }
        ]
      });
      await alert.present();
    });
  }

  requestGPSPermission() {
    this.locationAccuracy.canRequest().then((canRequest: boolean) => {
      if (canRequest) {
        console.log(canRequest, 256);
      } else {
        //Show 'GPS Permission Request' dialogue
        this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.ACCESS_COARSE_LOCATION).then(() => {
          // call method to turn on GPS
          this.askToTurnOnGPS();
        }, async error => {
          //Show alert if user click on 'No Thanks'
          console.log(error, 298);
          const alert = await this.AlertController.create({
            header: this.translate.instant('alertMessages.alert'),
            cssClass: 'singleButtonAlert',
            message: "Something went wrong, Please try again once",
            buttons: [
              {
                text: this.translate.instant('buttons.ok'),
                role: 'Ok',
                handler: data => {
                  console.log('Ok I will try');
                }
              }
            ]
          });
          await alert.present();
        }
        );
      }
    });
  }

  askToTurnOnGPS() {
    this.locationAccuracy.request(this.locationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY).then(async (data) => {
      console.log(data, 323);
      const alert = await this.AlertController.create({
        header: this.translate.instant('alertMessages.alert'),
        cssClass: 'wholeSellerAlert',
        message: "Press ok for current location",
        buttons: [
          {
            text: this.translate.instant('buttons.cancel'),
            role: 'cancel',
            handler: data => {
              console.log('cancel');
            }
          },
          {
            text: this.translate.instant('buttons.ok'),
            role: 'Ok',
            handler: data => {
              this.askGpsAgain();
              // this.currentLocation();
            }
          },
        ]
      });
      await alert.present();
    }).catch(async (error) => {
      console.log(error, 347);
      const alert = await this.AlertController.create({
        header: this.translate.instant('alertMessages.alert'),
        cssClass: 'singleButtonAlert',
        message: "Something went wrong, Please try again once",
        buttons: [
          {
            text: this.translate.instant('buttons.ok'),
            role: 'Ok',
            handler: data => {
              console.log('Ok I will try');
            }
          }
        ]
      });
      await alert.present();
    });
  }

  askGpsAgain() {
    // When GPS Turned ON call method to get Accurate location coordinates;
    this.zone.run(() => {
      var options = { enableHighAccuracy: true, timeout: 7000 };
      this.LoadingserviceService.loadingPresent();
      this.geolocation.getCurrentPosition(options).then((resp) => {
        this.LoadingserviceService.loadingDismiss();
        this.latitude = resp.coords.latitude;
        this.longitude = resp.coords.longitude;
        this.getGeoencoder(this.latitude, this.longitude);
      }).catch(async (error) => {
        this.LoadingserviceService.loadingDismiss();
        const alert = await this.AlertController.create({
          header: this.translate.instant('alertMessages.alert'),
          cssClass: 'singleButtonAlert',
          message: "Something went wrong, Please try again once",
          buttons: [
            {
              text: this.translate.instant('buttons.ok'),
              role: 'Ok',
              handler: data => {
                console.log('Ok I will try');
              }
            }
          ]
        });
        await alert.present();
      });
    });
  }
}


