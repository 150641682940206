import { distinctUntilChanged } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config } from '../helper/config';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
@Injectable({
  providedIn: 'root'
})
export class LayerRetailSupplierService {
  public autoOrderCount: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  cuurentAutoCount:any= this.autoOrderCount.asObservable();
  private tourStarted = new BehaviorSubject<boolean>(false);
  customerCreateOrEdit: any;
  allExpenses: any;
  retailSupplierData: any;
  i = 0;
  bookingOrder = {
    whole: false,
    orderWholeSeller: ''
  }
  editSaleData: any;
  deliverOrders: any;
  newRoutedArray: any = [];
  retailOrderData: any;
  wholesellerslist: any;
  details: any;
  public makeBreakExp = new BehaviorSubject<any>(false);
  // public makeBreakExp$ = this.makeBreakExp.asObservable();
  public makeBreakExp$ = this.makeBreakExp.asObservable().pipe(distinctUntilChanged());
  constructor(private http: HttpClient) { }

  getTourStatus(): Observable<boolean> {
    return this.tourStarted.asObservable();
  }
  setOrderDetails(data) {
    this.retailOrderData = data;
  }

  updatedTourStart(val) {
    this.tourStarted.next(val);
  }
  userData(data) {
    this.retailSupplierData = data;
  }
  getCreateOreditCustomer() {
    return this.customerCreateOrEdit;
  };
  createOredit(val) {
    this.customerCreateOrEdit = val;
  };
  addCustomer(data): Observable<any> {
    return this.http.post(config.api + "retailSupplier/rtlAddCustomer", data);
  };
  checkCustomerRegisterOrNot(phn): Observable<any> {
    return this.http.post(config.api + 'retailSupplier/rtlCustomerRegisterCheck', phn);
  };
  getLayerCustomer(data): Observable<any> {
    return this.http.post(config.api + 'retailSupplier/rtlCustomersList', data);
  }

  getNearestWholeSeller(send) {
    return this.http.post(config.api + 'retailSupplier/getWholesellerList', send);
  }
  getBookingOrders(send) {
    return this.http.post(config.api + 'retailSupplier/getBookingList', send);
  }
  expenses(data): Observable<any> {
    return this.http.post(config.api + 'retailSupplier/rtlExpenses', data);
  }
  blendFormvalues(data) {
    this.allExpenses = data
  }
  editExpenditureData(data): Observable<any> {
    return this.http.post(config.api + 'retailSupplier/updateOrInsert', data);
  }
  wholeseller(data) {
    console.log(data, 123)
    this.wholesellerslist = data;
  }
  wholesellers() {
    return this.wholesellerslist;
  }

  // get customers in select buyer page
  getBuyers(val): Observable<any> {
    return this.http.post(config.api + 'wholeseller/customersList', val);
  }
  //   getLayerCustomer(val):Observable<any> {
  //   return this.http.post(config.api + 'wholeseller/customersList', val);
  // }
  // create sale
  createSale(data): Observable<any> {
    return this.http.post(config.api + 'retailSupplier/createSale', data);
  }

  updateExitSale(data): Observable<any> {
    return this.http.post(config.api + 'retailSupplier/updateExitSale', data);
  }
  // add stock
  addStock(data): Observable<any> {
    return this.http.post(config.api + 'retailSupplier/addStock', data);
  };
  // get home page data
  getHomePageData(data): Observable<any> {
    return this.http.post(config.api + 'retailSupplier/getHomePageData', data);
  };
  createTour(data): Observable<any> {
    return this.http.post("https://api.routexl.com/tour", data)
  };
  unlinkWholeSeller(data): Observable<any> {
    return this.http.post(config.api + 'retailSupplier/unLinkWholeSeller', data);
  }

  acceptRequest(data) {
    return this.http.post(config.api + 'retailSupplier/acceptApi', data);
  }

  // declineRequest(data) {
  //   return this.http.post(config.api + 'retailSupplier/declineApi', data);
  // }
 
  changeOrderStatus(status) {
    return this.http.post(config.api + 'retailSupplier/orderStatusChange', status);
  }
  booKingAutoOrder(wholeSeller) {
    this.bookingOrder = {
      whole: wholeSeller.whole,
      orderWholeSeller: wholeSeller.wholeSellerData
    }
  }
  sendNotifcationToUser(data): Observable<any> {
    return this.http.post(config.api + "retailSupplier/sendNotification", data)
  }
  getRetailSupplierSettings(): Observable<any> {
    return this.http.get(config.api + 'auth/getUserSettings');
  };
  updatePrices(object): Observable<any> {
    return this.http.post(config.api + 'auth/updtAndGet', object);
  };

  setMapData(orders) {
    this.deliverOrders = orders;
  }
  getMapData() {
    return this.deliverOrders;
  }


  setRetailerOrder(data) {
    this.retailOrderData = [];
    this.retailSupplierData = data;
  }
  getRetailerOrderData() {
    return this.retailSupplierData
  }

  tourStartFor(ids): Observable<any> {
    return this.http.post(config.api + 'retailSupplier/tourStatusUpdate', ids);
  }
  updateTourInSettings(val): Observable<any> {
    return this.http.post(config.api + 'retailSupplier/updateTourStatus', val);
  }
  routedOrders(data) {
    this.newRoutedArray = data;
  }
  saleEditData(data) {
    console.log(data, 144);
    this.editSaleData = data;
  }
  getSaleEditData() {
    return this.editSaleData;
  }
  linkeCheck(data) {
    this.i = this.i + 1;
    this.makeBreakExp.next(data);
  }
  setAutoOrdersCount(data:number){
    this.autoOrderCount.next(data)
  }
}
