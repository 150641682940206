import { Component, Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'replaced'
})
export class aReplacePipe implements PipeTransform {
    transform(value: any): any {
        value = String(value).toString();
        let value1 = value.replace(/[,.]/g, '');
        let newValue = value1.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return `${newValue}`;
    }
}