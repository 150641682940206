import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { CommonService } from 'src/services/common.service';
import { Router, NavigationExtras, } from '@angular/router';
import { PageRefresherService } from 'src/app/page-refresher/page-refresher.service';
import { Subject } from 'rxjs';
import { LoadingController, NavController, ToastController, Platform, IonRouterOutlet, ModalController } from '@ionic/angular';
import { MyfarmCreateEditComponent } from '../../myfarm-create-edit/myfarm-create-edit.component';

import { InventoryInfoService } from "../../inventory-info/inventory-info.service"
import { LoadingserviceService } from '../../loadingservice.service';
import { TranslateService } from '@ngx-translate/core';
import { SupervisorService } from 'src/services/supervisor.service';
@Component({
  selector: 'app-myfarm',
  templateUrl: './myfarm.page.html',
  styleUrls: ['./myfarm.page.scss'],
})
export class MyfarmPage implements OnInit {
  myfarmData: any;
  index: number;
  emptyCheck: void;
  unSubscribe$: Subject<any> = new Subject();
  role_id = localStorage.getItem('role_id');
  enablerefresh: boolean = false;
  farmerdata;

  tempMyFarms: any;
  constructor(
    private platform: Platform,
    private commonService: CommonService,
    private router: Router,
    private refreshservice: PageRefresherService,
    private modalCtrl: ModalController,
    public navCtrl: NavController,
    public toastController: ToastController,
    public inven: InventoryInfoService,
    public loading: LoadingserviceService,
    private translate: TranslateService,
    private supervisor: SupervisorService
  ) {

  }
  ngOnInit() {
    this.refreshservice.initializesub();
    // this.commonService.getFarmerNotification().subscribe((data: any) => {
    //   this.myfarmData = data;
    // })

  }
  ionViewDidEnter() {
    if (this.role_id == "3") {
      this.farmerdata = this.supervisor.getFarmerDetails();
      this.commonService.myform_getData(this.farmerdata).subscribe((data) => {
        this.myfarmData = data;
      })
    } else {
      // this.commonService.navItem$.subscribe(
      //   data => {
      //     if (data.length > 0) {
      //       this.myfarmData = data;
      //       this.commonService.local_myfarms = data;
      //     }
      //   });
      this.tempMyFarms = this.commonService.local_myfarms;
      if (this.tempMyFarms) {
        this.myfarmData = this.commonService.local_myfarms;
      } else {
        this.commonService.myform_getData({}).subscribe((data) => {
          this.commonService.local_myfarms = data;
          this.myfarmData = data;
        })
      }
    }
  }
  async Edit(farmData) {
    Object.assign(farmData, { path: "Edit" })
    this.modalCtrl.create({
      component: MyfarmCreateEditComponent,
      componentProps: { obj: farmData }
    }).then(modal => {
      modal.present();
      modal.onDidDismiss().then(data => {
        if (data.data.farm) {
          this.myfarmData = data.data.newData;
          this.commonService.setAValue(data.data.newData);
        } else {

        }
      });
    });
  }
  View(data) {
    // this.loading.loadingPresent();
    this.commonService.myfarmSetData(data);
    // this.loading.loadingDismiss();
  }

  refresh(event: boolean) {
    if (event) {
      if (event) {
        this.enablerefresh = true;
        setTimeout(() => {
          this.enablerefresh = false;
        }, 1000);
      }
      if (this.role_id == "3") {
        this.commonService.myform_getData(this.farmerdata).subscribe(
          (data) => {
            this.myfarmData = data;
            this.commonService.local_myfarms = data;
          }
        )
      }
      else {
        this.commonService.myform_getData({}).subscribe(
          (data) => {
            this.myfarmData = data;
            this.commonService.local_myfarms = data;
          }
        )
      }
    }
  }
  ionViewDidLeave() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  async routingfun(data) {
    if (data == '1' || '13') {
      if (this.myfarmData.length == 5 || this.myfarmData.length > 5) {
        const toast = await this.toastController.create({
          message: this.translate.instant('flashMessages.moreThanFiveFarms'),
          duration: 3000,
          position: 'bottom'
        });
        toast.present();
      } else {
        this.modalCtrl.create({
          component: MyfarmCreateEditComponent
        }).then(modal => {
          modal.present();
          modal.onDidDismiss().then(data => {
            if (data.data.farm) {
              this.loading.loadingPresent();
              this.commonService.myfarm_farmInsert(data.data.farmData).subscribe((data) => {
                if (data.success) {
                  this.loading.loadingDismiss();
                  this.myfarmData = data.data;
                  this.commonService.setAValue(data.data);
                  this.inven.setInventoryData(data.inventory_list);
                  this.inven.setAValue(data.inventory_list);
                  this.loading.showError(this.translate.instant('flashMessages.insertedSuccessfully'));
                } else {
                  this.loading.loadingDismiss();
                }
              });


              // this.myfarmData = data.data.newData.data;
              // this.commonService.setAValue(data.data.newData.data);
              // this.inven.setInventoryData(data.data.newData.inventory_list);
              // this.inven.setAValue(data.data.newData.inventory_list);



              // this.inven.setAValue(data.data.newData.inventory_list);
              // this.inven.local_inventory_list = data.data.newData.inventory_list;
            } else {
            }
          });
        });
      }
    }
    else {
      console.log("role wasn't correct");
    }
  }
}