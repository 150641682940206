import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-trader-sort-popup',
  templateUrl: './add-trader-sort-popup.component.html',
  styleUrls: ['./add-trader-sort-popup.component.scss'],
})
export class AddTraderSortPopupComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
