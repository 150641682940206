import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'overallRating'
})
export class OverallRatingPipe implements PipeTransform {
  data: any;


  transform(ratingArr: any[], ratingKey): any {
    // console.log(ratingKey);
    // console.log(ratingArr);

    let obj = ratingArr.find(obj => obj.r_id == ratingKey);
    if (obj) {
      let report = 'Poor';
      if (obj.r_id == 5) {
        report = 'Excellent'
      } else if (obj.r_id == 4) {
        report = 'Good'
      } else if (obj.r_id == 3) {
        report = 'Average';
      } else if (obj.r_id == 2) {
        report = 'Below Average'
      }
      obj['report'] = report;
      return obj
    } else {
      let report = 'Poor';
      if (ratingKey == 5) {
        report = 'Excellent'
      } else if (ratingKey == 4) {
        report = 'Good'
      } else if (ratingKey == 3) {
        report = 'Average';
      } else if (ratingKey == 2) {
        report = 'Below Average'
      }
      return {
        percent_based_rating_count: 0,
        r_id: ratingKey,
        'report': report
      }
    }
  }

}
