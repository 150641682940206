import { Component, OnInit, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-custmmessage-pop-over',
  templateUrl: './custmmessage-pop-over.component.html',
  styleUrls: ['./custmmessage-pop-over.component.scss'],
})
export class CustmmessagePopOverComponent implements OnInit {
  @Input() id: any;
  ccShow = false;
  constructor(
   private pop:PopoverController
  ) {

  }

  ngOnInit() {
    if (this.id == 'cc') {
      this.ccShow = true;
    } else {
      this.ccShow = false;
    }
  }
  close(){
    this.pop.dismiss()
  }

}
