import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnInit {

  @Input('val') value: number;
  @Input('indicator-value') indValue: number = 0.000;
  @Input('min') minValue: number;
  @Input('max') maxValue: any;
  @Input('type') type: string;
  @Input('type1') type1: string;


  constructor() { }

  ngOnInit() {
    if (this.type == "fcr") {
       
      this.maxValue = this.fcr(this.indValue);

    } else {
      this.maxValue = this.calc(this.indValue);
    }

  }

  calc = function (indValue) {
    let a = indValue / 5;
    if(a  < 10){
      (a >= 0 && a <= 1) ? this.maxValue = 5 : (a >= 1 && a <= 2) ? this.maxValue = 10 : (a >= 2 && a <= 3) ? this.maxValue = 15 : (a >= 3 && a <= 4) ? this.maxValue = 20 : this.maxValue == 25;
      
      if (this.maxValue == 5) {
        this.value = (this.value * 20);
      } else if (this.maxValue == 10) {
        this.value = (this.value * 10);
      } else if (this.maxValue == 15) {
        this.value = (this.value * 6.66);
      } else if (this.maxValue == 20) {
        this.value = (this.value * 5);
      } else if (this.maxValue == 25) {
        this.value = (this.value * 4);
      } else {
        this.value = 10;
      }
      return this.maxValue;
    } else {
      this.maxValue = 100;
      this.value = this.value;
      return this.maxValue;
    }
    
  }

  fcr = function (indValue) {
    let a = indValue;
    
    if(a < 10) {
      (a >= 0 && a <= 2) ? this.maxValue = 2 : (a >= 2 && a <= 4) ? this.maxValue = 4 : (a >= 4 && a <= 6) ? this.maxValue = 6 : this.maxValue = 8;
      
      
      if (this.maxValue == 2) {
        this.value = (this.value * 50);
      } else if (this.maxValue == 4){
        this.value = (this.value*25 );
      } else if (this.maxValue == 6){
        this.value = (this.value * 16.66);
      } else {
        this.value = ( this.value * 12.5);
      }
      return this.maxValue;
    }
    else {
      this.maxValue = 100;
      this.value = this.value;
      return this.maxValue;
    }
  }
}








































 
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    



     
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    