import { Component, OnInit, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { InventoryService } from './inventory.service';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { Router } from '@angular/router';
import { NativeGeocoder, NativeGeocoderOptions, NativeGeocoderResult } from '@ionic-native/native-geocoder/ngx';
import { LoadingController, ToastController, ModalController } from '@ionic/angular';
import { Platform, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';



declare var google: any;

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.page.html',
  styleUrls: ['./inventory.page.scss'],
  providers: [InventoryService]
})
export class InventoryPage implements OnInit {
  currencySymbol: string = localStorage.getItem('currencySymbol');
  today = new Date();
  minDate: string = new Date('2018-09-28T00:00:00.000Z').toISOString();
  maxDateLimit = this.today.toISOString().substr(0, 10);
  role_id: string;
  DateChange(data) {
    console.log(data);
  }

  public inventory_add: FormGroup;
  public submitted = false;
  public nav: any;
  public GoogleAutocomplete = new google.maps.places.AutocompleteService();
  public location = '';
  public autocompleteItems = [];
  autocomplete = { input: '' };
  mapvalues: boolean = false;
  latitude: number;
  longitude: number;
  geoAddress: any;
  geoencoderOptions: NativeGeocoderOptions = {
    useLocale: true,
    maxResults: 5
  };
  statusButton: boolean = true;
  countryCode:string = localStorage.getItem('countryCode');

  constructor(public formbuilder: FormBuilder,
    private inventoryService: InventoryService, private modal: ModalController,
    private geolocation: Geolocation,
    private nativeGeocoder: NativeGeocoder,
    public zone: NgZone,
    private router: Router,
    private popover: PopoverController,
    private toastController: ToastController,
    public loadingController: LoadingController,
    private translate:TranslateService) {

    this.role_id = localStorage.getItem('role_id')
    
  }
  validate_message = {
    inventory_name: [
      { type: "required", message: "Name required" },
      {type:'minlength' , message:'Name minimum 3 characters required'},
      {type:'maxlength' , message:'Name too long'}
    ],
    no_of_birds: [
      { type: "required", message: "Number of Birds Required " }
    ],
    location: [
      { type: "required", message: "Location required" }
    ],
    arrival_date: [
      { type: "required", message: "Date Required" }
    ],
    mortality_rate: [
      { type: "required", message: "Mortality Required" }
    ],
    feed_bags: [
      { type: "required", message: "Feed Bags required" }
    ],
    bags_weight: [
      { type: "required", message: "Choose Bags weight" }
    ],
    chick_price: [
      { type: "required", message: "Chick Price Required" }
    ],
    formId: [
      { type: "required", message: "formid Required" }
    ],
    breed: [
      { type: "required", message: "Breed Required" }
    ]
  }
  ngOnInit() {
 
    this.inventory_add = this.formbuilder.group({
      inventory_name: ['',Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(16)])],
      no_of_birds: ["", Validators.required],
      location: ["", Validators.required],
      arrival_date: ["", Validators.required],
      mortality_rate: ["", Validators.required],
      feed_bags: ["", Validators.required],
      bags_weight: ["", Validators.required],
      chick_price: [""],
      formId: ["",],
      breed:['',Validators.required],
    })
  }
  geoCode(address: any) {
    let geocoder = new google.maps.Geocoder();
    geocoder.geocode({ 'address': address }, (results, status) => {
      
      this.latitude = results[0].geometry.location.lat();
      this.longitude = results[0].geometry.location.lng();
    });
  }

  updateSearchResults() {
    if (this.autocomplete.input == '') {
      this.autocompleteItems = [];
      return;
    }

    this.GoogleAutocomplete.getPlacePredictions({
      input: this.autocomplete.input, types: ['(cities)'],
      // componentRestrictions: { country: "IN" }
      componentRestrictions: { country: localStorage.getItem('countryCode') }
    },
      (predictions, status) => {
        this.autocompleteItems = [];
        this.zone.run(() => {
          predictions.forEach((prediction) => {
            
            this.autocompleteItems.push(prediction);
          });
        });
      });
  }

  selectSearchResult(item) {
    this.autocomplete.input = item.description;
    this.geoAddress = this.autocomplete.input;
    this.geoCode(this.autocomplete.input);
    
    if (this.autocomplete.input) {
      this.mapvalues = true;
    }
    else {
      this.mapvalues = false;
      this.updateSearchResults()
    }
  }

  getGeoLocation() {
    this.autocompleteItems = [];
    this.geolocation.getCurrentPosition().then((resp) => {
      this.latitude = resp.coords.latitude
      this.longitude = resp.coords.longitude;
      this.getGeoencoder(this.latitude, this.longitude);
    }).catch((error) => {
      console.log('Error getting location', error);
    });
  }

  getGeoencoder(latitude, longitude) {
    this.nativeGeocoder.reverseGeocode(latitude, longitude, this.geoencoderOptions)
      .then((result: NativeGeocoderResult[]) => {
        this.geoAddress = this.generateAddress(result[0]);
        this.inventory_add.controls.location.setValue(this.geoAddress);
      })
      .catch((error: any) => {
        alert('Error getting location' + JSON.stringify(error));
      });
  }

  generateAddress(addressObj) {
    let obj = [];
    let address = "";
    for (let key in addressObj) {
      obj.push(addressObj[key]);
    }
    obj.reverse();
    for (let val in obj) {
      if (obj[val].length)
        address += obj[val] + ', ';
    }
    return address.slice(0, -2);
  }

  


  onSumbmitInventory(inventoryTF: boolean) {
    if (inventoryTF) {
      if (new Date(new Date().setHours(0, 0, 0, 0)) >= new Date(new Date(this.inventory_add.value.arrival_date).setHours(0, 0, 0, 0))) {
        this.presentLoadingWithOptions();
        this.loadingController.dismiss().then(a => console.log('dismisssed', 33));
        this.submitted = true;
        if (this.inventory_add.invalid) {
          return;
        }
        let val = this.inventory_add.value;
        const data = { ...val, u_id: localStorage.getItem('u_id'), latitude: this.latitude, longitude: this.longitude };
        this.inventoryService.addInventory(data).subscribe(result => {
          if (result.success) {
             this.modal.dismiss({
                inven: inventoryTF,
                newData: result
            });
            this.showError(this.translate.instant('flashMessages.inventoryCreated'))
            this.inventory_add.reset();
            this.router.navigateByUrl('/home/tabs/inventory-info');
            
            this.loadingController.dismiss().then(a => console.log('dismisssed', 33));
          
          } else {
            this.showError(this.translate.instant('flashMessages.somethingWentWrong'))
          }
        });
      } else {
        this.showError("you can't choose future Dates");
      }
    } else {
      this.modal.dismiss({
        inven: inventoryTF,
        newData: this.inventory_add.value
      });

    }
  }

  async presentLoadingWithOptions() {
    const loading = await this.loadingController.create({
      spinner: 'lines-small',
      duration: 2000,
      message: this.translate.instant('common.pleaseWait'),
      translucent: true,
      backdropDismiss: false,
      cssClass: 'custom-class custom-loading'
    });
    return await loading.present();
  }
  async showError(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 3000,
      position: 'bottom',
      buttons: [
        {
          text: 'Done',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        }
      ],
      animated: true,
      cssClass: "my-custom-class",
    });
    toast.present();
  }

}
