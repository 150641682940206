import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingserviceService } from 'src/app/loadingservice.service';
import { TraderService } from 'src/services/trader.service';

@Component({
  selector: 'app-contact-not-add',
  templateUrl: './contact-not-add.page.html',
  styleUrls: ['./contact-not-add.page.scss'],
})
export class ContactNotAddPage implements OnInit {

  userAccessRole: string = '';
  userData;
  noMsg = false;
  constructor(
    private traderservice: TraderService,
    private router: Router,
    private loader: LoadingserviceService
  ) { }

  ngOnInit() {
  }

  ionViewWillEnter() {
    this.loader.loadingPresent();
    this.userData = [];
    this.traderservice.ContactsList({ skip: 0, contactType: "trader-supervisor" }).subscribe((res: any) => {
      if (res.data.length > 0) {
        this.userData = res.data;
        this.loader.loadingDismiss();
        this.noMsg = false;
      } else {
        localStorage.setItem("linked", "false")
        this.loader.loadingDismiss();
        this.noMsg = true;
      }
    });
  }

  ionViewDidEnter() {
    if (localStorage.getItem('role_id') == "8") {
      this.traderservice.adminAccessCheck({ uId: localStorage.getItem("u_id") }).subscribe((res: any) => {
        if (res.success) {
          this.userAccessRole = "admin";
          if (localStorage.getItem('role_id') == "8") {
            localStorage.setItem("linked", "true");
          } else {
            localStorage.removeItem("linked");
          }
        } else if (!res.success) {
          if (localStorage.getItem('role_id') == "8") {
            localStorage.setItem("linked", "false");
          } else {
            localStorage.removeItem("linked");
          }
        }
      });
    }
  }

  navigate(data) {
    if (data.requestAccepted) {
      this.router.navigateByUrl('/trader/tabs/orders');
    } else {
      console.log("not accepted");
    }
  }

  doRefresh(event) {
    this.ionViewWillEnter();
    setTimeout(() => {
      event.target.complete();
    }, 300);
  }

  deleteFarmer(item) {
    this.traderservice.contactUnlink({ _id: item._id, option: "trader-supervisor", createdFor: item.createdFor, contactSettingId: item.contactSettingId }).subscribe((data) => {
      if (data.success) {
        this.ionViewWillEnter();
      }
    });
  }


  createPurchaseSale() {
    this.router.navigateByUrl('/linked-contacts');
  }
}
