import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DeviceIdentityService {

  constructor() { }

  public isMobileDevice() {
    const iPadOS13Up = navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1;
    return /android|webos|iphone|ipad|ipod|blackberry|windows phone/.test(
      navigator.userAgent.toLowerCase()
    ) || iPadOS13Up;
  }
}
