import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortpipe'
})
export class SortpipePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (args == 'Capacity') {
      value.sort((a, b) => {
        return b.no_of_birds - a.no_of_birds
      })
      return value;
    } else if (args == 'Avg Weight') {
      value.sort((a, b) => {
        return b.day - a.day
      })
      return value;
    } else if (args == 'Price') {
     value.sort((a, b) => {
        return a.price - b.price
      })
      return value;
    }else{
      return value;
    }
  }
}
