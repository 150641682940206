import { TraderService } from 'src/services/trader.service';
import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-contact-access-details',
  templateUrl: './contact-access-details.page.html',
  styleUrls: ['./contact-access-details.page.scss'],
})
export class ContactAccessDetailsPage implements OnInit {

  contactData; adminCheck = false;
  finalData = [];
  selectedVans = [];
  vans = [];

  constructor(private navParams: NavParams,
    public traderService: TraderService,
    private modal: ModalController) {
    this.contactData = this.navParams.get("data");
    console.log(this.contactData)
  }

  ngOnInit() {
    this.traderService.traderVechilesList({ skip: 0 }).subscribe((data: any) => {
      this.vans = data.data;
    })
  }

  closeModal() {
    console.log("dismiss")
    this.modal.dismiss()
  }

  changedAdmin(event) {
    this.adminCheck = event.detail.checked;
    if (!this.adminCheck)
      this.finalData = [];
  }

  checkedVan(van, event) {
    if (event.detail.checked) {
      this.finalData.push(van._id);
    } else {
      for (var i = 0; i < this.finalData.length; i++) {
        if (van._id == this.finalData[i]) {
          this.finalData.splice(i, 1);
        }
      }
    }
  }


  finalSubmit() {
    let accessCheck;
    if (this.adminCheck) {
      accessCheck = { access: 'admin', '_id': this.contactData._id, createdBy: this.contactData.createdBy, createdFor: this.contactData.createdBy, "action": true, contactSettingId: this.contactData.contactSettingId }
    } else {
      accessCheck = { vanId: this.finalData, access: 'restricted', '_id': this.contactData._id, createdBy: this.contactData.createdBy, createdFor: this.contactData.createdBy, "action": true, contactSettingId: this.contactData.contactSettingId }
    }
    console.log(accessCheck)
    this.traderService.traderPermissionToSupervisor(accessCheck).subscribe((data) => {
      if (data.success) {
        this.modal.dismiss();
      }
      else{
        this.modal.dismiss();
      }
    })
  }
}
