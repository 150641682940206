import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { IonicModule } from '@ionic/angular';

import { InventoryPage } from './inventory.page';
import { CommonExportsModule } from 'src/app/common-exports/common-exports.module';
import { InterceptorService } from 'src/services/interceptor.service';

const routes: Routes = [
  {
    path: '',
    component: InventoryPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,CommonExportsModule,
    IonicModule,ReactiveFormsModule,
    RouterModule.forChild(routes),
    HttpClientModule,
  ],
  declarations: [InventoryPage],
  providers:[
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true }
  ]
})
export class InventoryPageModule {}
