import { Component, OnInit, ViewChild, ElementRef, NgZone, Output, Input } from '@angular/core';
import { CommonExportsModule } from "../common-exports/common-exports.module";
import { FormBuilder, Validators } from '@angular/forms';
import { CommonService } from 'src/services/common.service';
declare var google: any;
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingController, ToastController, ModalController, NavParams, AlertController } from '@ionic/angular';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { NativeGeocoder, NativeGeocoderOptions, NativeGeocoderResult } from '@ionic-native/native-geocoder/ngx';
import { Platform, PopoverController } from '@ionic/angular';
import { LoadingserviceService } from "../loadingservice.service";
import { isObject } from 'util';
import { TranslateService } from '@ngx-translate/core';
import { InventoryInfoService } from '../inventory-info/inventory-info.service';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';

@Component({
  selector: 'app-myfarm-create-edit',
  templateUrl: './myfarm-create-edit.component.html',
  styleUrls: ['./myfarm-create-edit.component.scss'],
})
export class MyfarmCreateEditComponent implements OnInit {
  currencySymbol = localStorage.getItem('currencySymbol');
  bagWeights = [
    { option: 1, weight: 50 },
    { option: 2, weight: 55 },
    { option: 3, weight: 60 },
    { option: 4, weight: 65 },
    { option: 5, weight: 70 },
    { option: 6, weight: 75 },
    { option: 7, weight: 80 },
    { option: 8, weight: 85 },
    { option: 9, weight: 90 },
    { option: 10, weight: 95 },
    { option: 11, weight: 100 },
  ];

  initWeight = [50];
  myHomeLocation = localStorage.getItem('address');
  userRole = localStorage.getItem('role_id');
  today = new Date();
  maxDateLimit = this.today.toISOString().substr(0, 10);
  presentDate = new Date();
  showMsg: string;

  mapvalues: boolean = false;
  submitted = false;
  private element: HTMLInputElement;
  validation_messages = {
    Name: [{ type: "required", message: "nameRequired" }, { type: 'minlength', message: 'farmCreateNameMin' }, { type: "maxlength", message: "farmCreateNameMax" }],
    location: [{ type: "required", message: "locationRequired" }],
    Capacity: [{ type: "required", message: "capacityRequired" }],
    arrivalDate: [{ type: "required", message: "dateRequired" }],
    // price: [{ type: "required", message: "priceRequired" }],
    // feedbags: [{ type: "required", message: "feedbagRequired" }],
    // bagsweight: [{ type: "required", message: "bagWeightRequired" }],
    formId: [],
    // feedPrice: [
    //   { type: "required", message: "priceRequired" },
    //   { type: "min", message: "minPriceRequiredInventory" },
    //   { type: "max", message: "maxPriceRequiredInventory" }
    // ],
  };
  id = "";
  disablevalue: boolean;
  name = '';
  location = '';
  Capacity = '';
  arrivalDate = '';
  GoogleAutocomplete = new google.maps.places.AutocompleteService();
  autocomplete = { input: '' };
  autocompleteItems = [];
  iddata: any;
  myfarmDataById: any;
  latitude: number;

  longitude: number;
  geoAddress: string;

  geoencoderOptions: NativeGeocoderOptions = {
    useLocale: true,
    maxResults: 5
  };
  editcheck: boolean = false;
  err: any;
  selectedItem: any;
  common_id: any;
  inventoryCount: number;
  default: string;
  countryCode: string = localStorage.getItem('countryCode');
  constructor(
    public loadingController: LoadingController,
    public navParams: NavParams,
    private Platform: Platform,
    private router: Router,
    private popover: PopoverController,
    private formBuilder: FormBuilder,
    private elRef: ElementRef,
    private geolocation: Geolocation,
    private nativeGeocoder: NativeGeocoder,
    public zone: NgZone,
    private commonService: CommonService,
    private modalCtrl: ModalController,
    private nav: Router,
    private toastController: ToastController,
    private route: ActivatedRoute,
    private LoadingserviceService: LoadingserviceService,
    private translate: TranslateService,
    private inventoryInfoService: InventoryInfoService,
    private androidPermissions: AndroidPermissions,
    private AlertController: AlertController,
    private locationAccuracy: LocationAccuracy,
  ) {
    this.inventoryInfoService.getBehaviourSubjectData().subscribe((data: any) => {
      console.log(data.length, 90);
      this.inventoryCount = data.length;
      // // this.inventoryInfo.push(data);
      // if (this.inventoryInfo && this.inventoryInfo.length > 0) {
      //   this.iterateInventoryData(this.inventoryInfo);
      // } else {
      //   console.log("no inventory data", 59)
      //   this.inventoryInfo = [];
      // }
    });
    this.selectedItem = this.navParams.get('obj');


  }
  myFarmForm = this.formBuilder.group({
    Name: ['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(16)])],
    location: [this.myHomeLocation, Validators.required],
    Capacity: ["", Validators.required],
    arrivalDate: ["", Validators.required],
    u_id: [localStorage.getItem('u_id')],
    // inventoryCheck: [true, Validators.required],
    price: [''],
    // bagsweight: ['', Validators.required],
    formId: [''],
    // feedPrice: ["", Validators.compose([Validators.required, Validators.min(1), Validators.max(100)])]
  });
  ngOnInit() {
    this.default = localStorage.getItem('lngCode');
    console.log(this.myHomeLocation, 124);
    this.myFarmForm.controls.location.setValue(this.myHomeLocation);
    this.autocomplete.input = this.myHomeLocation;
    this.latitude = Number(localStorage.getItem('lat'));
    this.longitude = Number(localStorage.getItem('lng'));
    console.log(this.selectedItem, 148);
    if (isObject(this.selectedItem)) {
      this.disablevalue = true;
      this.editcheck = true;
      // this.myFarmForm.controls.inventoryCheck.setValue(false);
      this.myfarmDataById = [this.selectedItem];
      this.name = this.selectedItem.name;
      this.location = this.selectedItem.address;
      this.autocomplete.input = this.selectedItem.address;
      this.Capacity = this.selectedItem.capacity;
      this.arrivalDate = this.selectedItem.arrivalDate;
      this.id = this.selectedItem._id;
      this.latitude = this.selectedItem.location.coordinates[1];
      this.longitude = this.selectedItem.location.coordinates[0];
      this.common_id = this.selectedItem.common_id;
    }
  }
  a: any;
  onSubmitMyForm(farm: boolean) {
    if (farm) {
      if ((this.myFarmForm.value.Capacity >= 100) && (new Date(new Date().setHours(0, 0, 0, 0)) >= new Date(new Date(this.myFarmForm.value.arrivalDate).setHours(0, 0, 0, 0)))) {
        let data = {
          u_id: localStorage.getItem('u_id'),
          Name: this.name,
          address: this.geoAddress || this.location || this.myHomeLocation,
          latitude: this.latitude,
          longitude: this.longitude,
          Capacity: this.Capacity,
          arrivalDate: new Date(this.arrivalDate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"))
        };
        if (this.myFarmForm.valid) {
          this.a = Object.assign(this.myFarmForm.value, data)
          if (this.inventoryCount < 5) {
            this.modalCtrl.dismiss({
              farm: farm,
              farmData: this.a,
            });
          } else {
            if (this.inventoryCount > 4) {
              this.presentAlert(this.translate.instant('alertMessages.inventorygrater'));
            } else {
              let message = this.translate.instant('flashMessages.inventorygrater');
              this.presentAlert(message)
            }
          }
        }
      } else {
        this.LoadingserviceService.loadingDismiss();
        if (this.myFarmForm.value.Capacity < 100) {
          this.LoadingserviceService.showError(this.translate.instant('minimum 100 chicks required'));
        } else {
          this.LoadingserviceService.showError(this.translate.instant('flashMessages.youCantChooseFeatureDates'));
        }
      }
    } else {
      this.modalCtrl.dismiss({
        farm: farm,
        id: "supervisor",
        formData: this.myFarmForm.value
      });
    }
  }

  geoCode(address: any) {
    let geocoder = new google.maps.Geocoder();
    geocoder.geocode({ 'address': address }, (results, status) => {
      this.latitude = results[0].geometry.location.lat();
      this.longitude = results[0].geometry.location.lng();
    });
  }
  updateSearchResults() {
    if (this.autocomplete.input == '') {
      this.autocompleteItems = [];
      return;
    }
    this.GoogleAutocomplete.getPlacePredictions({
      input: this.autocomplete.input, types: ['(cities)'],
      componentRestrictions: { country: localStorage.getItem('countryCode') }
    },
      (predictions, status) => {
        this.autocompleteItems = [];
        this.zone.run(() => {
          if (predictions?.length > 0) {
            predictions.forEach((prediction) => {
              this.autocompleteItems.push(prediction);
            });
          }
        });
      });
  }

  selectSearchResult(item) {
    this.autocomplete.input = item.description;
    this.geoAddress = this.autocomplete.input;
    this.geoCode(this.autocomplete.input);
    if (this.autocomplete.input) {
      this.mapvalues = true;
    }
    else {
      this.mapvalues = false;
      this.updateSearchResults()
    }
  }
  onChange(data) {
    if (data) {
      this.removevaliadations();
    }
  }




  resetForm() {
    this.myFarmForm.reset();
  }
  removevaliadations() {
    console.log("validation removing")
    const price = this.myFarmForm.get('price');
    // const bagsweight = this.myFarmForm.get('bagsweight');
    // const feedPrice = this.myFarmForm.get('feedPrice')
    const formId = this.myFarmForm.get('formId');
    if (this.myFarmForm.get('inventoryCheck').value) {
      price.setValidators([]);
      // bagsweight.setValidators([Validators.required]);
      // feedPrice.setValidators([Validators.required]);
    } else {
      price.clearValidators();
      // bagsweight.clearValidators();
      // feedPrice.clearValidators();
      formId.clearValidators();
      price.setValue('');
      // bagsweight.setValue('');
      // feedPrice.setValue('');
      formId.setValue('');
    }
    price.updateValueAndValidity();
    // bagsweight.updateValueAndValidity();
    // feedPrice.updateValueAndValidity();
    formId.updateValueAndValidity();
    console.log(this.myFarmForm.value);
  }




  dayverify(arrivalDate) {
    let day = new Date(arrivalDate)
    var d = new Date();
    var x = 60;
    let lessdays = (d.setDate(d.getDate() - x));


    if (day > new Date()) {
      this.LoadingserviceService.showError(this.translate.instant('flashMessages.youCantChooseFeatureDates'));
      this.myFarmForm.patchValue({ 'arrivalDate': '' })
    }
    else if (day < new Date(lessdays)) {
      this.LoadingserviceService.showError(this.translate.instant('flashMessages.arrivalDateLimit'));
      this.myFarmForm.patchValue({ 'arrivalDate': '' })
    } else {
    }
  }
  async presentAlert(msg) {
    const alert = await this.AlertController.create({
      header: this.translate.instant('alertMessages.alert'),
      cssClass: 'my-delete-class',
      message: msg,
      buttons: [
        {
          text: this.translate.instant('buttons.ok'),
        }
      ]
    });
    await alert.present();
  }


  getGeoLocation() {
    this.LoadingserviceService.loadingPresent();
    var positionOption = { timeout: 7000, enableHighAccuracy: true, maximumAge: 70000, };
    this.geolocation.getCurrentPosition(positionOption).then((resp: any) => {
      this.latitude = resp.coords.latitude
      this.longitude = resp.coords.longitude;
      this.getGeoencoder(this.latitude, this.longitude);
    }).catch((error) => {
      console.log('Error getting location', error);
      this.LoadingserviceService.loadingDismiss();
      this.checkGPSPermission();
    });
  }

  getGeoencoder(latitude, longitude) {
    console.log("geo cordinates ", latitude, longitude);
    this.nativeGeocoder.reverseGeocode(latitude, longitude, this.geoencoderOptions)
      .then((result: NativeGeocoderResult[]) => {
        this.LoadingserviceService.loadingDismiss();
        if (result[0].countryName == localStorage.getItem('country')) {
          this.geoAddress = this.generateAddress(result[0]);
          this.myFarmForm.controls.location.setValue(this.geoAddress);
        } else {
          this.LoadingserviceService.showError('Your country and location are mismatched')
        }
      })
      .catch((error: any) => {
        this.LoadingserviceService.loadingDismiss();
        alert('Error getting location' + JSON.stringify(error));
      });
  }

  generateAddress(addressObj) {
    console.log(addressObj, 125);
    let obj = [];
    let address = "";
    for (let key in addressObj) {
      obj.push(addressObj[key]);
    }
    obj.reverse();
    for (let val in obj) {
      if (obj[val].length)
        address += obj[val] + ', ';
    }
    return address.slice(0, -2);
  }




  // /////////////////this code is used for activate mobile location service/////
  checkGPSPermission() {
    this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.ACCESS_COARSE_LOCATION).then(result => {
      console.log(result, 258);
      if (result.hasPermission) {
        //If having permission show 'Turn On GPS' dialogue
        this.askToTurnOnGPS();
        // this.currentLocation();
      } else {
        //If not having permission ask for permission
        this.requestGPSPermission();
      }
    }, async err => {
      console.log(err, 267);
      const alert = await this.AlertController.create({
        header: this.translate.instant('alertMessages.alert'),
        cssClass: 'singleButtonAlert',
        message: "Something went wrong, Please try again once",
        buttons: [
          {
            text: this.translate.instant('buttons.ok'),
            role: 'Ok',
            handler: data => {
              console.log('already add this day');
            }
          }
        ]
      });
      await alert.present();
    });
  }

  requestGPSPermission() {
    this.locationAccuracy.canRequest().then((canRequest: boolean) => {
      if (canRequest) {
        console.log(canRequest, 256);
      } else {
        //Show 'GPS Permission Request' dialogue
        this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.ACCESS_COARSE_LOCATION).then(() => {
          // call method to turn on GPS
          this.askToTurnOnGPS();
        }, async error => {
          //Show alert if user click on 'No Thanks'
          console.log(error, 298);
          const alert = await this.AlertController.create({
            header: this.translate.instant('alertMessages.alert'),
            cssClass: 'singleButtonAlert',
            message: "Something went wrong, Please try again once",
            buttons: [
              {
                text: this.translate.instant('buttons.ok'),
                role: 'Ok',
                handler: data => {
                  console.log('Ok I will try');
                }
              }
            ]
          });
          await alert.present();
        }
        );
      }
    });
  }

  askToTurnOnGPS() {
    this.locationAccuracy.request(this.locationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY).then(async (data) => {
      console.log(data, 323);
      const alert = await this.AlertController.create({
        header: this.translate.instant('alertMessages.alert'),
        cssClass: 'wholeSellerAlert',
        message: "Press ok for current location",
        buttons: [
          {
            text: this.translate.instant('buttons.cancel'),
            role: 'cancel',
            handler: data => {
              console.log('cancel');
            }
          },
          {
            text: this.translate.instant('buttons.ok'),
            role: 'Ok',
            handler: data => {
              this.askGpsAgain();
              // this.currentLocation();
            }
          },
        ]
      });
      await alert.present();
    }).catch(async (error) => {
      console.log(error, 347);
      const alert = await this.AlertController.create({
        header: this.translate.instant('alertMessages.alert'),
        cssClass: 'singleButtonAlert',
        message: "Something went wrong, Please try again once",
        buttons: [
          {
            text: this.translate.instant('buttons.ok'),
            role: 'Ok',
            handler: data => {
              console.log('Ok I will try');
            }
          }
        ]
      });
      await alert.present();
    });
  }

  askGpsAgain() {
    // When GPS Turned ON call method to get Accurate location coordinates;
    this.zone.run(() => {
      var options = { enableHighAccuracy: true, timeout: 7000 };
      this.LoadingserviceService.loadingPresent();
      this.geolocation.getCurrentPosition(options).then((resp) => {
        console.log(resp, 370);
        this.LoadingserviceService.loadingDismiss();
        this.latitude = resp.coords.latitude;
        this.longitude = resp.coords.longitude;
        this.getGeoencoder(this.latitude, this.longitude);
      }).catch(async (error) => {
        console.log(error, 377);
        this.LoadingserviceService.loadingDismiss();
        const alert = await this.AlertController.create({
          header: this.translate.instant('alertMessages.alert'),
          cssClass: 'singleButtonAlert',
          message: "Something went wrong, Please try again once",
          buttons: [
            {
              text: this.translate.instant('buttons.ok'),
              role: 'Ok',
              handler: data => {
                console.log('Ok I will try');
              }
            }
          ]
        });
        await alert.present();
      });
    });
  }
}


 // this.LoadingserviceService.loadingPresent();
              // this.commonService.myfarm_farmInsert(this.a).subscribe((data) => {
              //   if (data.success) {
              //     this.isDisabled = true;
              //     this.LoadingserviceService.loadingDismiss();
              //     this.LoadingserviceService.showError(this.translate.instant('flashMessages.insertedSuccessfully'));
              //     this.modalCtrl.dismiss({
              //       farm: farm,
              //       newData: data,
              //     });
              //     this.nav.navigateByUrl('/home/tabs/myfarm');
              //   } else {
              //     this.isDisabled = true;
              //     this.LoadingserviceService.loadingDismiss();
              //   }
              // });

 // } else {
        //   //editing data 

        //   data['_id'] = this.id;
        //   data['common_id'] = this.common_id;
        //   this.LoadingserviceService.loadingPresent();
        //   this.commonService.updateMyFormByid(data).subscribe((data: any) => {
        //     this.LoadingserviceService.loadingDismiss();
        //     if (data.success) {
        //       this.LoadingserviceService.showError(this.translate.instant('flashMessages.updatedSuccess'));
        //       this.modalCtrl.dismiss({
        //         farm: farm,
        //         newData: data.data,
        //         id: 'supervisor'
        //       });
        //       this.myFarmForm.reset();
        //       if (localStorage.getItem("role_id") != "3") {
        //         this.nav.navigateByUrl('/home/tabs/myfarm');
        //       } else {
        //         this.nav.navigateByUrl('/supervisor');
        //       }
        //     } else {
        //       this.LoadingserviceService.showError(this.translate.instant('flashMessages.somethingWentWrong'));
        //       console.log("failed", 229)
        //     }
        //   }, err => {
        //     this.LoadingserviceService.loadingDismiss();
        //     this.LoadingserviceService.showError(this.translate.instant('flashMessages.somethingWentWrong'));
        //     console.log(err)
        //   });
        // }