import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { config } from '../helper/config';


@Injectable({
  providedIn: 'root'
})
export class RicehuskReview2Service {

  constructor(public http: HttpClient) { }
  reviewPosting(data): Observable<any> {
    console.log(data, 15);
    if (data.role_id === '10') {
      return this.http.post(config.api + 'trader/rating', data);
    }
    return this.http.post(config.api + 'farms/review', data);
  }
  updatereview(data): Observable<any> {
    console.log(data, 155);
    return this.http.post(config.api + 'dealer/editComment', data);
  }
}
