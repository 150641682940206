import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'priceByDesc'
})
export class PriceByDescPipe implements PipeTransform {

  transform(value: any[], args?: any): any {
    value.sort((a, b) => {
      return b.price - a.price ;
    });
  }

  }
