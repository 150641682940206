import { Component, OnInit } from '@angular/core';
// import { Router } from '@angular/router';
import { NavParams, ModalController } from '@ionic/angular';
import { LiftingsService } from 'src/services/liftings.service';
import { TraderService } from 'src/services/trader.service';
import { VanContactAdddPage } from '../van-contact-addd/van-contact-addd.page';

@Component({
  selector: 'app-order-liftingvan',
  templateUrl: './order-liftingvan.component.html',
  styleUrls: ['./order-liftingvan.component.scss'],
})
export class OrderLiftingvanComponent implements OnInit {
  vanData: any;
  lat: number;
  lng: number;
  locationdata: any[];
  vans: any;
  msg: boolean = false;
  vehicleNumber;
  selectedVan: any;
  details: any;
  userRole = localStorage.getItem('role_id');
  constructor(
    // private router: Router,
    protected navParams: NavParams,
    public traderService: TraderService,
    public liftings: LiftingsService,
    private modalController: ModalController) {
    // this.vanData = this.navParams.data.data;
    // console.log(this.vanData);
    console.log(this.navParams.data)
    if (this.navParams.data.selected == "orderToFarmer") {
      this.getVans();
    } else {
      this.selectedVan = this.navParams.data.selected;
    }
    if (this.navParams.data.data != undefined) {
      this.vanData = this.navParams.data.data;
      console.log(this.vanData, "vanData")
      if (this.vanData.length == 0) {
        this.msg = true;
      } else {
        this.msg = false;
      }
    } else {
      this.vanData = [];
      this.msg = true;
    }

    this.details = this.navParams.data.details;
  }

  ngOnInit() {
    this.lat = parseFloat(localStorage.getItem("lat"));
    this.lng = parseFloat(localStorage.getItem("lng"));
    this.locationdata = [this.lng, this.lat];
  }

  ionViewWillEnter() {
    if (localStorage.getItem('role_id') == '1' || localStorage.getItem("role_id") == "3") {
      this.vans = this.vanData;
      if (!this.vans.length) {
        let checkData = { phone: this.navParams.data['traderData']['phone'], contact: 'phone' }
        this.liftings.findTraderUser(checkData).subscribe((data) => {
          if (data.success && data.data.length) {
            this.vans = data.data[0]['contactsList']
          }
        })
      }
    } else {
      this.getVans();
    }
  }

  chooseCenter(data) {
    console.log(data, 42);
    this.modalController.dismiss({
      reason: "selected",
      data: data
    });
  }

  goBack() {
    this.modalController.dismiss({
      reason: "cancelled",
    });
  }

  getVans() {
    let myVans;
    if (localStorage.getItem('role_id') == "2") {
      myVans = this.traderService.traderVechilesList({ skip: 0 }).subscribe((data: any) => {
        console.log(data, 12423);
        if (data.data.length <= 0) {
          this.msg = true;
        }
        else {
          console.log(data.data, 518);
          this.vans = data.data;
          this.msg = false;
        }
      });
    } else {
      this.traderService.traderSupervisorVanSummary({ skip: 0 }).subscribe((data: any) => {
        console.log(data, 12423);
        if (data.data.length <= 0) {
          this.msg = true;
        } else {
          console.log(data.data, 518);
          this.vans = data.data;
          this.msg = false;
        }
      });
    }

  }

  addNewVan(data) {
    console.log(data);
    console.log("trader data", this.navParams.data['traderData']);
    this.modalController.create({
      component: VanContactAdddPage,
      componentProps: { data: "IncomingLifting", vansList: [], traderData: this.navParams.data['traderData'] },
    }).then(modal => {
      modal.present();
      modal.onDidDismiss().then(data => {
        console.log(data.data)
        // addLiftingFromFarmerTab
        if (localStorage.getItem('role_id') == '1') {
          console.log(this.navParams.data['traderData'], 8989)
          let checkData = { phone: this.navParams.data['traderData']['phone'], contact: 'phone' }
          this.liftings.findTraderUser(checkData).subscribe((data) => {
            if (data.success && data.data.length) {
              this.vans = data.data[0]['contactsList']
              this.msg = false;
            }
          })
        } else {
          this.ionViewWillEnter()
        }
      })
    })
  }

  doRefresh(e) {
    this.ngOnInit();
    setTimeout(() => {
      e.target.complete();
      let checkData = { phone: this.navParams.data['traderData']['phone'], contact: 'phone' }
      this.liftings.findTraderUser(checkData).subscribe((data) => {
        if (data.success && data.data.length) {
          this.vans = data.data[0]['contactsList']
        }
      })
    }, 500);
  }

  reload() {
    let checkData = { phone: this.navParams.data['traderData']['phone'], contact: 'phone' }
    this.liftings.findTraderUser(checkData).subscribe((data) => {
      if (data.success && data.data.length) {
        this.vans = data.data[0]['contactsList']
      }
    })
  }
}
