import { Pipe, PipeTransform } from '@angular/core';
import { CommonService } from "../../src/services/common.service"
@Pipe({
  name: 'getfile'
})
export class GetfilePipe implements PipeTransform {
  constructor(private commonservice: CommonService) { }
  async transform(fileId: string) {
    if (fileId && fileId != 'undefined') {
      const logoFile = await this.commonservice.getImageById(fileId);
      if (!logoFile.success) {
        return ''
      }
      return `data:image/jpg;base64,${logoFile.data.data.data}`;
    }
    return
  }
}
