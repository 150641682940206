import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './authentication.guard';

// import { AddChickenCentersComponent } from './add-chicken-centers/add-chicken-centers.component';
const routes: Routes = [
  { path: '', redirectTo: 'language', pathMatch: 'full', },
  { path: 'forgotpassword', loadChildren: './forgotpassword/forgotpassword.module#ForgotpasswordPageModule' },
  { path: 'otp', loadChildren: './otp/otp.module#OtpPageModule' },
  { path: 'infopage', loadChildren: './infopage/infopage.module#InfopagePageModule' },
  { path: 'disclaimer', loadChildren: './disclaimer/disclaimer.module#DisclaimerPageModule' },
  { path: 'login', loadChildren: './login/login.module#LoginPageModule', },
  { path: 'resetpassword', loadChildren: './resetpassword/resetpassword.module#ResetpasswordPageModule' },
  { path: 'register', loadChildren: './register/register.module#RegisterPageModule' },
  {
    path: 'user-authentication',
    loadChildren: () => import('./user-authentication/user-authentication.module').then(m => m.UserAuthenticationPageModule)
  },
  {
    path: 'address-search',
    loadChildren: () => import('./modal/address-search/address-search.module').then(m => m.AddressSearchPageModule)
  },
  { path: 'home', loadChildren: './home/home.module#HomePageModule', canActivate: [AuthGuardService] },
  // { path: 'userinfo', loadChildren: './userinfo/userinfo.module#UserinfoPageModule', canActivate: [AuthGuardService] },
  { path: 'myfarm', loadChildren: './home/myfarm/myfarm.module#MyfarmPageModule', canActivate: [AuthGuardService] },
  { path: 'inventory', loadChildren: './home/inventory/inventory.module#InventoryPageModule', canActivate: [AuthGuardService] },
  { path: 'poultry-home', loadChildren: './poultry-home/poultry-home.module#PoultryHomePageModule', canActivate: [AuthGuardService] },
  // { path: 'myfarmedit', loadChildren: './myfarmedit/myfarmedit.module#MyfarmeditPageModule' },
  { path: 'myfarm-info', loadChildren: './myfarm-info/myfarm-info.module#MyfarmInfoPageModule', canActivate: [AuthGuardService] },
  { path: 'ricehusk', loadChildren: './ricehusk/ricehusk.module#RicehuskPageModule', canActivate: [AuthGuardService] },
  // { path: 'fcr', loadChildren: './fcr/fcr.module#FcrPageModule', canActivate: [AuthGuardService] },
  { path: 'coalhusk', loadChildren: './ricehusk/ricehusk.module#RicehuskPageModule', canActivate: [AuthGuardService] },
  { path: 'scrapbags', loadChildren: './ricehusk/ricehusk.module#RicehuskPageModule', canActivate: [AuthGuardService] },
  { path: 'litter', loadChildren: './ricehusk/ricehusk.module#RicehuskPageModule', canActivate: [AuthGuardService] },
  { path: 'ricehuskpreview', loadChildren: './risehusk-preview/risehusk-preview.module#RisehuskPreviewPageModule', canActivate: [AuthGuardService] },
  { path: 'coalhuskpreview', loadChildren: './risehusk-preview/risehusk-preview.module#RisehuskPreviewPageModule', canActivate: [AuthGuardService] },
  { path: 'scrapbagspreview', loadChildren: './risehusk-preview/risehusk-preview.module#RisehuskPreviewPageModule', canActivate: [AuthGuardService] },
  { path: 'litterpreview', loadChildren: './risehusk-preview/risehusk-preview.module#RisehuskPreviewPageModule', canActivate: [AuthGuardService] },
  { path: 'add-farmer', loadChildren: './add-farmer/add-farmer.module#AddFarmerPageModule', canActivate: [AuthGuardService] },
  { path: 'addfarmer-supervisor', loadChildren: './addfarmer-supervisor/addfarmer-supervisor.module#AddfarmerSupervisorPageModule', canActivate: [AuthGuardService] },
  { path: 'vehicle-driver', loadChildren: './vehicle-driver/vehicle-driver.module#VehicleDriverPageModule', canActivate: [AuthGuardService] },
  { path: 'vehicle-driver/:vehicleId', loadChildren: './vehicle-driver/vehicle-driver.module#VehicleDriverPageModule', canActivate: [AuthGuardService] },
  { path: 'lease', loadChildren: './lease/lease/lease.module#LeasePageModule', canActivate: [AuthGuardService] },
  { path: 'lease-home', loadChildren: './lease/lease-home/lease-home.module#LeaseHomePageModule', canActivate: [AuthGuardService] },
  { path: 'RiceHuskreview', loadChildren: './ricehusk-review/ricehusk-review.module#RicehuskReviewPageModule', canActivate: [AuthGuardService] },
  { path: 'Coalreview', loadChildren: './ricehusk-review/ricehusk-review.module#RicehuskReviewPageModule', canActivate: [AuthGuardService] },
  { path: 'scrapbagsreview', loadChildren: './ricehusk-review/ricehusk-review.module#RicehuskReviewPageModule', canActivate: [AuthGuardService] },
  { path: 'Litterreview', loadChildren: './ricehusk-review/ricehusk-review.module#RicehuskReviewPageModule', canActivate: [AuthGuardService] },
  { path: 'lifting-vans', loadChildren: './lifting-vans/lifting-vans.module#LiftingVansPageModule', canActivate: [AuthGuardService] },
  { path: 'supervisor-farms-list/:farmId', loadChildren: './supervisor-farms-list/supervisor-farms-list.module#SupervisorFarmsListPageModule', canActivate: [AuthGuardService] },
  { path: 'lifting-supervisor', loadChildren: './lifting-supervisor/lifting-supervisor.module#LiftingSupervisorPageModule', canActivate: [AuthGuardService] },
  { path: 'scrapBagsreview2', loadChildren: './ricehusk-review2/ricehusk-review2.module#RicehuskReview2PageModule', canActivate: [AuthGuardService] },
  { path: 'scrapBagsreview2/:_id', loadChildren: './ricehusk-review2/ricehusk-review2.module#RicehuskReview2PageModule', canActivate: [AuthGuardService] },
  { path: 'RiceHuskreview2', loadChildren: './ricehusk-review2/ricehusk-review2.module#RicehuskReview2PageModule', canActivate: [AuthGuardService] },
  { path: 'RiceHuskreview2/:_id', loadChildren: './ricehusk-review2/ricehusk-review2.module#RicehuskReview2PageModule', canActivate: [AuthGuardService] },
  { path: 'Coalreview2', loadChildren: './ricehusk-review2/ricehusk-review2.module#RicehuskReview2PageModule', canActivate: [AuthGuardService] },
  { path: 'Coalreview2/:_id', loadChildren: './ricehusk-review2/ricehusk-review2.module#RicehuskReview2PageModule', canActivate: [AuthGuardService] },
  { path: 'Litterreview2', loadChildren: './ricehusk-review2/ricehusk-review2.module#RicehuskReview2PageModule', canActivate: [AuthGuardService] },
  { path: 'Litterreview2/:_id', loadChildren: './ricehusk-review2/ricehusk-review2.module#RicehuskReview2PageModule', canActivate: [AuthGuardService] },
  { path: 'supervisor-farms-list', loadChildren: './supervisor-farms-list/supervisor-farms-list.module#SupervisorFarmsListPageModule', canActivate: [AuthGuardService] },
  { path: 'van-details', loadChildren: './van-details/van-details.module#VanDetailsPageModule', canActivate: [AuthGuardService] },
  { path: 'dealer', loadChildren: './dealer/dealer.module#DealerPageModule', canActivate: [AuthGuardService] },
  { path: 'user-profile', loadChildren: './profile/user-profile/user-profile.module#UserProfilePageModule', canActivate: [AuthGuardService] },
  { path: 'edit-profile', loadChildren: './profile/edit-profile/edit-profile.module#EditProfilePageModule', canActivate: [AuthGuardService] },
  { path: 'user-edit-info', loadChildren: './profile/user-edit-info/user-edit-info.module#UserEditInfoPageModule', canActivate: [AuthGuardService] },
  { path: 'changepassword', loadChildren: './profile/changepassword/changepassword.module#ChangepasswordPageModule', canActivate: [AuthGuardService] },
  { path: 'about-us', loadChildren: './about-us/about-us.module#AboutUsPageModule', canActivate: [AuthGuardService] },
  { path: 'notifications', loadChildren: './notifications/notifications.module#NotificationsPageModule', canActivate: [AuthGuardService] },
  { path: 'products', loadChildren: './products/products.module#ProductsModule', canActivate: [AuthGuardService] },
  { path: 'products-list', loadChildren: './products/products-list/products-list.module#ProductsListPageModule', canActivate: [AuthGuardService] },
  { path: 'produc-notfications', loadChildren: './products/produc-notfications/produc-notfications.module#ProducNotficationsPageModule', canActivate: [AuthGuardService] },
  { path: 'add-new-card', loadChildren: './products/add-new-card/add-new-card.module#AddNewCardPageModule', canActivate: [AuthGuardService] },
  { path: 'ordered-list', loadChildren: './products/ordered-list/ordered-list.module#OrderedListPageModule', canActivate: [AuthGuardService] },
  { path: 'payment-cards-detail', loadChildren: './products/payment-cards-detail/payment-cards-detail.module#PaymentCardsDetailPageModule', canActivate: [AuthGuardService] },
  { path: 'payment', loadChildren: './products/payment/payment.module#PaymentPageModule', canActivate: [AuthGuardService] },
  { path: 'trader-message', loadChildren: './trader-message/trader-message.module#TraderMessagePageModule', canActivate: [AuthGuardService] },
  { path: 'trader-info', loadChildren: './trader-info/trader-info.module#TraderInfoPageModule', canActivate: [AuthGuardService] },
  { path: 'Dealer-review', loadChildren: './dealer-review/dealer-review.module#DealerReviewPageModule', canActivate: [AuthGuardService] },
  { path: 'liftings-farmer', loadChildren: './liftings-farmer/liftings-farmer.module#LiftingsFarmerPageModule', canActivate: [AuthGuardService] },
  { path: 'lifting-dcs', loadChildren: './lifting-dcs/lifting-dcs.module#LiftingDcsPageModule', canActivate: [AuthGuardService] },
  { path: 'market-indicators', loadChildren: './market-indicators/market-indicators.module#MarketIndicatorsPageModule', canActivate: [AuthGuardService] },
  { path: 'market-indicators-graph-view', loadChildren: './market-indicators-graph-view/market-indicators-graph-view.module#MarketIndicatorsGraphViewPageModule', canActivate: [AuthGuardService] },
  { path: 'trader', loadChildren: './trader/trader.module#TraderPageModule', canActivate: [AuthGuardService] },
  { path: 'trader-farm', loadChildren: './trader-farm/trader-farm.module#TraderFarmPageModule', canActivate: [AuthGuardService] },
  { path: 'homefarm-view', loadChildren: './homefarm-view/homefarm-view.module#HomefarmViewPageModule', canActivate: [AuthGuardService] },
  // new dev
  { path: 'trader-details', loadChildren: './trader-details/trader-details.module#TraderDetailsPageModule', canActivate: [AuthGuardService] },
  { path: 'chicken-center-add', loadChildren: './chicken-center-add/chicken-center-add.module#ChickenCenterAddPageModule', canActivate: [AuthGuardService] },
  { path: 'chicken-center', loadChildren: './chicken-center/chicken-center.module#ChickenCenterPageModule', canActivate: [AuthGuardService] },
  { path: 'pastorders', loadChildren: './pastorders/pastorders.module#PastordersPageModule', canActivate: [AuthGuardService] },
  { path: 'trader-add-van', loadChildren: './trader-add-van/trader-add-van.module#TraderAddVanPageModule', canActivate: [AuthGuardService] },
  { path: 'create-order', loadChildren: './create-order/create-order.module#CreateOrderPageModule', canActivate: [AuthGuardService] },
  { path: 'chicken-center-home', loadChildren: './chicken-center-home/chicken-center-home.module#ChickenCenterHomePageModule', canActivate: [AuthGuardService] },
  { path: 'trader-chicken-center-show', loadChildren: './trader-chicken-center-show/trader-chicken-center-show.module#TraderChickenCenterShowPageModule', canActivate: [AuthGuardService] },
  { path: 'trader-van-show', loadChildren: './trader-van-show/trader-van-show.module#TraderVanShowPageModule', canActivate: [AuthGuardService] },
  { path: 'trader-settings', loadChildren: './trader-settings/trader-settings.module#TraderSettingsPageModule', canActivate: [AuthGuardService] },

  { path: 'shownotifications', loadChildren: './shownotifications/shownotifications.module#ShownotificationsPageModule', canActivate: [AuthGuardService] },
  { path: 'trader-van-home', loadChildren: './trader-van-home/trader-van-home.module#TraderVanHomePageModule' },
  { path: 'vanHistory/:id', loadChildren: './trader-van-home/van-history/van-history.module#VanHistoryPageModule', canActivate: [AuthGuardService] },
  { path: 'google-maps-show', loadChildren: './google-maps-show/google-maps-show.module#GoogleMapsShowPageModule', canActivate: [AuthGuardService] },
  { path: 'google-maps-show/:_id', loadChildren: './google-maps-show/google-maps-show.module#GoogleMapsShowPageModule', canActivate: [AuthGuardService] },
  { path: 'chicksupplier', loadChildren: './ricehusk/ricehusk.module#RicehuskPageModule', canActivate: [AuthGuardService] },
  { path: 'feedsupplier', loadChildren: './ricehusk/ricehusk.module#RicehuskPageModule', canActivate: [AuthGuardService] },
  { path: 'chicksupplierpreview', loadChildren: './risehusk-preview/risehusk-preview.module#RisehuskPreviewPageModule', canActivate: [AuthGuardService] },
  { path: 'feedsupplierpreview', loadChildren: './risehusk-preview/risehusk-preview.module#RisehuskPreviewPageModule', canActivate: [AuthGuardService] },
  { path: 'chicksupplierkreview', loadChildren: './ricehusk-review/ricehusk-review.module#RicehuskReviewPageModule', canActivate: [AuthGuardService] },
  { path: 'chicksupplierreview', loadChildren: './ricehusk-review/ricehusk-review.module#RicehuskReviewPageModule', canActivate: [AuthGuardService] },
  { path: 'feedsupplierreview', loadChildren: './ricehusk-review/ricehusk-review.module#RicehuskReviewPageModule', canActivate: [AuthGuardService] },
  { path: 'feedsupplierreview2', loadChildren: './ricehusk-review2/ricehusk-review2.module#RicehuskReview2PageModule', canActivate: [AuthGuardService] },
  { path: 'chicksupplierreview2', loadChildren: './ricehusk-review2/ricehusk-review2.module#RicehuskReview2PageModule', canActivate: [AuthGuardService] },
  { path: 'chicksupplierreview2/:_id', loadChildren: './ricehusk-review2/ricehusk-review2.module#RicehuskReview2PageModule', canActivate: [AuthGuardService] },
  { path: 'feedsupplierreview2/:_id', loadChildren: './ricehusk-review2/ricehusk-review2.module#RicehuskReview2PageModule', canActivate: [AuthGuardService] },
  { path: 'google-maps-show', loadChildren: './google-maps-show/google-maps-show.module#GoogleMapsShowPageModule' },
  { path: 'traderRatings', loadChildren: './trader-ratings/trader-ratings.module#TraderRatingsPageModule', canActivate: [AuthGuardService] },
  { path: 'supervisor', loadChildren: './supervisor-home/supervisor-home.module#SupervisorHomePageModule' },
  { path: 'layersinventorycreate', loadChildren: './layers-inventory/layers-inventory-create/layers-inventory-create.module#LayersInventoryCreatePageModule', canActivate: [AuthGuardService] },
  { path: 'farmerdetails', loadChildren: './superviso-farmer-details/superviso-farmer-details.module#SupervisoFarmerDetailsPageModule', canActivate: [AuthGuardService] },
  { path: 'farmdetail', loadChildren: './supervisorfarmdetail/supervisorfarmdetail.module#SupervisorfarmdetailPageModule', canActivate: [AuthGuardService] },
  {
    path: 'statisticks', canActivate: [AuthGuardService],
    loadChildren: () => import('./statisticks-folder/statisticks/statisticks.module').then(m => m.StatisticksPageModule)
  },
  {
    path: 'expensives', canActivate: [AuthGuardService],
    loadChildren: () => import('./statisticks-folder/expensives/expensives.module').then(m => m.ExpensivesPageModule)
  },
  {
    path: 'update-expensives', canActivate: [AuthGuardService],
    loadChildren: () => import('./statisticks-folder/update-expensives/update-expensives.module').then(m => m.UpdateExpensivesPageModule)
  },
  {
    path: 'supervisor-expenses-list', canActivate: [AuthGuardService],
    loadChildren: () => import('./supervisor-expenses-list/supervisor-expenses-list.module').then(m => m.SupervisorExpensesListPageModule)
  },
  {
    path: 'layermanualorder', canActivate: [AuthGuardService],
    loadChildren: () => import('./layermanualorder/layermanualorder.module').then(m => m.LayermanualorderPageModule)
  },
  {
    path: 'layersdailyinventory', canActivate: [AuthGuardService],
    loadChildren: () => import('./layers-inventory/layers-daily-inventory-view/layers-daily-inventory-view.module').then(m => m.LayersDailyInventoryViewPageModule)
  },
  {
    path: 'layersdailyinventory/:id', canActivate: [AuthGuardService],
    loadChildren: () => import('./layers-inventory/layers-daily-inventory-view/layers-daily-inventory-view.module').then(m => m.LayersDailyInventoryViewPageModule)
  },
  {
    path: 'layersdayUpdate', canActivate: [AuthGuardService],
    loadChildren: () => import('./layers-inventory/layers-inventory-update-and-edit/layers-inventory-update-and-edit.module').then(m => m.LayersInventoryUpdateAndEditPageModule)
  },
  {
    path: 'layersinventorysummary', canActivate: [AuthGuardService],
    loadChildren: () => import('./layers-inventory/layers-inventory-summary/layers-inventory-summary.module').then(m => m.LayersInventorySummaryPageModule)
  },
  {
    path: 'layers-inventory-detail', canActivate: [AuthGuardService],
    loadChildren: () => import('./layers-inventory/layers-inventory-detail/layers-inventory-detail.module').then(m => m.LayersInventoryDetailPageModule)
  },
  {
    path: 'layers-sales', canActivate: [AuthGuardService],
    loadChildren: () => import('./layers-sales/layers-sales.module').then(m => m.LayersSalesPageModule)
  },
  {
    path: 'layerssalesdetails', canActivate: [AuthGuardService],
    loadChildren: () => import('./layers-sales-details/layers-sales-details.module').then(m => m.LayersSalesDetailsPageModule)
  },
  {
    path: 'layerssalesadd', canActivate: [AuthGuardService],
    loadChildren: () => import('./layers-sales-add/layers-sales-add.module').then(m => m.LayersSalesAddPageModule)
  },
  {
    path: 'language',
    loadChildren: () => import('./language/language.module').then(m => m.LanguagePageModule)
  },
  {
    path: 'layer-price-setting', canActivate: [AuthGuardService],
    loadChildren: () => import('./layers-inventory/layer-price-setting/layer-price-setting.module').then(m => m.LayerPriceSettingPageModule)
  },
  {
    path: 'layserPriceSetting', canActivate: [AuthGuardService],
    loadChildren: () => import('./layers-inventory/sale-price-setting/sale-price-setting.module').then(m => m.SalePriceSettingPageModule)
  },
  {
    path: 'creatfarm', canActivate: [AuthGuardService],
    loadChildren: () => import('./layer/creat-farm/creat-farm.module').then(m => m.CreatFarmPageModule)
  },
  {
    path: 'wholeseller', canActivate: [AuthGuardService],
    loadChildren: () => import('./layer-wholeSeller/whole-seller-home.module').then(m => m.WholeSellerHomePageModule)
  },
  {
    path: 'roledashboard',
    loadChildren: () => import('./roledashboard/roledashboard.module').then(m => m.RoledashboardPageModule)
  },
  {
    path: 'accountsettings',
    loadChildren: () => import('./account-settings/account-settings.module').then(m => m.AccountSettingsPageModule)
  },



  // {
  //   path: 'layerfarmer',
  //   loadChildren: () => import('./layer-farmer/layer-farmer.module').then( m => m.LayerFarmerPageModule)
  // },
  // {
  //   path: "layerfarmer",
  //   loadChildren: () => import('./layer-farmer/layer-farmer.module').then(m => m.LayerFarmerPageModule)
  // }
  {
    path: 'layerfarmer',
    loadChildren: './layer-farmer/layer-farmer.module#LayerFarmerPageModule',
    canActivate: [AuthGuardService]
  },
  {
    path: 'retailer', canActivate: [AuthGuardService],
    loadChildren: () => import('./retail-supplier/retail-supplier.module').then(m => m.RetailSupplierPageModule)
  },

  {
    path: 'layer-seller', canActivate: [AuthGuardService],
    loadChildren: () => import('./layer-seller/layer-seller.module').then(m => m.LayerSellerPageModule)
  },
  {
    path: 'van-number', canActivate: [AuthGuardService],
    loadChildren: () => import('./popup/van-number/van-number.module').then(m => m.VanNumberPageModule)
  },
  {
    path: 'tracking',
    loadChildren: () => import('./tracking/tracking.module').then(m => m.TrackingPageModule)
  },
  {
    path: 'select-formula', canActivate: [AuthGuardService],
    loadChildren: () => import('./layers-inventory/select-formula/select-formula.module').then(m => m.SelectFormulaPageModule)
  },
  {
    path: 'layersupervisor', canActivate: [AuthGuardService],
    loadChildren: () => import('./layersupervisor/home/home.module').then(m => m.HomePageModule)

  },
  {
    path: 'layeradd-farmersupervisor', canActivate: [AuthGuardService],
    loadChildren: () => import('./layersupervisor/layeradd-farmersupervisor/layeradd-farmersupervisor.module').then(m => m.LayeraddFarmersupervisorPageModule)
  },
  {
    path: 'layer-add-farmer', canActivate: [AuthGuardService],
    loadChildren: () => import('./layersupervisor/layer-add-farmer/layer-add-farmer.module').then(m => m.LayerAddFarmerPageModule)
  },
  {
    path: 'layersupervisorfarmerdetails', canActivate: [AuthGuardService],
    loadChildren: () => import('./layersupervisor/layer-supervisor-details/layer-supervisor-details.module').then(m => m.LayerSupervisorDetailsPageModule)
  },
  {
    path: 'navigate',
    loadChildren: () => import('./navigation-maps/navigation-maps.module').then(m => m.NavigationMapsPageModule)
  },
  {
    path: 'mapnavigation',
    loadChildren: () => import('./mapnavigation/mapnavigation.module').then(m => m.MapnavigationPageModule)
  },
  {
    path: 'show-feed-over-view', canActivate: [AuthGuardService],
    loadChildren: () => import('./layers-inventory/show-feed-over-view/show-feed-over-view.module').then(m => m.ShowFeedOverViewPageModule)
  },
  {
    path: 'deviceconfig',
    loadChildren: () => import('./AutomaticSystem/device-configure/device-configure.module').then(m => m.DeviceConfigurePageModule)
  },
  {
    path: 'deviceotp',
    loadChildren: () => import('./AutomaticSystem/device-otp/device-otp.module').then(m => m.DeviceOtpPageModule)
  },
  {
    path: 'autosystemhome',
    loadChildren: () => import('./AutomaticSystem/autosystemhome/autosystemhome.module').then(m => m.AutosystemhomePageModule)
  },
  {
    path: 'devicesdashboard',
    loadChildren: () => import('./AutomaticSystem/devices-dashboard/devices-dashboard.module').then(m => m.DevicesDashboardPageModule)
  },
  // {
  //   path: 'alarm-modal',
  //   loadChildren: () => import('./AutomaticSystem/alarm-modal/alarm-modal.module').then( m => m.AlarmModalPageModule)
  // },
  {
    path: 'inventory-update', canActivate: [AuthGuardService],
    loadChildren: () => import('./inventory-update/inventory-update.module').then(m => m.InventoryUpdatePageModule)
  },
  {
    path: 'supervisortabs',
    loadChildren: () => import('./superviso-farmer-details/supervisortabs/supervisortabs.module').then(m => m.SupervisortabsPageModule)
  },
  {
    path: 'layerfarmerfarms', canActivate: [AuthGuardService],
    loadChildren: () => import('./layersupervisor/layerfarmerfarms/layerfarmerfarms.module').then(m => m.LayerfarmerfarmsPageModule)
  },
  {
    path: 'inventory-detail', canActivate: [AuthGuardService],
    loadChildren: () => import('./inventory-detail/inventory-detail.module').then(m => m.InventoryDetailPageModule)
  },
  {
    path: 'farmerolddata',
    loadChildren: () => import('./farmer-past-data/farmer-past-data.module').then(m => m.FarmerPastDataPageModule)
  },
  {
    path: 'past-inventory-detail-view',
    loadChildren: () => import('./farmer-past-data/past-inventory-detail-view/past-inventory-detail-view.module').then(m => m.PastInventoryDetailViewPageModule)
  },
  {
    path: 'past-detail-expenses', canActivate: [AuthGuardService],
    loadChildren: () => import('./farmer-past-data/past-detail-expenses/past-detail-expenses.module').then(m => m.PastDetailExpensesPageModule)
  },
  {
    path: 'past-detail-lifting-data', canActivate: [AuthGuardService],
    loadChildren: () => import('./farmer-past-data/past-detail-lifting-data/past-detail-lifting-data.module').then(m => m.PastDetailLiftingDataPageModule)
  },
  {
    path: 'pastinventoryDetailOverallData', canActivate: [AuthGuardService],
    loadChildren: () => import('./farmer-past-data/inventoy-overall-data/inventoy-overall-data.module').then(m => m.InventoyOverallDataPageModule)
  },
  {
    path: 'vechilePastOrderBox', canActivate: [AuthGuardService],
    loadChildren: () => import('./farmer-past-data/lifting-driver-boxes-data/lifting-driver-boxes-data.module').then(m => m.LiftingDriverBoxesDataPageModule)
  },
  {
    path: 'pastInventory',
    loadChildren: () => import('./farmer-past-data/past-inventories-list/past-inventories-list.module').then(m => m.PastInventoriesListPageModule)
  },
  {
    path: 'vancontactadd', canActivate: [AuthGuardService],
    loadChildren: () => import('./trader/van-contact-addd/van-contact-addd.module').then(m => m.VanContactAdddPageModule)
  },
  {
    path: 'linked-contacts', canActivate: [AuthGuardService],
    loadChildren: () => import('./linked-contacts/linked-contacts.module').then(m => m.LinkedContactsPageModule)
  },
  {
    path: 'add-box', canActivate: [AuthGuardService],
    loadChildren: () => import('./select-box-view/add-box/add-box.module').then(m => m.AddBoxPageModule)
  },
  {
    path: 'contact-not-add', canActivate: [AuthGuardService],
    loadChildren: () => import('./emptycard/contact-not-add/contact-not-add.module').then(m => m.ContactNotAddPageModule)
  },

  {
    path: 'boxesView', canActivate: [AuthGuardService],
    loadChildren: () => import('./select-box-view/select-box-view.module').then(m => m.SelectBoxViewPageModule)
  },
  {
    path: 'trader-as-contact', canActivate: [AuthGuardService],
    loadChildren: () => import('./trader-as-contact/trader-as-contact.module').then(m => m.TraderAsContactPageModule)
  },
  {
    path: 'notifications-new', canActivate: [AuthGuardService],
    loadChildren: () => import('./notifications-new/notifications-new.module').then(m => m.NotificationsNewPageModule)
  },
  {
    path: 'order-type-select', canActivate: [AuthGuardService],
    loadChildren: () => import('./trader-order-show/order-type-select/order-type-select.module').then(m => m.OrderTypeSelectPageModule)
  },
  {
    path: 'order-process-select-van', canActivate: [AuthGuardService],
    loadChildren: () => import('./trader-order-show/order-process-select-van/order-process-select-van.module').then(m => m.OrderProcessSelectVanPageModule)
  },
  {
    path: 'order-process-select-farmer', canActivate: [AuthGuardService],
    loadChildren: () => import('./trader-order-show/order-process-select-farmer/order-process-select-farmer.module').then(m => m.OrderProcessSelectFarmerPageModule)
  },
  {
    path: 'order-process-select-shop', canActivate: [AuthGuardService],
    loadChildren: () => import('./trader-order-show/order-process-select-shop/order-process-select-shop.module').then(m => m.OrderProcessSelectShopPageModule)
  },
  {
    path: 'order-process-common-details', canActivate: [AuthGuardService],
    loadChildren: () => import('./trader-order-show/order-process-common-details/order-process-common-details.module').then(m => m.OrderProcessCommonDetailsPageModule)
  },
  {
    path: 'temp-farmer-add', canActivate: [AuthGuardService],
    loadChildren: () => import('./trader-order-show/temp-farmer-add/temp-farmer-add.module').then(m => m.TempFarmerAddPageModule)
  },
  {
    path: 'chickenshopDetailed', canActivate: [AuthGuardService],
    loadChildren: () => import('./trader/centers/chickenshop-detailed/chickenshop-detailed.module').then(m => m.ChickenshopDetailedPageModule)
  },
  {
    path: 'order-summary', canActivate: [AuthGuardService],
    loadChildren: () => import('./trader-order-show/order-summary/order-summary.module').then(m => m.OrderSummaryPageModule)
  },
  {
    path: 'trader-list', canActivate: [AuthGuardService],
    loadChildren: () => import('./add-vehicle/trader-list/trader-list.module').then(m => m.TraderListPageModule)
  },
  {
    path: 'purchase-detail-show', canActivate: [AuthGuardService],
    loadChildren: () => import('./trader-order-show/purchase-detail-show/purchase-detail-show.module').then(m => m.PurchaseDetailShowPageModule)
  },
  {
    path: 'unassigned-sales',
    loadChildren: () => import('./trader/unassigned-sales/unassigned-sales.module').then(m => m.UnassignedSalesPageModule)
  },

  { path: 'trader-order-show', loadChildren: './trader-order-show/trader-order-show.module#TraderOrderShowPageModule', canActivate: [AuthGuardService] },
  {
    path: 'over-view-farms',
    loadChildren: () => import('./EC-Farm-Automate/overview-farmsList/over-view-farms.module').then( m => m.OverViewFarmsPageModule)
  },  {
    path: 'ec-farm-details',
    loadChildren: () => import('./EC-Farm-Automate/ec-farm-details/ec-farm-details.module').then( m => m.EcFarmDetailsPageModule)
  },
  {
    path: 'ec-farm-create',
    loadChildren: () => import('./EC-Farm-Automate/ec-farm-create/ec-farm-create.module').then( m => m.EcFarmCreatePageModule)
  },
  {
    path: 'ec-farm-graph-view',
    loadChildren: () => import('./EC-Farm-Automate/ec-farm-graph-view/ec-farm-graph-view.module').then( m => m.EcFarmGraphViewPageModule)
  },


  // { path: 'traderSupervisor', loadChildren: './trader-order-show/trader-order-show.module#TraderOrderShowPageModule', canActivate: [AuthGuardService] },
  // { path: 'traderSupervisor', loadChildren: './trader/trader.module#TraderPageModule', canActivate: [AuthGuardService] },

]
@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule],

})

export class AppRoutingModule { }