import { Directive, HostListener } from '@angular/core';
import { Location } from '@angular/common';

@Directive({
  selector: '[backFlip]'
})

export class BackFlipDirective {
  @HostListener('click') onClick() {
    this.loc.back();
  }

  constructor(public loc: Location) { }

}
