import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'priceByAsc'
})
export class PriceByAscPipe implements PipeTransform {

  transform(value: any[], args?: any): any {
    value.sort((a, b) => {
      return a.price - b.price ;
    });
  }

  }

