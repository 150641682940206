import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { config } from '../app/helper/config';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AddDriverService {
  url = config.api + 'liftingvans';

  public driverNotification = new BehaviorSubject<any>([]);
  public driverNotificationObserv$ = this.driverNotification.asObservable();



  constructor(public http: HttpClient, ) {
  }

  add_driver(data): Observable<any> {
    console.log(data, 16)
    return this.http.post(this.url + '/insertFarm', data);
  }

  update_driver(data): Observable<any> {
    console.log(data, 16)
    return this.http.post(this.url + '/updateFarm', data);
  }

  driver_getData(data): Observable<any> {
    console.log(data, 72)
    return this.http.post(this.url + '/farmsList', data);
  }

  driver_getData_id(data): Observable<any> {
    console.log(data, 72)
    return this.http.post(this.url + '/farmsList/id', data);
  }

  driverNotifications(senderData): Observable<any> {
    this.setDriverNotifications(senderData);
    return this.http.post(config.api + 'driver/driverNotifications', senderData).pipe(map((res) => {
      this.driverNotification.next(res);
      return res;
    }))

  }

  clearDriverNotifications(senderData): Observable<any> {
    return this.http.post(config.api + 'trader/removeVanNotification', senderData)
  }
  setDriverNotifications(data) {
    this.driverNotification.next(data);
  }
  driverConformationUpdate(data): Observable<any> {
    return this.http.post(config.api + "driver/driverUpdateStatus", data)
  }
}
