import { Component, OnInit } from '@angular/core';
import { ChickenService } from '../chicken.service';
import { PopoverController } from '@ionic/angular';
@Component({
  selector: 'app-chicken-popover',
  templateUrl: './chicken-popover.component.html',
  styleUrls: ['./chicken-popover.component.scss'],
})
export class ChickenPopoverComponent implements OnInit {
  items = ['pricelowtohigh', 'pricehightolow', 'name', 'available', 'distance']
  sortingData: any;
  lat: number;
  lng: number;
  locationdata: number[];
  activeSort: string = "Distance";
  constructor(private ChickenService: ChickenService, private popctrl: PopoverController) { }

  ngOnInit() {
    localStorage.setItem('sortOption', 'Price: Low To High')
    this.sortingData = this.ChickenService.getLocalSavedTraderList();
    this.activeSort = localStorage.getItem('activeSort');
  }
  onsort(val) {
    this.activeSort = val;
    localStorage.setItem('activeSort', val)

    this.ChickenService.getTradersList({ skip: 0, sort: val }).subscribe(
      (data: any) => {
        if (data.success) {
          this.popctrl.dismiss({
            newdata: data.data
          })
        }
      }
    )
  }
}
