import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(value: any, terms?: any): any {
    console.log(value,9);
    console.log(terms,10);
    if(!value) return [];
    if(!terms) return value;
     terms= terms.toLowerCase();
      return value.filter( it => {
      return it.name.toLowerCase().includes(terms);
    });
  }

}
