import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController, NavParams, ToastController, Platform, LoadingController, AlertController } from '@ionic/angular';
import { LiftingsService } from "src/services/liftings.service";
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { isObject } from 'util';
import { OrderLiftingvanComponent } from '../trader/order-liftingvan/order-liftingvan.component';
import { VanContactAdddPage } from '../trader/van-contact-addd/van-contact-addd.page';
import { FarmerService } from '../services/farmer-service.service';
import { SupervisorService } from 'src/services/supervisor.service';



@Component({
  selector: 'app-add-vehicle',
  templateUrl: './add-vehicle.page.html',
  styleUrls: ['./add-vehicle.page.scss'],
})

export class AddVehiclePage implements OnInit {
  newTrader: boolean = false;
  intialState: boolean = true;
  currencySymbol: string = localStorage.getItem('currencySymbol');
  countryCallingCode: string = localStorage.getItem('countryCallingCode');
  units: string = localStorage.getItem('solidUnits');
  userRole = localStorage.getItem('role_id');
  traderExists = false;
  date = new Date().toISOString();
  default;
  showNumber: boolean = true;
  @Input() firstName: string;
  @Input() lastName: string;
  @Input() middleInitial: string;
  traderData = {};
  selecteTraderVehicle = {};
  AddForm: FormGroup;
  mytime = '';
  inventoryId: any;
  typeDoc: any;
  validate_message = {
    vehicle_number: [
      { type: "required", message: "vehicleNumber" },
      { type: 'maxlength', message: 'vehicleNumberMax' },
    ],
    driver_number: [
      { type: 'required', message: 'phnNoRequired' },
    ],
    traderNumber: [
      { type: 'required', message: 'phnNoRequired' },
      { type: 'pattern', message: 'leasePhnPattern' },
    ],
    booking_date: [
      { type: "required", message: "bookingDate" }
    ],
    DriverName: [
      { type: "required", message: "nameRequired" },
      { type: 'pattern', message: 'nameWithNUMError' },
    ],
    price: [
      { type: "required", message: "priceRequired" }
    ],
    traderName: [
      { type: "required", message: "fieldRequired" }
    ],
    payment: [
      { type: "required", message: "fieldRequired" }
    ],
    totalBirds: [
      { type: "required", message: "fieldRequired" }
    ],
    birdsWeight: [
      { type: "required", message: "fieldRequired" }
    ],
    van_type: []
  }
  vanInfo: any;
  selectedInventory: any;
  invenPrice: any
  model: any;
  payInfo;
  averageWt: any;
  generalEdit: any;
  amount: string;
  inventoryName: string = '';
  validationType: string = 'phone';
  listOpen: boolean = false;
  traderContacts: any;
  constructor(
    public formbuilder: FormBuilder,
    protected modalCtrl: ModalController,
    private toastController: ToastController,
    protected navParams: NavParams,
    private liftings: LiftingsService,
    private router: Router,
    private Platform: Platform,
    private translate: TranslateService,
    protected loadingCtrl: LoadingController,
    private farmerservice: FarmerService,
    private alert: AlertController,
    private supervisor: SupervisorService
  ) {
    this.vanInfo = this.navParams.get('vehicleInfo');
    this.inventoryId = this.navParams.get("inventory_id");
    this.inventoryName = this.navParams.get("inventoryName");
    this.model = this.navParams.get("res");
    this.vanInfo = this.navParams.get('vehicleInfo');
    this.generalEdit = this.navParams.get('generalEdit');
    let matchInv = this.inventoryId || this.vanInfo?.inventory_id;
    this.selectedInventory = this.liftings.getSelectedInventoryData();
    if (this.selectedInventory) {
      if (this.selectedInventory.inventory_id == matchInv) {
        this.invenPrice = this.selectedInventory.price || '--';
      } else {
        console.log("selectedInventory id not matched");
      }
    } else {
      console.log("selectedInventory data not available");
    }
  }

  farmerdata;
  ngOnInit() {
    if (localStorage.getItem("role_id") == "3") {
      this.farmerdata = this.supervisor.getFarmerDetails();
    }
    console.log(this.model, 117);
    if (this.model == "general" || this.model == "editGeneral") {
      this.AddForm = this.formbuilder.group({
        van_type: [""],
        booking_date: [],
        traderName: [],
        price: [0],
        payment: ["notPaid"],
        totalBirds: [0],
        birdsWeight: [0],
        avgWeight: [0],
        traderNumber: ["", Validators.compose([Validators.required, Validators.pattern('^[1-9][0-9]{9}')])],
      });
      if (this.generalEdit) {
        if (!this.generalEdit.traderNumber) {
          this.AddForm.removeControl("traderNumber");
          this.showNumber = false;
        }
      }
    } else {
      this.AddForm = this.formbuilder.group({
        traderNumber: ["", Validators.compose([Validators.required, Validators.pattern('^[1-9][0-9]{9}')])],
        van_type: [""],
        vehicle_number: [""],
        driver_number: [""],
        booking_date: [""],
        DriverName: [""],
        in_time: [""],
        out_time: [""],
        price: [0],
        payment: ["notPaid"],
        traderName: [""],
      });
      if (this.vanInfo) {
        if (!this.vanInfo.traderNumber) {
          this.AddForm.removeControl("traderNumber")
          this.showNumber = false;
        }
      }
      // this.intialState = false;
    }
    console.log(this.vanInfo, "edit ");

    if (isObject(this.vanInfo)) {
      //if trader not available please create new empty form
      this.AddForm.get('traderName').setValidators([Validators.required]);
      this.AddForm.get('van_type').setValidators([Validators.required]);
      this.AddForm.get('vehicle_number').setValidators([Validators.required]);
      this.AddForm.get('driver_number').setValidators([]);
      this.AddForm.get('booking_date').setValidators([Validators.required]);
      this.AddForm.get('DriverName').setValidators([]);
      this.AddForm.get('price').setValidators([]);
      this.AddForm.get('payment').setValidators([Validators.required]);
      this.AddForm.get('traderName').setValidators([Validators.required]);
      if (this.vanInfo.traderNumber) {
        this.AddForm.controls['traderNumber'].setValue(this.vanInfo.traderNumber);
      }
      this.AddForm.controls['vehicle_number'].setValue(this.vanInfo.vehicle_number);
      this.AddForm.controls['driver_number'].setValue(this.vanInfo.driver_number);
      this.date = this.vanInfo.booking_date;
      this.AddForm.controls['booking_date'].setValue(this.vanInfo.booking_date);
      this.AddForm.controls['out_time'].setValue(this.vanInfo.out_time);
      this.AddForm.controls['DriverName'].setValue(this.vanInfo.DriverName || this.vanInfo.driver_name);
      this.AddForm.controls['in_time'].setValue(this.vanInfo.in_time);
      this.AddForm.controls['price'].setValue(this.vanInfo.price || 0);
      this.AddForm.controls['traderName'].setValue(this.vanInfo.traderName);
      this.AddForm.controls['payment'].setValue(this.vanInfo.payment);
      this.intialState = false;
    } else if (this.model == "editGeneral") {
      console.log("edit general");

      this.AddForm.get('booking_date').setValidators([Validators.required]);
      this.AddForm.get('price').setValidators([]);
      this.AddForm.get('payment').setValidators([Validators.required]);
      this.AddForm.get('traderName').setValidators([Validators.required]);
      this.AddForm.controls['totalBirds'].setValue(this.generalEdit.total_birds);
      this.AddForm.controls['birdsWeight'].setValue(this.generalEdit.total_weight);
      if (this.generalEdit.traderNumber) {
        this.AddForm.controls['traderNumber'].setValue(this.generalEdit.traderNumber);
      }
      this.AddForm.controls['van_type'].setValue("general");
      this.AddForm.controls['booking_date'].setValue(this.generalEdit.booking_date);
      this.date = this.generalEdit.booking_date;
      this.AddForm.controls['price'].setValue(this.generalEdit.sellingprice);
      this.AddForm.controls['traderName'].setValue(this.generalEdit.traderName);
      this.AddForm.controls['payment'].setValue(this.generalEdit.payment);
      this.AddForm.controls['totalBirds'].setValue(this.generalEdit.total_birds);
      this.AddForm.controls['birdsWeight'].setValue(this.generalEdit.total_weight);
      this.intialState = false;
      this.weightCheck();
      this.amountCheck();
    }

    if (this.navParams.data['preSelected'] && this.navParams.data['preSelected'] != "") {
      this.AddForm.controls['traderNumber'].setValue(this.navParams.data['preSelected']);
      this.findTraderUser(true)
    }
  }

  ionViewWillEnter() {
    this.default = localStorage.getItem('lngCode');
  }

  editSubmit() {
    Object.assign(this.AddForm.value, {
      lifting_id: this.generalEdit.lifting_id,
      averageWt: this.averageWt,
      van_id: this.generalEdit.van_id,
      orderNew: this.generalEdit.orderNew,
      _id: this.generalEdit._id
    });
    this.liftings.generalEdit(this.AddForm.value).subscribe((result) => {
      console.log(result);
    })
    this.modalCtrl.dismiss();
  }

  weightCheck() {
    this.averageWt = (this.AddForm.get("birdsWeight").value / this.AddForm.get("totalBirds").value).toFixed(2)
  }

  amountCheck() {
    if (this.model == "general" || this.model == "editGeneral") {
      this.amount = (this.AddForm.get("birdsWeight").value * this.AddForm.get("price").value).toFixed(2);
    } else { }
  }

  async closeModal(addVan: boolean) {
    this.AddForm.controls['van_type'].setValue(this.model);

    if (addVan) {
      if (this.AddForm.valid) {
        if (this.vanInfo) {
          let loading = await this.loadingCtrl.create({
            message: this.translate.instant('common.pleaseWait')
          });
          loading.present();
          let data = Object.assign(this.AddForm.value, {
            vehicleId: this.vanInfo.vehicleId
          })
          this.liftings.editVanDetails({ data: data }).subscribe(data => {
            loading.dismiss();
            if (data.success) {
              this.modalCtrl.dismiss({
                addVan: addVan,
                formData: this.AddForm.value
              });
            }
          }, err => {
            loading.dismiss();
          });
        } else {
          Object.assign(this.AddForm.value, {
            traderExists: this.traderExists,
            traderuId: this.traderData['u_id'],
            traderLocation: this.traderData['location'],
            traderAddress: this.traderData['address'],
            inventoryId: this.inventoryId,
            inventory_id: this.inventoryId,
            inventoryName: this.inventoryName,
            traderId: this.traderData['traderId'],
            vanId: this.selecteTraderVehicle['_id'],
            createdFor: this.selecteTraderVehicle['createdFor'],
            vanCapacity: this.selecteTraderVehicle['capacity']
          });
          console.log(this.AddForm.value);
          this.modalCtrl.dismiss({
            addVan: addVan,
            formData: this.AddForm.value
          });
        }
      } else {
        this.modalCtrl.dismiss({
          addVan: addVan,
          formData: this.AddForm.value
        });
      }
    } else {
      this.modalCtrl.dismiss({
        addVan: addVan,
      });
    }
  }

  dateCheck(e) {
    if (this.model == "editGeneral" || this.model == "edit") {

    } else {
      new Date(new Date(e).setHours(0, 0, 0, 0)) < new Date(new Date().setHours(0, 0, 0, 0)) ?
        [this.AddForm.controls['booking_date'].setValue(''),
        this.showError(this.translate.instant('flashMessages.youCantChoosePastDates'))] : ''
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  async showError(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 3000,
      position: 'bottom', buttons: [
        {
          text: this.translate.instant('buttons.done'),
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        }
      ],
      animated: true,
      cssClass: "my-custom-class",
    });
    toast.present();
  }

  selectSearchResult(item) {
    if (this.validationType != 'phone') {
      this.AddForm.get('traderNumber').setValue(item.phone);
      this.listOpen = false;
    } else {
      this.AddForm.get('traderNumber').setValue(item.phone);
    }
  }

  addedTraders;
  showList = true;
  async traderList() {
    let inputs = [];
    this.farmerservice.farmerTraderContacts({ skip: 0, contactType: "trader-farmer" }).subscribe((res) => {
      this.addedTraders = res.data;
      console.log(this.addedTraders);
      for (let i = 0; i < this.addedTraders.length; i++) {
        console.log(this.addedTraders[i]['name'])
        let name = this.addedTraders[i]['name'];
        let phone = this.addedTraders[i]['phone'];
        let label = name + " " + (phone);
        console.log(label);
        inputs.push(
          {
            name: name,
            type: 'radio',
            label: label,
            value: phone,
          },
        )
      }
      console.log(inputs);
      this.alertShow(inputs)
    })
  }

  noTrader = false;
  async alertShow(input) {
    const alert = await this.alert.create({
      inputs: input,
      header: "Traders List",
      cssClass: 'wholeSellerAlert',
      buttons: [
        {
          text: this.translate.instant('buttons.cancel'),
          role: 'cancel',
          handler: (blah) => {
            console.log('you cont choose any account');
            this.showList = true;
          }
        },
        {
          text: this.translate.instant('buttons.ok'),
          handler: (result) => {
            this.AddForm.get("traderNumber").setValue(result);
            this.showList = false;
            for (let i = 0; i < this.addedTraders.length; i++) {
              if (this.addedTraders[i]['phone'] == result) {
                if (this.addedTraders[i]["poultryAccount"] == false) {
                  console.log("true");
                  this.noTrader = true;
                } else {
                  this.noTrader = false;
                }
              }
            }
          }
        }
      ]
    });
    await alert.present();
  }

  findTraderUser(event) {
    this.listOpen = false;
    if (this.addedTraders && this.addedTraders.length) {
      this.AddForm.addControl("orderStatus", new FormControl("inventoryassigned"))
    } else {
      this.AddForm.addControl("orderStatus", new FormControl("waiting"))
    }
    let checkData;
    if (this.validationType != 'phone') {
      checkData = { email: this.AddForm.get('traderNumber').value, contact: 'email' }
    } else {
      checkData = { phone: this.AddForm.get('traderNumber').value, contact: 'phone' }
    }
    if (localStorage.getItem("role_id") == "3") {
      checkData['farmer_uid'] = this.farmerdata['u_id']
    }
    this.liftings.findTraderUser(checkData).subscribe((data) => {
      if (data.success) {
        console.log(data.user)
        if (data.user[0].tempUserCreatedAt) {
          console.log("inside temp user");
          this.noTrader = true;
        } else {
          this.noTrader = false;
        }
        this.intialState = false;
        if (data.data.length > 0) {
          this.traderData = data.data[0];
          if (this.model != 'general' && this.model != 'editGeneral') {
            // this.selectVan(data.data[0]['contactsList'], data.data[0]);
          } else {
            if (data.data[0]['name'] != null) {
              this.traderExists = true;
            }
            else {
              this.traderExists = true;
            }
            this.AddForm.controls['traderName'].setValue(data.data[0]['name']);
          }
          if (this.model == "boxmodel") {
            this.AddForm.get('traderName').setValidators([Validators.required]);
            this.AddForm.get('van_type').setValidators([Validators.required]);
            this.AddForm.get('vehicle_number').setValidators([Validators.required]);
            this.AddForm.get('driver_number').setValidators([]);
            this.AddForm.get('booking_date').setValidators([Validators.required]);
            this.AddForm.get('DriverName').setValidators([]);
            this.AddForm.get('price').setValidators([]);
            this.AddForm.get('payment').setValidators([Validators.required]);
            this.AddForm.get('traderName').setValidators([Validators.required]);
          }
          else {
            this.AddForm.get('booking_date').setValidators([Validators.required]);
            this.AddForm.get('price').setValidators([]);
            this.AddForm.get('payment').setValidators([Validators.required]);
            this.AddForm.get('traderName').setValidators([Validators.required]);
          }
        } else {
          this.traderData = {};
          if (this.model == "boxmodel") {
            //if trader not available please create new empty form
            this.AddForm.get('traderName').setValidators([Validators.required]);
            this.AddForm.get('van_type').setValidators([Validators.required]);
            this.AddForm.get('vehicle_number').setValidators([Validators.required]);
            this.AddForm.get('driver_number').setValidators([]);
            this.AddForm.get('booking_date').setValidators([Validators.required]);
            this.AddForm.get('DriverName').setValidators([]);
            this.AddForm.get('price').setValidators([]);
            this.AddForm.get('payment').setValidators([Validators.required]);
            this.AddForm.get('traderName').setValidators([Validators.required]);
            this.intialState = false;

          } else {
            this.AddForm.get('booking_date').setValidators([Validators.required]);
            this.AddForm.get('price').setValidators([]);
            this.AddForm.get('payment').setValidators([Validators.required]);
            this.AddForm.get('traderName').setValidators([Validators.required]);
          }
          this.newTrader = true;
          this.traderData = data.tempUserData;
        }
      }
    });
  }

  selectVanInit() {
    this.traderData = {
      ...this.traderData,
      phone: this.AddForm.get('traderNumber').value,
      email: this.AddForm.get('traderNumber').value,
    };
    if (Object.keys(this.traderData).length > 0) {
      this.selectVan(this.traderData['contactsList'], this.traderData);
    } else {
      this.selectVan([], this.traderData);
    }

  }

  async selectVan(vansList, orginalOBJ) {
    this.modalCtrl.create({
      component: OrderLiftingvanComponent,
      componentProps: { data: vansList, selected: this.AddForm.value.vehicle_number, traderData: orginalOBJ },
    }).then(modal => {
      modal.present();
      modal.onDidDismiss().then((data: any) => {
        console.log(data, 425);
        if (data.data.reason) {
          if (data.data.reason != 'new') {
            if (data.data.reason != 'cancelled') {
              this.selecteTraderVehicle = data.data.data;
              this.traderExists = true;
              if (this.model != "general" && this.model != 'editGeneral') {
                this.AddForm.controls['vehicle_number'].setValue(data.data.data.vanNumber);
                this.AddForm.controls['traderName'].setValue(orginalOBJ.name);
                this.AddForm.controls['DriverName'].setValue("");
              } else {
                this.AddForm.controls['traderName'].setValue(orginalOBJ.name);
              }
            } else {
              console.log("cancelled", 439);
            }
          } else if (data.data.reason == "new") {
            this.modalCtrl.create({
              component: VanContactAdddPage,
              componentProps: { data: "fromVanSelect", vansList: vansList, traderData: orginalOBJ },
            }).then(modal => {
              modal.present();
              modal.onDidDismiss().then((data: any) => {
                if (data.data.status) {
                  if (data.data.status == "addedNew") {
                    this.traderExists = true;
                    if (this.traderData.hasOwnProperty('contactsList')) {
                      this.traderData['contactsList'].push(data.data.data);
                    } else {
                      this.traderData = { ...this.traderData, contactsList: [] };
                      this.traderData['contactsList'].push(data.data.data);
                    }
                  }
                } else {
                  console.log("no select van just close", 455);
                }
              });
            });
          } else {
            console.log("no van select", 454);
          }
        } else {
          console.log("no select van just close", 464);
        }
      });
    });
  }

  ionViewWillLeave() {
    this.traderExists = false;
  }

  createOrderTemp(addVan: boolean) {
    console.log(this.noTrader, "no trader");

    let orderData = {};
    if (this.validationType != 'phone') {
      orderData = {
        ...this.AddForm.value,
        totalBirds: this.AddForm.value.totalBirds || 0,
        email: this.AddForm.value.traderNumber,
        contact: 'email',
        traderId: this.traderData['traderId'],
        traderuId: this.traderData['uId']
      }
    } else {
      orderData = {
        ...this.AddForm.value,
        totalBirds: this.AddForm.value.totalBirds || 0,
        phone: this.AddForm.value.traderNumber,
        contact: 'phone',
        traderId: this.traderData['traderId'],
        traderuId: this.traderData['uId']
      }
    }
    if (this.model != "general") {
      orderData = {
        ...orderData,
        totalBirds: this.AddForm.value.totalBirds || 0,
        farmerUid: localStorage.getItem("u_id"),
        farmerUnits: localStorage.getItem("solidUnits"),
        farmerAddress: localStorage.getItem('address'),
        farmerLocation: {
          "type": "Point",
          "coordinates": [
            localStorage.getItem("lng"),
            localStorage.getItem("lat")
          ]
        },
        price: this.AddForm.get("price").value,
        noTrader: this.noTrader,
        vanModel: this.selecteTraderVehicle['vanModel'],
        van_type: this.selecteTraderVehicle['vanModel'],
        vanNumber: this.selecteTraderVehicle['vanNumber'],
        orderBookingDate: this.AddForm.get("booking_date").value,
        traderId: this.selecteTraderVehicle["createdFor"],
        traderuId: this.traderData["u_id"],
        inventoryId: this.inventoryId,
        inventory_id: this.inventoryId,
        traderExists: this.model == "boxmodel" ? this.traderExists : true,
        vanId: this.selecteTraderVehicle["_id"],
        // vanStatus: this.selecteTraderVehicle['vanStatus'],
        // vanId: this.selecteTraderVehicle['contactSettingId'],
        // vanType: this.model,
        // van_type: this.model,
        // createdFor: this.selecteTraderVehicle['createdFor'],
        // vanCapacity: this.selecteTraderVehicle['capacity'],
        // inventoryName: this.inventoryName,
        // traderExists: this.model == "boxmodel" ? this.traderExists : true,
        // traderLocation: this.traderData.hasOwnProperty('location') ? this.traderData['location'] : localStorage.getItem('coord'),
        // traderAddress: this.traderData.hasOwnProperty('address') ? this.traderData['address'] : localStorage.getItem('address'),
      }
    }
    else {
      orderData = {
        ...orderData,
        farmerUid: localStorage.getItem("u_id"),
        farmerUnits: localStorage.getItem("solidUnits"),
        farmerAddress: localStorage.getItem('address'),
        "farmerLocation": {
          "type": "Point",
          "coordinates": [
            localStorage.getItem("lng"),
            localStorage.getItem("lat")
          ]
        },
        price: this.AddForm.get("price").value,
        vanModel: "general",
        van_type: "general",
        orderBookingDate: this.AddForm.get("booking_date").value,
        traderId: this.traderData["traderId"],
        traderuId: this.traderData["u_id"],
        // orderStatus: this.AddForm.get("orderStatus").value,
        inventoryId: this.inventoryId,
        inventory_id: this.inventoryId,
        orderedBirds: this.AddForm.get("totalBirds").value || 0,
        roughEstimatedWeight: this.AddForm.get("birdsWeight").value,
        payment: this.AddForm.get("payment").value,
        traderExists: this.model == "boxmodel" ? this.traderExists : true,
        vanId: this.selecteTraderVehicle['contactSettingId'],
        // vanStatus: this.selecteTraderVehicle['vanStatus'],
        // vanId: this.selecteTraderVehicle['contactSettingId'],
        // vanType: this.model,
        // van_type: this.model,
        // createdFor: this.selecteTraderVehicle['createdFor'],
        // vanCapacity: this.selecteTraderVehicle['capacity'],
        // inventoryName: this.inventoryName,
        // traderExists: this.model == "boxmodel" ? this.traderExists : true,
        // traderLocation: this.traderData.hasOwnProperty('location') ? this.traderData['location'] : localStorage.getItem('coord'),
        // traderAddress: this.traderData.hasOwnProperty('address') ? this.traderData['address'] : localStorage.getItem('address'),
      }
    }
    if (addVan) {
      // if user created or edited the order.
      if (this.vanInfo) {
        //if order is edit mode
        let data = Object.assign(
          this.AddForm.value,
          {
            _id: this.vanInfo.van_id
          }
        )
        this.liftings.boxmodelEdit(data).subscribe((res) => {
          if (res.success) {
            this.modalCtrl.dismiss({
              addVan: false
            })
          }
        });
      } else {
        // if order created  newly it comes here
        const dataObj = { ...orderData, loaded: false };
        console.log(dataObj, 136);
        this.liftings.addVehicle(dataObj).subscribe((data: any) => {
          if (data.success) {
            this.modalCtrl.dismiss({
              addVan: addVan,
              formData: dataObj
            });
          }
        })
      }

    }
    // else {
    // if order is not submitted or not checked or it goes back it comes here

    // }
  }

  contactList = false;
  // tradersList(event) {
  //   if (event.target.value == "") {
  //     this.listOpen = false;
  //     this.traderContacts = [];
  //   } else {
  //     this.liftings.tradersList(event.target.value).subscribe((res) => {
  //       this.contactList = true;
  //       if (res.success) {
  //         this.traderContacts = [];
  //         this.listOpen = true;
  //         this.traderContacts = res.data;
  //         if (!res.data.length && res.hasOwnProperty('tempUserData')) {
  //           this.traderContacts = [];
  //         }
  //       }
  //     })
  //   }
  // }

  inputCheck(event) {
    let inputValue = event.target.value;
    if (inputValue.match(/^[0-9]*$/)) {
      this.validationType = 'phone';
    } else if (inputValue.match(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)) {
      this.validationType = 'email';
    }
  }
}