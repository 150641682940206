import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';
import {  OrdersortPipe } from '../pipes/ordersort.pipe'
import { TraderOrderShowPage } from './trader-order-show.page';
import { TraderCreateOrderComponent } from '../trader-create-order/trader-create-order.component';
import { TraderPriceSettingsComponent } from '../trader-price-settings/trader-price-settings.component'
import { SharedModule } from '../shared.module';
import { CommonExportsModule } from '../common-exports/common-exports.module';
import { BoxviewOrderInfoComponent } from '../boxview-order-info/boxview-order-info.component';

const routes: Routes = [
  {
    path: '',
    component: TraderOrderShowPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SharedModule,
    ReactiveFormsModule,
    CommonExportsModule,
    RouterModule.forChild(routes)
  ],
  declarations: [
    TraderOrderShowPage,
    BoxviewOrderInfoComponent,
    TraderCreateOrderComponent,TraderPriceSettingsComponent, OrdersortPipe],
  entryComponents:[TraderCreateOrderComponent,TraderPriceSettingsComponent]
})
export class TraderOrderShowPageModule {}
