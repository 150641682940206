import { Injectable } from '@angular/core';
import { config } from './helper/config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import * as io from 'socket.io-client';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SocketService {
  value = new BehaviorSubject<any>('');
  _value = this.value.asObservable();
  public socket;
  constructor(public http: HttpClient) { }

  // public initSocket = () => {
  //   this.socket = io(`${config.api}?u_id=${localStorage.getItem('u_id')}`, { autoConnect: true, reconnectionDelay: 1000, });
  //   this.socket.on('connection', (data) => {
  //     console.log(data);
  //   })
  // }

  public disconnect = () => {
    console.log("disconnect");
    if (this.socket) {
      // this.socket.emit('disconnect', { trader: "hello" });
      // this.socket.on('disconnect', { trader: "hello" } , (data) => {
      //   console.log(data);
      // });
      this.socket.disconnect();
    } else {
      console.log("no socket");
    }
    // this.socket.emit('disconnect_user', {sc: this.socket});
  }

  public sendVanId = (van) => {
    this.socket.emit('vanId', { vanData: van.driver_uid });
  }

  public disk = () => {
    return new Promise((resolve, reject) => {
      this.socket.on('disk', data => {
      })
    })
  }



  public vanLocation = () => {
    // this.socket = io(`${config.api}?u_id=${localStorage.getItem('u_id')}`, { autoConnect: true, reconnectionDelay: 1000, });
    return new Observable(observer => {
      this.socket.on('van_location', data => {
        observer.next(data);
      })
    })
  }

  public getSocket = () => {
    if (this.socket) {
      return true;
    } else {
      return false;
    }
    // return this.socket || undefined;
  }
}
