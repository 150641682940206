import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { config } from '../helper/config';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private httpRequest: HttpClient) { }
  priceNotifications(postData): Observable<any> {
    return this.httpRequest.post(config.api + "notify/getNotifications", postData)
  }

  notificationsList(data):Observable<any>{
    return this.httpRequest.post(config.api + "notification/userNotifications", data)
  }

  requestNotification(data):Observable<any>{
    return this.httpRequest.post(config.api + "notification/requestAction", data)
  }
}
